import React, { useState } from'react'
import { useHistory } from 'react-router-dom'
import { Box, Text, SimpleGrid, useDisclosure, Stack, Flex, Button, Link, Heading } from '@chakra-ui/core'

// Load assets
import Card1 from '../../images/cards/card1.png'
import Card2 from '../../images/cards/card2.png'
import Card3 from '../../images/cards/picture-2.png'
import Card4 from '../../images/cards/picture-3.png'
import Card5 from '../../images/cards/picture-4.png'
import Card6 from '../../images/cards/picture-5.png'
import Card7 from '../../images/cards/picture-6.png'
import Card8 from '../../images/cards/picture-7.png'

// Load vids
import vidone from '../../video/Scenario1.mp4'
import vidtwo from '../../video/Scenario2.mp4'
import vidthree from '../../video/Scenario3.mp4'
import vidfour from '../../video/Scenario4.mp4'
import vidfive from '../../video/Scenario5.mp4'
import vidsix from '../../video/Scenario6.mp4'
import vidseven from '../../video/Scenario7.mp4'
import videight from '../../video/Scenario8.mp4'

// Load components
import Modal from '../../components/Modal/Modal'
import Video from '../../components/Video/Video'
import Footer from '../../components/Footer/Footer'
import Resources from '../Resources/Resources'
import Drawer from '../../components/Drawer/Drawer'

// Load assets
import SmPC from '../../pdf/SmPC.pdf'
import PIL from '../../pdf/PIL.pdf'
import {ReactComponent as Logo } from '../../images/Hana_Logo_White.svg'

// Load layout
import Layout from '../../layout/Layout.card'
import LayoutBase from '../../layout/Layout.base'

// Load style
import { headingText, secondaryText } from '../../style/styleFormat'

const SideNav = ({open, setOpen}) => {
    const history = useHistory()
   return ( 
    <Stack spacing={7} align="center" paddingBottom={{base: "30px", lg: "0"}}>
        <Box display={{base: "none", lg: "block"}} mt="25px" width="35%">
            <Logo onClick={() => history.push('/')} height="100%" width="100%" />
        </Box>
        <Box width="100%" position="relative">
            <Drawer show={open} close={setOpen}>
                {open &&<Resources close={setOpen}  />}
            </Drawer>
        </Box>
        <Box width="90%">
            <Text fontSize="sm" color="purple.300" lineHeight="wide">
                For full information about Hana, including all contraindications, warnings and possible side effects,
                please click on the links to access the Summary of Product Characteristics (SmPC) and the Patient Information Leaflet (PIL).
            </Text>
        </Box>
        <Box width={{base:"80%", lg: "90%"}}>
            <Flex align="center" justify="space-between" alignItems="space-between" mb=".5rem">
                <Button width="7.5rem" backgroundColor="purple.800" color="white" variant="solid">
                    <Link display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" href={SmPC} target="_blank" rel="noopener noreferrer">SmPC</Link>
                </Button>
                <Button width="7.5rem" backgroundColor="purple.800" color="white" borderRadius="4px" variant="solid">
                    <Link display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" href={PIL} target="_blank" rel="noopener noreferrer">PIL</Link>
                </Button>
            </Flex>
                <Button onClick={setOpen} backgroundColor="purple.800" color="white" variant="solid" width="full">Resources</Button>
        </Box>
    </Stack>
    )
}



const VideoCard = ({ img, body, click }) => {

    
    return (
        img ? 
        <Box backgroundImage={`url(${img})`} backgroundPosition="center" minHeight="205px"  backgroundSize="cover" backgroundRepeat="no-repeat" height="100%" width="100%" cursor="pointer" onClick={click}>
            <Box width="100%" height="100%" padding="4" display="flex" alignItems="center" justifyContent="center" >
                <Text fontSize="14px" color="#fff" fontFamily="Gotham Bold"  fontWeight="bold" textAlign="center">
                    {body}
                </Text>
            </Box>
        </Box>
        :
        <Box backgroundColor="purple.200"  backgroundPosition="center" minHeight="205px"  backgroundSize="cover" backgroundRepeat="no-repeat" height="100%" width="100%" cursor="pointer" onClick={click}>
            <Box width="100%" height="100%" padding="4" display="flex" alignItems="center" justifyContent="center" >
                <Text fontSize="14px" color="#fff" fontFamily="Gotham Bold"  fontWeight="bold" textAlign="center">
                    {body}
                </Text>
            </Box>
        </Box>
    )
}

const AssessingSuitability = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [activeVid, setVid] = useState(false)

    const [drawerOpen, setDrawer] = useState(false)

    function openModal(content) {
        setVid(content)
        onOpen()
    }
    return (
        <LayoutBase sideNav={<SideNav open={drawerOpen} setOpen={() => setDrawer(!drawerOpen)} />}>
            <Box height="100%" padding="8" display="flex" flexDirection="column" justifyContent="space-between" alignItems="space-between">
            <Layout>
                <Heading {...headingText} marginBottom="20px">Animations</Heading>
                <Text {...secondaryText} marginBottom="20px" fontWeight="bold">
                    Click on the links below to watch short animated videos showing pharmacy consultations with women wanting to purchase Hana
                </Text>
                <SimpleGrid minChildWidth="243px" spacing="10px">
                    <VideoCard img={Card1} click={() => openModal({vid: vidone, thumb: Card1})} body="A woman who has never used any form of contraception" />
                    <VideoCard img={Card2} click={() => openModal({vid: vidtwo, thumb: Card2})} body="A woman switching from combined oral contraception" />
                    <VideoCard img={Card3} click={() => openModal({vid: vidthree, thumb: Card3})} body="An adolescent woman who has never used any form of contraception" />
                    <VideoCard img={Card4} click={() => openModal({vid: vidfour, thumb: Card4})} body="A woman who has previously purchased Hana from a pharmacy"/>
                    <VideoCard img={Card5} click={() => openModal({vid: vidfive, thumb: Card5})} body="A woman who has previously been prescribed desogestrel by her doctor" />
                    <VideoCard img={Card6} click={() => openModal({vid: vidsix, thumb: Card6})} body="A woman who has previously purchased Hana from a pharmacy and has experienced bleeding after sex" />
                    <VideoCard img={Card7} click={() => openModal({vid: vidseven, thumb: Card7})} body="A woman who has never used Hana before and who has a history of hepatitis" />
                    <VideoCard img={Card8} click={() => openModal({vid: videight, thumb: Card8})} body="A woman who has previously purchased Hana from a pharmacy and has experienced heavy bleeding" />
                </SimpleGrid>

                <Modal isOpen={isOpen} onClose={onClose} size="lg-custom" title="Video">
                    <Video
                        vidSrc={activeVid.vid}
                        imgThumb={activeVid.thumb}
                    />
                </Modal>
            </Layout>
            <Footer productCode="0003" />
            </Box>
        </LayoutBase>
        
    )
}

export default AssessingSuitability