import React from 'react'
import { VisuallyHidden, ControlBox, Box, Icon } from '@chakra-ui/core'
import {ReactComponent as Cancel} from '../../../images/cancel-2.svg'
import {ReactComponent as Correct} from '../../../images/tick-2.svg'
import {ReactComponent as CorrectAmber} from '../../../images/tick-2-amber.svg'


const Checkbox = ({ label, id, name, on_change, checked, success, fail, amber, reveal, ...props }) => {
    const bkColor = reveal ? 'transparent' : "#9894C6"
    return (
        <label style={{maxHeight: "32px"}}>
            {label && <Box maxHeight="32px" {...props} mr={3}>{label}</Box>}
            <VisuallyHidden as="input" type="checkbox" id={id} checked={checked} name={name} disabled={reveal} onChange={on_change} />
            <ControlBox
                id={id}
                name={name}
                borderWidth="1px"
                size="32px"
                maxHeight="32px"
                rounded="100%"
                cursor="pointer"
                _focus={{ borderColor: "#9894C6", boxShadow: "outline" }}
                _checked={{ borderColor: "transparent", background: bkColor }}
                borderColor="#9894C6"
            >
                {(!reveal && checked) && <Icon name="check" size="16px" color="#fff"/>}
                {(reveal && success && checked) && <Correct width="100%" />}
                {(reveal && amber) && <CorrectAmber color="" width="100%" />}
                {(reveal && fail && checked) && <Cancel width="100%" />}
            </ControlBox>
        </label>
    )
}

export default Checkbox
