import React from 'react'
import { Box, Text, Heading, useDisclosure, ListItem, List, Stack } from '@chakra-ui/core'

// Load components
import Reference from '../../components/UI/Typography/Reference'
import Modal from '../../components/Modal/Modal'
import {ReactComponent as Info} from '../../images/infoAlt.svg'

// Load style
import { normalText, referenceText, referenceLink, bkLightRed, headingTextbkRnd, purpleBullet } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const headingText = {
    fontSize: 'lg',
    lineHeight: 'tight',
    color: 'purple.100'
}

const Interactions = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Stack spacing="6">
                <Text as="h3" {...headingText} fontWeight="300">
                    Use of Hana with emergency contraception
                </Text>
                <Heading {...headingTextbkRnd} width={{base: "auto", lg:"max-content"}}>
                    Women starting or resuming <span style={{position: 'relative'}}>Hana<Reference>1</Reference></span>
                </Heading>
                <Text {...normalText}>
                    If a woman wishes to start Hana after using emergency contraception, it is advisable to <Text as="span" color="#755FBC" fontWeight="bold" fontFamily="Gotham Bold">start tablet-taking on day 1 of the woman’s natural cycle.</Text>
                </Text>

                <Text {...normalText}>
                    If it is considered necessary to start sooner (referred to as quick starting), or if Hana is being resumed after inconsistent use, the following advice should be noted:
                </Text>

                <List spacing="4" marginLeft="20px">
                    <ListItem 
                    {...normalText}
                    paddingLeft="20px"
                    position="relative"
                    display="block"
                    _before={{
                      ...purpleBullet,
                      top: "2px"
                        }}
                    >
                        levonorgestrel – she can <Text as="span" color="#755FBC" fontWeight="bold" fontFamily="Gotham Bold">start or restart Hana that same day and should use additional contraceptive measures (abstinence or barrier methods) for the first 7 days</Text> of Hana use
                    </ListItem>
                    <ListItem 
                    {...normalText}
                    paddingLeft="20px"
                    position="relative"
                    display="block"
                    _before={{
                        ...purpleBullet,
                        top: "2px"
                        }}
                    >
                    ulipristal acetate* – she should <Text as="span" color="#755FBC" fontWeight="bold" fontFamily="Gotham Bold">wait 5 days (120 hours) after taking ulipristal acetate before starting or restarting Hana. She should also use additional contraceptive measures (abstinence or barrier methods) during the 5 days of waiting to start or restart Hana, and for an additional 7 after starting or restarting Hana (i.e. 12 days in total)</Text>
                    </ListItem>
                </List>

                <Text {...normalText} color="#515456">
                    Additional information on quick starting is provided in Part 2. However, it should be noted that quick starting is outside the approved use of Hana and its SmPC.
                </Text>
                
                <Text {...normalText} fontSize="sm">
                    * Emergency contraception containing ulipristal acetate and Hana both bind to the progesterone receptor<span style={{position: "relative"}}>.<Reference>1</Reference></span>&nbsp; Concomitant use may result in reduced efficacy of both Hana and ulipristal and is therefore not recommended<span style={{position: "relative"}}>.<Reference>1</Reference></span>
                </Text>

                <Box {...bkLightRed} padding="8" position="relative">
                    <Box position="absolute" top="-6px" left="-10px">
                    <Info name="info" borderRadius="50%" border="2px solid #fff" backgroundColor="#fff" color="#F0CED5" size="40px" />
                    </Box>
                    <Text {...normalText}>
                        Please read the SmPC for full details of contraindications, warnings and precautions. Remind every woman to read the PIL carefully before taking Hana.
                    </Text>
                </Box>

                <Text {...referenceLink} as="u" onClick={onOpen}>
                    References
                </Text>
            </Stack>

            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" spacing="4" styleType="decimal">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default Interactions