import React from 'react'
import { Box, Text, List, ListItem, useDisclosure } from '@chakra-ui/core'

// Load assets
import {ReactComponent as WomanAtom } from '../../images/womanAtom.svg'
import {ReactComponent as PregTest } from '../../images/pregTest.svg'

// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceLink, referenceText, blueBullet } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const ExcludingPregnancy = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Box borderTop="2px solid #4580AB" flexDirection={{base: "column", lg: "row"}} display="flex">
                <Box borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"35%"}} height="100%">
                    <Box backgroundColor="#E3ECF3" borderBottom="1px solid #739FBF" padding="2" height="86.5px">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Women who have not recently been pregnant
                        </Text>
                    </Box>
                    <Text {...secondaryText} padding="2" >
                        Pregnancy can reasonably be excluded if the <span style={{position: 'relative'}}>woman: <Reference>1</Reference></span>
                    </Text>
                    <List spacing="4" padding="2">
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="20px"
                        position="relative"
                        _before={{
                           ...blueBullet
                            }}>
                            has not had unprotected sex since the start of her last menstrual period
                        </ListItem>
                        <Text {...secondaryText} marginTop="-10px" display="block" textAlign="center">or</Text>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="20px"
                        position="relative"
                        _before={{
                           ...blueBullet
                            }}>
                            has been correctly and consistently using a reliable method of contraception (including condoms where those have been used correctly for every episode of sexual intercourse)
                        </ListItem>
                        <Text {...secondaryText}  marginTop="-10px" display="block" textAlign="center">or</Text>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="20px"
                        position="relative"
                        _before={{
                            ...blueBullet
                            }}>
                            has had unprotected sex but only between day 1 and day 5 of her menstrual cycle
                        </ListItem>
                    </List>
                </Box>
                <Box width={{base: "100%", lg:"75%"}} overflow="hidden">
                    <Box backgroundColor="#E3ECF3" borderTop={{base: "2px solid #4580AB", lg:"none"}} borderBottom="1px solid #739FBF" textAlign="center">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" textAlign="center">
                            Women who have recently been pregnant
                        </Text>
                    </Box>
                    <Box display="flex" flexDirection={{base: "column", lg: "row"}} height="100%">
                        <Box borderRight={{base: "none", lg:"1px solid #739FBF"}} width="100%" height="100%">
                            <Box backgroundColor="#F5F9FB" height="63.5px" padding="2" borderBottom="1px solid #739FBF">
                                <Text {...secondaryText} >
                                    Following childbirth
                                </Text>
                            </Box>
                           <Text {...secondaryText} padding="2" margin="0 5px">
                            Pregnancy can reasonably be excluded if the <span style={{position: 'relative'}}>woman:<Reference>1</Reference></span>
                           </Text>
                           <List spacing="4" padding="2" margin="0 10px">
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="20px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    has not had unprotected sex since childbirth
                                </ListItem>
                                <Text {...secondaryText}  marginTop="-10px" display="block" textAlign="center">or</Text>
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="20px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    has had unprotected sex but less than 21 days after childbirth
                                </ListItem>
                                <Text {...secondaryText}  marginTop="-10px" display="block" textAlign="center">or</Text>
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="20px"
                                position="relative"
                                _before={{
                                  ...blueBullet
                                    }}>
                                    is fully breastfeeding, amenorrhoeic and less than 6 months postpartum
                                </ListItem>
                           </List>
                        </Box>
                        <Box width="100%" height="100%">
                            <Box backgroundColor="#F5F9FB" height="63.5px" borderBottom="1px solid #739FBF" borderTop={{base: "1px solid #739FBF", lg:"none"}}>
                                <Text {...secondaryText} padding="2">
                                    Following miscarriage, abortion or ectopic pregnancy
                                </Text>
                            </Box>
                           <Text {...secondaryText} padding="2" margin="0 5px">
                            Pregnancy can reasonably be excluded if the <span style={{position: 'relative'}}>woman:<Reference>1</Reference></span>
                           </Text>
                           <List spacing="4" padding="2" margin="0 10px">
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="20px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    has not had unprotected sex since miscarriage, abortion or ectopic pregnancy
                                </ListItem>
                                <Text {...secondaryText}  marginTop="-10px" display="block" textAlign="center">or</Text>
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="20px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    has had unprotected sex but less than 5 days after miscarriage, abortion or ectopic pregnancy
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box margin="20px 0">
                <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center">
                    <WomanAtom />
                    <Text {...secondaryText} marginLeft="10px">
                        The woman should use a reliable form of contraception (current contraceptive or a barrier method) until day 1 of her next menstrual period before starting <span style={{position: 'relative'}}>Hana.<Reference>1</Reference></span>
                    </Text>
                </Box>
                <Box display="flex" flexDirection={{base: "column", lg: "row"}}  alignItems={{base: "center", lg:"flex-start"}} marginTop="20px">
                    <PregTest />
                    <Box marginLeft="20px">
                        <Text {...secondaryText} marginBottom="20px">
                            She should be advised that if her period is already late or does not come when she expects it, she may be pregnant and she should do a pregnancy test (at least 3 weeks after the last episode of unprotected sexual intercourse) or see her <span style={{position: 'relative'}}>doctor<Reference>1</Reference></span>
                        </Text>
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="20px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                Provided the pregnancy test is negative, she can start Hana on day 1 of her next <span style={{position: 'relative'}}>period<Reference>1</Reference></span>
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="20px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                If her period comes in the meantime, she can start Hana on day 1 of that <span style={{position: 'relative'}}>period<Reference>1</Reference></span>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Box>
            <Text {...referenceLink} color="blue.50" as="u" fontWeight="bold" cursor="pointer" onClick={onOpen}>References</Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default ExcludingPregnancy