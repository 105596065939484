import React from 'react'
import { Box, Text, Stack } from '@chakra-ui/core'

// Load style
import { normalText } from '../../style/styleFormat'

const MoreInfo17 = () => {
    return (
        <Box borderTop="2px solid #393767" display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
            <Box width="100%">
                <Box padding="10px 20px" borderBottom="1px solid #393767" background="#EAE9FF" marginBottom="20px">
                    <Text {...normalText} fontWeight="bold" fontFamily="Gotham Bold" color="purple.900">REMEMBER</Text>
                </Box>
                
                <Stack spacing="4">
                    <Text {...normalText}>
                        <Text as="span" fontFamily="Gotham Bold">Hana is not suitable for women who:</Text> have breast cancer or other known or suspected sex-steroid sensitive cancers, have severe liver disorders that have not resolved (blood tests show the liver function values have not returned to normal), have active venous or pulmonary thrombosis, have unexplained vaginal bleeding between their periods or repeatedly have bleeding after sex (women experiencing this type of bleeding should be referred to their doctor), are allergic to desogestrel or to any of the other ingredients in Hana, are pregnant.
                    </Text>

                    <Text {...normalText}>
                        <Text as="span" fontFamily="Gotham Bold">Hana can be taken:</Text> after birth or termination of a pregnancy, when breastfeeding, after use of emergency contraception and in all women who are still fertile (at risk of pregnancy).
                    </Text>
                    <Text {...normalText}>
                        <Text as="span" fontFamily="Gotham Bold">Women should see their doctor to assess suitability for Hana before being provided with it if they have:</Text> a history of breast, ovarian or uterine cancer, current liver cancer or other liver disorders, Type 1 or Type 2 diabetes.
                    </Text>

                    <Text {...normalText} fontFamily="Gotham Bold">
                        Women should be referred to their doctor for further advice if using enzyme inducers, such as the medicines used to treat epilepsy, chronically or long term.
                    </Text>
                    <Text {...normalText} fontFamily="Gotham Bold">
                        Women with a history of venous thrombosis or pulmonary embolism should be counselled about the possibility of a recurrence.
                    </Text>
                </Stack>
              
            </Box>
        </Box>
    )
}

export default MoreInfo17