import React from 'react'
import { Box, Text, Heading, ListItem, List, useDisclosure } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import Reference from '../../components/UI/Typography/Reference'
import {ReactComponent as Info} from '../../images/infoAlt.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, bkLightRed, blueBullet, referenceLink } from '../../style/styleFormat'

const AdviceTakingHanaThree = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Heading {...secondaryText} display="block" fontSize="lg">
                How to take Hana
            </Heading>
            <Text {...secondaryText} fontFamily="Gotham Bold" margin="20px 0" fontWeight="bold">
                How to take Hana after emergency contraception containing:
            </Text>
            <List spacing="4" marginBottom="20px">
                <ListItem 
                {...secondaryText}
                paddingLeft="25px"
                position="relative"
                display="block"
                _before={{
                   ...blueBullet
                    }}>
                    levonorgestrel (synthetic progestogen)
                </ListItem>
                <ListItem 
                {...secondaryText}
                paddingLeft="25px"
                position="relative"
                _before={{
                    ...blueBullet
                    }}>
                    ulipristal acetate (selective progesterone receptor modulator)
                </ListItem>
            </List>

            <Box margin="20px 0" borderTop={{base: "1px solid #4580AB", lg:"2px solid #4580AB"}} display="flex" flexDirection="column">
                <Box display="flex" flexDirection={{base:"column", lg: "row"}} borderBottom="1px solid #739FBF" width="100%">
                    <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"20%"}}>
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            If the woman is starting Hana
                        </Text>
                    </Box>
                    <Box padding="4" width={{base: "100%", lg:"80%"}}> 
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                                ...blueBullet
                                }}>
                                wait until day 1 of her next period to start Hana; she should continue to use other precautions until this <span style={{position: 'relative'}}>time<Reference>1</Reference></span>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Box display="flex" flexDirection={{base:"column", lg: "row"}} borderBottom={{base: "none", lg:"1px solid #739FBF"}} width="100%">
                    <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"20%"}}>
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            If quick starting or woman has taken emergency contraception due to missed Hana tablet(s)
                        </Text>
                    </Box>
                    <Box padding="4" width={{base: "100%", lg:"80%"}}> 
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Levonorgestrel:
                        </Text>
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                                ...blueBullet
                                }}>
                                take or resume Hana that same day and use additional contraceptive measures (abstinence or barrier methods) for the first 7 days of Hana <span style={{position: 'relative'}}>use<Reference>1</Reference></span>
                            </ListItem>
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                Ulipristal acetate:
                            </Text>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                wait 5 days after taking ulipristal acetate before starting or restarting Hana.  Use additional contraceptive measures (abstinence or barrier methods) during the 5 days of waiting and for the first 7 days of Hana use (i.e. 12 days in total<span style={{position: 'relative'}}>)<Reference>1</Reference></span>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Box>

            <Box {...bkLightRed} padding="8" margin="20px 0" maxWidth={{base:"100%", lg:"70%"}} position="relative">
                <Box position="absolute" top="-6px" left="-10px">
                    <Info name="info" borderRadius="50%" border="2px solid #fff" backgroundColor="#fff" color="#F0CED5" size="40px" />
                </Box>
                <Text {...secondaryText} fontWeight="bold" fontFamily="Gotham Bold">
                    Remind every woman to read the PIL carefully before taking Hana.
                </Text>
            </Box>

            <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default AdviceTakingHanaThree