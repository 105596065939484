import React from 'react'
import { Box, Heading, PseudoBox, Flex, Text, Divider } from '@chakra-ui/core'

// Load assetss
import {ReactComponent as  SVG} from '../../images/part2.svg'

const textStyle = {
    fontSize: "md",
    lineHeight: "tighter",
    display: "block",
    color: "#fff",
}

const LearningOutcome = ({number, content, borderStyle }) => {

    return (
        <Box display="flex" justify="space-between" alignItems="center" marginBottom="20px">
            <Heading minWidth="25px" as="h2" fontSize="xlg" color="#fff" lineHeight="widest" marginRight="5">
                {number}
            </Heading>
            <Box width="100%">
                <PseudoBox 
                as="text" {...textStyle} 
                position="relative"
                padding="10px 0"
                _after={borderStyle && {
                    content: "''",
                    position: 'absolute',
                    width: "100%",
                    bottom: '-15px',
                    left: '0',
                    border: "1px dashed #fff"
                }}
                >
                    {content}
                </PseudoBox>
            </Box>
        </Box>
    )
}

const LearningOutComesPt1 = () => {
    return (
        <Flex align="center" direction="column">
            <SVG width="190px" height="190px" />
    
            <Box width={{base: "100%", lg:"80%"}} margin="0 auto">
                <Heading textAlign="center" textTransform="uppercase" fontSize="xlg" color="#fff" lineHeight="widest" margin="25px 0"> 
                    Learning outcomes at a glance
                </Heading>

                <LearningOutcome
                number={1}
                content={<Text {...textStyle} color="#fff">Identify whether a woman seeking regular oral contraception is suitable or unsuitable for Hana
                and know what advice to give to women in different situations:</Text>}
                />

                <Box display="flex" margin="20px 0" >
                    <Box borderRight="1px solid #fff" padding="0 10px" >
                        <Text {...textStyle} fontWeight="bold" textTransform="uppercase" marginBottom="20px">
                            New or historical user of desogestrel
                        </Text>
                        <Text {...textStyle}>
                            First-time supply of Hana to women who have not used desogestrel before, or supply of Hana to women who have not used it for several months
                        </Text>
                    </Box>
                    <Box padding="0 10px">
                        <Text {...textStyle} fontWeight="bold" textTransform="uppercase" marginBottom="20px">
                            Current or recent user of desogestrel
                        </Text>
                       
                       <Box display="flex" justifyContent="space-between">
                        <Text {...textStyle}>
                            Repeat supply of Hana to women who have purchased Hana from a pharmacy
                        </Text>
                        <Divider orientation="vertical" opacity="1" color="#fff" />
                        <Text {...textStyle}>
                            Changing supply of desogestrel from doctor to pharmacy: women who have been prescribed desogestrel and now want to purchase Hana without
                            prescription
                        </Text>
                       </Box>
                    </Box>
                </Box>

                <LearningOutcome
               
                borderStyle
                />
                
                <LearningOutcome
                number={2}
                borderStyle
                content={<Text {...textStyle} color="#fff">Know when to refer the woman to her doctor</Text>}
                />
                <LearningOutcome
                number={3}
                content={<Text {...textStyle} color="#fff">Know the key information that should be communicated to women purchasing Hana in each of
                these different situations</Text>}
                />
            </Box>          
        </Flex>
    )
}

export default LearningOutComesPt1