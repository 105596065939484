import { useState } from 'react'

function useInputHook(setState, single=false, trueFalse=false) {
    const [form, setValue] = useState(setState)
    const [allowNext, setAllow] = useState(false)
   
    function inputCheckHandler(event) {
        const target = event.target
        const value = target.checked
        const name = target.name
      
        let formCopy = {...form}
        let formElement = {...form[name]}
       
        // Only allow one box checked
        if (single) {
            for (let el in formCopy) {
                formCopy[el].value = false
            }
        }

        // Make sure only True or false is selected
        if (trueFalse) {
            const falseAnswer = name + 'B'
            const trueAnwser = name.replace('B', '')
            
            // False always appends B
            if (name.includes('B')) {
                form[trueAnwser].value = false
            }

            // Flip the opposite back to false
            if (name === trueAnwser) {
                form[falseAnswer].value = false
            }
        }
      
        formElement.value = value
        formElement.validation.valid = checkValidation(formElement.value, formElement.validation)
        formElement.validation.touched = true
        formCopy[name] = formElement

        // Check if all answer removed do not let proceed
        if (allowNext && !value) {
            let isValid = false
            
            // Loop over and check if we have a true value still
            for (let item in formCopy) {
                if (formCopy[item].value) {
                    isValid = true
                }
            }
            setAllow(isValid)
        }

        if (!allowNext) {
             // Set progress allowed
            setAllow(formCopy[name].value)
        }

        // Seperate validation to make sure all answered
        if (trueFalse) {
            let isValid = true

            for (let item in formCopy) {

                // Ignore going over the false fields seperatley
                if (!item.includes('B')) {
                    // If true is not equal to false we have a selection
                    isValid = formCopy[item].value !== formCopy[item + 'B'].value && isValid
                }
            }
            setAllow(isValid)
        }
        
       

        return setValue(formCopy)
    }

    function checkCorrect() {
        let isCorrect = true

            for (let element in form) {

                // Check we got a correct answer
                if (form[element].status === 'CORRECT') {
                    isCorrect = form[element].value && isCorrect
                }

                // Check no incorrect answer was picked
                if (form[element].status === 'INCORRECT') {
                    isCorrect = form[element].value === false && isCorrect
                }
            }
        return isCorrect
    }

    return [form, inputCheckHandler, allowNext, checkCorrect]
}

export default useInputHook

function checkValidation(value, validation) {
    let isValid = true
    const { required, rules } = validation

    if (required) {
        isValid = value.length.trim('') > 0 && isValid
    }

    if (rules.anwser) {
        isValid = value !== false && isValid
    }

    if (rules.correct) {
        return {
            correct: value !== false,
            wrong: false
        }
    }

    if (rules.wrong) {
        return {
            correct: false,
            wrong: value === true
        }
    }


    return isValid
}
