import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

/**
 * CASE ONE REVEAL
 */
export const CaseOneYesNo = () => <Text {...normalText}>
This woman <Text as="span" {...boldGreen}>can be supplied</Text> with Hana, 3 months for a first-time supply. Pregnancy has been excluded, she has no contraindications and she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana. There is <Text as="span" {...boldGreen}>nothing to indicate that she should be referred</Text> to her doctor. This woman should wait until the first day of her next period to start Hana. 
</Text>

export const CaseOneYesYes = () => <Text {...normalText}>
This woman <Text as="span" {...boldRed}>can be supplied</Text> with Hana, 3 months for a first time supply. Pregnancy has been excluded, she has no contraindications and she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana. There is nothing to indicate that she should be referred to her doctor. This woman should wait until the first day of her next period to start Hana. She does <Text as="span" {...boldRed}>not need to be referred</Text> to her doctor.
</Text>

export const CaseOneNoYes = () => <Text {...normalText}>
This woman <Text as="span" {...boldRed}>can be supplied</Text> with Hana, 3 months for a first time supply. Pregnancy has been excluded, she has no contraindications and she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana. There is nothing to indicate that she should be referred to her doctor. This woman should wait until the first day of her next period to start Hana. She does <Text as="span" {...boldRed}>not need to be referred</Text> to her doctor.
</Text>

export const CaseOneNoNo = () => <Text {...normalText}>
This woman <Text as="span" {...boldRed}>can be supplied</Text> with Hana, 3 months for a first time supply. Pregnancy has been excluded, she has no contraindications and she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana. There is <Text as="span" {...boldRed}>nothing to indicate that she should be referred</Text> to her doctor. This woman should wait until the first day of her next period to start Hana.
</Text>




