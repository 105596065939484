import React from 'react'
import { Box, Text, List, ListItem, useDisclosure, Stack } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, blueBullet, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const RepeatSupply = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Stack spacing="4">
                <Text {...secondaryText}>
                    This includes women who are using desogestrel and have purchased Hana from a pharmacy or have been prescribed desogestrel.
                </Text>
                <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                    If you establish that the woman is satisfied with and tolerating Hana, you can provide her with a maximum 12-month supply, unless she is under 18 years of age in which case it is a maximum 3-month supply. Encourage the woman to advise her doctor that she has been supplied with Hana in pharmacy if she is changing supply, or if she has not previously advised her doctor in the case of repeat supply.
                </Text>

                <Box borderTop="1px solid #4580AB" flexDirection={{base: "column", lg: "row"}} display="flex">
                    <Box width="100%" position="relative">
                        <Box backgroundColor="#E3ECF3" padding="4">
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                Pharmacy supply aid reminder
                            </Text>
                        </Box>
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" marginTop="30px"> 
                        Check 1 tablet has been taken at the same time
                        every day, no break between packs and no
                        missed doses
                        </Text>
                    </Box>
                    <Box width="100%"  borderTop={{base: "1px solid #4580AB", lg: "none"}} borderLeft={{base: "none", lg:"1px solid #739FBF"}}>
                        <Box backgroundColor="#E3ECF3" padding="4">
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                Guidance on pharmacist action
                            </Text>
                        </Box>
                        <Box padding="4">
                            <Text {...secondaryText} margin="10px 0">
                                <Text as="span" fontFamily="Gotham Bold">Hana is not suitable for women who are <span style={{position: 'relative'}}>pregnant;<Reference>1</Reference></span></Text>&nbsp; guidance on excluding pregnancy in women who are currently using desogestrel is provided in the next section.
                            </Text>
                            <List spacing="4">
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                ...blueBullet
                                    }}>
                                    If a woman is not pregnant, she should be supplied with Hana and reminded to start the next pack without any breaks
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                </Box>
                <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
                    References
                </Text>
                <Modal isOpen={isOpen} onClose={onClose} title="References">
                    <List as="ol" styleType="decimal" spacing="4">
                        <ListItem {...referenceText}>
                            Hana (desogestrel) Summary of Product Characteristics, November 2020.
                        </ListItem>
                    </List>
                </Modal>
            </Stack>
        </Layout>
    )
}

export default RepeatSupply