import React from 'react'
import { Box, Text, Link } from '@chakra-ui/core'

// Load hook
import useWindowSize from '../../hooks/useWindowSize'

// Load pdf
import guide from '../../pdf/Hana_Pharmacy_Guide.pdf'

// Load style
import { normalText, secondaryText} from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const PartTwoComplete = ({ openResources }) => {
    const size = useWindowSize()
    const display = size.width > 900 ? 'desktop' : 'mobile'
    return (
        <Layout>
            <Text {...secondaryText}>
                You have completed Part 2!
            </Text>
            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center" margin="20px 0">
                <Link href={guide} borderRadius="4px" display="flex" alignItems="center" target="_blank" rel="noopener noreferrer" download marginBottom={{base: "20px", lg: "0"}} width={{base: "100%", lg: "50%" }} backgroundColor="orange.900" height="78px" padding="20px" marginRight={{base: "0", lg:"10px"}}>
                    <Text {...normalText} color="#fff" fontWeight="bold">
                        Click here to download a printable version of the guide
                    </Text>
                </Link>
                <Box onClick={() => openResources(display)} backgroundColor="orange.900" display="flex" width={{base: "100%", lg: "50%" }} alignItems="center" height="78px" padding="20px" borderRadius="4px">
                    <Text {...normalText} cursor="pointer" color="#fff" fontWeight="bold">
                        Click here for a list of other useful downloadable documents and resources
                    </Text>
                </Box>
            
            </Box>
            <Text {...secondaryText}>
                Continue to Part 3, which demonstrates how consultations for different supply situations could work in practice using a series of animated videos.    
            </Text>
        </Layout>
    )
}

export default PartTwoComplete