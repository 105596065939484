import { useEffect, useState } from 'react'

function useWindowSize() {
    const [windowSize, setWindowSize] = useState(getSize)
        
    function getSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    useEffect(() => {

        function handleResize() {
            setWindowSize(getSize())
        }

        // attach once onmount
        window.addEventListener('resize', handleResize)

        // Clean up and remove on unmount
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowSize
}

export default useWindowSize