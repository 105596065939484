import React from 'react'
import { Box, Text, List, ListItem, Link, Image, Button } from '@chakra-ui/core'

// Load assets
import HanaChecklist from '../../images/hanaChecklist.png'

// Load pdf
import pharmacyCheckList from '../../pdf/Pharmacy_supply_aid_checklist.pdf'
import algorithm from '../../pdf/Pharmacy_supply_algorithm.pdf'



// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { blueBullet, secondaryText } from '../../style/styleFormat'

const SupplySituationTwo = () => {
    
    return (
        <Layout>
            <Text {...secondaryText} fontWeight="bold">
                To guide you in your consultations with women requesting Hana in pharmacy, a Pharmacy supply aid checklist has been developed for you to have within easy reach. This consists of:
            </Text>
            <Box display="flex" flexDirection={{base: "column", lg: "row"}} margin="20px 0">
                <Image src={HanaChecklist} alt="Hana Checklist illustration" objectFit="contain" maxHeight="400px" maxWidth={{base: "100%", lg:"50%"}} />
                <Box padding={{base: "10px 0", lg:"0 20px"}} width={{base: "100%", lg:"50%"}}>
                    <List spacing="4">
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="20px"
                        position="relative"
                        _before={{
                           ...blueBullet
                            }}>
                            a short checklist for women who have never used desogestrel before or who have not used it for several months to complete
                        </ListItem>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="20px"
                        position="relative"
                        _before={{
                           ...blueBullet
                            }}>
                            a useful pharmacy supply aid that matches up with the checklist detailing additional questions to ask and responses to give; this supply aid also includes guidance for consultations with women who are currently using or have recently used desogestrel
                        </ListItem>
                    </List>
                        <Text {...secondaryText} margin="20px 0">
                        Printed copies of the Pharmacy supply aid checklist have been distributed to all pharmacies. Use of this material is optional, and you are advised to use your professional judgement to decide when and how to use it.
                    </Text>
                    <Button fontWeight="bold" fontSize="sm" backgroundColor="#3E7DAA" whiteSpace="initial" color="#fff" padding="8" height="auto" >
                        <Link href={pharmacyCheckList} target="_blank" rel="noopener noreferrer" download>Click here to download the Pharmacy supply aid checklist</Link>
                    </Button>
                </Box>
            </Box>
            <Text {...secondaryText} margin="10px 0">
                A useful algorithm that further clarifies the questions and responses for the different supply situations has also been developed.
            </Text>
            <Text {...secondaryText} margin="10px 0">
                <Link href={algorithm} target="_blank" rel="noopener noreferrer" download  fontWeight="bold" fontFamily="Gotham Bold" textDecoration="underline">Click here</Link> to view or download this optional material.
            </Text>
            <Text {...secondaryText}>
                The information on the following screens is intended to support your use of the Pharmacy supply aid checklist in pharmacy; it ties together the questions women will be asked on the checklist with pharmacist responses as outlined in the pharmacy supply aid, providing additional rationale and guidance.
            </Text>
        </Layout>
    )
}

export default SupplySituationTwo