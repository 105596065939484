import React from 'react'
import { Box } from '@chakra-ui/core'

const LayoutBase = ({ sideNav, children}) => {
    return (
        <Box display="flex" width="100%" minHeight="100vh">
            <Box display={{base: "none", lg: "block"}} backgroundColor="purple.900" width="sm" position="relative" maxWidth="320px" minWidth="320px">
                {sideNav}
            </Box>
            <Box width="full">
                {children}
            </Box>
        </Box>
    )
}

export default LayoutBase