import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

export const CaseElevenYesNo = () => <Text {...normalText}>
This woman <Text as="span" {...boldGreen}>can be provided</Text> with Hana, 3 months for a supply to those under 18 years of age. The woman appears to fulfil the conditions of the Fraser guidelines, in that she will understand the advice and what is involved; cannot be persuaded to inform her parents; is very likely to begin or continue to have sexual intercourse without contraception; her physical or mental health (or both) will suffer without contraceptive advice or treatment; and her best interests require her to receive contraceptive advice or treatment with or without parental consent. <br/><br/>Pregnancy has been excluded, she has no contraindications, she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana and <Text as="span" {...boldGreen}>nothing indicates that she should be referred to her doctor</Text>. <br/><br/>This woman should wait until the first day of her next period to start Hana. She should be encouraged to inform a parent about being on contraception.
</Text>

export const CaseElevenYesYes = () => <Text {...normalText}>
This woman <Text as="span" {...boldRed}>can be provided</Text> with Hana, 3 months for a supply to those under 18 years of age. The woman appears to fulfil the conditions of the Fraser guidelines, in that she will understand the advice and what is involved; cannot be persuaded to inform her parents; is very likely to begin or continue to have sexual intercourse without contraception; her physical or mental health (or both) will suffer without contraceptive advice or treatment; and her best interests require her to receive contraceptive advice or treatment with or without parental consent. <br/><br/>Pregnancy has been excluded, she has no contraindications, she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana and nothing indicates that she should be referred to her doctor. <br/><br/>This woman should wait until the first day of her next period to start Hana. She does <Text as="span" {...boldRed}>not need to be referred to her doctor.</Text> She should be encouraged to inform a parent about being on contraception.
</Text>

export const CaseElevenNoYes = () => <Text {...normalText}>
This woman <Text as="span" {...boldRed}>can be provided</Text> with Hana, 3 months for a supply to those under 18 years of age. The woman appears to fulfil the conditions of the Fraser guidelines, in that she will understand the advice and what is involved; cannot be persuaded to inform her parents; is very likely to begin or continue to have sexual intercourse without contraception; her physical or mental health (or both) will suffer without contraceptive advice or treatment; and her best interests require her to receive contraceptive advice or treatment with or without parental consent. <br/><br/>Pregnancy has been excluded, she has no contraindications, she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana and nothing indicates that she should be referred to her doctor. <br/><br/>This woman should wait until the first day of her next period to start Hana. She does <Text as="span" {...boldRed}>not need to be referred to her doctor.</Text> She should be encouraged to inform a parent about being on contraception.
</Text>

export const CaseElevenNoNo = () => <Text {...normalText}>
This woman <Text as="span" {...boldRed}>can be provided</Text> with Hana, 3 months for a supply to those under 18 years of age. The woman appears to fulfil the conditions of the Fraser guidelines, in that she will understand the advice and what is involved; cannot be persuaded to inform her parents; is very likely to begin or continue to have sexual intercourse without contraception; her physical or mental health (or both) will suffer without contraceptive advice or treatment; and her best interests require her to receive contraceptive advice or treatment with or without parental consent. <br/><br/>Pregnancy has been excluded, she has no contraindications, she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana and <Text as="span" {...boldRed}>nothing indicates that she should be referred to her doctor.</Text> <br/><br/>This woman should wait until the first day of her next period to start Hana. She should be encouraged to inform a parent about being on contraception.

</Text>