import React, { useState } from 'react'
import { Box, Text, Heading, useDisclosure, ListItem, List } from '@chakra-ui/core'

// Load components
import Reference from '../../components/UI/Typography/Reference'
import Modal from '../../components/Modal/Modal'

// Load assets
import {ReactComponent as Woman } from '../../images/Group 17.svg'
import {ReactComponent as Liver } from '../../images/Group 21.svg'
import {ReactComponent as Unexplained } from '../../images/Group 23.svg'
import {ReactComponent as Star } from '../../images/Group 31.svg'
import {ReactComponent as Vein } from '../../images/Group 27.svg'
import {ReactComponent as Molecule } from '../../images/Group 43.svg'

// Load style
import { normalText, referenceText, headingTextbkRnd, referenceLink, headingText } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const Row = ({ svg: SVG, content }) => {
    return (
        <Box display="flex" alignItems="flex-start" marginBottom="40px">
            <Box marginRight="10px">
                <SVG />
            </Box>
            <Box minHeight="50px">
                {content}
            </Box>
        </Box>
    )
}

const ReferencePopup = () => (
    <List as="ol" spacing="4" styleType="decimal">
        <ListItem {...referenceText}>
            Hana (desogestrel) Summary of Product Characteristics, November 2020.
        </ListItem>
    </List>
)

const InfoPopUp = () => (
    <List spacing="4">
        <ListItem {...referenceText}>
            <Text as="span" fontWeight="bold">Ingredients</Text>:<sup>1</sup><br/>
            Alpha-tocopherol, Lactose monohydrate, Maize starch, Povidone K25, Stearic acid
        </ListItem>
        <ListItem {...referenceText}>
            <Text as="span" fontWeight="bold">Film coating</Text>:<sup>1</sup><br/>
            Hypromellose, Macrogol 400, Titanium dioxide (E 171)
        </ListItem>
    </List>
)

const WhoCanUseHana = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [content, setContent] = useState('')

    const loadContent = content === 'References' ? <ReferencePopup /> : <InfoPopUp />

    function modalContent(content) {
        setContent(content)
        onOpen()
    }
    return (
        <Layout>
            <Text {...headingText} display="block">
                Hana is indicated as an oral contraceptive in women of childbearing <span style={{position: 'relative'}}>age.<Reference>1</Reference></span>
            </Text>
            <Heading {...headingTextbkRnd} fontSize="18px" margin="20px 0">
                Contraindications to Hana
            </Heading>
            <Text {...normalText} marginBottom="20px" display="block" color="purple.900" fontFamily="Gotham Bold">
                There are a few situations in which Hana should <Text as="u" fontFamily="Gotham Bold">not</Text> be used. Women <span style={{position: 'relative'}}>who:<Reference>1</Reference></span>
            </Text>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between">
                <Box width={{base: "100%", lg:"45%"}}>
                    <Row
                    svg={Star}
                    content={<Text {...normalText}>have breast cancer or other known or suspected sex-steroid sensitive cancers, such as ovarian or uterine cancer</Text>}
                    />
                    <Row 
                    svg={Vein}
                    content={<Text {...normalText}>have active venous thrombosis or pulmonary embolism*</Text>}
                    />
                    <Row 
                    svg={Molecule}
                    content={<Text {...normalText}>are allergic to desogestrel or to any of the other ingredients in Hana; <Box as="u" cursor="pointer" fontFamily="Gotham Bold" onClick={() => modalContent('Excipients in Hana')} >click here</Box> for a list of ingredients</Text>}
                    />
                </Box>
                <Box width={{base: "100%", lg:"45%"}}>
                    <Row
                    svg={Liver}
                    content={<Text {...normalText}>have, or have had, severe liver disorders that have not resolved (blood tests show the liver function values have not returned to normal)</Text>}
                    />
                    <Row
                    svg={Woman}
                    content={<Text {...normalText} display="block" paddingTop="10px">are pregnant</Text>}
                    />
                    <Row
                    svg={Unexplained}
                    content={<Text {...normalText} display="block">have unexplained vaginal bleeding between their periods or repeatedly have bleeding after sex. Women experiencing this type of bleeding should be referred to their doctor for further investigation</Text>}
                    />
                </Box>
            </Box>

            <Text fontSize="sm" color="#3B2B51" margin="30px 0">* A history of these conditions is not a contraindication for use of Hana; these conditions are not thought to be caused by POPs.<sup>1</sup></Text>
        
            <Text as="u" {...referenceLink} onClick={() => modalContent('References')}>References</Text>

            <Modal isOpen={isOpen} onClose={onClose} title={content}>
                {loadContent}
            </Modal>
        </Layout>
    )
}

export default WhoCanUseHana