import React from 'react'
import { Text, Stack, Heading, Button } from '@chakra-ui/core'

// Load layout
import Layout from '../../layout/Layout.card'

// Load styles
import { normalText } from '../../style/styleFormat'

const Failed = ({ correct="0", restart, isPharmacy = false }) => {
    return (
        <Layout>
            <Stack spacing="4">
                <Heading fontSize="20px" color="#2E1C45">You scored {correct}/20</Heading>
                <Text {...normalText}>
                But you can retake the exercise and have another go at scoring 100% to get your {!isPharmacy && 'CPD'} certificate.
                </Text>
                <Button onClick={restart} display="flex" alignItems="center" justifyContent="center" href="#" isExternal backgroundColor="#DF6532" width="100%" maxW="300px" fontWeight="bold" color="#fff" height="78px" borderRadius="4px" padding="4" textDecoration="none" fontSize="14px">
                    Restart {isPharmacy ? 'test' : 'assessment'}
                </Button>
            </Stack>
        </Layout>
    )
}

export default Failed