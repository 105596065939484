import React, { useEffect, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi'
import { Link as ReachLink, useHistory } from 'react-router-dom'

import { Box, AspectRatioBox, Button, useDisclosure, Heading } from '@chakra-ui/core'
import Cookies from 'js-cookie'

import {ReactComponent as HanaNavy} from '../../images/Hana_Logo_Navy_Tag.svg'

// Load assets
import PartOneBk from '../../images/bg1.png'
import PartTwoBk from '../../images/bg2.png'
import PartThreeBk from '../../images/bg3.png'

// Load components
import Cookienotice from '../Cookiebanner/Cookienotice';
import Footer from '../Footer/Footer'

const buttonPrimary = {
    backgroundColor:"orange.900",
    borderRadius:"25px",
    color:"#fff",
    fontFamily:"Gotham Bold",
    fontSize: {base: "sm", sm: "sm", lg: "16px"},
    textTransform: "uppercase"
}

function Privacy() {
  const MAX_PAGE = 99
  const [page, setPage] = useState(0)
  const [correct, setCorrect] = useState(0)
  const [allowNext, setAllow] = useState(false)
  const passed = correct >= 20
  const fail = correct < 20

  const history = useHistory()

  // Mobile menu
  const { isOpen, onClose, onOpen } = useDisclosure()

  // Control sideDrawer display
  const [open, setOpen] = useState(false)
 
  // Make sure on each page change we are scrolled to top
  function scrollInView() {
    // Element to target
    const element = document.getElementById('next-top')

    // Scroll into viewport
    if (element) {
     return element.scrollIntoView()
    }
      return
  }

  useEffect(() => {
    scrollInView()
  }, [page])

  useEffect(() => {
    const progress = Cookies.getJSON('hana-progress')
    if (progress) {
       setPage(parseInt(progress.page))
       setCorrect(parseInt(progress.correct))
       history.push(progress.url)
    }
       
  }, [history])

  let background = PartOneBk

  // Change background as progress
  if (page > 22) {
      background = PartTwoBk
  }

  if (page > 59) {
      background = PartThreeBk
  }

  if (page > 62) {
      background = PartOneBk
  }

  return (
    <Box backgroundColor="#f4f1e8" minHeight="100vh">
        {/** HEADER */}
        <AspectRatioBox backgroundColor="#f4f1e8"  maxWidth="100vw" minHeight={{base: "200px", md:"200px"}} maxHeight={{base: "200px", lg: "200px"}} overflow={'hidden'}>
            <Box>
                <Box as="header" padding={{base: 6, lg: 16}}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <Box width={{base:"25%", lg: "15%"}} maxWidth={{base: "100px",  lg:"150px"}}>
                            <HanaNavy />
                        </Box>
                        <Button onClick={onOpen} {...buttonPrimary} display="flex" minHeight="51px"  alignItems="center" justifyContent="center">
                                Resources
                            <Box marginLeft="10px" marginBottom="2px" fontSize="lg" as={GiHamburgerMenu} />
                        </Button>
                    </Box>
                </Box>
            </Box>
        </AspectRatioBox>

        <div className="contentbox">
            <Heading textAlign="center" fontSize={{base: "70px", lg:"128px"}} fontWeight="300" lineHeight={{base: "55px", lg:"80px"}} fontFamily="kokomo_breezeregular" color="rgb(46, 28, 69)">
                Cookie Policy
            </Heading>
        </div>

        <Box as="section">
            <Cookienotice />
        </Box>

        {/** Footer */}
        <Box as="footer" backgroundColor="#CCCEE6">
        <Footer productCode="0301" />
        </Box>

    </Box>
  );
}

export default Privacy;
