import React, { useState } from 'react'
import { Box, Text, List, ListItem, useDisclosure, Stack, Icon } from '@chakra-ui/core'

// Load assets
import {ReactComponent as People } from '../../images/people.svg'
import {ReactComponent as FirstTime  } from '../../images/firstTimeSupply.svg';
import {ReactComponent as HanaArrow } from '../../images/hanaArrow.svg'
import {ReactComponent as DSG } from '../../images/dsg.svg'

// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, normalText, blueBullet, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const ReferencePopup = () => (
    <List as="ol" spacing="4" styleType="decimal">
        <ListItem {...referenceText}>
            Hana (desogestrel) Summary of Product Characteristics, November 2020.
        </ListItem>
        <ListItem {...referenceText}>
            Hana (desogestrel) Patient Information Leaflet, November 2020.
        </ListItem>
    </List>
)

const WhoCanUseHana = () => (
    <Stack spacing="4">
        <Text {...normalText} fontWeight="bold">
            Hana is <u>not</u> suitable for women <span style={{position: 'relative'}}>who:<Reference>1</Reference></span>
        </Text>
        <List as="ol" spacing="4" styleType="decimal">
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                have breast cancer or other known or suspected sex-steroid sensitive  cancers, such as ovarian or 	  uterine cancer
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                have, or have had, severe liver disorders that have not resolved (blood tests show the liver function values have not returned to normal)
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                have active venous thrombosis or pulmonary embolism*
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                have unexplained vaginal bleeding between their periods or repeatedly have bleeding after sex. 
                Women experiencing this type of bleeding should be referred to their doctor for further 	   investigation
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                are allergic to desogestrel or to any of the other ingredients in Hana
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                are pregnant
            </ListItem>
        </List>
        <Text {...normalText} fontSize="10px">
        * A history of these conditions is not a contraindication for use of Hana; these conditions are not thought to be caused by <span style={{position: 'relative'}}>POPS.<Reference>1</Reference></span>
        </Text>

        <Text {...normalText} fontWeight="bold">
            Hana may be suitable in other conditions, <u>but</u> a doctor would need to assess the woman <span style={{position: 'relative'}}>first: &nbsp;<Reference>&nbsp;1,2</Reference></span>
        </Text>
        <List spacing="4">
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                history of breast, ovarian or uterine cancer
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                current liver cancer or other liver disorders
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                Type 1 or Type 2 diabetes
            </ListItem>
        </List>
        <Text {...normalText} fontWeight="bold">
            Counsel women before or during use of Hana
        </Text>
        <Text {...normalText}>
            Hana can be used by women with a history of chloasma or thromboembolic disorders, or who have high blood pressure, but they should be counselled as follows before <span style={{position: 'relative'}}>use:<Reference>1</Reference></span>
        </Text>
        <List spacing="4">
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                chloasma (yellowish-brown pigmentation patches on the skin, particularly of the face) may 	  occasionally occur, especially in women who have had it before; these women should avoid exposure to the sun or UV radiation
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                there is the possibility of a recurrence of venous thrombosis or pulmonary embolism in women with a history
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="15px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '40px'
                }}>
                women who develop sustained hypertension during use of Hana, or a significant increase in blood pressure that does not adequately respond to antihypertensive therapy, should see their doctor to decide whether Hana should be discontinued (pharmacists are not expected to check/monitor blood pressure prior to or during supply)
            </ListItem>
        </List>
    </Stack>
)


const SupplySituation = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [content, setContent] = useState('')
    
    const loadContent = content === 'References' ? <ReferencePopup /> : <WhoCanUseHana />

    function modalContent(content) {
        setContent(content)
        onOpen()
    }
    return (
        <Layout>
            <Stack spacing="4">
                <Text {...secondaryText} fontWeight="300" fontSize="lg">
                    Hana is for women of childbearing age who want to prevent <span style={{position: 'relative'}}>pregnancy.<Reference>1</Reference></span>
                </Text>

                <Box display="flex" flexDirection={{base: "column", lg:"row"}} alignItems="center">
                    <Box position="relative">
                        <People width="80px" />
                    <Icon name="close" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" color="orange.900" fontWeight="bold" fontSize="28px" />
                    </Box>
                    <Text {...secondaryText} marginLeft={{base: "0", lg:"10px"}} >
                        Situations in which Hana should not be used were listed in Part 1. <Text as="u" fontFamily="Gotham Bold" fontWeight="bold" cursor="pointer" onClick={() => modalContent('Who can use Hana')}>Click here</Text> to remind yourself when Hana is NOT a suitable form of contraception or when a woman should be referred to her doctor before she can be provided with Hana.
                    </Text>
                </Box>
                <Text {...secondaryText} fontWeight="300" marginBottom="30px" fontSize="lg">
                    You could have women in your pharmacy requesting Hana who:
                </Text>
                <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems={{base:"center", lg: "initial"}}>
                    <FirstTime width="80px" height="50px" />
                    <Box marginLeft={{base: "0", lg:"10px"}} >
                        <Text {...secondaryText} marginBottom="10px">
                            <Text as="span" fontFamily="Gotham Bold">have never used desogestrel before or who have not used it for several months</Text> – first-time supply; this includes women:
                        </Text>
            
                        <List spacing="4">
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="20px"
                        position="relative"
                        display="block"
                        _before={{
                           ...blueBullet
                            }}
                        >
                            who have never used any form of contraception, or who have previously used desogestrel but not taken it for several months
                        </ListItem>

                        <ListItem 
                        {...secondaryText}
                        paddingLeft="20px"
                        position="relative"
                        display="block"
                        _before={{
                          ...blueBullet
                            }}
                        >
                            who are currently using or have previously used other regular hormonal contraception
                        </ListItem>

                        <ListItem 
                        {...secondaryText}
                        paddingLeft="20px"
                        position="relative"
                        display="block"
                        _before={{
                           ...blueBullet
                            }}
                        >
                            who are currently using or have previously used other non-hormonal contraception
                        </ListItem>
                        </List>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" flexDirection={{base: "column", lg: "row"}}>
                    <HanaArrow width="80px" height="60px" />
                    <Text {...secondaryText}  marginLeft={{base: "0", lg:"10px"}} marginTop={{base: "20px", lg: "0"}}>
                        <Text as="span" fontFamily="Gotham Bold">are currently using or have recently used Hana, purchased from a pharmacy</Text> – repeat supply
                    </Text>
                </Box>
                <Box display="flex" alignItems="center" flexDirection={{base: "column", lg: "row"}}>
                    <DSG width="80px" height="60px" />
                    <Text {...secondaryText} marginLeft={{base: "0", lg:"10px"}} marginTop={{base: "20px", lg: "0"}}>
                       <Text  as="span" fontFamily="Gotham Bold"> are currently using or have recently used desogestrel, prescribed by their doctor</Text> – changing supply
                    </Text>
                </Box>

                <Text {...secondaryText}>
                    How you check suitability for Hana and the advice you give will be different depending on the circumstances.
                </Text>
                <Text {...referenceLink} color="blue.50" as="u" fontWeight="bold" cursor="pointer" onClick={() => modalContent('References')}>
                    References
                </Text>

                <Modal isOpen={isOpen} onClose={onClose} title={content}>
                    {loadContent}
                </Modal>
            </Stack>
        </Layout>
    )
}

export default SupplySituation