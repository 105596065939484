import React from 'react'
import { Box, Stack, Text } from '@chakra-ui/core'

// Load style
import { normalText } from '../../style/styleFormat'

const MoreInfo18 = () => {
    return (
        <Box borderTop="2px solid #393767" display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
            <Box width="100%">
                <Box padding="10px 20px" borderBottom="1px solid #393767" background="#EAE9FF" marginBottom="20px">
                    <Text {...normalText} fontWeight="bold" fontFamily="Gotham Bold"  color="purple.900">REMEMBER</Text>
                </Box>

                <Stack spacing="4">
                    <Text {...normalText}>
                    If a woman experiences pain or swelling in her leg with hot, red skin over the painful site, she may
                    have a deep venous thrombosis. If she experiences breathlessness with unexplained chest pain or an
                    unusual cough, she may have a pulmonary embolism. If a woman experiences pelvic pain with either
                    vaginal bleeding/spotting or amenorrhoea, she may have an ectopic pregnancy, particularly if Hana
                    has been used inconsistently or incorrectly. If she develops jaundice, she has an underlying liver
                    disorder, the cause and extent of which needs to be determined. <Text as="span" fontFamily="Gotham Bold">All of these cases are medical
                    emergencies and the woman should be advised to seek immediate help.</Text>
                    </Text>

                    <Text {...normalText}>
                        Women who develop heavy or prolonged bleeding or notice any changes to their breasts whilst using Hana <Text as="span" fontFamily="Gotham Bold">should see their doctor as soon as possible, but these are not medical emergencies.</Text>
                    </Text>

                    <Text {...normalText}>
                        Guidance on how to manage non-medical emergencies is given in the SmPC.
                    </Text>
                </Stack>
           
            </Box>
        </Box>
    )
}

export default MoreInfo18