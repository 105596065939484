import React, { useState } from 'react'
import { Text, Stack, Heading, Input, Link, Box, Button } from '@chakra-ui/core'

// Load pdf
import teamguide from '../../pdf/Pharmacy_team_guide.pdf'
import teamcert from '../../images/Pharmacy_team_certificate.png'
import certImg from '../../pdf/Hana_Pharmacy_CPD_Certificate.png'

// Load layout
import Layout from '../../layout/Layout.card'
import { normalText } from '../../style/styleFormat'
import jsPDF from 'jspdf'

const Passed = () => {
    const [name, setName] = useState('')
    const date = new Date()
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const formatDate = `${day}-${month}-${year}`

    function downloadPdf(textInput, date) {
        const canvas = document.getElementById('img')
        let pdf = new jsPDF({orientation: 'landscape', unit: 'px', format: [500, 350]})
    
        pdf.addImage(canvas, 'PNG', 0,0, 500, 350)
        pdf.text(textInput, 250, 190, { align: "center"})
        pdf.text(date, 170, 300)
        pdf.save("certificate.pdf")
    }


    return (
        <Layout>
            <Stack spacing="4">
                <Heading fontSize="20px" color="#2E1C45">You have passed the assessment</Heading>
                <Text {...normalText}>
                    Please enter your full name in the box below, then click the button to download your CPD certificate
                </Text>
                <Input placeholder="Enter your name here..." value={name} size="lg" width="100%" maxW="400px" backgroundColor="#F7F6FF" height="61px" focusBorderColor="#9894C6" borderColor="#9894C6" onChange={(event) => setName(event.target.value)} />
                <Button onClick={() => downloadPdf(name, formatDate)} display="flex" alignItems="center" justifyContent="flex-start" textAlign="left" lineHeight="wide" backgroundColor="#DF6532" width="100%" maxW="400px" color="#fff" height="78px" borderRadius="4px" padding="4" textDecoration="none" fontSize="14px" fontWeight="bold" whiteSpace="initial">
                      Click here to download your CPD certificate
                  </Button>
            </Stack>
            <Box height="10px" overflow="hidden">
                <img id="img" src={certImg} alt="certificate" style={{opacity: 0}} />
            </Box>
        </Layout>
    )
}


export const PharmaPassed = () => {
        const [name, setName] = useState('')
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        const formatDate = `${day}-${month}-${year}`

        function downloadPdf(textInput, date) {
            const canvas = document.getElementById('img')
            let pdf = new jsPDF({orientation: 'landscape', unit: 'px', format: [500, 350]})
    
            pdf.addImage(canvas, 'PNG', 0,0, 500, 350)
            pdf.text(textInput, 210, 190, { align: "center"})
            pdf.text(date, 160, 300)
            pdf.save("certificate.pdf")
        }

      return (
          <Layout>
            <Stack spacing="4">
                <Heading fontSize="20px" color="#2E1C45">You've finished your Hana pharmacy team training and passed the test!</Heading>
                <Text {...normalText}>
                <Link href={teamguide} target="_blank" textDecoration="underline" download fontFamily="Gotham Bold">Click here</Link> to download a printable summarised version of the training.
                </Text>
                <Text {...normalText}>
                    Please enter your name here for your certificate:
                </Text>
                <Input placeholder="Enter your name here..." size="lg" value={name} width="100%" maxW="400px" backgroundColor="#F7F6FF" height="61px" focusBorderColor="#9894C6" borderColor="#9894C6" onChange={(event) => setName(event.target.value)} />
                <Button onClick={() => downloadPdf(name, formatDate)} display="flex" alignItems="center" justifyContent="flex-start" textAlign="left" lineHeight="wide" backgroundColor="#DF6532" width="100%" maxW="400px" color="#fff" height="78px" borderRadius="4px" padding="4" textDecoration="none" fontSize="14px" fontWeight="bold" whiteSpace="initial">
                    Click here to download your named certificate of completion
                </Button>
                <Box height="10px" overflow="hidden">
                    <img id="img" src={teamcert} alt="certificate" style={{opacity: 0}} />
                </Box>
            </Stack>
          </Layout>
      )
}

export default Passed