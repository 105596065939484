import React from 'react'
import { Box, Text, useDisclosure, List, ListItem } from '@chakra-ui/core'

// Load components
import Reference from '../../components/UI/Typography/Reference'
import Modal from '../../components/Modal/Modal'

// Load assets
import {ReactComponent as Lights } from '../../images/lightsTraffic.svg'
import {ReactComponent as Hana} from '../../images/hanaArrow.svg'
import {ReactComponent as DSG } from '../../images/dsg.svg'
import {ReactComponent as FirstTime} from '../../images/firstTimeSupply.svg'
import {ReactComponent as Info} from '../../images/info.svg'

// Load style
import { blueBullet, referenceLink, referenceText, secondaryText} from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const Reminder = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Text {...secondaryText}>
                Before you continue with Part 3, here is a recap of Part 2.
            </Text>
            <Box display="flex"  alignItems={{base: "center", lg:"flex-start"}} flexDirection={{base: "column", lg: "row"}} margin="10px 0">
                <Lights width="60px" height="60px" />
                <Text {...secondaryText}  marginLeft={{base: "0", lg:"20px"}} marginTop={{base: "10px", lg: "0"}}>
                    Hana is a progestogen-only oral contraceptive pill (POP), which contains the progestogen <span style={{position: 'relative'}}>desogestrel<Reference>1</Reference></span>
                </Text>
            </Box>
            <Text {...secondaryText}>
                    Hana can be supplied to women who:
            </Text>
            <Box display="flex" alignItems={{base: "center", lg:"flex-start"}} flexDirection={{base: "column", lg: "row"}} margin="10px 0">
                <FirstTime  />
                <Box marginLeft={{base: "0", lg:"20px"}} marginTop={{base: "10px", lg: "0"}}>
                    <Text {...secondaryText} marginBottom="20px">
                        have never used desogestrel before or who have not used it for several months – first-time supply; this includes women:
                    </Text>
                    <List spacing="4">
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="25px"
                        position="relative"
                        _before={{
                            ...blueBullet,
                            top: "5px"
                            }}>
                            who have never used any form of contraception, or who have previously used desogestrel but not taken it for several months
                        </ListItem>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="25px"
                        position="relative"
                        _before={{
                            ...blueBullet,
                            top: "5px"
                            }}>
                            who are currently using or have previously used other regular hormonal contraception
                        </ListItem>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="25px"
                        position="relative"
                        _before={{
                            ...blueBullet,
                            top: "5px"
                            }}>
                            who are currently using or have previously used other non-hormonal contraception
                        </ListItem>
                    </List>
                </Box>
            </Box>

            <Box display="flex" alignItems={{base: "center", lg:"center"}} flexDirection={{base: "column", lg: "row"}}  margin="10px 0">
                <Hana width="60px" />
                <Text {...secondaryText} marginLeft={{base: "0", lg:"15px"}} marginTop={{base: "10px", lg: "0"}}>
                are currently using or have recently used Hana, purchased from a pharmacy – repeat supply
                </Text>
            </Box>
            <Box display="flex" alignItems={{base: "center", lg:"center"}} flexDirection={{base: "column", lg: "row"}} margin="10px 0" >
                <DSG width="60px" />
                <Text {...secondaryText} marginLeft={{base: "0", lg:"15px"}} marginTop={{base: "10px", lg: "0"}}>
                are currently using or have recently used desogestrel, prescribed by their doctor – changing supply
                </Text>
            </Box>
            <Box display="flex" alignItems={{base: "center", lg:"center"}} flexDirection={{base: "column", lg: "row"}} margin="10px 0" paddingLeft="10px" >
                <Info width="120px" />
                <Text {...secondaryText}  marginLeft={{base: "0", lg:"20px"}} marginTop={{base: "10px", lg: "0"}}>
                    In addition to checking for contraindications, warnings and drug interactions, you should provide advice on how to take Hana and when women should be referred to their doctor, and general advice about cervical and breast cancer screening and protection against sexually transmitted infections
                </Text>
            </Box>
           
            <Text {...referenceLink} fontWeight="bold" color="blue.50" cursor="pointer" as="u" onClick={onOpen}>
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" spacing="4" styleType="decimal">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default Reminder