import React, { useState } from 'react'
import { Box, Text, List, ListItem, useDisclosure, Stack, Link, PseudoBox } from '@chakra-ui/core'

// Load assets
import leaflet from '../../pdf/HRA_Contraceptive_Options_Leaflet.pdf'

// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, normalText, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const References = () => (
    <List as="ol" styleType="decimal" spacing="4">
        <ListItem {...referenceText}>
            Faculty of Sexual & Reproductive Healthcare Clinical Guidance (FSRH). Contraceptive Choices for Young People. March 2010 (amended May 2019). Available at: <Link href="https://www.fsrh.org/standards-and-guidance/documents/cec-ceu-guidance-young-people-mar-2010/" isExternal textDecoration="underline">https://www.fsrh.org/standards-and-guidance/documents/cec-ceu-guidance-young-people-mar-2010/</Link>. Accessed: November 2020.
        </ListItem>
    </List>
)

const SupplyingHana = () => (
    <Stack spacing="4">
        <Text {...normalText}>
            You may receive requests for oral contraception from women younger than 16 years of age. It is important that you have undertaken appropriate training (e.g. CPPE module on safeguarding children and vulnerable adults) to be able to supply Hana with confidence and are fully aware of the Fraser Guidelines surrounding consent and confidentiality on sexual health services in women younger than 16 years of age. This guidance allows healthcare professionals to give advice and treatment provided they are satisfied <span style={{position: 'relative'}}>that:<Reference>1</Reference></span>
        </Text>
        <List spacing="4">
            <ListItem 
            {...normalText}
            paddingLeft="20px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'blue.50',
                fontSize: '40px'
                }}>
                the young person will understand the advice and understands what is involved
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="20px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'blue.50',
                fontSize: '40px'
                }}>
                the young person cannot be persuaded to inform their parents, or allow the 
                healthcare professional to inform their parents
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="20px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'blue.50',
                fontSize: '40px'
                }}>
                the young person is very likely to begin, or continue to have, sexual intercourse without contraception
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="20px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'blue.50',
                fontSize: '40px'
                }}>
                without contraceptive advice or treatment, the young person’s physical or mental health (or both) would suffer
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="20px"
            position="relative"
            _before={{
                position: 'absolute',
                top: '-3px',
                left: '-5px',
                content: "'•'",
                color: 'blue.50',
                fontSize: '40px'
                }}>
                the young person’s best interests require them to receive contraceptive advice or treatment with or without parental consent
            </ListItem>
        </List>
    </Stack>
)

const FirstTimeSupply = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [content, setContent] = useState('')
    
    const loadContent = content === 'References' ? <References /> : <SupplyingHana />

    function modalContent(content) {
        setContent(content)
        onOpen()
    }
    return (
        <Layout>
            <Stack spacing="4">
                <Text {...secondaryText}>
                    <Text as="span" fontFamily="Gotham Bold">Before using the checklist to check suitability for a first-time supply of Hana, ask the woman if she is aware of other contraceptive options. </Text>
                    If not, signpost her to a reliable source such as the “Contraceptive options” leaflet <Link href={leaflet} target="_blank" cursor="pointer" textDecoration="underline" isExternal>(available here)</Link> or <Link href="https://www.nhs.uk" isExternal textDecoration="underline">https://www.nhs.uk</Link> for information, and advise her to see her doctor if she is interested in an option other than Hana. Confirm she is willing for you to check her suitability for Hana, and supply it if appropriate, while she evaluates other options.
                </Text>
                <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                If, after using the checklist, you establish that the woman is suitable for Hana, you can provide her with a maximum 3-month first-time supply. Encourage the woman to advise her doctor that she has been supplied with Hana in pharmacy.
                </Text>

                <Box borderTop="1px solid #4580AB" display="flex" flexDirection={{base: "column", lg: "row"}}>
                    <Box width="100%" position="relative">
                        <Box backgroundColor="#E3ECF3" padding="4">
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                Checklist question
                            </Text>
                        </Box>
                        <PseudoBox 
                        position="relative" 
                        margin="30px auto"
                        width="80%"
                        height={{base: "100px", lg:"50%"}}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        backgroundColor="#E3ECF3"
                        borderRadius="23px"
                        padding="4"
                        textAlign="center"
                        _before={{
                            content: "''",
                            width: "0px",
                            height: '0px',
                            position: 'absolute',
                            top: '20px',
                            left: '-28px',
                            borderLeft: "25px solid transparent",
                            borderRight: "25px solid #E3ECF3",
                            borderTop: "25px solid #E3ECF3",
                            borderBottom: "25px solid transparent"
                        }}
 
                        >
                            <Text {...secondaryText} fontWeight="bold" fontFamily="Gotham Bold" fontSize="lg"> 
                                How old are you?
                            </Text>
                        </PseudoBox>
                    </Box>
                    <Box width="100%" borderLeft={{base: "none", lg:"1px solid #739FBF"}} borderTop={{base: "1px solid #739FBF", lg: "none"}}>
                        <Box backgroundColor="#E3ECF3" padding="4">
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                Guidance on pharmacist action
                            </Text>
                        </Box>
                        <Box padding="4">
                            <Text {...secondaryText} margin="10px 0">
                                Given the need to check suitability, Hana should only be supplied to the end user.
                            </Text>
                            <Text {...secondaryText}>
                                There are additional checks for women less than 16 years of age, so if there is any uncertainty around her age, a woman should be asked her birth year when she requests Hana.
                            </Text>
                            <Text {...secondaryText} margin="10px 0">
                                <Text as="u" fontWeight="bold" fontFamily="Gotham Bold" cursor="pointer" onClick={() => modalContent('Supplying Hana to adolescents')}>Click here</Text> for guidance on what to do if a woman requesting Hana is under 16 years of age
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={() => modalContent('References')}>
                    References
                </Text>
                <Modal isOpen={isOpen} onClose={onClose} title={content}>
                    {loadContent}
                </Modal>
            </Stack>
        </Layout>
    )
}

export default FirstTimeSupply