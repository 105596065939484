import React from 'react'
import { 
    Modal, 
    ModalOverlay, 
    ModalHeader, 
    ModalContent, 
    ModalCloseButton, 
    ModalBody,
    Heading
} from '@chakra-ui/core'

const modalHeader = {
    backgroundColor: '#393767',
    color: '#fff',
    borderRadius: "20px 20px 0 0"
}

const ModalBase = ({ title, children, isOpen, onClose, size="xl" }) => {
    const modalSize = size === 'lg-custom' ? '80%' : size
    return (
        <Modal isCentered isOpen={isOpen} size={modalSize} onClose={onClose} closeOnEsc>
            <ModalOverlay />
            <ModalContent zIndex="8000" borderRadius="20px" maxHeight="90vh" overflow="auto" boxShadow="0 2px 33px 10px rgba(0,0,0,0.5)">
                <ModalHeader {...modalHeader}>
                    <Heading fontSize="md" fontWeight="bold">{title}</Heading>
                </ModalHeader>
                <ModalCloseButton color="#fff" />
                <ModalBody padding="20px 25px">
                    {children}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ModalBase