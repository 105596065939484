import React from 'react'
import { Text, useDisclosure, ListItem, List } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import DrugInfo from '../../components/DrugInteractions/DrugInteractions'

// Load style
import { normalText, referenceText, referenceLink, subTitlelightPurple } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'


const DrugInteractions = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Text {...subTitlelightPurple}>
                The product information for any concomitant medicines needs to be consulted to identify potential interactions and any recommendations.<sup style={{fontSize:"12px"}}>1</sup>
            </Text>
            <Text {...normalText} margin="20px 0">
                Some medicines or herbal remedies impact CYP enzymes and affect the clearance of contraceptive hormones, and may have an impact on the contraceptive efficacy of Hana.<sup>1</sup> These can be divided into:
            </Text>
            <DrugInfo />
            <Text as="u" {...referenceLink} onClick={onOpen}>References</Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" spacing="4" styleType="decimal">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Patient Information Leaflet, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default DrugInteractions