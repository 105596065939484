import React, { useState } from 'react'
import { Box, Text, Button, Heading, Image, useDisclosure, ListItem, List } from '@chakra-ui/core'

// Load components
import Reference from '../../components/UI/Typography/Reference'
import Modal from '../../components/Modal/Modal'
import {ReactComponent as Info} from '../../images/infoAlt.svg'
import Video from '../../components/Video/Video'

// Load assets
import DoctorImg from '../../images/doctor.png'
import Animation from '../../video/MOA.mp4'

// Load style
import { normalText, bkLightRed, referenceText, referenceLink } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const ReferencePopup = () => (
    <List as="ol" spacing="4" styleType="decimal">
        <ListItem {...referenceText}>
            Hana (desogestrel) Summary of Product Characteristics, November 2020.
        </ListItem>
    </List>
)

const HowHanaWorks = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [content, setContent] = useState('')
    const text = {
        ...normalText,
        display: 'block',
        margin: '20px 0'
    }

    const loadContent = content === 'References' ? <ReferencePopup /> : <Video vidSrc={Animation} />

    function modalContent(content) {
        setContent(content)
        onOpen()
    }
    return (
        <Layout>
            <Box display={{base: "none", lg: "block"}} style={{ shapeOutside: 'circle(50%)'}} position="relative" float="right" height="630px" width="450px">
                <Image src={DoctorImg} position="absolute" top="0" right="-14%"/>
            </Box>
            
            <Text {...text}>
                Hana is a POP, which contains the progestogen <span style={{position: 'relative'}}>desogestrel.<Reference>1</Reference></span>&nbsp; Hana works primarily by inhibiting <span style={{position: "relative"}}>ovulation.<Reference>1</Reference></span>&nbsp; It also increases the viscosity of the cervical mucus, creating a barrier to stop sperm from entering the <span style={{position: 'relative'}}>uterus.<Reference>1</Reference></span>&nbsp;
            </Text>
            <Text {...text}>
                If Hana is taken as recommended, at the same time every day, ovulation will not occur<span style={{position: 'relative'}}>.<Reference>1</Reference></span>&nbsp; However, if a pill is missed, the effect of Hana on ovulation may diminish and an egg may start to develop if it is more than 12 hours late<span style={{position: 'relative'}}>.<Reference>1&nbsp;</Reference></span> The effect of Hana on the viscosity of the cervical mucus may also be reversed, hence sperm may be able to enter the uterus again.
            </Text>

            <Text {...text}>
                This means that although Hana should be taken at the same time every day, if a woman is up to 12 hours late in taking her pill, she should take it as soon as she remembers within those 12 <span style={{position: 'relative'}}>hours.<Reference>1</Reference></span>&nbsp; She should take the next pill at the usual time, without needing to use any additional <span style={{position: 'relative'}}>precautions.<Reference>1</Reference></span>&nbsp;
            </Text>  
            <Button {...text} onClick={() => modalContent('Hana mechanism of action')} whiteSpace="wrap" fontWeight="bold" textAlign="left" overflow="hidden" borderColor="#9894C6" backgroundColor="#fff" borderRadius="4px"  variant="outline" height={{base: "auto", lg:"52px"}}>
                Click here to watch an animation detailing the mechanism of action of Hana
            </Button>   

            <Box {...bkLightRed} display="block" width={{base: "100%", lg:"65%"}} position="relative" padding="6" marginBottom="20px">
                <Box position="absolute" top="-10px" left="-12px">
                    <Info name="info" borderRadius="50%" border="2px solid #fff" backgroundColor="#fff" color="#F0CED5" size="40px" />
                </Box>
                <Heading marginTop="10px" as="h2" fontSize="md" lineHeight="tight" color="orange.900">
                    The Hana 12-hour window for missed pills 
                </Heading>

                <Text {...text}>
                    Hana should be taken at the same time every day, i.e. the doses are 24 hours <span style={{position: 'relative'}}>apart.<Reference>1</Reference></span>&nbsp;
                </Text>
                <Text {...text}>
                    The effect of Hana on ovulation may diminish from 12 hours after the next dose should have been <span style={{position: 'relative'}}>taken.<Reference>1</Reference></span>&nbsp;
                </Text>
                <Text {...text} marginBottom="0">
                    This means that there are an additional 12 hours in which to take a missed pill before the effect of Hana on ovulation may diminish and an egg may start to develop.
                </Text>
            </Box>

            <Text {...referenceLink} margin="20px 0" onClick={() => modalContent('References')} as="u">References</Text>

            <Modal title={content} isOpen={isOpen} size={content === 'References' ? 'xl' : 'lg-custom'} onClose={onClose}>
                {loadContent}
            </Modal>
        </Layout>
    )
}

export default HowHanaWorks