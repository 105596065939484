import React, { useState } from'react'
import { Box, Text, List, Stack, ListItem, useDisclosure, PseudoBox } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, normalText, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const ReferencesPopUp = () => (
    <List as="ol" styleType="decimal" spacing="4">
        <ListItem {...referenceText}>
            Hana (desogestrel) Summary of Product Characteristics, November 2020.
        </ListItem>
    </List>
)

const ExcipInHana = () => (
    <Stack spacing="4">
        <Text {...normalText} >
            <Text a="span" fontWeight="bold" style={{position: 'relative'}}>Ingredients:<sup>1</sup></Text>
            Alpha-tocopherol, Lactose monohydrate, Maize starch, Povidone K25, Stearic acid
        </Text>
        <Text {...normalText} style={{position: 'relative'}}>
            <Text as="span" fontWeight="bold" style={{position: 'relative'}}>Film coating:<sup>1</sup></Text>
            <br/>
            Hypromellose, Macrogol 400, Titanium dioxide (E 171)
        </Text>
    </Stack>
)

const FirstTimeSupplyTwo = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [content, setContent] = useState('')
    
    const loadContent = content === 'References' ? <ReferencesPopUp /> : <ExcipInHana />

    function modalContent(content) {
        setContent(content)
        onOpen()
    }
    return (
        <Layout>
            <Box borderTop="1px solid #4580AB" flexDirection={{base: "column", lg: "row"}} display="flex" minHeight="300px">
                <Box width="100%" position="relative">
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Checklist question
                        </Text>
                    </Box>
                    <PseudoBox 
                    position="relative" 
                    margin="30px auto"
                    width="80%"
                    height="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#E3ECF3"
                    borderRadius="23px"
                    padding="6"
                    textAlign="center"
                    _before={{
                        content: "''",
                        width: "0px",
                        height: '0px',
                        position: 'absolute',
                        top: '20px',
                        left: '-28px',
                        borderLeft: "25px solid transparent",
                        borderRight: "25px solid #E3ECF3",
                        borderTop: "25px solid #E3ECF3",
                        borderBottom: "25px solid transparent"
                    }}
                    >
                    <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" fontSize="lg" textAlign="left"> 
                        Do you have any allergies?
                    </Text>
                </PseudoBox>
            </Box>
            <Box width="100%" borderLeft={{base: "none", lg:"1px solid #739FBF"}} borderTop={{base: "1px solid #739FBF", lg: "none"}}>
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Guidance on pharmacist action
                        </Text>
                    </Box>
                    <Box padding="4">
                        <Text {...secondaryText} margin="10px 0">
                            Hana is not suitable for women who are allergic to desogestrel or to any of the other ingredients in Hana, for example <span style={{position: 'relative'}}>lactose.<Reference>1</Reference></span>
                            &nbsp;&nbsp;<Text as="u" fontWeight="bold" fontFamily="Gotham Bold" cursor="pointer" onClick={() => modalContent('Excipients in Hana')}>Click here</Text> for a reminder of the ingredients, or read the SmPC
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Text {...referenceLink} color="blue.50" as="u" cursor="pointer" onClick={() => modalContent('References')} marginTop="20px">
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title={content}>
               {loadContent}
            </Modal>
        </Layout>
    )
}

export default FirstTimeSupplyTwo