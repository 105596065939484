import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

export const CaseThirteenYesNo = () => <Text {...normalText}>
This woman <Text as="span" {...boldGreen}>can be supplied with emergency contraception containing ulipristal acetate as well as Hana, </Text>a maximum of 3 months for a first-time supply. She has no contraindications and <Text as="span" {...boldGreen}>nothing indicates that she should be referred to her doctor. <br/><br/></Text>Given her repeated use of emergency contraception, use professional judgement to determine if she may be especially vulnerable to unplanned pregnancy if regular contraception is not started as soon as possible. Remember, quick starting is outside the product label. If this is considered to be in the best interests of the woman, advise her to wait 5 days after taking ulipristal acetate before starting Hana. She should use additional contraceptive measures (abstinence or barrier methods) during the 5 days of waiting and for the first 7 days of Hana use (i.e. 12 days in total). Because pregnancy cannot be excluded prior to quick starting, the woman should do a pregnancy test at least 3 weeks after the last episode of unprotected sex, including any episodes within the 7 days it takes for Hana to reach its maximum effectiveness. <br/><br/>If quick starting is not considered to be in her best interests, advise the woman to use other precautions until the first day of her next period and to start Hana then.
</Text>

export const CaseThirteenYesYes = () => <Text {...normalText}>
This woman <Text as="span" {...boldRed}>can be supplied with emergency contraception containing ulipristal acetate as well as Hana, </Text>a maximum of 3 months for a first-time supply. She has no contraindications and <Text as="span" {...boldRed}>nothing indicates that she should be referred to her doctor. <br/><br/></Text>Given her repeated use of emergency contraception, use professional judgement to determine if she may be especially vulnerable to unplanned pregnancy if regular contraception is not started as soon as possible. Remember, quick starting is outside the product label. If this is considered to be in the best interests of the woman, advise her to wait 5 days after taking ulipristal acetate before starting Hana. She should use additional contraceptive measures (abstinence or barrier methods) during the 5 days of waiting and for the first 7 days of Hana use (i.e. 12 days in total). Because pregnancy cannot be excluded prior to quick starting, the woman should do a pregnancy test at least 3 weeks after the last episode of unprotected sex, including any episodes within the 7 days it takes for Hana to reach its maximum effectiveness. <br/><br/>If quick starting is not considered to be in her best interests, advise the woman to use other precautions until the first day of her next period and to start Hana then.
</Text>

export const CaseThirteenNoYes = () => <Text {...normalText}>
This woman <Text as="span" {...boldRed}>can be supplied with emergency contraception containing ulipristal acetate as well as Hana, </Text>a maximum of 3 months for a first-time supply. She has no contraindications and <Text as="span" {...boldRed}>nothing indicates that she should be referred to her doctor. <br/><br/></Text>Given her repeated use of emergency contraception, use professional judgement to determine if she may be especially vulnerable to unplanned pregnancy if regular contraception is not started as soon as possible. Remember, quick starting is outside the product label. If this is considered to be in the best interests of the woman, advise her to wait 5 days after taking ulipristal acetate before starting Hana. She should use additional contraceptive measures (abstinence or barrier methods) during the 5 days of waiting and for the first 7 days of Hana use (i.e. 12 days in total). Because pregnancy cannot be excluded prior to quick starting, the woman should do a pregnancy test at least 3 weeks after the last episode of unprotected sex, including any episodes within the 7 days it takes for Hana to reach its maximum effectiveness. <br/><br/>If quick starting is not considered to be in her best interests, advise the woman to use other precautions until the first day of her next period and to start Hana then.
</Text>

export const CaseThirteenNoNo = () => <Text {...normalText}>
This woman <Text as="span" {...boldRed}>can be supplied with emergency contraception containing ulipristal acetate as well as Hana, </Text>a maximum of 3 months for a first-time supply. She has no contraindications and <Text as="span" {...boldRed}>nothing indicates that she should be referred to her doctor. <br/><br/></Text>Given her repeated use of emergency contraception, use professional judgement to determine if she may be especially vulnerable to unplanned pregnancy if regular contraception is not started as soon as possible. Remember, quick starting is outside the product label. If this is considered to be in the best interests of the woman, advise her to wait 5 days after taking ulipristal acetate before starting Hana. She should use additional contraceptive measures (abstinence or barrier methods) during the 5 days of waiting and for the first 7 days of Hana use (i.e. 12 days in total). Because pregnancy cannot be excluded prior to quick starting, the woman should do a pregnancy test at least 3 weeks after the last episode of unprotected sex, including any episodes within the 7 days it takes for Hana to reach its maximum effectiveness. <br/><br/>If quick starting is not considered to be in her best interests, advise the woman to use other precautions until the first day of her next period and to start Hana then.
</Text>