import React from 'react'
import { Box, Text, useDisclosure, List, ListItem, Icon, Image } from '@chakra-ui/core'

// Load components
import Reference from '../../components/UI/Typography/Reference'
import Modal from '../../components/Modal/Modal'

// Load assets
import {ReactComponent as Lights } from '../../images/lightsTraffic.svg'
import {ReactComponent as People } from '../../images/people.svg'
import {ReactComponent as Arrow } from '../../images/doubleArrow.svg'
import {ReactComponent as Percent } from '../../images/upToPercent.svg'
import Woman from '../../images/womanIcon.png'

// Load style
import { normalText, referenceLink, referenceText} from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const Reminder = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Text {...normalText}>
                Before you continue with Part 2, here is a recap of Part 1.
            </Text>
            <Box display="flex" flexDirection={{base: "column", lg: "row"}}  alignItems="center" margin="20px 0">
                <Box width="50px">
                    <Lights width="45px" height="50px" />
                </Box>
                <Text {...normalText} alignSelf={{base: "flex-start", lg: "center"}}  marginLeft="10px">
                    Hana is a progestogen-only oral contraceptive pill (POP), which contains the progestogen <span style={{position: 'relative'}}>desogestrel<Reference>1</Reference></span>
                </Text>
            </Box>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center" margin="10px 0">
                <Box width="50px">
                    <Image src={Woman} width="100%" maxWidth="50px" minWidth="50px" />
                </Box>
                <Text {...normalText}  marginLeft="10px">
                    Hana works primarily by inhibiting ovulation. It also increases the viscosity of the cervical mucus, creating a barrier to stop sperm from entering the <span style={{position: 'relative'}}>uterus<Reference>1</Reference></span>
                </Text>
            </Box>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center"  margin="10px 0">
                <Box width="50px">
                    <People width="50px" />
                </Box>
                <Text {...normalText} marginLeft="10px">
                    Like other POPs, Hana is suitable for most women, including during breastfeeding and by women who are unable to, or prefer not to use <span style={{position: "relative"}}>oestrogens<Reference>1</Reference></span>
                </Text>
            </Box>
            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center" margin="10px 0" >
                <Box width="50px">
                    <Percent width="50px" />
                </Box>
                <Text {...normalText}  marginLeft="10px">
                    If taken as recommended, i.e. at the same time every day without a break between packs, Hana inhibits ovulation in up to 97% of <span style={{position: 'relative'}}>cycles &nbsp; <Reference> 2,3</Reference></span>
                </Text>
            </Box>
            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center" margin="10px 0" >
                <Box position="relative" width="50px" >
                    <People width="50px" />
                    <Icon name="close" position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" color="orange.900" fontWeight="bold" fontSize="28px" />
                </Box>
                <Text {...normalText}  marginLeft="10px">
                    There are some situations in which Hana should not be used. These are listed in the SmPC<span style={{position: "relative"}}>.<Reference>1</Reference></span>&nbsp; Advice for how to identify these will be covered in Part 2
                </Text>
            </Box>
            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center" margin="10px 0" >
                <Box width="50px">
                    <Arrow width="50px" />
                </Box>
                <Text {...normalText} marginLeft="10px">
                    There are some medicines or herbal remedies that affect the clearance of contraceptive hormones, and may have an impact on the contraceptive efficacy of Hana. These are listed in the SmPC<span style={{position: "relative"}}>.<Reference>1</Reference></span>&nbsp; Advice for women about these drug interactions will be covered in Part 2
                </Text>
            </Box>
            <Text {...referenceLink} as="u" onClick={onOpen}>
                References
            </Text>

            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" spacing="4" styleType="decimal">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                    Rice CF, Killick SR, Dieben T <em>et al</em>. A comparison of the inhibition of ovulation achieved by desogestrel 75 micrograms and levonorgestrel 30 micrograms daily. Hum Reprod 1999;14(4):982–985.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Rice C, Killick S, Hickling D, <em>et al</em>. Ovarian activity and vaginal bleeding patterns with a desogestrel-only preparation at three different doses. Hum Reprod 1996;11(4):737–740.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default Reminder