import React, { useState } from 'react'
import { Box, Text, List, ListItem, useDisclosure } from '@chakra-ui/core'

// Load components
import DrugInteraction from '../../components/DrugInteractions/DrugInteractions'
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const ReferencesPopUp = () => (
    <List as="ol" styleType="decimal" spacing="4">
        <ListItem {...referenceText}>
            Hana (desogestrel) Summary of Product Characteristics, November 2020.
        </ListItem>
        <ListItem {...referenceText}>
            Hana (desogestrel) Patient Information Leaflet, November 2020.
        </ListItem>
    </List>
)

const RepeatSupplyFive = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [content, setContent] = useState('')
    
    const loadContent = content === 'References' ? <ReferencesPopUp /> : <DrugInteraction />

    function modalContent(content) {
        setContent(content)
        onOpen()
    }
    return (
        <Layout>
            <Box borderTop="1px solid #4580AB" flexDirection={{base: "column", lg: "row"}} display="flex">
                <Box width="100%" position="relative">
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Pharmacy supply aid reminder
                        </Text>
                    </Box>
                    <Text {...secondaryText} fontFamily="Gotham Bold" padding="2" fontWeight="bold" marginTop="20px"> 
                    Check for changes to regular medications or herbal remedies, or use of other medication
                    </Text>
                </Box>
                <Box width="100%" borderTop={{base: "1px solid #739FBF", lg: "none"}} borderLeft={{base: "none", lg:"1px solid #739FBF"}}>
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText}  fontFamily="Gotham Bold" fontWeight="bold">
                            Guidance on pharmacist action
                        </Text>
                    </Box>
                    <Box padding="4">
                        <Text {...secondaryText} margin="10px 0">
                        Checking whether there are any new potential drug interactions, as some may affect the clearance of contraceptive hormones, and may have an impact on the contraceptive efficacy of Hana<span style={{position: "relative"}}>. <Reference>1</Reference></span>
                        </Text>
                        <Text {...secondaryText}>
                            <Text as="u" cursor="pointer" fontFamily="Gotham Bold" fontWeight="bold" onClick={() => modalContent('Drug interactions')}>Click here</Text> for the list of medicines impacting CYP enzymes.
                        </Text>
                        <Text {...secondaryText} marginTop="10px">
                            Read the SmPC for a full list of drug interactions.
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Text onClick={() => modalContent('References')} as="u" {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer">References</Text>
            <Modal isOpen={isOpen} onClose={onClose} title={content} size={content === 'References' ? 'xl': "full"}>
                {loadContent}
            </Modal>
        </Layout>
    )
}

export default RepeatSupplyFive