import React  from'react'
import { Box, Text, List, Stack, ListItem, useDisclosure } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'

// Load assets
import {ReactComponent as Test} from '../../images/pregTest.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, blueBullet, subBullet, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const RepeatSupplySix = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Stack spacing="4">
                <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                    Before starting a new pack of Hana, a woman should be reasonably certain that she is not pregnant<span style={{position: 'relative'}}>.<Reference>1</Reference></span>
                </Text>
                <Text {...secondaryText}>
                    If in doubt, particularly if she has not been using Hana consistently and correctly, she should be advised that there is a chance that she may be <span style={{position: 'relative'}}>pregnant.<Reference>1</Reference></span>&nbsp; Hana can be supplied, but she should be advised <span style={{position: 'relative'}}>to:<Reference>1</Reference></span>
                </Text>

                <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems={{base: "center", lg:"flex-start"}}>
                    <Test />
                    <List spacing="4" marginLeft={{base: "0", lg:"20px"}}>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="25px"
                        position="relative"
                        display="block"
                        _before={{
                            ...blueBullet
                            }}
                        >
                            do a pregnancy test at least 3 weeks after the last episode of unprotected sex
                        </ListItem>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="50px"
                        position="relative"
                        display="block"
                        _before={{
                          ...subBullet
                            }}
                        >
                            continue taking Hana until the result of the pregnancy test is available
                        </ListItem>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="50px"
                        position="relative"
                        display="block"
                        _before={{
                         ...subBullet
                            }}
                        >
                            stop Hana immediately and see a doctor if the pregnancy test is positive
                        </ListItem>
                    </List>
                </Box>
                <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
                    References
                </Text>
                <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
            </Stack>
        </Layout>
    )
}

export default RepeatSupplySix