import React from 'react'
import { Box, Text, List, ListItem, useDisclosure, Stack, Heading } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import {ReactComponent as Info} from '../../images/infoAlt.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { normalText, bkLightRed, referenceText, referenceLink, subTitlelightPurple } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'



const CaseStudy = ({ studyNumber, content }) => {
    return (
        <Box marginBottom="20px" borderRadius="31px 31px 0 0" backgroundColor="#F8F7FC" border="1px solid #6C66AC" width={{base: "100%", lg:"48%"}} minHeight="220px" overflow="hidden">
            <Box backgroundColor="purple.900" padding="4" display="flex" alignItems="center" justifyContent="space-between">
                <Box as="svg" viewBox="0 0 120 120" height="50px" width="50px" style={{ strokeDasharray: "0.1 12.5", strokeLinecap: "round", stroke: "yellow"}}>
                    <Box as="circle" cx="55" cy="55" r="50" fill="transparent" style={{strokeWidth: '5'}} />
                </Box>
                <Heading textAlign="center" as="h2" fontSize="16px" color="#fff" lineHeight="tighter" margin="0 10px">STUDY {studyNumber}</Heading>
                <Box as="svg" viewBox="0 0 120 120" height="50px" width="50px" style={{ strokeDasharray: "0.1 12.5", strokeLinecap: "round", stroke: "yellow"}}>
                    <Box as="circle" cx="55" cy="55" r="50" fill="transparent" style={{strokeWidth: '5'}} />
                </Box>
            </Box>
            <Box padding="4">
                {content}
            </Box>
        </Box>
    )
}


const EfficacyOfHana = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Stack spacing={4}>
            <Text {...subTitlelightPurple}>
                Two clinical studies have clearly shown that Hana is effective in inhibiting ovulation and in preventing pregnancy<span style={{position: 'relative'}}>.<Reference>1</Reference></span>
            </Text>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between" alignItems="center">
                <CaseStudy
                    studyNumber="1"
                    content={<Text {...normalText}>In the first study, ovulation occurred in only 1% (1/103) of menstrual cycles and inhibition of ovulation started from the first cycle after women started taking Hana<span style={{position: 'relative'}}>.&nbsp;<Reference>1,2</Reference></span></Text>}
                />
                <CaseStudy
                    studyNumber="2"
                    content={<Text {...normalText}>In the second study, which compared Hana to another POP, the <Text as="span" fontFamily="Gotham Bold">Pearl Index</Text> was 0.4 for Hana<span style={{position: "relative"}}>.<Reference>3</Reference></span></Text>}
                />
            </Box>


            <Box {...bkLightRed} padding="8" position="relative" marginTop="10px">
                <Box position="absolute" top="-10px" left="-12px">
                    <Info name="info" borderRadius="50%" border="2px solid #fff" backgroundColor="#fff" color="#F0CED5" size="40px" />
                </Box>
                <Text {...normalText}>
                    The Pearl Index (named after the American scientist Raymond Pearl) is a measure of the effectiveness of contraceptives: the smaller the Pearl Index, the more effective the method of contraception. E.g. a Pearl Index of 0.1 means that 1 in 1000 women who use the same contraceptive for 1 year becomes pregnant.
                </Text>
            </Box>
            <Text as="u" {...referenceLink} onClick={onOpen} >References</Text>

            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" spacing="4" styleType="decimal">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Korver T, Klipping C, Heger-Mahn D, <em>et al</em>. Maintenance of ovulation inhibition with the 75-microg desogestrel-only contraceptive pill (Cerazette) after scheduled 12-h delays in tablet intake. Contraception 2005;71(1):8-13.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Collaborative Study Group on the Desogestrel-containing Progesterone-only Pill. A double-blind study comparing the contraceptive efficacy, acceptability and safety of two progestogen-only pills containing desogestrel 75 micrograms/day or levonorgestrel 30 micrograms/day. Eur J Contracept Reprod Health Care 1998;3(4):169-178.
                    </ListItem>
                </List>
            </Modal>
            </Stack>
        </Layout>
    )
}

export default EfficacyOfHana