import React, { useEffect, useMemo } from 'react'
import { Box, Text, Heading } from '@chakra-ui/core'

// Load components
import Checkbox from '../../UI/Checkbox/Checkbox'

// Load hook
import useInputHook from '../../../hooks/useInputHook'

// Load style
import { normalText, bkglight, headingText} from '../../../style/styleFormat'

// Load layout
import Layout from '../../../layout/Layout.card'


const Card = ({content, checkboxTrue, checkboxFalse}) => {
    return (
        <Box display="flex" alignItems="flex-start" marginBottom={{base: "10px", lg: "0"}} flexDirection={{base: "column", lg: "row"}} maxWidth={{base: "100%", lg:"65%"}}>
            <Box {...bkglight} marginBottom={{base: "35px", lg:"0"}} display="flex" alignItems="center" minHeight="67px" borderRadius="10px">
                <Text {...normalText}>
                    {content}
                </Text>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-around" marginBottom="10px" width="100%">
            <Box {...bkglight} height="67px" margin={{base: "0", lg:"0 20px"}}  borderRadius="10px" position="relative">
                <Text {...normalText} fontWeight="bold" position="absolute" top="-30px" left="50%" transform="translateX(-50%)">TRUE</Text>
                {checkboxTrue}
            </Box>   
            <Box {...bkglight}  marginBottom="0" height="67px" borderRadius="10px" position="relative">
            <Text {...normalText} fontWeight="bold" position="absolute" top="-30px" left="50%" transform="translateX(-50%)">FALSE</Text>
                {checkboxFalse}
            </Box>
            </Box>
            
        </Box>
    )
}

const QuestionThree = ({ questions=[], question, title, subTitle, reveal, setNext, setAnswer }) => {
   const [form, inputCheckHandler, allowNext, checkCorrect] = useInputHook(questions, true)

   const answer = useMemo(() => checkCorrect(), [checkCorrect]) 

 
   useEffect(() => {

        if (reveal) {
            setNext(true)
        }
        // Allow next is one answer been selected
        setNext(allowNext)
   }, [reveal, allowNext, setNext])

   useEffect(() => {
        
        // Check if the answer was correct and fire callback
        if (reveal) {
        setAnswer(answer)
    
        }
   }, [reveal, setAnswer, answer])
 
    return (
        <Layout>
            <Heading {...headingText} margin="10px 0">
               {title}
            </Heading>

            <Text {...normalText} marginBottom="40px">
                {subTitle}
            </Text>
                <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems={{base: "center", lg:"flex-start"}} justifyContent="space-between">
                    <Card 
                    content={question}
                    checkboxTrue={<Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={form.q1.value} fail={form.q1.validation.valid.wrong} success={form.q1.validation.valid.correct} name="q1" id="q1" />}
                    checkboxFalse={<Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={form.q2.value} fail={form.q2.validation.valid.wrong} success={form.q2.validation.valid.correct} name="q2" id="q2" />}
                    />
                    {reveal && (
                        <Box width={{base: "100%", lg:"45%"}} marginLeft="15px">
                        {form.q1.value && 
                            <Text {...normalText} color={questions.q1.status === 'CORRECT' ? "green.900" : "red.900" }>
                                <strong>{questions.q1.label}</strong> - {questions.q1.reveal}
                            </Text>
                        }
                        {form.q2.value &&
                            <Text {...normalText} color={questions.q2.status === 'CORRECT' ? "green.900" : "red.900" }>
                                <strong>{questions.q2.label}</strong> - {questions.q2.reveal}
                            </Text>
                        }
                        </Box>
                        )
                    }
                </Box>
        </Layout>
    )
}

export default QuestionThree