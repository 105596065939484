function scrollView() {
   
    // Element to target
    const element = document.getElementById('next-top')

    // Scroll into viewport
    if (element) {
        return element.scrollIntoView()
    }
    return
}

export default scrollView

    