import React, { useEffect, useMemo } from 'react'
import { Box, Text, Heading, Button, Icon } from '@chakra-ui/core'

// Load components
import Checkbox from '../UI/Checkbox/Checkbox'

// Load hook
import useInputHook from '../../hooks/useInputHook'

// Load style
import { normalText, bkglight, headingText, submitBtn } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'


const Card = ({content, checkboxTrue, checkboxFalse, index, trueText = 'TRUE', falseText = 'FALSE' }) => {
    return (
        <Box display="flex" alignItems={{base:"space-between", lg: "center"}} justifyContent={{base: "flex-start", lg:"space-between"}} marginBottom="20px" width={{base: "100%", lg:"65%"}} flexWrap="wrap">
            <Box {...bkglight} marginBottom="0" width={{base:"100%", lg: "60%"}} display="flex" alignItems="center" minHeight="67px" borderRadius="10px">
                <Text {...normalText}>
                    {content}
                </Text>
            </Box>
            <Box {...bkglight} height="67px" margin={{base: "0", lg:"0 20px"}} marginTop={{base: "20px", lg: "0"}} marginRight={{base: "20px", lg: "0"}}  borderRadius="10px" position="relative">
                {index === 0 && <Text {...normalText} display={{base: "none", lg: "block"}} fontWeight="bold" position="absolute" top="-30px" left="50%" transform="translateX(-50%)" color="purple.200" fontFamily="Gotham Bold">{trueText}</Text>}
                {checkboxTrue}
                <Text {...normalText} display={{base: "block", lg: "none"}} fontWeight="bold" position="absolute" bottom="-30px" left="50%" transform="translateX(-50%)" color="purple.200" fontFamily="Gotham Bold">{trueText}</Text>
            </Box>   
            <Box {...bkglight}  marginBottom="0" marginTop={{base: "20px", lg: "0"}} height="67px" borderRadius="10px" position="relative">
                {index === 0 && <Text {...normalText} display={{base: "none", lg: "block"}} fontWeight="bold" position="absolute" top="-30px" left="50%" transform="translateX(-50%)" color="purple.200" fontFamily="Gotham Bold">{falseText}</Text>}
                {checkboxFalse}
                <Text {...normalText} display={{base: "block", lg: "none"}} fontWeight="bold" position="absolute" bottom="-30px" left="50%" transform="translateX(-50%)" color="purple.200" fontFamily="Gotham Bold">{falseText}</Text>
            </Box>
        </Box>
    )
}

const MultiTrueFalse = ({ questions=[], title, subTitle, reveal, setNext, setAnswer, trueText, falseText, changePage }) => {
   const [form, inputCheckHandler, allowNext, checkCorrect] = useInputHook(questions, false, true)

   const answer = useMemo(() => checkCorrect(), [checkCorrect]) 

   useEffect(() => {

        if (reveal) {
            setNext(true)
        }
        // Allow next is one answer been selected
        setNext(allowNext)
   }, [reveal, allowNext, setNext])

   useEffect(() => {
        
        // Check if the answer was correct and fire callback
        if (reveal) {
        setAnswer(answer)
    
        }
   }, [reveal, setAnswer, answer])
 
    return (
        <Layout>
            <Heading {...headingText} margin="10px 0">
               {title}
            </Heading>

            <Box marginBottom="40px">
                {subTitle}
            </Box>
             
            <Box display="flex" flexDirection="column" alignItems={{base: "center", lg:"flex-start"}} justifyContent="space-between">
                {Object.keys(questions).map((question, index) => {
                    const b = question + 'B'
                    
                    if (question.includes('B')) {
                        return null
                    }
                    return (
                            <Card 
                        key={question}
                        index={index}
                        content={questions[question].question}
                        trueText={trueText}
                        falseText={falseText}
                        checkboxTrue={questions[question].label === "TRUE" && <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={form[question].value} fail={form[question].validation.valid.wrong} success={form[question].validation.valid.correct} name={question} id={question} />}
                        checkboxFalse={questions[b].label === "FALSE" && <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={form[b].value} fail={form[b].validation.valid.wrong} success={form[b].validation.valid.correct} name={b} id={b} />}
                        />
                    )
                })}
            </Box>
            {!reveal &&
            <Box display="flex" width={{base: "100%", lg:"65%"}} justifyContent="flex-end">
                <Button 
                {...submitBtn}
                onClick={() => changePage('increment')}
                >Submit<Icon name="chevron-right" fontSize="32px" /></Button>
            </Box>
            }
        </Layout>
    )
}

export default MultiTrueFalse