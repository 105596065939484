import React from 'react'
import { Box, Heading } from '@chakra-ui/core'

// Load assets
import {ReactComponent as Wrong} from '../../images/cancel-2.svg'
import { FaCheckCircle as Correct } from 'react-icons/fa'

// Load layout
import Layout from '../../layout/Layout.card'

const CaseReveal = ({ text, status }) => {
    return (
        <Layout>
            <Box position="relative">
                <Box position="absolute" top="-45px" left="50%" transform="translateX(-50%)">
                    {status === 'wrong' && <Box backgroundColor="#fff" borderRadius="50%"><Wrong /></Box>}
                    {status === 'correct' && <Box backgroundColor="#fff" borderRadius="50%" borderColor="#239436"><Correct fontSize="30px" color="#239436" /></Box>}
                </Box>
                {status === 'correct' && <Heading fontSize="18px" color="green.900">Well done!</Heading>}
                {text}
            </Box>
        </Layout>
    )
} 

export default CaseReveal