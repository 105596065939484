import React from 'react'
import { Box, Text, Heading, ListItem, List, useDisclosure, Link } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import Reference from '../../components/UI/Typography/Reference'
import {ReactComponent as Info} from '../../images/infoAlt.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, bkLightRed, referenceLink, blueBullet } from '../../style/styleFormat'

const AdviceTakingHanaSeven = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Heading {...secondaryText} display="block" fontSize="lg">
                Ongoing sexual health management
            </Heading>
    
            <Box margin="20px 0" borderTop={{base: "1px solid #4580AB", lg:"2px solid #4580AB"}} display="flex" flexDirection="column">
                <Box display="flex" flexDirection={{base:"column", lg: "row"}} borderBottom="1px solid #739FBF" width="100%">
                    <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"20%"}}>
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                        Sexually transmitted infections
                        </Text>
                    </Box>
                    <Box padding="4" width={{base: "100%", lg:"80%"}}> 
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                Remind the woman that only condoms protect against sexually transmitted <span style={{position: 'relative'}}>infections<Reference>1</Reference></span>
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                                ...blueBullet
                                }}>
                                Advise the woman to go for a check-up at a sexual health clinic as soon as she can if she is worried she has a sexually transmitted infection; many of these infections, including HIV (AIDS), have no symptoms so the woman can only be certain if she gets <span style={{position: 'relative'}}>tested <Reference>2</Reference></span>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Box display="flex" flexDirection={{base:"column", lg: "row"}} borderBottom="1px solid #739FBF" width="100%">
                    <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"20%"}}>
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                        Cervical screening
                        </Text>
                    </Box>
                    <Box padding="4" width={{base: "100%", lg:"80%"}}> 
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                Advise the woman to continue to have regular smear tests to check the health of cells in the <span style={{position: 'relative'}}>cervix <Reference>2</Reference></span>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Box display="flex" flexDirection={{base:"column", lg: "row"}} borderBottom="1px solid #739FBF" width="100%">
                    <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"20%"}}>
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Breast screen/check
                        </Text>
                    </Box>
                    <Box padding="4" width={{base: "100%", lg:"80%"}}> 
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                                ...blueBullet
                                }}>
                                Advise women aged 50 years and above to attend regular breast screening to detect early signs of breast <span style={{position: 'relative'}}>cancer <Reference>2</Reference></span>
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                Advise women aged below 50 years to report any lump or change in their breasts to their <span style={{position: 'relative'}}>doctor <Reference>2</Reference></span>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Box display="flex" flexDirection={{base:"column", lg: "row"}} borderBottom="1px solid #739FBF" width="100%">
                    <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"20%"}}>
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Emergency contraception
                        </Text>
                    </Box>
                    <Box padding="4" width={{base: "100%", lg:"80%"}}> 
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                                ...blueBullet
                                }}>
                                Advise women to consider taking emergency contraception after unprotected sex, or if there has been a threat to the efficacy of their usual contraceptive <span style={{position: 'relative'}}>method <Reference>1</Reference></span>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Box display="flex" flexDirection={{base:"column", lg: "row"}} borderBottom={{base: "none", lg:"1px solid #739FBF"}} width="100%">
                    <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"20%"}}>
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Further information on contraception and screening
                        </Text>
                    </Box>
                    <Box padding="4" width={{base: "100%", lg:"80%"}}> 
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                                ...blueBullet
                                }}>
                                Refer women to <Link href="https://www.nhs.uk" textDecoration="underline" isExternal>https://www.nhs.uk</Link> for further information on all methods of contraception, emergency contraception or <span style={{position: 'relative'}}>screening <Reference>1</Reference></span>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Box>

            <Box {...bkLightRed} padding="8" margin="20px 0" maxWidth={{base: "100%", lg:"70%"}} position="relative">
                <Box position="absolute" top="-6px" left="-10px">
                    <Info name="info" borderRadius="50%" border="2px solid #fff" backgroundColor="#fff" color="#F0CED5" size="40px" />
                </Box>
                <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                    Remind every woman to read the PIL carefully before taking Hana.
                </Text>
            </Box>

            <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Patient Information Leaflet, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default AdviceTakingHanaSeven