import React from 'react'
import { Box, Text, List, ListItem, useDisclosure, Stack, Link, Heading } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import {ReactComponent as Info} from '../../images/infoAlt.svg'
import {ReactComponent as Calendar } from '../../images/Group39.svg'
import {ReactComponent as Bottle } from '../../images/bottleX.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { normalText, bkLightRed, referenceText, referenceLink, headingText } from '../../style/styleFormat'

const PharamaHanaImportant = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Stack spacing={4}>
            <Text {...normalText}>
                To help you effectively engage women in conversations about regular oral contraception and recommend Hana if appropriate, it’s important to have an understanding of the need for Hana.
            </Text>
            <Text {...normalText}>
                This need is supported by a number of compelling facts and statistics, including:
            </Text>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between" maxWidth={{base: "100%", lg:"70%"}} margin="0 auto">
                <Box width={{base:"100%", lg:"45%"}}>
                    <Box display="flex" alignItems="center" marginBottom="20px">
                        <Calendar />
                        <Box marginLeft="20px">
                            <Text {...normalText} marginBottom="10px">ALMOST</Text>
                            <Heading {...headingText} fontSize="44px">1 in 5</Heading>
                        </Box>
                    </Box>
                    <Text {...normalText}>
                        women find it <Text as="span" fontFamily="Gotham Bold" color="purple.900">difficult or very difficult</Text> to book a contraception appointment<sup>1</sup>
                    </Text>
                </Box>

                <Box width={{base:"100%", lg:"45%"}}>
                    <Box display="flex" alignItems="center" marginBottom="20px">
                        <Bottle />
                        <Heading {...headingText} fontSize="44px" marginLeft="20px">1 in 5</Heading>
                    </Box>
                    <Text {...normalText}>
                        pregnancies in the UK ends in <Text as="span" fontFamily="Gotham Bold" color="purple.900">abortion,</Text> the majority result from <Text as="span" fontFamily="Gotham Bold" color="purple.900">inconsistently or incorrectly using contraception, or not using contraception</Text><sup>2</sup>
                    </Text>
                </Box>
            
            </Box>

            
          


            <Box {...bkLightRed} padding="8" position="relative" marginTop="10px">
                <Box position="absolute" top="-10px" left="-12px">
                    <Info name="info" borderRadius="50%" border="2px solid #fff" backgroundColor="#fff" color="#F0CED5" size="40px" />
                </Box>
                <Text {...normalText}>
                Availability of Hana in pharmacy gives women access to an effective method of contraception without the need for a prescription
                </Text>
            </Box>
            <Text as="u" {...referenceLink} onClick={onOpen} >References</Text>

            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" spacing="4" styleType="decimal">
                    <ListItem {...referenceText}>
                        Advisory Group on Contraception (AGC). At tipping point. An audit of cuts to contraceptive services and their consequences for women. November 2018. Available at: <Link href="http://theagc.org.uk/wp-content/uploads/2018/11/At_tipping_point_AGC_Nov_18.pdf" target="_blank" isExternal style={{lineBreak: "anywhere"}}>http://theagc.org.uk/wp-content/uploads/2018/11/At_tipping_point_AGC_Nov_18.pdf. Accessed: November 2020.</Link>
                    </ListItem>
                    <ListItem {...referenceText}>
                        Pratt R, Stephenson J, Mann S. What influences contraceptive behaviour in women who experience unintended pregnancy? A systematic review of qualitative research. J Obstet Gynaecol 2014;34(8):693-699
                    </ListItem>
                </List>
            </Modal>
            </Stack>
        </Layout>
    )
}

export default PharamaHanaImportant