import React from 'react'
import { Stack, Heading, Text, List, ListItem, Box, Link } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

export const bullet = {
    position: 'absolute',
    top: '-21px',
    left: '-5px',
    content: "'•'",
    fontSize: '40px',
    display: "block"
}

export const subBullet = {
    position: 'absolute',
    top: '6px',
    left: '26px',
    content: "''",
    border: '1px solid #111111',
    borderRadius: "50%",
    height: '12px',
    width: "12px",
    backgroundColor: "#fff"
}

const Privacy = () => {
    return (
        <Stack spacing="4" overflowY="auto">
            <Heading {...normalText} fontSize="18px" textDecoration="underline" textAlign="center">
                Privacy Policy
            </Heading>
            <Text {...normalText}>
                Last Updated:  November 2020
            </Text>
            <Text {...normalText}>
                This Privacy Policy (“Privacy Policy”) applies to Personal data (as defined below) collected through the website https://hana.co.uk or https://hanapharmacist.co.uk (hereafter “Website”). 
            </Text>

            <Text {...normalText}>
                Laboratoire HRA Pharma (hereafter “HRA Pharma” “we,” “our,” “us”), located at 200 avenue de Paris, 92320 Châtillon, France and registered to the Trade Registry of Nanterre under the number 420 792 582, is the Controller for the Processing of your personal data Personal data for the purposes described below. HRA Pharma will collect your Personal data in accordance with this Privacy Policy and applicable data protection and privacy laws, including but not limited to the General Data Protection Regulation (hereafter “GDPR”).
            </Text>
            <Text {...normalText}>
                We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy and, in some cases, we may provide you with additional notice (such as adding a statement to our website or sending you a notification). We encourage you to review this Privacy Policy regularly to stay informed about our information practices and the choices available to you.
            </Text>
            <Text {...normalText}>
                The following terms “Personal data”, “Processing”, “Controller”, “Processor”, “Data Protection Authority” take the definitions given by Article 4 of the GDPR.
            </Text>

            <Heading {...normalText} fontFamily="Gotham Bold">
                CONTENTS
            </Heading>

            <List spacing="2">
                <ListItem {...normalText} display="block">
                    COLLECTION OF PERSONAL DATA
                </ListItem>
                <ListItem {...normalText} display="block">
                    YOUR CHOICES
                </ListItem>
                <ListItem {...normalText} display="block">
                    ADVERTISING AND ANALYTICS
                </ListItem>
                <ListItem {...normalText} display="block">
                    FOR DATA SUBJECTS WITHIN THE EUROPEAN ECONOMIC AREA (“EEA”)
                </ListItem>
                <ListItem {...normalText} display="block">
                    CONTACT US
                </ListItem>
            </List>

            <Text {...normalText}>
                COLLECTION OF PERSONAL DATA
            </Text>

            <Heading {...normalText} fontFamily="Gotham Bold">
                INFORMATION YOU PROVIDE TO US
            </Heading>
            <Text {...normalText}>
                What Personal data is collected?
            </Text>
            <Text {...normalText}>
                We collect information you provide directly to us. For example, HRA Pharma collects Personal data when you submit such information to us via the Website and the forms available on the Website, communicate with us via third-party platforms, request customer support, or otherwise communicate with us. The types of Personal data we may collect include your name, email address, location, and any other information you choose to provide. 
            </Text>
            <Text {...normalText}>
                When choosing to complete and submit a form available on the Website, Personal data may be collected. Please note, the completion of a form found on our Website is not mandatory. Depending on the form, the collected data is:
            </Text>

            <List as="ul" spacing="2" listStyleType="disc" marginLeft="20px">
                <ListItem {...normalText} display="inline list-item">
                    Contact Form: You may complete this form if you have a suggestion or would like to contact us on a particular subject such as request for promotional material, question or comment. The categories of Personal data processed are the following: your name, email address, the content of your message (which may include medical information if relevant to your message), and our corresponding answer (if we deem appropriate). 
                </ListItem>
            </List>

            <Text {...normalText}>
                These forms are at your disposal in case you want to contact us. We would like to remind you that their completion is not mandatory. 
            </Text>

            <Text {...normalText}>
                Please note, you may reduce the amount of Personal data we collect about you by limiting the amount of Personal data you provide through the forms to the fields denoted as “mandatory.”
            </Text>

            <Text {...normalText} fontFamily="Gotham Bold">
                INFORMATION COLLECTED AUTOMATICALLY ABOUT YOU WHEN YOU ACCESS THIS WEBSITE
            </Text>

            <Text {...normalText}>
                When you access or use our Website or otherwise transact business with us, we automatically collect certain information corresponding to the following categories:
            </Text>

            <List as="ul" listStyleType="disc" spacing="4">
                <ListItem {...normalText} display="block list-item">
                        <Text as="span" fontFamily="Gotham Bold">Activity Information:</Text> We collect information about your activity on our Website, such as the date and time you browsed the Website, length of visit to the Website, and pages most frequently accessed
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    <Text as="span" fontFamily="Gotham Bold">Device and Usage Information:</Text> We collect information about how you access our Website, including data about the device and network you use, such as your hardware model, operating system version, mobile network, IP address, unique device identifiers, browser type, and the website from which you access our Website.
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    <Text as="span" fontFamily="Gotham Bold">Location Information:</Text> If applicable In accordance with your device permissions, we may collect information about the precise location of your device. You may stop the collection of precise location information at any time (see the YOUR CHOICES section below for details and our Cookies policy).
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    <Text as="span" fontFamily="Gotham Bold">Information Collected by Cookies and Similar Tracking Technologies:</Text> We (and our service providers) use tracking technologies, such as cookies and web beacons, to collect information about you. Cookies are small data files stored on your hard drive or in device memory that help us improve our Website and your experience, see which areas and features of our Website are popular, and count visits. Web beacons (also known as “pixel tags” or “clear GIFs”) are electronic images that we use on our Website and in our emails to help deliver cookies, count visits, and understand usage and campaign effectiveness. For more information about cookies and how to disable them, see the YOUR CHOICES section below and our Cookies policy.
                </ListItem>
            </List>

            <Text {...normalText} fontFamily="Gotham Bold">
                INFORMATION COLLECTED FROM OTHER SOURCES
            </Text>
            <Text {...normalText}>
                HRA Pharma may supplement the Personal data it collects with information obtained from other third-party sources. Additionally, if you create or log into your “Hana” account through a third-party platform (such as Facebook or Google), we will have access to certain information from that platform, such as your name, birthday, and profile picture, in accordance with the authorization procedures determined by such platform.
            </Text>

            <Text {...normalText} fontFamily="Gotham Bold">
                USE AND SHARING OF INFORMATION
            </Text>
            <Text {...normalText}>
                What are the purposes for Processing and the legal basis?
            </Text>
            <Text {...normalText}>
                Your Personal data will be processed for the following purposes and in accordance with the legal basis set out below. The table below will also provide you with categories of recipients of your Personal data. 
            </Text>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
                <Box width={{base: "100%", lg:"28%"}} marginBottom={{base: "20px", lg: "0"}}>
                    <br/>
                    <Text>General</Text>
                </Box>
                <Box width={{base: "100%", lg:"28%"}} marginBottom={{base: "10px", lg: "0"}}>
                    <Text {...normalText} fontFamily="Gotham Bold">
                        PURPOSE
                    </Text>
                    <Text {...normalText}>
                        Managing and providing the Website (please see our Cookie Policy) Performing statistics on the use of the Website (please see our Cookie Policy) to improve the quality of our website
                    </Text>
                </Box>
                <Box width={{base: "100%", lg:"28%"}} marginBottom={{base: "10px", lg: "0"}}>
                    <Text {...normalText} fontFamily="Gotham Bold">
                        LEGAL BASIS
                    </Text>
                    <Text {...normalText}>
                        Our legitimate interest to provide a Website; Your consent
                    </Text>
                </Box>
                <Box width={{base: "100%", lg:"28%"}} marginBottom={{base: "10px", lg: "0"}}>
                    <Text {...normalText} fontFamily="Gotham Bold">
                        CATEGORIES OF RECIPIENTS
                    </Text>
                    <Text {...normalText}>
                        Laboratoire HRA Pharma SAS its subsidiaries,
                    </Text>
                </Box>
            </Box>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
                <Box width={{base: "100%", lg:"28%"}} marginBottom={{base: "20px", lg: "0"}}>
                    <Text>Users of the Conact Form</Text>
                </Box>
                <Box width={{base: "100%", lg:"28%"}} marginBottom={{base: "10px", lg: "0"}}>
                    <Text {...normalText}>
                        Answering your request or your comment
                    </Text>
                </Box>
                <Box width={{base: "100%", lg:"28%"}} marginBottom={{base: "10px", lg: "0"}}>
                    <Text {...normalText}>
                        Our legitimate interest to develop or maintain our commercial relationship and comply with our legal obligations as the case may be
                    </Text>
                </Box>
                <Box width={{base: "100%", lg:"28%"}} marginBottom={{base: "10px", lg: "0"}}>
                    <Text {...normalText}>
                        Laboratoire HRA Pharma 
                        Subsidiary(ies) of Laboratoire HRA Pharma 
                        Services Providers working on behalf of Laboratoire HRA Pharma or it(s) subsidiary(ies) 
                    </Text>
                </Box>
            </Box>

            <Text {...normalText}>
                In addition to the categories of recipients above mentioned, HRA Pharma will transmit your Personal data to our authorized Processors which will process your Personal data on behalf of HRA Pharma. HRA Pharma will also communicate your Personal data to relevant authorities as required by applicable laws. 
            </Text>

            <Text {...normalText}>
                DATA RETENTION
            </Text>

            <Text {...normalText}>
                In accordance with applicable data protection laws, the information collected about you when you access the Website will not be retained for longer than three years.
            </Text>

            <Text {...normalText}>
                Regarding your Personal data, the retention period is fixed according to the purpose of the Processing, as follows:
            </Text>

            <List as="ul" spacing="2" listStyleType="disc" marginLeft="20px">
                <ListItem {...normalText} display="block list-item">
                    Managing and providing the Website: 3 years
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    Performing statistics on the use of the Website: 3 years  
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                Managing your question or comment: the time needed to process your request 
                </ListItem>
            </List>

            <Text {...normalText} fontFamily="Gotham Bold">
                DATA SECURITY
            </Text>
            <Text {...normalText}>
                HRA Pharma implements appropriate technical and organizational measures to ensure an appropriate level of security regarding the risk incurred and protect your Personal data against unauthorized access, disclosure, alteration or destruction. 
            </Text>

            <Text {...normalText} fontFamily="Gotham Bold">
                What are your rights? How can you exercise them?
            </Text>
            
            <Text {...normalText}>
                YOUR RIGHTS
            </Text>
            <Text {...normalText}>
                Under applicable data protection and privacy laws, you have a number of rights with regard to your Personal data. Those rights are as follows: 
            </Text>

            <Text {...normalText}>
                RIGHT TO ACCESS
            </Text>
            <Text {...normalText}>
                You can ask to see the Personal data HRA Pharma holds about you. In connection with a request, HRA Pharma may request specific information about you to enable us to confirm your identity and right access, as well as search for and provide you with the Personal data HRA Pharma holds about you. In the event we cannot provide you with access to your Personal data (for instance, Personal data may have been destroyed, erased or made anonymous), we will inform you of the reasons why. 
            </Text>

            <Text {...normalText}>
                CORRECTION OR DELETION OF PERSONAL DATA
            </Text>
            <Text {...normalText}>
                HRA Pharma works to ensure that Personal data in its possession is accurate, current and complete. If you believe that the Personal data HRA Pharma holds on you is incorrect, inaccurate, incomplete or outdated, you may request the revision or correction of that information. If it is determined that Personal data is inaccurate, incomplete or outdated, we will revise it.
            </Text>

            <Text {...normalText}>
                WITHDRAWAL OF CONSENT
            </Text>
            <Text {...normalText}>
                If you have provided consent for the Processing of your data, you have the right to withdraw that consent at any time which will not affect the lawfulness of the Processing before your consent was withdrawn.
            </Text>

            <Text {...normalText}>
                OBJECTION TO PROCESSING
            </Text>
            <Text {...normalText}>
                You have the possibility to object to the Processing of your Personal data including profiling, on grounds relating to your particular situation as provided by data protection laws. When profiling is related to direct marketing you always have a right to object.
            </Text>

            <Text {...normalText}>
                LIMITATION TO PROCESSING
            </Text>
            <Text {...normalText}>
                You have the right to obtain from us restriction of Processing in certain instances as provided by data protection laws.
            </Text>

            <Text {...normalText}>
                RIGHT TO DATA PORTABILITY
            </Text>
            <Text {...normalText}>
                You have the right to receive the Personal data, which you have provided to us, in a structured, commonly used and machine-readable format when the Processing is based on your consent or on a contract. You also have the right to ask us to transmit it to another data controller of your choice. 
            </Text>

            <Text {...normalText}>
                COMPLAINTS
            </Text>
            <Text {...normalText}>
                You have the right to lodge a complaint to the Data Protection Authority, if you believe that HRA Pharma has not complied with the requirements of the GDPR with regard to your Personal data.
            </Text>
            <Text {...normalText}>
                If you wish to exercise one of these rights, please send a request in this regard to our Group Data Protection Officer, stating both your name and surname, together with a copy of your identity card:
            </Text>
            <Text {...normalText}>
                Via the email address <Link href="mailto:dataprivacy@hra-pharma.com" textDecoration="underline">dataprivacy@hra-pharma.com</Link> By post to HRA Pharma, 200 avenue de Paris, 92320 Châtillon, FranceIf you have unresolved concerns you also have the right to complain to the Data Protection Authority in the country where you live or work, or to the Data  Protection Authority of the place of the alleged infringement.
            </Text>

            <Text {...normalText} as="i">
                Date of preparation: December 2020 | UK/HANA/0118
            </Text>
            
        
        </Stack>
    )
}

export default Privacy