import { theme } from '@chakra-ui/core'

// First, create an alias for breakpoints
const breakpoints = ["20em", "48em", "62em", "80em", "95em"];

// aliases
breakpoints.sm = breakpoints[0]; // 480px
breakpoints.md = breakpoints[1]; // 768 px
breakpoints.lg = breakpoints[2]; // 992 px
breakpoints.xl = breakpoints[3]; // 1280px
breakpoints.xxl = breakpoints[4]

const customTheme = {
    ...theme,
    colors: {
        ...theme.colors,
        purple: { // Brand purples
            900: "#5D599E",
            800: "#9591D9",
            700: "#311A48",
            600: "#2E1C45",
            500: "#9894C6",
            400: "#7177B7",
            300: "#A7AFCE",
            200: "#6E64B1",
            100: "#6C66AC"
        },
        white: "#FFFFFF",
        green: {
            900: "#239436"
        },
        red: {
            900: "#CD2323"
        },
        blue: {
            900: "#393767",
            800: "#9894C6",
            700: "#4580AB",
            600: "#7389B9",
            500: "#4D69A6",
            400: "#6F9BBD",
            300: "#311A48",
            200: "#253C6C",
            100: "#463D90",
            50: "#657DB2",
            10: "#001C59"
        },
        orange: {
            900: "#DF6532",
            500: "#FEF3F6",
            400: "#F9DCBD"
        },
        neutral: {
            900: "#F6F8FA",
            800: "#F5F9FB",
            700: "#F8F7FC"
        },
        background: {
            1: "#F8F7FC",
            2: "#F5F9FB",
            3: "#F6F8FA",
            4: "#FEF3F6",
            5: "#F8F7FC",
            6: "#FBFCFD"
        },
        text: {
            900: "#311A48",
            800: "#2E1C45",
            700: "#412341"
        }
    },
    fonts: {
        body: "Gotham Book",
        heading: "Gotham Bold"
    },
    fontSizes: {
        xs: "10px",
        sm: "12px",
        md: "14px",
        lg: "20px",
        xlg: "40px"
    },
    fontWeights: {
        normal: 300,
        medium: 600,
        bold: 700
    },
    lineHeights: {
        tighter: "20px",
        tight: "22px",
        normal: "0",
        wide: "24px",
        wider: "30px",
        widest: "50px"
    },
    sizes: {
        ...theme.sizes,
        "1xs": "18rem",
        sm: "26rem"
    }
}

export default customTheme