import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

export const CaseFiveYesNo = () => <Text {...normalText}>
This woman is <Text as="span" {...boldGreen}>suitable</Text> for a 12-month repeat supply of Hana. Pregnancy has been excluded, her medical history has not changed, and she is not experiencing any distressing side effects. She says she is happy to manage the irregular bleeding. She does <Text as="span" {...boldGreen}>not need to be referred to her doctor.</Text> She should be advised to start the next pack as soon as she finishes her current one, without a break.
</Text>

export const CaseFiveYesYes = () => <Text {...normalText}>
This woman is <Text as="span" {...boldRed}>suitable</Text> for a 12-month repeat supply of Hana. Pregnancy has been excluded, her medical history has not changed, and she is not experiencing any distressing side effects. She says she is happy to manage the irregular bleeding. She does <Text as="span" {...boldRed}>not need to be referred to her doctor.</Text> She should be advised to start the next pack as soon as she finishes her current one, without a break.
</Text>

export const CaseFiveNoYes = () => <Text {...normalText}>
This woman is <Text as="span" {...boldRed}>suitable</Text> for a 12-month repeat supply of Hana. Pregnancy has been excluded, her medical history has not changed, and she is not experiencing any distressing side effects. She says she is happy to manage the irregular bleeding. She does <Text as="span" {...boldRed}>not need to be referred to her doctor.</Text> She should be advised to start the next pack as soon as she finishes her current one, without a break.
</Text>

export const CaseFiveNoNo = () => <Text {...normalText}>
This woman is <Text as="span" {...boldRed}>suitable</Text> for a 12-month repeat supply of Hana. Pregnancy has been excluded, her medical history has not changed, and she is not experiencing any distressing side effects. She says she is happy to manage the irregular bleeding. She does <Text as="span" {...boldRed}>not need to be referred to her doctor.</Text> She should be advised to start the next pack as soon as she finishes her current one, without a break.
</Text>