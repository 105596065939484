import React, { forwardRef } from 'react'

// Load components
import { Box, TabList, Tabs, Tab, TabPanel, TabPanels, Text, Icon, Link, List, ListItem, Stack } from '@chakra-ui/core'

const th = {
    border: "1px solid #9894C6",
    backgroundColor: "purple.900",
    color: "white",
    verticalAlign: "top",
    textAlign: "left",
    px: 2,
    py: 4
}

const PanelOne = () => {
    return (
        <>
            <Text mb={4}>
                For women who are <strong>not currently using hormonal contraception</strong>, you’ll find instructions for starting Hana below <sup>[Hana Pharmacy Guide]</sup>:
            </Text>

            <Box as="table">
                <Box as="tbody">
                     <Box as="tr">
                        <Box as="th" {...th}>
                            NO CONTRACEPTIVE USED IN THE PAST MONTH
                        </Box>
                        <Box as="td">
                            <List>
                                <ListItem>
                                    She can start taking Hana on day 1 of her period, without needing to take extra precautions
                                </ListItem>
                                <ListItem>
                                    Or she can start Hana on days 2-5 of her period with extra precautions recommended for the first week
                                </ListItem>
                            </List>
                        </Box>
                        <Box as="td" fontStyle="italic">
                            <Text>
                                 Hana is effective immediately
                            </Text>
                            <br />
                            <Text>
                                 Hana will be effective after 7 days
                            </Text>
                           
                        </Box>
                    </Box>

                    <Box as="tr">
                        <Box as="th" {...th}>
                            FOLLOWING MISCARRIAGE OR ABORTION
                        </Box>
                        <Box as="td">
                            <List>
                                <ListItem>
                                    She can start immediately, or within 5 days after miscarriage or abortion. There’s no need for extra precautions
                                </ListItem>
                            </List>
                        </Box>
                        <Box as="td" fontStyle="italic">
                            <Text>
                                Hana is effective immediately
                            </Text>
                        </Box>
                    </Box>

                    <Box as="tr">
                        <Box as="th" {...th}>
                            FOLLOWING CHILDBIRTH
                        </Box>
                        <Box as="td">
                            <List>
                                <ListItem>
                                    She can start any day between day 1 and day 21 after childbirth, without the need for extra precautions
                                </ListItem>
                                <ListItem>
                                    She can start after day 21, but extra precautions are recommended for the first week
                                </ListItem>
                                <ListItem>
                                    If she has already had unprotected sex, advise her to take a pregnancy test or see her doctor before starting Hana
                                </ListItem>
                            </List>
                        </Box>
                        <Box as="td" fontStyle="italic">
                            <Text>
                                Hana is effective immediately
                            </Text>
                            <br />
                            <Text>
                                Hana will be effective after 7 days
                            </Text>
                        </Box>
                    </Box>
                </Box>
             </Box>
        </>
    )
}

const PanelTwo = () => {
    return (
        <>
            <Text mb={4}>
                Advising women who want to <strong>switch to Hana from another form of hormonal contraception</strong> depends on what they’re currently using <sup>[Hana Pharmacy Guide]</sup>:
            </Text>

            <Box as="table">
                <Box as="tbody">
                     <Box as="tr">
                        <Box as="th" {...th} >
                            SWITCH FROM PROGESTOGEN-ONLY METHOD (MINI-PILL, INJECTION, IMPLANT OR INTRAUTERINE SYSTEM)
                        </Box>
                        <Box as="td">
                            <List>
                                <ListItem>
                                    For the mini pill, she can switch to Hana on whichever day she chooses. She just takes Hana instead of the mini-pill, without any need to take extra precautions
                                </ListItem>
                                <ListItem>
                                    For the injection, she can start Hana on the day the next injection is due, without any need for extra precautions
                                </ListItem>
                                <ListItem>
                                    For the implant or intrauterine system, she can start Hana on the day of it’s removal without any need for extra precautions
                                </ListItem>
                            </List>
                        </Box>
                        <Box as="td" fontStyle="italic">
                            <Text>
                                 Hana is effective immediately
                            </Text>
                            <br />
                            <Text>
                                Hana is effective immediately
                            </Text>
                            <br />
                            <Text>
                                Hana is effective immediately
                            </Text>
                        </Box>
                    </Box>

                    <Box as="tr">
                        <Box as="th" {...th} borderBottomColor="purple.900">
                            SWITCH FROM COMBINED ORAL CONTRACEPTIVE PILL, VAGINAL RING OR SKIN PATCH
                        </Box>
                        <Box as="td">
                            <List>
                                <ListItem>
                                    For the combined pill, she can start Hana the day after the last tablet (if this is an everyday [ED] pill, she should start the day after the last tablet containing an active substance). There’s no need for extra precautions
                                </ListItem>
                            </List>
                        </Box>
                        <Box as="td" fontStyle="italic">
                            <Text>
                                Hana is effective immediately
                            </Text>
                        </Box>
                    </Box>

                    <Box as="tr">
                        <Box as="th" {...th} borderBottomColor="purple.900">
                          
                        </Box>
                        <Box as="td">
                            <List>
                                <ListItem>
                                    For the vaginal ring or skin patch, she can start Hana on the day of removal, without the need for extra precautions
                                </ListItem>
                            </List>
                        </Box>
                        <Box as="td" fontStyle="italic">
                            <Text>
                                Hana is effective immediately
                            </Text>
                        </Box>
                    </Box>
                    <Box as="tr">
                        <Box as="th" {...th} borderBottomColor="purple.900">
                          
                        </Box>
                        <Box as="td">
                            <List>
                                <ListItem>
                                    Or she can start Hana on the day after the usual tablet, ring- or patch-free interval (or if an ED pill, the day after the last non-active tablet). Extra precautions are recommended for the first week
                                </ListItem>
                            </List>
                        </Box>
                        <Box as="td" fontStyle="italic">
                            <Text>
                                Hana will be effective after 7 days
                            </Text>
                        </Box>
                    </Box>
                </Box>
             </Box>
        </>
    )
}

const PanelThree = () => {
    return (
        <Stack spacing={2}>
            <Text>
                After a woman has taken <strong>an emergency hormonal contraceptive</strong>, advise her to wait until day 1 of her next normal cycle before starting Hana. She should continue to use other precautions until then.
            </Text>
            <Text>
                <strong>If you think that there’s potential for an unplanned pregnancy, she may need to start on Hana right away</strong>. ‘Quick starting’ is outside the approved use of Hana, so it’s down to your professional judgement to decide whether it’s in her best interests. You’ll find more information on p30 of the Pharmacy Guide to help you make your decision. If a woman is starting Hana right away:
            </Text>

            <List>
                <ListItem>
                    She can start Hana immediately after levonorgestrel, with additional contraception (barrier or abstinence) for the first 7 days
                </ListItem>
                <ListItem>
                    She should wait 5 days (or 120 hours) to start Hana after taking ulipristal acetate. She will need additional contraception (barrier or abstinence) for the 5 waiting days and the first 7 days after starting Hana (i.e., 12 days in total)<sup>[Hana Pharmacy Guide]</sup>
                </ListItem>
            </List>
        </Stack>
    )
}


const CustomTab = forwardRef((props, ref) => {
    return (
        <Tab ref={ref} isSelected={props.isSelected} py={4} {...props} _selected={{ color: "purple.900", bg: "white"}} width={{base: "100%" , md: "30%"}} mb={{base: 4, md: 0}} height="auto" bg="purple.900" color="white" borderRadius="md" display="flex" flexDirection="column" justifyContent="space-around" alignItems="center" boxShadow="lg" _focus>
           <Text fontSize="sm" fontWeight="bold">
                {props.children}
           </Text>
            <Icon name={!props.isSelected ? "add" : "minus"} />
        </Tab>
    )
})

const WebinarQOne = () => {
    return (
        <Box>
            <Text mb={4}>
                Your advice will depend on her situation. You’ll need to confirm one of the following:
            </Text>

            <Tabs variant='unstyled' mb={6} defaultIndex={0}>
                <TabList alignItems="stretch" justifyContent="space-between" flexDirection={{base: "column", md: "row"}}>
                    <CustomTab>
                        SHE IS NOT CURRENTLY USING A HORMONAL CONTRACEPTION CORRESPONDS WITH:
                    </CustomTab>
                    <CustomTab>
                        SHE IS SWITCHING FROM ANOTHER FORM OF HORMONAL CONTRACEPTION CORRESPONDS WITH:
                    </CustomTab>
                    <CustomTab>
                        SHE HAS JUST TAKEN EMERGENCY HORMONAL CONTRACEPTION
                    </CustomTab>
                </TabList>
                <TabPanels mt={6}>
                    <TabPanel>
                        <PanelOne />
                    </TabPanel>
                    <TabPanel>
                        <PanelTwo />
                    </TabPanel>
                    <TabPanel>
                        <PanelThree />
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <Text>
            There’s further information on when to start Hana, in part 2 of the Hana Pharmacy online training at <Link href='https://hanapharmacist.co.uk/training' isExternal>https://hanapharmacist.co.uk/training</Link> or p29 of the Hana Pharmacy Guide. Alternatively, you can refer to the Hana SmPC or PIL.
            </Text>
        </Box>
    )
}

export default WebinarQOne