import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

export const CaseTwelveYesNo = () => <Text {...normalText}>
This woman is <Text as="span" {...boldGreen}>suitable</Text> for a supply of Hana. She has no contraindications, she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana and <Text as="span" {...boldGreen}>nothing indicates that she should be referred to her doctor</Text>. <br/><br/>Because pregnancy could not be excluded, explain to her that she should wait until the first day of her next period to ensure she is not pregnant before starting Hana, advising her to use a reliable barrier method (such as condoms) until then. If her period does not start when she expects it to, the woman should take a pregnancy test at least 3 weeks after the last episode of unprotected sex or see her doctor. If her period comes in the meantime, she can start Hana on day 1 of that period.
</Text>

export const CaseTwelveYesYes = () => <Text {...normalText}>
This woman is <Text as="span" {...boldRed}>suitable</Text> for a supply of Hana. She has no contraindications, she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana and she does <Text as="span" {...boldRed}>not need to be referred to her doctor</Text>. <br/><br/>Because pregnancy could not be excluded, explain to her that she should wait until the first day of her next period to ensure she is not pregnant before starting Hana, advising her to use a reliable barrier method (such as condoms) until then. If her period does not start when she expects it to, the woman should take a pregnancy test at least 3 weeks after the last episode of unprotected sex or see her doctor. If her period comes in the meantime, she can start Hana on day 1 of that period.
</Text>

export const CaseTwelveNoYes = () => <Text {...normalText}>
This woman is <Text as="span" {...boldRed}>suitable</Text> for a supply of Hana. She has no contraindications, she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana and she does <Text as="span" {...boldRed}>not need to be referred to her doctor</Text>. <br/><br/>Because pregnancy could not be excluded, explain to her that she should wait until the first day of her next period to ensure she is not pregnant before starting Hana, advising her to use a reliable barrier method (such as condoms) until then. If her period does not start when she expects it to, the woman should take a pregnancy test at least 3 weeks after the last episode of unprotected sex or see her doctor. If her period comes in the meantime, she can start Hana on day 1 of that period. 
</Text>

export const CaseTwelveNoNo = () => <Text {...normalText}>
This woman is <Text as="span" {...boldRed}>suitable</Text> for a supply of Hana. She has no contraindications, she is not taking any medicines or herbal remedies that could reduce the effectiveness of Hana and she does <Text as="span" {...boldRed}>not need to be referred to her doctor</Text>. <br/><br/>Because pregnancy could not be excluded, explain to her that she should wait until the first day of her next period to ensure she is not pregnant before starting Hana, advising her to use a reliable barrier method (such as condoms) until then. If her period does not start when she expects it to, the woman should take a pregnancy test at least 3 weeks after the last episode of unprotected sex or see her doctor. If her period comes in the meantime, she can start Hana on day 1 of that period.
</Text>