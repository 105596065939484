import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import Cookies from 'js-cookie'
import date from 'date-and-time'
import { Stack, Heading, Text, List, ListItem, Box, Link } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

export const bullet = {
    position: 'absolute',
    top: '-21px',
    left: '-5px',
    content: "'•'",
    fontSize: '40px',
    display: "block"
}

export const subBullet = {
    position: 'absolute',
    top: '6px',
    left: '26px',
    content: "''",
    border: '1px solid #111111',
    borderRadius: "50%",
    height: '12px',
    width: "12px",
    backgroundColor: "#fff"
}

const CookiePolicy = () => {
    const today = new Date()
    const [getDate, setDate] = useState('')
    const [getId, setId] = useState('')
    
    function formatDate(dateString) {
        const newDate = dateString ? new Date(dateString) : new Date()
        const format = date.format(newDate, 'ddd, MMMM, YYYY HH:mm:ss')

        return format
    }

    useEffect(() => {
        function loadCookies() {
            const cookie = Cookies.getJSON('hana-cookies')
    
            if (cookie) {
                setId(cookie.id)
                return setDate(formatDate(cookie.date))
            }
        }
        loadCookies()
    }, [])

    function removeCookie() {
        Cookies.remove('hana-cookies')
        setDate('')
        setId('')
    }

    function setCookie() {
        const id = uuid()
        Cookies.set('hana-cookies', {accepted: true, date: today, id}, {expires: 365})
        setDate(formatDate())
        setId(id)
    }
   

    return (
        <Stack spacing="4" overflowY="auto">
            <Heading {...normalText} fontSize="18px" textDecoration="underline" textAlign="center">
                Cookies Policy
            </Heading>

            <Text {...normalText}>
                Welcome to https://hana.co.uk or to https://hanapharmacist.co.uk (hereafter "Website") which are owned by Laboratoire HRA Pharma ("HRA Pharma"). This Cookies Policy ("Cookies Policy") is designed to tell you about our practices regarding the collection of information from you when visiting the Website, arising from the use of cookies and other tracking technologies. 
            </Text>

            <Text {...normalText}>
                On your first visit to the Website, you will have seen a pop up to inform you on purposes for which cookies are being used and a means to opt in or to opt out. You may withdraw your cookies consent at any time by following the instructions described in the Cookies Policy, except for categories of cookies which are needed for essential functions of the Website (see below).
            </Text>

            <Text {...normalText}>
                After your initial visit to the Website, we may change the cookies we use. The Cookies Policy will always allow you to know who is placing cookies, for what purpose and give you the means to disable them so you should check it from time to time.
            </Text>

            <Text {...normalText} fontFamily="Gotham Bold">
                What are cookies?
            </Text>
            <Text {...normalText}>
                Cookies are text files containing small amounts of information which are downloaded to your device when you visit a website. Cookies are then sent back to the originating web domain on your subsequent visits to that domain. Most web pages contain elements from multiple web domains so when you visit the Website, your browser may receive cookies from several sources.
            </Text>
            <Text {...normalText}>
                Cookies are useful because they allow a website to recognize a user's device. Cookies allow you to navigate between pages efficiently, remember preferences and generally improve the user experience. They can also be used to tailor advertising to your interests through tracking your browsing across websites.
            </Text>
            <Text {...normalText}>
                Session cookies are deleted automatically when you close your browser and persistent cookies remain on your device after the browser is closed (for example to remember your user preferences when you return to the site).
            </Text>

            <Text {...normalText} fontFamily="Gotham Bold">
                What types of cookies does HRA Pharma use and how to manage them?
            </Text>

            <List as="ul" spacing="2" styleType="disc" marginLeft="20px">
                <ListItem 
                 {...normalText} 
                 fontFamily="Gotham Bold"
                 textDecoration="underline"
                 position="relative"
                 display="block"
                 _before={{
                     ...subBullet,
                     top: "4px",
                     left: "-20px"
                    }}
                 marginLeft="40px"
                 >
                    Functional Cookies
                </ListItem>
            </List>
            <Text {...normalText}>
                HRA Pharma with the technical assistance of its communication agency uses a cookie for the sole purpose of keeping track of the application state. No personal information is stored within this cookie. You can return within 30 days to restore a previously saved session. Our cookie stores the URL path, current page number and current assessment score.
            </Text>

            <Text {...normalText}>
                If you delete or prevent cookies from being stored within your browser, then the previous session cannot be restored if the webpage is refreshed or upon returning to the website.
            </Text>

            <List as="ul" spacing="2" styleType="disc" marginLeft="20px">
                <ListItem 
                {...normalText} 
                fontFamily="Gotham Bold"
                textDecoration="underline"
                position="relative"
                display="block"
                _before={{
                    ...subBullet,
                    top: "4px",
                    left: "-20px"
                    }}
                marginLeft="40px"
                >
                    Performance Cookies
                </ListItem>
            </List>
            <List as="ul" spacing="2" styleType="disc">
                <ListItem {...normalText} display="block list-item">
                    Name: __utma, __utmb, __utmc, __utmt, __utmz
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    Source: Google Analytics
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    Purpose: HRA Pharma uses analytic cookies to analyze how the visitors use the website and to monitor the website performance. This allows HRA Pharma to provide a high quality experience by customizing its offering and quickly identifying and fixing any issues that could arise. For example, HRA Pharma might use performance cookies to keep track of which pages are most popular, which method of linking between pages is most effective, and to determine why some pages are receiving error messages. It might also use these cookies to highlight articles or site services that could be of interest to you based on your usage of the website. The information collected by the cookies is not associated with the identification data of website users
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    Expiration: Some of these cookies are automatically deleted from your device when you close your browser. Others may last up to 13 months from your last visit to our site
                </ListItem>

                <ListItem {...normalText} display="block list-item">
                    Further information and Opt-out means: <Link href="https://tools.google.com/dlpage/gaoptout?hl=fr" target="_blank" isExternal>https://tools.google.com/dlpage/gaoptout?hl=fr</Link>
                </ListItem>
            </List>

            <Box border="1px solid #F6F8FA" display="flex" marginTop="10px">
                <Box width="10%"  borderRight="1px solid #F6F8FA">
                    <Box height="61px" padding="2" borderBottom="1px solid #F6F8FA" marginBottom="10px">
                        <Text {...normalText}>Name</Text>
                    </Box>
                    <Box padding="2">
                        <Text {...normalText}>__utma,</Text>
                        <Text {...normalText}>__utmb,</Text>
                        <Text {...normalText}>__utmc,</Text>
                        <Text {...normalText}>__utmt,</Text>
                        <Text {...normalText}>__utmz</Text>
                    </Box>
               </Box>
                <Box width="10%"  borderRight="1px solid #F6F8FA">
                    <Box height="61px" padding="2" borderBottom="1px solid #F6F8FA" marginBottom="10px">
                        <Text {...normalText}>
                            Source
                        </Text>
                    </Box>
                    <Box padding="2">
                        <Text {...normalText}>
                            Google Analytics
                        </Text>
                    </Box>
               </Box>
                <Box width="30%" borderRight="1px solid #F6F8FA">
                    <Box height="61px" padding="2" borderBottom="1px solid #F6F8FA" marginBottom="10px">
                        <Text {...normalText}>
                            Purpose
                        </Text>
                    </Box>
                    <Box padding="2">
                        <Text {...normalText}>
                            HRA Pharma uses analytic cookies to analyze how the visitors use the website and to monitor the website performance. This allows HRA Pharma to provide a high quality experience by customizing its offering and quickly identifying and fixing any issues that could arise. For example, HRA Pharma might use performance cookies to keep track of which pages are most popular, which method of linking between pages is most effective, and to determine why some pages are receiving error messages. It might also use these cookies to highlight articles or site services that could be of interest to you based on your usage of the website. The information collected by the cookies is not associated with the identification data of website users
                        </Text>
                    </Box>
                </Box>
                <Box width="25%" borderRight="1px solid #F6F8FA">
                    <Box height="61px" padding="2" borderBottom="1px solid #F6F8FA" marginBottom="10px">
                        <Text {...normalText}>
                            Expiration
                        </Text>
                    </Box>
                    <Box padding="2">
                        <Text {...normalText}>
                            Some of these cookies are automatically deleted from your device when you close your browser. Others may last up to 13 months from your last visit to our site
                        </Text>
                    </Box>
                </Box>
                <Box width="25%">
                    <Box height="61px" padding="2" borderBottom="1px solid #F6F8FA" marginBottom="10px">
                        <Text {...normalText}>
                            Further information and Opt-out means
                        </Text>
                    </Box>
                    <Box padding="2">
                        <Link {...normalText} href="https://tools.google.com/dlpage/gaoptout?hl=fr" target="_blank" isExternal textDecoration="underline">
                            https://tools.google.com/dlpage/gaoptout?hl=fr
                        </Link>
                    </Box>
                   
                </Box>
            </Box>

            <Text {...normalText} fontFamily="Gotham Bold">
                Use of IP addresses and web logs
            </Text>
            <Text {...normalText}>
                What is an IP address? It is a numeric code that identifies your computer on the internet. IP addresses are Personal Data. Your IP address is used to gather location information.
            </Text>
            <Text {...normalText}>
                HRA Pharma may also use your IP address and browser type to help diagnose problems with our server, to administer its website and to improve the quality of the services offered to you.
            </Text>
            <Text {...normalText}>
                HRA Pharma may perform IP lookups to determine which domain you are coming from (e.g. google.com) to more accurately gauge our users' demographics.
            </Text>
            <Text {...normalText}>
                Information from these types of cookies and technologies or about website usage is not combined with information about you from any other source.
            </Text>

            <Text {...normalText} fontFamily="Gotham Bold">
                How to accept or refuse cookies?
            </Text>

            <Text {...normalText}>
                You can access and change your cookie preferences at any time by clicking the links below:
            </Text>

            <Text {...normalText}>
                Your current state: {getDate === '' ? 'Consent withdrawn' : 'Allow all cookies'}
            </Text>

            <Text {...normalText}>
                Your consent ID: {getId}
            </Text>

            <Text {...normalText}>
                Consent date: {getDate}
            </Text>

    
            {getDate === '' ? <Text {...normalText} cursor="pointer" onClick={setCookie}>Change your consent</Text> : <Text {...normalText} cursor="pointer" onClick={removeCookie}>Withdraw your consent</Text>}
           
            <Text {...normalText} fontFamily="Gotham Bold">
                Scope of this Cookies Policy
            </Text>
            <Text {...normalText}>
                Please note that the Cookies Policy does not apply to, and we are not responsible for, the privacy practices of third party websites which may be linked to the Website.
            </Text>
            <Text {...normalText} fontFamily="Gotham Bold">
                How to contact us
            </Text>
            <Text {...normalText}>
                If you have any questions, comments or concerns about this Cookies Policy or the information practices of the Website, please contact our Group Data Protection Officer:
            </Text>

            <List as="ul" spacing="2" styleType="disc">
                <ListItem {...normalText} display="block list-item">
                    Via email: at dataprivacy@hra-pharma.com
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    By post at HRA Pharma, 200 avenue de Paris, 92320 Châtillon, France
                </ListItem>
            </List>
            <Text {...normalText} fontFamily="Gotham Bold">
                Changes to the Cookies Policy
            </Text>
            <Text {...normalText}>
                We may update this Cookies Policy and we would encourage you to review the policy from time to time to stay informed of how we are using cookies. This Cookies Policy was last updated on November 2020.
            </Text>
            <Text {...normalText} as="i">
                Date of preparation: December 2020 | UK/HANA/0119
            </Text>
        </Stack>
    )
}

export default CookiePolicy