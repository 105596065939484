import React from 'react'
import { Text, Box } from '@chakra-ui/core'

// Load assetss
import {ReactComponent as PartOne } from '../../images/part1Altnew.svg'
import {ReactComponent as PartTwo } from '../../images/part2Alt.svg'
import {ReactComponent as PartThree } from '../../images/part3Alt.svg'

// Load components
import Layout from '../../layout/Layout.card'
import { textBoldPara } from '../../style/styleFormat'

const textStyle = {
    color: "text.900",
    fontSize: "md",
    lineHeight: "wide",
    display: "inline-block"
}

const bkgStyleOne = {
    backgroundColor: "background.5",
    border: "1px solid #9894C6",
    marginBottom: "20px"
}

const bkgStyleTwo = {
    backgroundColor: "background.2",
    border: "1px solid #6F9BBD",
    marginBottom: "20px"
}

const bkgStyleThree = {
    backgroundColor: "background.6",
    border: "1px solid #41A2CD",
    marginBottom: "20px"
}

const AssementCard = ({ content, containerStyle, imgSrc: SVG, textColor= "text", svgSize, svgHeight }) => {
    return (
        <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center" margin="0 auto" borderRadius="21px" padding="4" maxWidth={{base:"100%", lg:"90%"}} {...containerStyle}>
            <Box marginBottom={{base: "15px", lg: "0"}}>
                {SVG}
            </Box>
            <Text marginLeft="4" {...textStyle} color={textColor}>
                {content}
            </Text>
        </Box>
    )
}

const AssementIntro = () => {
  return (
    <Layout>
        <Text {...textStyle} marginBottom="20px">
            There are three parts to this training to support your conversations with women seeking Hana. Printed copies of the guide have been distributed to all pharmacies.
        </Text>
       
            <AssementCard 
            imgSrc={<PartOne />} 
            imgAlt="Part One illustration" 
            content="Includes an overview of the reproductive cycle and explains how Hana works. It provides information about the  safety and efficacy of Hana, and who Hana is and is not indicated for."   
            containerStyle={bkgStyleOne}
            svgSize="136px"
            svgHeight="136px"
            />

            <AssementCard
            imgSrc={<PartTwo/>}
            imgAlt="Part One illustration" 
            content="Outlines what you need to do, the questions you need to ask and the advice you need to give women who request Hana in your pharmacy. This includes managing repeat supply and continued use."
            containerStyle={bkgStyleTwo}
            textColor="#244C6A"
            svgSize="220px"
            />

            <AssementCard
            imgSrc={<PartThree/>}
            imgAlt="Part One illustration"
            content="Demonstrates how consultations for different supply situations could work in practice using a series of animated videos."
            containerStyle={bkgStyleThree}
            textColor="#253C6C"
            svgSize="136px"
            />
        
      
        <Text {...textStyle} paddingLeft="15px" display="block">
            You will be assessed on all 3 parts for Continuing Professional Development (CPD) certification.
        </Text>

        <Text {...textBoldPara} padding="4" backgroundColor="#F8F7FB" borderRadius="7px" marginTop="10px">
            All of the guide and useful documents can be downloaded and printed using the links at the end of each part.
        </Text>
    </Layout>
  )
}

export default AssementIntro