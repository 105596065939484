import React from 'react'
import { Box, Text, List, ListItem, useDisclosure } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, blueBullet, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const RepeatSupplyThree = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Box borderTop="1px solid #4580AB" flexDirection={{base: "column", lg: "row"}}  display="flex">
                <Box width="100%" position="relative">
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Pharmacy supply aid reminder
                        </Text>
                    </Box>
                    <Text {...secondaryText} fontFamily="Gotham Bold" padding="2" fontWeight="bold" marginTop="20px"> 
                        Check for changes in health since last Hana purchase or desogestrel prescription
                    </Text>
                </Box>
                <Box width="100%" borderTop={{base: "1px solid #739FBF", lg: "none"}} borderLeft={{base: "none", lg:"1px solid #739FBF"}}>
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Guidance on pharmacist action
                        </Text>
                    </Box>
                    <Box padding="4">
                        <Text {...secondaryText} margin="10px 0">
                            Checking for:
                        </Text>
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                                ...blueBullet
                                }}>
                                any changes that are contraindications; in this case, the woman should be advised to see her <span style={{position: 'relative'}}>doctor<Reference>1</Reference></span>
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                any change in the woman’s health status (no new conditions and no worsening of existing conditions) since the last time she saw her doctor for a prescription or the last time she was provided with Hana; for any changes that are warnings, the  woman should be advised to see her <span style={{position: 'relative'}}>doctor<Reference>1</Reference></span>
                            </ListItem>
                        </List>
                        <Text {...secondaryText} marginTop="20px">
                            If a woman is referred to her doctor to check any contraindications or warnings to determine suitability for Hana, she should be advised that if her suitability is confirmed, she can return to the pharmacy to obtain Hana.
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default RepeatSupplyThree