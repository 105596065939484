import React, { Component } from 'react'

// Load content - Part One
import PharmaWelcome from '../../content/PharmaLearning/PharamWelcome'
import PharmacyOutcomes from '../../content/PharmaLearning/PharamLearningOutcomes'
import PharmacyHanaImportant from '../../content/PharmaLearning/PharmaHanaImportant'
import PharmaKeyFacts from '../../content/PharmaLearning/PharmaKeyFacts'
import PharamHowHanaWorks from '../../content/PharmaLearning/PharamHowHanaWorks'
import PharmaSideEffects from '../../content/PharmaLearning/PharmaSideEffects'
import PharmaSuitable from '../../content/PharmaLearning/PharamSuitable'
import PharmaProcess from '../../content/PharmaLearning/PharmaProcess'
import PharmaAtEase from '../../content/PharmaLearning/PharmaAtEase'

// Questions
import PharmaQuestions from '../../content/PharmaLearning/PharmaQuestions'

// Load assessments/Learning for part one
class PharmaModule extends Component {

    constructor(props) {
        super(props)

        // Content pages
        this.pageList = [
             {
                 key: 0,
                 Component: () => <PharmaWelcome nextPage={this.navigation} />  
             },
             {
                 key: 1,
                 Component: () => <PharmacyOutcomes />
             },
             {
                 key: 2,
                 Component: () => <PharmacyHanaImportant />
             },
             {
                 key: 3,
                 Component: () => <PharmaKeyFacts />
             },
             {
                key: 4,
                Component: () => <PharamHowHanaWorks />
            },
            {
                key: 5,
                Component: () => <PharmaSideEffects />
            },
            {
                key: 6,
                Component: () => <PharmaSuitable />
            },
            {
                key: 7,
                Component: () => <PharmaProcess />
            },
            {
                key: 8,
                Component: () => <PharmaAtEase />
            },
            {
                key: 9,
                Component: () => <PharmaQuestions click={this.navigation} />
            }
        ]
    }

    // Click here nav on questions
    navigation = () => {
        const {navControl} = this.props

        navControl('increment')
    }

    render() {
        const { currentPage } = this.props

        let component = null

        // Loop over available content
        this.pageList.forEach((item) => {

            if (currentPage === item.key) {
                component = item.Component()
            }
        })
      
        return component
    }
}

export default PharmaModule