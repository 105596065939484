import React, { useEffect } from 'react'
import { Box, Text, Heading, Button, Icon } from '@chakra-ui/core'

// Load components
import Checkbox from '../../components/UI/Checkbox/Checkbox'

// Load hook
import useInputHook from '../../hooks/useInputHook'

// Load style
import { normalText, bkglight, headingText, submitBtn } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const questionThree = {
    q1: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: false
            }
        }

    },
    q2: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    },
    q3: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: false
            }
        }
    },
    q4: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    },
    q5: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    }
}

const Card = ({content, checkbox, ...props}) => {
    return (
        <Box {...bkglight} display="flex" {...props} alignItems="center" justifyContent="space-between" width={{base: "100%", lg:"48%"}}>
            <Text {...normalText} maxWidth="85%">
                {content}
            </Text>
            {checkbox}
        </Box>
    )
}

const QuestionThree = ({ reveal, setNext, changePage }) => {
   const [form, inputCheckHandler, allowNext] = useInputHook(questionThree)
  
   // Form fields
   const { q1, q2, q3, q4, q5 } = form

   useEffect(() => {
    // Allow next is one answer been selected
    setNext(allowNext)
    }, [allowNext, setNext])
 
    return (
        <Layout>
            <Heading {...headingText} margin="10px 0">
                What are the key safety messages for all women taking Hana?
            </Heading>

            <Text {...normalText} marginBottom="20px">
                Select ALL that apply
            </Text>
            <Box display="flex" alignItems="center" flexDirection={{base: "column", lg: "row"}}  justifyContent="space-between">
              <Card 
                content='Change to the bleeding pattern is common and there is no need to return or to see your doctor in the first few months even if you are experiencing heavy or extended bleeding'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={form.q1.value} fail={q1.validation.valid} name="q1" id="q1" />}
                />
                {(reveal && q1.value) && <Box width={{base: "100%", lg:"48%"}} marginTop={{base: "-10px", lg: "0"}}>
                    <Text {...normalText} color="red.900">
                        <strong>INCORRECT</strong> - 50% of women will experience change to bleeding patterns, but it is important for the woman to come back or to see her doctor if the bleeding pattern is unacceptable
                    </Text>
                </Box>
                }
            </Box>
            <Box display="flex" alignItems="center" flexDirection={{base: "column", lg: "row"}}  justifyContent="space-between">
                <Card 
                border={(reveal && !q2.value) ? "1px solid #DF6532" : "1px solid #9894C6"} 
                content='Change to bleeding pattern is common and it is likely to settle in the first few months; however, return to the pharmacy or see your doctor if the bleeding is unacceptable'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={reveal ? reveal : form.q2.value} success={q2.validation.valid} amber={!form.q2.value} name="q2" id="q2" />}
                />
                {reveal && <Box width={{base: "100%", lg:"48%"}} marginTop={{base: "-10px", lg: "0"}}>
                    <Text {...normalText} color={(reveal && !q2.value) ? "#DF6532" : "green.900"}>
                        <strong>CORRECT</strong> - 50% of women will experience change to bleeding patterns, but it is important for the woman to come back or to see her doctor if the bleeding pattern is unacceptable
                    </Text>
                </Box>
                }
            </Box>

            <Box display="flex" alignItems="center" flexDirection={{base: "column", lg: "row"}}  justifyContent="space-between">
                <Card
                content='If you forget to take a tablet and it has been more than 12 hours, take that tablet immediately and then take the next scheduled dose as usual. There is no need to use extra precautions'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={form.q3.value} fail={q3.validation.valid} name="q3" id="q3" />}
                />
                {(reveal && q3.value) && <Box width={{base: "100%", lg:"48%"}} marginTop={{base: "-10px", lg: "0"}}>
                    <Text {...normalText} color="red.900">
                        <strong>INCORRECT</strong> - also need to remind the woman to use extra precautions for 7 days if more than 12 hours have passed; consider the need for emergency contraception
                    </Text>
                </Box>}
            </Box>

            <Box display="flex" alignItems="center" flexDirection={{base: "column", lg: "row"}}  justifyContent="space-between">
                <Card 
                border={(reveal && !q4.value) ? "1px solid #DF6532" : "1px solid #9894C6"} 
                content='If you forget to take a tablet and it has been more than 12 hours, take that tablet immediately and then take the next scheduled dose as usual. Remember to use extra precautions for the next 7 days, and consider the need for emergency contraception'
                checkbox={<Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={reveal ? reveal : form.q4.value} success={q4.validation.valid} amber={!form.q4.value} name="q4" id="q4" />}
                />
                {reveal && <Box width={{base: "100%", lg:"48%"}} marginTop={{base: "-10px", lg: "0"}}>
                    <Text {...normalText} color={(reveal && !q4.value) ? "#DF6532" : "green.900"}>
                        <strong>CORRECT</strong> - even if that means the woman takes two tablets in one day
                    </Text>
                </Box>
                }
            </Box>

            <Box display="flex" alignItems="center" flexDirection={{base: "column", lg: "row"}}  justifyContent="space-between">
                <Card 
                border={(reveal && !q5.value) ? "1px solid #DF6532" : "1px solid #9894C6"} 
                content='There is no need for extra precautions if you remember to take the forgotten tablet within 12 hours of when you usually take it'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={reveal ? reveal : form.q5.value} success={q5.validation.valid} amber={!form.q5.value} name="q5" id="q5" />}
                />
                {reveal && <Box width={{base: "100%", lg:"48%"}} marginTop={{base: "-10px", lg: "0"}}>
                    <Text {...normalText} color={(reveal && !q5.value) ? "#DF6532" : "green.900"}>
                        <strong>CORRECT</strong> - contraceptive protection of Hana is only reduced if more than 12 hours have elapsed since the next tablet should have been taken (12-hour window)
                    </Text>
                </Box>
                }
            </Box>
            {!reveal &&
            <Box display="flex" width={{base: "100%", lg:"48%"}} justifyContent="flex-end">
            <Button 
            {...submitBtn}
            onClick={changePage}
            >Submit<Icon name="chevron-right" fontSize="32px" /></Button>
            </Box>
            }
        </Layout>
    )
}

export default QuestionThree