import React from 'react'
import { Box, Text, Stack } from '@chakra-ui/core'

// Load components
import {ReactComponent as WomanHands } from '../../images/womanHands.svg'
import {ReactComponent as DoorLock } from '../../images/doorLock.svg'
import {ReactComponent as BubbleLock } from '../../images/bubbleLock.svg'
import {ReactComponent as FriendWoman } from '../../images/friendWoman.svg'
import {ReactComponent as ArrowBoard } from '../../images/arrowBoard.svg'
import {ReactComponent as LanguageBubble } from '../../images/languageBubble.svg'
import {ReactComponent as Doors } from '../../images/doors.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { normalText } from '../../style/styleFormat'

const Row = ({ svg: SVG, content }) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="flex-start" marginBottom="40px">
            <Box marginRight="30px" width="50px">
                <SVG width="50px" />
            </Box>
            <Box>
                {content}
            </Box>
        </Box>
    )
}



const PharamaAtEase = () => {
    return (
        <Layout>
            <Stack spacing={4}>
            <Text {...normalText}>
                As part of the pharmacy team, you’re often the first person customers come into contact with, and their experience with you will have an impact on their satisfaction with and use of the pharmacy service.
            </Text>
            <Text {...normalText}>
                Your role of <Text as="span" color="purple.200" fontFamily="Gotham Bold">welcoming customers</Text> to the pharmacy and <Text as="span" color="purple.200" fontFamily="Gotham Bold">engaging them in conversation</Text> about their needs is important when it comes to women seeking contraception. Topics related to sexual behaviour are <Text as="span" color="purple.200" fontFamily="Gotham Bold">sensitive and private</Text>, and women may <Text as="span" color="purple.200" fontFamily="Gotham Bold">find it difficult to talk</Text> about them.
            </Text>
            <Text {...normalText}>
                Ways you can help women feel at ease:
            </Text>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between">
                <Box width={{base: "100%", lg:"45%"}}>
                    <Row
                    svg={WomanHands}
                    content={<Text {...normalText}>be approachable, positive and supportive</Text>}
                    />
                    <Row 
                    svg={DoorLock}
                    content={<Text {...normalText}>offer them a more private place to talk if possible, such as the consultation room</Text>}
                    />
                    <Row 
                    svg={BubbleLock}
                    content={<Text {...normalText}>reassure them that the pharmacy provides a confidential service to all customers, including women under the age of 16 years</Text>}
                    />
                    <Row
                    svg={FriendWoman}
                    content={<Text {...normalText}>consider how you would feel if it were you or a friend asking for help</Text>}
                    />
                </Box>
                <Box width={{base: "100%", lg:"45%"}}>
                    <Row
                    svg={ArrowBoard}
                    content={<Text {...normalText}>be matter-of-fact</Text>}
                    />
                    <Row
                    svg={LanguageBubble}
                    content={<Text {...normalText} display="block">use their language</Text>}
                    />
                    <Row
                    svg={Doors}
                    content={<Text {...normalText} display="block">reassure them that they can return to the pharmacy to talk about their contraceptive needs and issues at any time</Text>}
                    />
                </Box>
            </Box>

            </Stack>
        </Layout>
    )
}

export default PharamaAtEase