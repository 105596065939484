/**
 * Load pages titles.
 */
export const titles = {
    0: 'HANA PHARMACY GUIDE',
    1: 'GUIDE AND ASSESSMENT',
    2: '',
    3: 'INTRODUCING HANA',
    4: 'MENSTRUAL CYCLE OVERVIEW',
    5: 'HOW HANA WORKS',
    6: 'EFFICACY OF HANA',
    7: 'EFFICACY OF HANA',
    8: 'WHO CAN USE HANA',
    9: 'WHO CAN USE HANA',
    10: 'DRUG INTERACTIONS',
    11: 'DRUG INTERACTIONS',
    12: 'QUESTIONS',
    13: 'QUESTION 1',
    14: 'QUESTION 1 - Answer',
    15: 'QUESTION 2',
    16: 'QUESTION 2 - Answer',
    17: 'QUESTION 3',
    18: 'QUESTION 3 - Answer',
    19: 'QUESTION 4',
    20: 'QUESTION 4 - Answer',
    21: 'WELL DONE',
    22: 'REMINDERS',
    23: '',
    24: 'SUPPLY SITUATIONS',
    25: 'SUPPLY SITUATIONS',
    26: 'First-Time Supply – using the Pharmacy supply aid checklist',
    27: 'First-Time Supply – using the Pharmacy supply aid checklist',
    28: 'First-Time Supply – using the Pharmacy supply aid checklist',
    29: 'First-Time Supply – using the Pharmacy supply aid checklist',
    30: 'First-Time Supply – using the Pharmacy supply aid checklist',
    31: 'First-Time Supply – using the Pharmacy supply aid checklist',
    32: 'First-Time Supply – excluding pregnancy',
    33: 'First-Time Supply – excluding pregnancy',
    34: 'First-Time Supply – starting hana',
    35: 'First-Time Supply – starting hana',
    36: 'Repeat Supply or Changing Supply – using the Pharmacy supply aid checklist',
    37: 'Repeat Supply or Changing Supply – using the Pharmacy supply aid checklist',
    38: 'Repeat Supply or Changing Supply – using the Pharmacy supply aid checklist',
    39: 'Repeat Supply or Changing Supply – using the Pharmacy supply aid checklist',
    40: 'Repeat Supply or Changing Supply – using the Pharmacy supply aid checklist',
    41: 'Repeat Supply or Changing Supply - excluding pregnancy',
    42: 'Information on quick starting Hana',
    43: 'Information on quick starting Hana',
    44: 'Advice for all women taking Hana',
    45: 'Advice for all women taking Hana',
    46: 'Advice for all women taking Hana',
    47: 'Advice for all women taking Hana',
    48: 'Advice for all women taking Hana',
    49: 'Advice for all women taking Hana',
    50: 'Advice for all women taking Hana',
    51: 'Questions',
    52: 'Question 1',
    53: 'Question 1 - Answer',
    54: 'Question 2',
    55: 'Question 2 - Answer',
    56: 'Question 3',
    57: 'Question 3 - Answer',
    58: 'Well Done',
    59: 'Reminders',
    60: '',
    61: 'Assessing suitability and providing advice',
    62: 'Well Done',
    63: 'ASSESSMENT',
    64: 'Question 1',
    65: 'Question 1 - Answer',
    66: 'Question 2',
    67: 'Question 2 - Answer',
    68: 'Question 3',
    69: 'Question 3 - Answer',
    70: 'Question 4',
    71: 'Question 4 - Answer',
    72: 'Question 5',
    73: 'Question 5 - Answer',
    74: 'Question 6',
    75: 'Question 6 - Answer',
    76: 'Question 7',
    77: 'Question 7 - Answer',
    78: 'Question 8',
    79: 'Question 8 - Answer',
    80: 'Question 9',
    81: 'Question 9 - Answer',
    82: 'Question 10',
    83: 'Question 10 - Answer',
    84: 'Question 11',
    85: 'Question 11 - Answer',
    86: 'Question 12',
    87: 'Question 12 - Answer',
    88: 'Question 13',
    89: 'Question 13 - Answer',
    90: 'Question 14',
    91: 'Question 14 - Answer',
    92: 'Question 15',
    93: 'Question 15 - Answer',
    94: 'CASE STUDIES',
    95: 'CASE STUDIES',
    96: 'CASE STUDIES',
    97: 'CASE STUDIES',
    98: 'CASE STUDIES',
}

export const pharmaTitles = {
    0: 'HANA PHARMACY TEAM TRAINING',
    1: '',
    2: 'WHY IS THE AVAILABILITY OF HANA IN PHARMACY IMPORTANT?',
    3: 'WHAT ARE THE KEY FACTS ABOUT HANA?',
    4: 'HOW DOES HANA WORK?',
    5: 'WHAT ARE THE POSSIBLE SIDE EFFECTS WITH HANA?',
    6: 'WHO MAY NOT BE SUITABLE FOR HANA?',
    7: 'WHAT IS THE PROCESS FOR SUPPLYING HANA IN PHARMACY?',
    8: 'WHAT CAN HELP WOMEN FEEL AT EASE IN PHARMACY?',
    9: 'Questions',
    10: 'Question 1',
    11: 'Question 1 - Answer',
    12: 'Question 2',
    13: 'Question 2 - Answer',
    14: 'Question 3',
    15: 'Question 3 - Answer',
    16: 'Question 4',
    17: 'Question 4 - Answer',
    18: 'Question 5',
    19: 'Question 5 - Answer'
}