import React, { useState } from'react'
import { Box, Text, SimpleGrid, useDisclosure } from '@chakra-ui/core'

// Load assets
import Card1 from '../../images/cards/card1.png'
import Card2 from '../../images/cards/card2.png'
import Card3 from '../../images/cards/picture-2.png'
import Card4 from '../../images/cards/picture-3.png'
import Card5 from '../../images/cards/picture-4.png'
import Card6 from '../../images/cards/picture-5.png'
import Card7 from '../../images/cards/picture-6.png'
import Card8 from '../../images/cards/picture-7.png'

// Load vids
import vidone from '../../video/Scenario1.mp4'
import vidtwo from '../../video/Scenario2.mp4'
import vidthree from '../../video/Scenario3.mp4'
import vidfour from '../../video/Scenario4.mp4'
import vidfive from '../../video/Scenario5.mp4'
import vidsix from '../../video/Scenario6.mp4'
import vidseven from '../../video/Scenario7.mp4'
import videight from '../../video/Scenario8.mp4'

// Load components
import Modal from '../../components/Modal/Modal'
import Video from '../../components/Video/Video'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText } from '../../style/styleFormat'


const VideoCard = ({ img, body, click }) => {
    return (
        <Box backgroundImage={`url(${img})`} backgroundPosition="center" minHeight="205px"  backgroundSize="cover" backgroundRepeat="no-repeat" height="100%" width="100%" cursor="pointer" onClick={click}>
            <Box width="100%" height="100%" padding="4" display="flex" alignItems="center" justifyContent="center" >
                <Text fontSize="14px" color="#fff" fontFamily="Gotham Bold"  fontWeight="bold" textAlign="center">
                    {body}
                </Text>
            </Box>
        </Box>
    )
}

const AssessingSuitability = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [activeVid, setVid] = useState(false)

    function openModal(content) {
        setVid(content)
        onOpen()
    }
    return (
        <Layout>
            <Text {...secondaryText} marginBottom="20px" fontWeight="bold">
                Click on the links below to watch short animated videos showing pharmacy consultations with women wanting to purchase Hana
            </Text>
            <SimpleGrid minChildWidth="243px" spacing="10px">
                <VideoCard img={Card1} click={() => openModal({vid: vidone, thumb: Card1})} body="A woman who has never used any form of contraception" />
                <VideoCard img={Card2} click={() => openModal({vid: vidtwo, thumb: Card2})} body="A woman switching from combined oral contraception" />
                <VideoCard img={Card3} click={() => openModal({vid: vidthree, thumb: Card3})} body="An adolescent woman who has never used any form of contraception" />
                <VideoCard img={Card4} click={() => openModal({vid: vidfour, thumb: Card4})} body="A woman who has previously purchased Hana from a pharmacy"/>
                <VideoCard img={Card5} click={() => openModal({vid: vidfive, thumb: Card5})} body="A woman who has previously been prescribed desogestrel by her doctor" />
                <VideoCard img={Card6} click={() => openModal({vid: vidsix, thumb: Card6})} body="A woman who has previously purchased Hana from a pharmacy and has experienced bleeding after sex" />
                <VideoCard img={Card7} click={() => openModal({vid: vidseven, thumb: Card7})} body="A woman who has never used Hana before and who has a history of hepatitis" />
                <VideoCard img={Card8} click={() => openModal({vid: videight, thumb: Card8})} body="A woman who has previously purchased Hana from a pharmacy and has experienced heavy bleeding" />
            </SimpleGrid>
            <Modal isOpen={isOpen} onClose={onClose} size="lg-custom" title="Video">
                <Video
                    vidSrc={activeVid.vid}
                    imgThumb={activeVid.thumb}
                />
            </Modal>
        </Layout>
    )
}

export default AssessingSuitability