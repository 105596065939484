import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

export const CaseEightYesYes = () => <Text {...normalText}>
You <Text as="span" {...boldGreen}>can resupply</Text> just 3 months of Hana to this woman to prevent pregnancy, which has been excluded, but she should be advised to <Text as="span" {...boldGreen}>see her doctor</Text> because of the change in her epilepsy. Her doctor will decide whether Hana should be discontinued and an alternative method of contraception found. She should be advised to start the next pack of Hana as soon as she finishes her current one, without a break.
</Text>

export const CaseEightYesNo = () => <Text {...normalText}>
You <Text as="span" {...boldRed}>can resupply</Text> just 3 months of Hana to this woman to prevent pregnancy, which has been excluded, but she should be advised to <Text as="span" {...boldRed}>see her doctor</Text> because of the change in her epilepsy. Her doctor will decide whether Hana should be discontinued and an alternative method of contraception found. She should be advised to start the next pack of Hana as soon as she finishes her current one, without a break.
</Text>

export const CaseEightNoNo = () => <Text {...normalText}>
You <Text as="span" {...boldRed}>can resupply</Text> just 3 months of Hana to this woman to prevent pregnancy, which has been excluded, but she should be advised to <Text as="span" {...boldRed}>see her doctor</Text> because of the change in her epilepsy. Her doctor will decide whether Hana should be discontinued and an alternative method of contraception found. She should be advised to start the next pack of Hana as soon as she finishes her current one, without a break.
</Text>

export const CaseEightNoYes = () => <Text {...normalText}>
You <Text as="span" {...boldRed}>can resupply</Text> just 3 months of Hana to this woman to prevent pregnancy, which has been excluded, but she should be advised to <Text as="span" {...boldRed}>see her doctor</Text> because of the change in her epilepsy. Her doctor will decide whether Hana should be discontinued and an alternative method of contraception found. She should be advised to start the next pack of Hana as soon as she finishes her current one, without a break.
</Text>