import React, { Fragment } from 'react'
import { Stack, Text, Box, Flex, Button, Link } from '@chakra-ui/core'

// Load components
import {ReactComponent as Logo } from '../../images/Hana_Logo_White.svg'
import {ReactComponent as Shield } from '../../images/Shield.svg'
import {ReactComponent as Arrow } from '../../images/right-arrow.svg'
import {CircleProgress } from 'react-gradient-progress'
import Drawer from '../../components/Drawer/Drawer'
import Menu from '../Menu/Menu'
import Resources from '../Resources/Resources'

const PresentationBreadcrumb = () => {
    return (
        <Box display="flex" alignItems="center" margin="15px 20px">
            <Box backgroundColor="#fff" marginRight="8px" width="6px" height="6px" borderRadius="50%" border="1px solid #fff"></Box>
            <Box backgroundColor="#9591D9" marginRight="8px" width="6px" height="6px" borderRadius="50%" border="1px solid #9591D9"></Box>
            <Box backgroundColor="#9591D9" marginRight="8px" width="6px" height="6px" borderRadius="50%" border="1px solid #9591D9"></Box>
            <Box backgroundColor="#9591D9" marginRight="10px" width="25px" height="6px" borderRadius="3px" border="1px solid #9591D9"></Box>
        </Box>
    )
}

const SideNav = ({ progress, maxPage, setPage, open, setOpen, sidedrawer, hasMenu = true }) => {
    const partone = progress <= 22
    const parttwo = (progress > 22 && progress <= 59)
    const partthree = progress > 59
    const complete = ((progress / maxPage) * 100).toFixed(0) 
   
    // Direct nav and close menu
    function closeChangePage(number) {
            setPage(number)
            setOpen(false)
    }
    return (
        <Stack spacing={7} align="center" paddingBottom={{base: "30px", lg: "0"}}>
            <Box display={{base: "none", lg: "block"}} mt="25px" width="35%">
                <Logo height="100%" width="100%" />
            </Box>
            <Box display={{base: "none", lg: "block"}} maxWidth="3xs">
               <CircleProgress strokeLinecap="square" width={100} strokeWidth={8} primaryColor={["#E5753A", "#fae6ca"]} percentage={parseInt(complete)} fontSize="16px" fontColor="#fff" fontFamily="Gotham Bold" />
            </Box>
            <Box width="100%" position="relative">
                <Drawer show={open} close={setOpen}>
                    {open !== 3 && <Menu changePage={closeChangePage} close={setOpen} toDisplay={open} />}
                    {open === 3 && <Resources isPharmacy={hasMenu} close={setOpen}  />}
                </Drawer>
                {hasMenu && 
                <Fragment>
                <Box cursor="pointer" onClick={() => sidedrawer(1)} display="flex" backgroundColor={open === 1 ? '#423F7E' : null} flexDirection="column" justifyContent="center" borderLeft={`solid 2px ${partone ? '#E5753A' :'#9590EF'}`} width="100%" height="75px" position="relative">
                    <Text display="flex" alignItems="center" fontSize="md" opacity={partone ? 1 : .5}  color="white" fontWeight={700} marginLeft="20px"><Shield style={{marginRight: "5px"}} /> Part 1</Text>
                    {partone && <Box position="absolute" top="55%" left="30px"><PresentationBreadcrumb /></Box>}
                    <Box position="absolute" right="15px" top="50%" transform="translateY(-50%)"><Arrow /></Box>
                </Box>
                <Box cursor="pointer" onClick={() => sidedrawer(2)} display="flex" backgroundColor={open === 2 ? '#423F7E' : null} flexDirection="column" justifyContent="center" borderLeft={`solid 2px ${parttwo ? '#E5753A' :'#9590EF'}`} width="100%" height="75px" position="relative">
                    <Text display="flex" alignItems="center" fontSize="md" opacity={parttwo ? 1 : .5}  color="white" fontWeight={700} marginLeft="20px"><Shield style={{marginRight: "5px"}} />Part 2</Text>
                    {parttwo && <Box position="absolute" top="55%" left="30px"><PresentationBreadcrumb /></Box>}
                    <Box position="absolute" right="15px" top="50%" transform="translateY(-50%)"><Arrow /></Box>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" borderLeft={`solid 2px ${partthree ? '#E5753A' :'#9590EF'}`} width="100%" height="75px" position="relative">
                    <Text display="flex" alignItems="center" fontSize="md" opacity={partthree ? 1 : .5}  color="white" fontWeight={700} marginLeft="20px"><Shield style={{marginRight: "5px"}} />Part 3</Text>
                    {partthree && <Box position="absolute" top="55%" left="30px"><PresentationBreadcrumb /></Box>}
                </Box>
                </Fragment>
                }
            </Box>
            <Box width="90%">
                <Text fontSize="sm" color="purple.300" lineHeight="wide">
                    For full information about Hana, including all contraindications, warnings and possible side effects,
                    please click on the links to access the Summary of Product Characteristics (SmPC) and the Patient Information Leaflet (PIL).
                </Text>
            </Box>
            <Box width={{base:"80%", lg: "90%"}}>
                <Flex align="center" justify="space-between" alignItems="space-between" mb=".5rem">
                    <Button width="7.5rem" backgroundColor="purple.800" color="white" variant="solid">
                        <Link display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" href="https://www.medicines.org.uk/emc/product/12735/smpc" target="_blank" rel="noopener noreferrer">SmPC</Link>
                    </Button>
                    <Button width="7.5rem" backgroundColor="purple.800" color="white" borderRadius="4px" variant="solid">
                        <Link display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" href="https://www.medicines.org.uk/emc/product/12735/pil" target="_blank" rel="noopener noreferrer">PIL</Link>
                    </Button>
                </Flex>
                    <Button onClick={() => sidedrawer(3)} backgroundColor="purple.800" color="white" variant="solid" width="full">Resources</Button>
                </Box>
        </Stack>
    )
}

export default SideNav