import React, { useEffect } from 'react'
import { Box, Text, Heading, Button, Icon } from '@chakra-ui/core'

// Load components
import Checkbox from '../../components/UI/Checkbox/Checkbox'

// Load hook
import useInputHook from '../../hooks/useInputHook'

// Load style
import { normalText, bkglight, headingText, submitBtn } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const questionFour = {
    q1: {
        value: false,
        content: 'Phenytoin',
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }

    },
    q2: {
        value: false,
        content: 'Paracetamol',
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: false
            }
        }
    },
    q3: {
        value: false,
        content: 'Rifampicin',
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    },
    q4: {
        value: false,
        content: 'Bosentan',
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    },
    q5: {
        value: false,
        content: 'Ulipristal acetate',
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    },
    q6: {
        value: false,
        content: 'St. John’s Wort',
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    },
    q7: {
        value: false,
        content: 'Efavirenz',
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    },
    q8: {
        value: false,
        content: 'Ibuprofen',
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: false
            }
        }
    },
    q9: {
        value: false,
        content: 'Carbamazepine',
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    }
}

const QuestionFour = ({ reveal, setNext, changePage }) => {
    const [form, inputCheckHandler, allowNext] = useInputHook(questionFour)
    
    useEffect(() => {
        // Allow next is one answer been selected
        setNext(allowNext)
    }, [allowNext, setNext])

    const Card = ({content, value, validation, name, change}) => {
      
        const wrong = validation.rules.anwser === false && value
        const amber = !value && validation.rules.anwser
        return (
            <Box display="flex" alignItems="baseline" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between"  width="100%">
                <Box width={{base: "100%", lg:"48%"}} height="100%" display="flex" alignItems="center"> 
                    <Box flexGrow="1" height="100%" {...bkglight} borderColor={(reveal && amber) ? "orange.900" :"#9894C6"} borderRadius="10px" marginRight="10px">
                        <Text {...normalText}>
                            {content}
                        </Text>
                    </Box>
                    <Box  height="62px" width="65px" {...bkglight} borderColor={(reveal && amber) ? "orange.900" :"#9894C6"} borderRadius="10px">
                        {validation.rules.anwser && <Checkbox reveal={reveal} on_change={change} checked={reveal ? reveal : value} success={validation.valid} amber={!value} name={name} id={name} />}
                        {!validation.rules.anwser && <Checkbox reveal={reveal} on_change={change} checked={value} fail={validation.valid} name={name} id={name} />}
                    </Box>
                </Box>
                {(reveal && wrong)  && 
                    <Box width="48%" height="50%" marginTop={{base: "-20px", lg: "0"}}>
                    <Text {...normalText} color="red.900">
                        <strong>INCORRECT</strong>
                    </Text>
                </Box>
                }
                {(reveal && validation.rules.anwser) &&
                <Box width="48%" height="50%" marginTop={{base: "-20px", lg: "0"}}>
                    <Text {...normalText} color={value ? "green.900" : "orange.900"}>
                        <strong>CORRECT</strong>
                    </Text>
                </Box>
                }
            </Box>
        )
    }

    return (
        <Layout>
            <Box>
                <Heading {...headingText}>
                    Which of the following medicines or herbal remedies can reduce the effectiveness of Hana?
                </Heading>
                <Text {...normalText} margin="15px 0">
                    Select ALL that apply
                </Text>

                {Object.keys(form).map((key) => {
                    return (
                        <Card
                            key={key}
                            content={form[key].content}
                            value={form[key].value}
                            validation={form[key].validation}
                            name={key}
                            change={inputCheckHandler}
                        />
                    )
                })}
            </Box>
            <Box display="flex" width={{base: "100%", lg:"48%"}} justifyContent="flex-end">
            {!reveal &&
                <Button 
            {...submitBtn}
            onClick={changePage}
            >Submit<Icon name="chevron-right" fontSize="32px" /></Button>
            }
            </Box>
        </Layout>
    )
}

export default QuestionFour