import React from 'react'
import { Box, Text } from '@chakra-ui/core'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText } from '../../style/styleFormat'

const RepeatSupplyTwo = () => {
    return (
        <Layout>
            <Box borderTop="1px solid #4580AB" flexDirection={{base: "column", lg: "row"}} display="flex">
                <Box width="100%" position="relative">
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Pharmacy supply aid reminder
                        </Text>
                    </Box>
                    <Text {...secondaryText} fontFamily="Gotham Bold" padding="2" fontWeight="bold" marginTop="20px"> 
                        Check for any problems related to Hana or desogestrel use since initiation
                    </Text>
                </Box>
                <Box width="100%" borderTop={{base: "1px solid #739FBF", lg: "none"}} borderLeft={{base: "none", lg:"1px solid #739FBF"}}>
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Guidance on pharmacist action
                        </Text>
                    </Box>
                    <Box padding="4">
                        <Text {...secondaryText} margin="10px 0">
                            Checking for any side effects and for an opportunity to provide advice on managing common side effects, or referring her to see her doctor. 
                        </Text>
                        <Text {...secondaryText}>
                            Lists of common as well as uncommon side effects are provided later in this guide.
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Layout>
    )
}

export default RepeatSupplyTwo