import React from 'react'
import { Box } from '@chakra-ui/core'


const LayoutCard = ({ children }) => {
    return (
        <Box background="white" width="100%" borderRadius="20px" padding="8">
            {children}  
        </Box>
    )
}


export default LayoutCard