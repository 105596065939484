import React from 'react'
import { Box, Text, Image } from '@chakra-ui/core'

// Load assets
import HanaPacket from '../../images/HANA_Packshot.png'

// Load style
import { normalText, subTitlelightPurple } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const PharmaQuestions = ({ click }) => {
    return (
        <Layout>
            <Box display="flex" alignItems="center" flexDirection={{base: "column", lg: "row"}} justifyContent="space-around" paddingBottom="20px">
                <Image src={HanaPacket} width={{base: "100%", lg:"45%"}} alt="Hana product" />
                <Box marginLeft="0px" width={{base: "100%", lg:"45%"}}>
                    <Text {...subTitlelightPurple} color="purple.200" marginTop={{base: "20px", lg: "0"}}>
                    You’ve finished your Hana pharmacy team training! To check your own knowledge, take a short test and earn your certificate of completion.
                    </Text>
                    <Text {...normalText} marginTop="10px" color="purple.900">
                        <Text as="u" cursor="pointer" fontWeight="bold" onClick={() => click('increment')}>Click here</Text> (or next) to start.
                    </Text>
                </Box>
            </Box>
        </Layout>
    )
}

export default PharmaQuestions