import React from 'react'
import { Box, Text, List, ListItem, useDisclosure } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, blueBullet, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const RepeatSupplyFour = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Box borderTop="1px solid #4580AB" flexDirection={{base: "column", lg: "row"}}  display="flex">
                <Box width="100%" position="relative">
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Pharmacy supply aid reminder
                        </Text>
                    </Box>
                    <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" padding="2" marginTop="20px"> 
                        Check for changes in vaginal bleeding (e.g. heavy or prolonged bleeding, or bleeding following sexual intercourse)
                    </Text>
                </Box>
                <Box width="100%" borderTop={{base: "1px solid #739FBF", lg: "none"}} borderLeft={{base: "none", lg:"1px solid #739FBF"}}>
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Guidance on pharmacist action
                        </Text>
                    </Box>
                    <Box padding="4">
                        <Text {...secondaryText} margin="10px 0">
                            Checking for changes to bleeding pattern or unusual bleeding.
                        </Text>
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                Hana primarily acts by inhibiting ovulation, so it is normal for the woman to experience some change in her bleeding <span style={{position: 'relative'}}>patterns<Reference>1</Reference></span>
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                If periods are more frequent and this is bothersome for the woman, she should be referred to her doctor for an alternative method of <span style={{position: 'relative'}}>contraception<Reference>1</Reference></span>
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                If periods differ from those expected with Hana or are unusually heavy, she should be referred to her doctor for investigation to rule out any underlying  <span style={{position: 'relative'}}>pathology<Reference>1</Reference></span>
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                if she repeatedly has bleeding after sex, she should be referred  to her doctor for investigation (e.g. for cervical cancer<span style={{position: 'relative'}}>)<Reference>1</Reference></span>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Box>
            <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default RepeatSupplyFour