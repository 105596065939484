import React, { useEffect } from 'react';

function checkForCookieBanner() {
  let intervalCounter = 0;
  const interval = setInterval(() => {
      const banner = document.querySelector('#onetrust-banner-sdk');
      if (banner) {
          clearInterval(interval);

          var acceptBtn = document.getElementById("onetrust-accept-btn-handler");
          acceptBtn.style.background='#006400';
          acceptBtn.style.borderColor = "green";
          var declineBtn = document.getElementById("onetrust-reject-all-handler");
          declineBtn.style.background='#FF0000';
          declineBtn.style.borderColor = "red";
          acceptBtn.parentNode.insertBefore(acceptBtn,acceptBtn.parentNode.children[1])
      } else {
        if(intervalCounter > 5) clearInterval(interval);
        intervalCounter++;
      }
  }, 500);
}

const CookieBanner = () => {
  useEffect(() => {
    // Create and append OtAutoBlock script
    const otAutoBlockScript = document.createElement('script');
    otAutoBlockScript.src = "https://cdn.cookielaw.org/consent/cc4de529-90e2-4372-9122-f7478f7bf215/OtAutoBlock.js";
    otAutoBlockScript.type = "text/javascript";
    otAutoBlockScript.charset = "UTF-8";
    document.head.appendChild(otAutoBlockScript);

    // Create and append otSDKStub script
    const otSDKStubScript = document.createElement('script');
    otSDKStubScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    otSDKStubScript.type = "text/javascript";
    otSDKStubScript.charset = "UTF-8";
    otSDKStubScript.setAttribute('data-domain-script', 'cc4de529-90e2-4372-9122-f7478f7bf215');
    document.head.appendChild(otSDKStubScript);

    // Initialize the OneTrust script
    otSDKStubScript.onload = () => {
      window.OptanonWrapper && window.OptanonWrapper();
      checkForCookieBanner();
    };

    return () => {
      document.head.removeChild(otAutoBlockScript);
      document.head.removeChild(otSDKStubScript);
    };
  }, []);

  return null; // No UI for the banner, it is handled by OneTrust
};

export default CookieBanner;