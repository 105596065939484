import React, { useEffect, useState } from 'react'
import { Box, Text, Heading, ListItem } from '@chakra-ui/core'

// Load svg
import {ReactComponent as Cross} from '../../images/cancel-2.svg'
import {ReactComponent as Tick} from '../../images/tick-2.svg'

// DnD
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// Load style
import { normalText, bkglight, headingText} from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const content = {
    initial: [
        {
            name: 'Currently breastfeeding',
            answer: true,
            id: 'correct-1'
        },
        {
            name: 'Has liver cancer',
            answer: false,
            id: 'wrong-1'
        },
        {
            name: 'Has diabetes',
            answer: false,
            id: 'wrong-2'
        },
        {
            name: 'Following an abortion',
            answer: true,
            id: 'correct-2'
        },
        {
            name: 'Younger than 18 years old',
            answer: true,
            id: 'correct-3'
        },
        {
            name: 'Has a history of chloasma',
            answer: true,
            id: 'correct-4'
        },
        {
            name: 'Unable to take contraception containing oestrogen',
            answer: true,
            id: 'correct-5'
        },
        {
            name: 'Undiagnosed vaginal bleeding after sex',
            answer: false,
            id: 'wrong-3'
        },
        {
            name: 'Has a history of breast cancer',
            answer: false,
            id: 'wrong-4'
        },
        {
            name: 'Mother requesting Hana for her daughter',
            answer: false,
            id: 'wrong-5'
        },
        {
            name: 'Has previously had a venous thromboembolic disorder',
            answer: true,
            id: 'correct-6'
        },
        {
            name: 'Has epilepsy, receiving treatment',
            answer: false,
            id: 'wrong-6'
        },
        {
            name: 'Has tuberculosis, receiving treatment',
            answer: false,
            id: 'wrong-7'
        },
        {
            name: 'First-time user, with no known contraindications',
            answer: true,
            id: 'correct-7'
        },
        {
            name: 'Allergic to the active substance',
            answer: false,
            id: 'wrong-8'
        },
        {
            name: 'Repeat user, with no known contraindications and a positive experience with desogestrel',
            answer: true,
            id: 'correct-8'
        },
        {
            name: 'Allergic to the excipients (inactive substances)',
            answer: false,
            id: 'wrong-9'
        },
        {
            name: 'Previously using a contraceptive skin patch',
            answer: true,
            id: 'correct-9'
        }
    ],
    correct: [],
    wrong: []
}


// Reorder list
function reorder(list, startIndex, endIndex) {
   
    // Reordered the same list
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
  
    return result
}

// Move items in list
function move(init, source, destination, droppableSource, droppableDestination) {
    const sourceClone = Array.from(source)
    const destinationClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destinationClone.splice(droppableDestination.index, 0, removed)

    const result = {...init}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destinationClone

    return result

}

// Apply stlye when dragging over drop box
function getListStyle(isDraggingOver) {
 return isDraggingOver ? '.7' : '1'
 
}

const QuestionThree = ({ reveal, setNext }) => {
    const [list, updateList] = useState(content)
    const allowNext = list.initial.length <= 0
   
    useEffect(() => {
        // Allow next is one answer been selected
        setNext(allowNext)
    }, [setNext, allowNext])

    // Access list from store
    function getList (id) {
        return list[id]
    }

    // Hanlde onDragEnd data
    function handleDragEnd(result) {
        const { source, destination } = result
       
        // Check we are within drag constraints
        if (!result.destination) {
            return
        }

        // Reorder same list
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            )
             
            let copyState = {...list}
            let listUpdate = {...copyState[source.droppableId]}
            listUpdate = items
            copyState[source.droppableId] = listUpdate


           return updateList(copyState)
        }   
        
        // Move list
        if (source.droppableId !== destination.droppableId) {
            const result = move(
                list,
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            )

            return updateList(result)
        }
    }


    return (
        <Layout>
        <DragDropContext onDragEnd={handleDragEnd}>
            <Heading {...headingText}>
            Can the woman be immediately supplied with Hana, or should she be referred to her doctor or
            refused supply?
            </Heading>
            <Text {...normalText} margin="10px 0">
                Whilst many of the women presenting for regular contraception will be healthy women, it is important that you understand situations in which Hana should not be provided in pharmacy, and/or women should be referred to their doctor.
            </Text>
            <Text {...normalText}  margin="20px 0">
                Drag your answers into the <Text as="span" fontFamily="Gotham Bold" color="purple.100">YES</Text>, provide Hana immediately box, or the <Text as="span" fontFamily="Gotham Bold" color="purple.100">NO</Text>, refer to doctor or refuse box
            </Text>

            <Box width="100%">
                <Droppable droppableId="initial">
                    {(provided) => (
                        <Box as="ul" textDecoration="none" listStyleType="none" {...provided.droppableProps}  ref={provided.innerRef} display="flex" justifyContent="flex-start" flexWrap="wrap" >
                        {list['initial'].map((item, index) => {
                            return <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                                <ListItem
                                listStyleType="none"
                                textDecoration="none"
                                {...bkglight}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                padding="10px"
                                borderRadius="10px"
                                textAlign="center"
                                width={{base: "100%", lg: "auto"}}
                                maxWidth={{base: "100%", lg:"290px"}}
                                marginRight={{base: "0", lg:"10px"}}
                                height="60px"
                                display="flex"
                                alignSelf="center"
                                alignItems="center"
                                >
                                <Text {...normalText} fontSize="10px">
                                    {item.name}
                                </Text>
                                </ListItem>
                            )}
                            </Draggable>
                        })}
                        </Box>
                    )}
                
                </Droppable>
            </Box>

            <Box width="100%" height={{base: "800px", lg:"500px"}} display="flex" flexDirection={{base: "column", lg:"row"}} justifyContent="space-between" alignItems="flex-start">
                <Droppable droppableId="correct">
                    {(provided, snapshot) => (
                        <Box height="100%" overflowY="auto" width={{base: "100%", lg:"48%"}} {...bkglight}  opacity={getListStyle(snapshot.isDraggingOver)}  {...provided.droppableProps}  ref={provided.innerRef}>
                            <Text {...normalText} color="green.900" marginBottom="20px">
                                <Text as="span" fontWeight="bold" fontFamily="Gotham Bold">YES</Text>, provide Hana immediately assuming no other contraindications or precautions required and counselling provided:
                            </Text>
                            <Box as="ul" textDecoration="none" listStyleType="none" spaccing="4">
                                {list['correct'].length > 0 && list['correct'].map((item, index) => {
                                    return (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided) => (
                                                <ListItem
                                                textDecoration="none"
                                                listStyleType="none"
                                                {...bkglight}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                padding="10px"
                                                borderRadius="10px"
                                                textAlign="center"
                                                maxWidth="100%"
                                                marginRight="10px"
                                                height="60px"
                                                display="flex"
                                                alignSelf="center"
                                                alignItems="center"
                                                borderColor={(!reveal) ? "#9894C6" : (reveal && !item.answer)  ? "red.900" : "green.900"}
                                                >
                                                <Text {...normalText} width="100%" fontSize="12px" display="flex" alignItems="center" justifyContent="space-between"> 
                                                    {item.name}
                                                    {(reveal && !item.answer) &&
                                                        <Box as="span" width="10px">
                                                            <Cross width="15px" height="15px" />
                                                    </Box>
                                                    }
                                                    {(reveal && item.answer) &&
                                                        <Box as="span" width="10px">
                                                            <Tick width="15px" height="15px" />
                                                    </Box>
                                                    }
                                                </Text>
                                            </ListItem>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </Box>
                        </Box>
                    )}
                </Droppable>

                <Droppable droppableId="wrong">
                    {(provided, snapshot) => (
                        <Box height="100%" overflowY="auto" width={{base: "100%", lg:"48%"}} {...bkglight}  opacity={getListStyle(snapshot.isDraggingOver)}  {...provided.droppableProps}  ref={provided.innerRef}>
                            <Text {...normalText} color="red.900" marginBottom="20px">
                                    <Text as="span" fontWeight="bold" fontFamily="Gotham Bold">NO</Text>, refer to doctor or refuse:
                            </Text>
                            <Box as="ul" textDecoration="none" listStyleType="none" spaccing="4">
                                {list['wrong'].length > 0 && list['wrong'].map((item, index) => {
                                    return (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided) => (
                                                <ListItem
                                                textDecoration="none"
                                                listStyleType="none"
                                                {...bkglight}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                padding="10px"
                                                borderRadius="10px"
                                                textAlign="center"
                                                maxWidth="100%"
                                                marginRight="10px"
                                                height="60px"
                                                display="flex"
                                                alignSelf="center"
                                                alignItems="center"
                                                borderColor={(!reveal) ? "#9894C6" : (reveal && item.answer)  ? "red.900" : "green.900"}
                                                >
                                                <Text {...normalText} width="100%" fontSize="12px" display="flex" alignItems="center" justifyContent="space-between"> 
                                                    {item.name}
                                                    {(reveal && item.answer) &&
                                                        <Box as="span" width="10px">
                                                            <Cross width="15px" height="15px" />
                                                    </Box>
                                                    }
                                                    {(reveal && !item.answer) &&
                                                        <Box as="span" width="10px">
                                                            <Tick width="15px" height="15px" />
                                                    </Box>
                                                    }
                                                </Text>
                                            </ListItem>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </Box>
                        </Box>
                    )}
                </Droppable>
            </Box>
            </DragDropContext>
        </Layout>
    )
} 

export default QuestionThree