import React from 'react'
import { Box, Text, ListItem, List, useDisclosure, Heading } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import Reference from '../../components/UI/Typography/Reference'
import {ReactComponent as Info} from '../../images/infoAlt.svg'

// Load assets
import {ReactComponent as Symbol} from '../../images/symbolPlus.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, bkLightRed, blueBullet, referenceLink } from '../../style/styleFormat'

const AdviceTakingHanaSix = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Heading {...secondaryText} display="block" fontSize="lg">
                What side effects to be aware of
            </Heading>
            <Text {...secondaryText} margin="10px 0">
                Hana is generally well-tolerated and serious side effects are <span style={{position: 'relative'}}>rare. &nbsp;<Reference>1,2</Reference></span>&nbsp; Below are lists of the commonly (occurring in at least 1 woman per 100) and uncommonly reported (at least 1 woman per 1000) side effects with Hana. <Text as="span" fontFamily="Gotham Bold">Please read the SmPC for full details of side effects.</Text>
            </Text>

            <Box margin="20px 0" borderTop="2px solid #4580AB" display="flex" flexDirection="column">
                <Box display="flex" borderBottom="1px solid #739FBF" width="100%">
                    <Box backgroundColor="#E3ECF3" padding="4" borderRight="1px solid #739FBF" width="50%">
                        <Text {...secondaryText} display="inline-block" fontFamily="Gotham Bold" fontWeight="bold" position="relative">
                            Common<Reference><Text fontFamily="Gotham Book" fontSize="10px" fontWeight="300">1</Text></Reference>
                        </Text>
                    </Box>
                    <Box backgroundColor="#E3ECF3" padding="4" width="50%">
                        <Text {...secondaryText} display="inline-block" fontFamily="Gotham Bold" fontWeight="bold" position="relative">
                            Uncommon<Reference><Text fontFamily="Gotham Book" fontSize="10px" fontWeight="300">1</Text></Reference>
                        </Text>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box padding="4" borderRight="1px solid #739FBF" backgroundColor="#F5F9FB" width="50%">
                        <List as="ul" styleType="none" spacing="4">
                            <ListItem {...secondaryText} display="block">
                                Altered mood, depressed mood
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Decreased libido
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Headache
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Nausea
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Acne
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Breast pain
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Irregular menstruation or amenorrhoea
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Increased body weight
                            </ListItem>
                        </List>
                    </Box>
                    <Box padding="4" width="50%">
                        <List as="ul" styleType="none" spacing="4">
                            <ListItem {...secondaryText}  display="block">
                                Vaginal infection
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Contact lens intolerance
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Vomiting
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Alopecia
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Dysmenorrhoea
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Ovarian cyst
                            </ListItem>
                            <ListItem {...secondaryText} display="block">
                                Fatigue
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Box>

            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" marginBottom="10px">
                Irregular bleeding
            </Text>
            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems={{base: "center", lg:"flex-start"}}>
                <Symbol />
                <Box padding="4">
                    <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" marginBottom="20px">
                        The most commonly reported side effect is irregular bleeding, which has been reported by up to 50% of women using Hana<span style={{position: 'relative'}}>.<Reference>1</Reference></span>
                    </Text>
                    <List spacing="4">
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="25px"
                        position="relative"
                        _before={{
                           ...blueBullet
                            }}>
                            Bleeding may become more frequent in 20-30% of women and less frequent or totally absent in <span style={{position: 'relative'}}>20%<Reference>1</Reference></span>
                        </ListItem>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="25px"
                        position="relative"
                        _before={{
                           ...blueBullet
                            }}>
                            Bleeding episodes tend to become less frequent and settle after a few months of <span style={{position: 'relative'}}>treatment<Reference>1</Reference></span>
                        </ListItem>
                    </List>
                </Box>
            </Box>

            <Box {...bkLightRed} padding="8" margin="20px 0" maxWidth={{base: "100%", lg:"70%"}} position="relative">
                <Box position="absolute" top="-6px" left="-10px">
                    <Info name="info" borderRadius="50%" border="2px solid #fff" backgroundColor="#fff" color="#F0CED5" size="40px" />
                </Box>
                <Text {...secondaryText} fontWeight="bold" fontFamily="Gotham Bold">
                    Remind every woman to read the PIL carefully before taking Hana.
                </Text>
            </Box>
            <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Patient Information Leaflet, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default AdviceTakingHanaSix