import React from 'react'
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io'
import { Box } from '@chakra-ui/core'


const bubble = {
    position: "relative",
	borderRadius: "5px"
}

const SpeechBox = ({ color, children, arrow="left" }) => {
    return (
    
        <Box {...bubble} backgroundColor={color} width="75%" padding="4" >
            {arrow === "left" &&<IoMdArrowDropleft fontSize="20px" style={{position: 'absolute', left: '-12px', top: '50%', transform: 'translateY(-50%)'}} color="#fff" />}
            {children}
            {arrow === "right" &&<IoMdArrowDropright fontSize="20px" style={{position: 'absolute', right: '-12px', top: '50%', transform: 'translateY(-50%)'}} color="#239436" />}
        </Box>
    )
}

export default SpeechBox