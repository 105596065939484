import React from 'react'
import { Box, Heading, PseudoBox, Flex } from '@chakra-ui/core'

// Load assetss
import {ReactComponent as  SVG} from '../../images/partThree.svg'

const textStyle = {
    fontSize: "md",
    lineHeight: "wide",
    display: "block",
    color: "#fff",
}

const LearningOutcome = ({number, content, borderStyle }) => {

    return (
        <Box display="flex" justify="space-between" alignItems="center" marginBottom="20px">
            <Heading as="h2" fontSize="xlg" color="#fff" lineHeight="widest" maxWidth="10px" marginRight="8">
                {number}
            </Heading>
            <Box width="100%">
                <PseudoBox 
                as="text" {...textStyle} 
                position="relative"
                padding="10px 0"
                _after={borderStyle && {
                    content: "''",
                    position: 'absolute',
                    width: "100%",
                    bottom: '-15px',
                    left: '0',
                    border: "1px dashed #fff"
                }}
                >
                    {content}
                </PseudoBox>
            </Box>
        </Box>
    )
}

const LearningOutComesPt1 = () => {
    return (
        <Flex align="center" direction="column">
            <SVG height="200px" width={{base: "100%", lg:"400px"}} />
            <Box>
                <Heading fontSize="xlg" color="#fff" lineHeight="widest" margin="25px 0" textTransform="uppercase">
                    Learning outcomes at a glance
                </Heading>
            
                <LearningOutcome
                number={1}
                content="Consolidate knowledge of when supply of Hana is/is not appropriate"
                borderStyle
                />
                <LearningOutcome
                number={2}
                content="Know what questions to ask women when discussing Hana in different supply situations"
                />
            </Box>
        </Flex>
    )
}

export default LearningOutComesPt1