import React, { useState } from'react'
import { Box, Text, List, Stack, ListItem, useDisclosure, PseudoBox } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import Drug from '../../components/DrugInteractions/DrugInteractions'

// Load assets
// import {ReactComponent as ArrowAngle } from '../../images/arrowAngle.svg'
// import {ReactComponent as ArrowRight } from '../../images/arrowRight.svg'
// import {ReactComponent as ArrowDouble } from '../../images/doubleArrow.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'


// const circle = {
//     position: 'absolute',
//     top: '-90px',
//     left: '50%',
//     transform: 'translateX(-50%)',
//     border: '1px solid #463D90',
//     height: '108px',
//     width: '108px',
//     background: '#fff',
//     borderRadius: '50%',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
// }

// const highlight = {
//     color: "#7162B6",
//     fontWeight: "bold",
//     fontFamily: "Gotham Bold",
//     fontSize: "14px"
// }

const ReferencesPopUp = () => (
    <List as="ol" styleType="decimal" spacing="4">
        <ListItem {...referenceText}>
            Hana (desogestrel) Summary of Product Characteristics, November 2020.
        </ListItem>
        <ListItem {...referenceText}>
            Hana (desogestrel) Patient Information Leaflet, November 2020.
        </ListItem>
    </List>
)

// const DrugPopUp = () => (
//     <Box {...bkLightRed} display="flex" flexDirection="row" justifyContent="space-between" marginTop="60px" marginBottom="20px" padding="8">
//         <Box width="32%" padding="4" position="relative">
//             <Box {...circle}>
//                 <ArrowAngle />
//             </Box>
//             <Box marginBottom="20px"></Box>
//             <PseudoBox 
//             as="div"
//             position="relative" 
//             height="100%"
//             _after={{
//                 position: 'absolute',
//                 content: "''",
//                 top: 0,
//                 right: "-8%",
//                 width: '1px',
//                 height: '100%',
//                 backgroundColor: '#4B3F8D',
//             }}
//             >
//         <Stack spacing="4" >
//             <Text {...normalText}>
//             Substances may <Text as="span" {...highlight}>increase the clearance of contraceptive hormones</Text>
//             by enzyme induction, which may lead to breakthrough bleeding and/or contraceptive <span style={{position: 'relative'}}>failure.<Reference>1</Reference></span>
//             </Text>
//             <Text {...highlight} >
//             Women need to use additional precautions during treatment with and for 28 days after stopping these enzyme-inducing medicines. If these medicines are used chronically or long term, the woman should be referred to her doctor for further <span style={{position: 'relative'}}>advice. &nbsp;<Reference>1,2</Reference></span>
//             </Text>
//             <Text {...normalText} color="#7162B6">
//                 These medicines include treatments <span style={{position: 'relative'}}>for: &nbsp;<Reference>1,2</Reference></span>
//             </Text>

//             <List spacing="4">
//                 <ListItem 
//                 {...normalText}
//                 paddingLeft="20px"
//                 position="relative"
//                 display="block"
//                 _before={{
//                     position: 'absolute',
//                     top: '-5px',
//                     left: '-5px',
//                     content: "'•'",
//                     color: 'purple.500',
//                     fontSize: '54px'
//                     }}
//                 >
//                     epilepsy (e.g. primidone, phenytoin, carbamazepine, oxcarbazepine, felbamate, topiramate, phenobarbital)
//                 </ListItem>
//                 <ListItem 
//                 {...normalText}
//                 paddingLeft="20px"
//                 position="relative"
//                 display="block"
//                 _before={{
//                     position: 'absolute',
//                     top: '-5px',
//                     left: '-5px',
//                     content: "'•'",
//                     color: 'purple.500',
//                     fontSize: '54px'
//                     }}
//                 >
//                     tuberculosis (e.g. rifampicin, rifabutin)
//                 </ListItem>
//                 <ListItem 
//                 {...normalText}
//                 paddingLeft="20px"
//                 position="relative"
//                 display="block"
//                 _before={{
//                     position: 'absolute',
//                     top: '-5px',
//                     left: '-5px',
//                     content: "'•'",
//                     color: 'purple.500',
//                     fontSize: '54px'
//                     }}
//                 >
//                     HIV infections(e.g. efavirenz)
//                 </ListItem>
//                 <ListItem 
//                 {...normalText}
//                 paddingLeft="20px"
//                 position="relative"
//                 display="block"
//                 _before={{
//                     position: 'absolute',
//                     top: '-5px',
//                     left: '-5px',
//                     content: "'•'",
//                     color: 'purple.500',
//                     fontSize: '54px'
//                     }}
//                 >
//                     pulmonary arterial hypertension (e.g. bosentan)
//                 </ListItem>
//                 <ListItem 
//                 {...normalText}
//                 paddingLeft="20px"
//                 position="relative"
//                 display="block"
//                 _before={{
//                     position: 'absolute',
//                     top: '-5px',
//                     left: '-5px',
//                     content: "'•'",
//                     color: 'purple.500',
//                     fontSize: '54px'
//                     }}
//                 >
//                     depression (the herbal remedy St. John’s Wort)
//                 </ListItem>
//                 <ListItem 
//                 {...normalText}
//                 paddingLeft="20px"
//                 position="relative"
//                 display="block"
//                 _before={{
//                     position: 'absolute',
//                     top: '-5px',
//                     left: '-5px',
//                     content: "'•'",
//                     color: 'purple.500',
//                     fontSize: '54px'
//                     }}
//                 >
//                     fungal infections (e.g. griseofulvin)
//                 </ListItem>
//             </List>
//         </Stack>
//     </PseudoBox>
//     </Box>

//     <Box width="32%" padding="4" position="relative">
//        <Box {...circle}>
//         <ArrowDouble />
//        </Box>
//        <Box marginBottom="20px"></Box>
//         <Stack spacing="4">
//             <Text {...normalText}>
//                 Substances may have <Text as="span" {...highlight}>variable effects on the clearance of contraceptive hormones,</Text> increasing or decreasing the plasma concentrations of progestins, which may have a clinically relevant net effect in some <span style={{position: 'relative'}}>cases.<Reference>1</Reference></span>
//             </Text>
//             <Text {...highlight} >
//                 In case of doubt, women should use additional precautions during treatment with these medicines. If these medicines are used chronically or long term, the woman should be referred to her doctor for further <span style={{position: 'relative'}}>advice. &nbsp;<Reference>1,2</Reference></span>
//             </Text>
//             <Text {...normalText} color="#7162B6">
//                 These medicines include treatments <span style={{position: 'relative'}}>for: &nbsp;<Reference>1,2</Reference></span>
//             </Text>
//             <List spacing="4">
//                 <ListItem 
//                 {...normalText}
//                 paddingLeft="20px"
//                 position="relative"
//                 display="block"
//                 _before={{
//                     position: 'absolute',
//                     top: '-5px',
//                     left: '-5px',
//                     content: "'•'",
//                     color: 'purple.500',
//                     fontSize: '54px'
//                     }}
//                 >
//                     HIV infections (e.g. ritonavir, nelfinavir, nevirapine)
//                 </ListItem>
//                 <ListItem 
//                 {...normalText}
//                 paddingLeft="20px"
//                 position="relative"
//                 display="block"
//                 _before={{
//                     position: 'absolute',
//                     top: '-5px',
//                     left: '-5px',
//                     content: "'•'",
//                     color: 'purple.500',
//                     fontSize: '54px'
//                     }}
//                 >
//                     hepatitis C virus infection (e.g. boceprevir, telaprevir)
//                 </ListItem>
//             </List>
//         </Stack>
//     </Box>

//     <Box width="32%" padding="4" position="relative">
//         <Box {...circle}>
//             <ArrowRight />
//         </Box>
//         <Box marginBottom="20px"></Box>
//         <PseudoBox 
//         as="div"
//         position="relative" 
//         height="100%"
//         _before={{
//             position: 'absolute',
//             content: "''",
//             top: 0,
//             left: "-8%",
//             width: '1px',
//             height: '100%',
//             backgroundColor: '#4B3F8D',
//         }}
//         >
//         <Stack spacing="4">
//             <Text {...normalText}>
//                 Substances may <Text as="span" {...highlight}>decrease the clearance of contraceptive hormones</Text> by enzyme inhibition, increasing the serum concentrations of progestins including the active metabolite of desogestrel, the clinical relevance of which remains <span style={{position: 'relative'}}>unknown.<Reference>1</Reference></span>
//             </Text>
//             <Text {...highlight}>
//                 If these medicines are used chronically or long term, the woman should be referred to her doctor for further <span style={{position: 'relative'}}>advice. &nbsp;<Reference>1,2</Reference></span>
//             </Text>
//             <Text {...normalText} color="#7162B6">
//                 These medicines include treatments <span style={{position: 'relative'}}>for: &nbsp;<Reference>1,2</Reference></span>
//             </Text>
//             <List spacing="4">
//                 <ListItem 
//                 {...normalText}
//                 paddingLeft="20px"
//                 position="relative"
//                 display="block"
//                 _before={{
//                     position: 'absolute',
//                     top: '-5px',
//                     left: '-5px',
//                     content: "'•'",
//                     color: 'purple.500',
//                     fontSize: '54px'
//                     }}
//                 >
//                     fungal infections (e.g. ketoconazole, itraconazole, fluconazole)
//                 </ListItem>
//                 <ListItem 
//                 {...normalText}
//                 paddingLeft="20px"
//                 position="relative"
//                 display="block"
//                 _before={{
//                     position: 'absolute',
//                     top: '-5px',
//                     left: '-5px',
//                     content: "'•'",
//                     color: 'purple.500',
//                     fontSize: '54px'
//                     }}
//                 >
//                     bacterial infections (e.g. clarithromycin, erythromycin)
//                 </ListItem>
//                 <ListItem 
//                 {...normalText}
//                 paddingLeft="20px"
//                 position="relative"
//                 display="block"
//                 _before={{
//                     position: 'absolute',
//                     top: '-5px',
//                     left: '-5px',
//                     content: "'•'",
//                     color: 'purple.500',
//                     fontSize: '54px'
//                     }}
//                 >
//                     high blood pressure, abnormal heart rhythm or angina (e.g. diltiazem)
//                 </ListItem>
//             </List>
//             </Stack>
//         </PseudoBox>
//     </Box>
// </Box>
// )

const FirstTimeSupplySix = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [content, setContent] = useState('')
    
    const loadContent = content === 'References' ? <ReferencesPopUp /> : <Drug />

    function modalContent(content) {
        setContent(content)
        onOpen()
    }
    return (
        <Layout>
            <Box borderTop="1px solid #4580AB" flexDirection={{base: "column", lg: "row"}} display="flex" minHeight="300px">
                <Box width="100%" position="relative">
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Checklist question
                        </Text>
                    </Box>
                    <PseudoBox 
                    position="relative" 
                    margin="30px auto"
                    width="80%"
                    height="25%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#E3ECF3"
                    borderRadius="23px"
                    padding="6"
                    textAlign="center"
                    _before={{
                        content: "''",
                        width: "0px",
                        height: '0px',
                        position: 'absolute',
                        top: '20px',
                        left: '-28px',
                        borderLeft: "25px solid transparent",
                        borderRight: "25px solid #E3ECF3",
                        borderTop: "25px solid #E3ECF3",
                        borderBottom: "25px solid transparent"
                    }}
                    >
                    <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" fontSize="lg" textAlign="left"> 
                        Are you currently taking any regular medication or herbal remedies, or have you recently taken any other medication?
                    </Text>
                </PseudoBox>
            </Box>
            <Box width="100%" borderLeft={{base: "none", lg:"1px solid #739FBF"}} borderTop={{base: "1px solid #739FBF", lg: "none"}}>
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Guidance on pharmacist action
                        </Text>
                    </Box>
                    <Stack spacing="4" padding="4">
                        <Text {...secondaryText}>
                            Some medicines or herbal remedies affect the clearance of contraceptive hormones, and may have an impact on the contraceptive efficacy of <span style={{position: 'relative'}}>Hana. &nbsp;<Reference>1,2</Reference></span>
                        </Text>
                        <Text {...secondaryText}>
                        The prescribing information for any concomitant medicines needs to be consulted to identify potential Interactions and any <span style={{position: 'relative'}}>recommendations.<Reference>1</Reference></span>
                        </Text>
                        <Text {...secondaryText}>
                            If enzyme-inducing medicines or herbal remedies are intended for short-term use, Hana can be supplied to the woman but she should be advised to use extra precautions whilst she is taking these substances and for 28 days after she stops taking <span style={{position: 'relative'}}>them. &nbsp;<Reference> 1,2</Reference></span>&nbsp; If medicines or herbal remedies impacting CYP enzymes are used chronically or long term, Hana may not be the most appropriate method of contraception and the woman should be referred to her doctor for further advice.<sup>1,2</sup>
                        </Text>
                        <Text {...secondaryText}>
                            <Text as="u" fontFamily="Gotham Bold" cursor="pointer" fontWeight="bold" onClick={() => modalContent('Drug Interactions')}>Click here</Text> for a list of medicines impacting CYP enzymes; read the SmPC for a full list of drug interactions.
                        </Text>
                        <Text {...secondaryText}>
                            Emergency contraception containing ulipristal acetate and Hana both bind to the progesterone receptor<span style={{position: "relative"}}>.<Reference>1</Reference></span>&nbsp; Concomitant use may result in reduced efficacy of both Hana and ulipristal and is therefore not recommended.<sup>1</sup> Guidance on taking Hana after emergency contraception was introduced in Part 1, and is expanded later in Part 2. 
                        </Text>
                    </Stack>
                </Box>
            </Box>
            <Text {...referenceLink} color="blue.50" as="u" cursor="pointer" onClick={() => modalContent('References')} marginTop="20px">
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title={content} size={content === 'References' ? 'xl' : 'lg-custom'}>
               {loadContent}
            </Modal>
        </Layout>
    )
}

export default FirstTimeSupplySix