import React, { useEffect, useMemo } from 'react'
import { Box, Text, Heading, Button, Icon } from '@chakra-ui/core'

// Load components
import Checkbox from '../../UI/Checkbox/Checkbox'

// Load hook
import useInputHook from '../../../hooks/useInputHook'

// Load style
import { normalText, bkglight, headingText, submitBtn} from '../../../style/styleFormat'

// Load layout
import Layout from '../../../layout/Layout.card'

const Card = ({content, checkbox, ...props}) => {
    return (
        <Box {...bkglight} display="flex" {...props} alignItems="center" justifyContent="space-between" width={{base: "100%", lg:"45%"}}>
            <Text {...normalText} maxWidth="85%">
                {content}
            </Text>
            {checkbox}
        </Box>
    )
}

const SelectWithAnswer = ({ changePage, questions=[], title, subTitle, reveal, setNext, setAnswer, single }) => {
   const [form, inputCheckHandler, allowNext, checkCorrect] = useInputHook(questions, single)
   const answer = useMemo(() => checkCorrect(), [checkCorrect]) 

   useEffect(() => {

    // We are on answer on reload allow next
    if (reveal){
        return setNext(true)
    }
    // Allow next is one answer been selected
    setNext(allowNext)
    }, [reveal, allowNext, setNext])

   useEffect(() => {
        
        // Check if the answer was correct and fire callback
        if (reveal) {
        setAnswer(answer)
    
        }
   }, [reveal, setAnswer, answer])
 
    return (
        <Layout>
            <Heading {...headingText} margin="10px 0" dangerouslySetInnerHTML={{__html: title}} />
        
            <Text {...normalText} marginBottom="20px">
                {subTitle}
            </Text>
            {Object.keys(questions).map((key) => {
                return (
                    <Box key={key} display="flex" flexDirection={{base: "column", lg: "row"}} alignItems={{base: "center", lg:"flex-start"}} justifyContent="space-between">
                        <Card 
                        border={(reveal && questions[key].status === 'CORRECT' && !form[key].value) ? "1px solid #DF6532" : "1px solid #9894C6"}
                        content={questions[key].content}
                        checkbox={<Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={(questions[key].status === 'CORRECT' && reveal) ? reveal : form[key].value} fail={form[key].validation.valid.wrong} amber={!form[key].value} success={(form[key].value && form[key].validation.valid.correct)} name={key} id={key} />}
                        />
                        {(reveal && form[key].value && questions[key].status === 'INCORRECT') && <Box width={{base: "100%", lg:"45%"}}>
                            <Text {...normalText} color={questions[key].status === 'CORRECT' ? form[key].value ? "green.900" : "orange.900" : "red.900"} marginBottom="15px">
                                <strong>{questions[key].status}</strong> - {questions[key].reveal}
                            </Text>
                        </Box>
                        }
                        {(reveal && questions[key].status === 'CORRECT') && <Box width={{base: "100%", lg:"45%"}}>
                        <Text {...normalText} color={questions[key].status === 'CORRECT' ? form[key].value ? "green.900" : "orange.900" : "red.900"} marginBottom="15px">
                            <strong>{questions[key].status}</strong> - {questions[key].reveal}
                        </Text>
                    </Box>
                    }
                    </Box>
                )
            })}
            {(!single && !reveal) &&
                <Box display="flex" width="100%" justifyContent="flex-end">
                <Button 
                {...submitBtn}
                onClick={() => changePage('increment')}
                >Submit<Icon name="chevron-right" fontSize="32px" /></Button>
                </Box>
            }
        </Layout>
    )
}

export default SelectWithAnswer