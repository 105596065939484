import React from 'react'
import { Box, Text, useDisclosure, ListItem, List, PseudoBox } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'

// Load assets
import {ReactComponent as Woman } from '../../images/AssetWoman.svg'

// Load style
import { normalText, referenceText, referenceLink, purpleBullet } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const left = {
    position: 'absolute',
    content: "''",
    top: '-10px',
    left: "-10px",
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: '10px solid #fff',
}

const right = {
    position: 'absolute',
    content: "''",
    bottom: '-8px',
    right: "-2px",
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid #fff',
}

const LineArrow = ({ direction, length }) => {
    return (
        <PseudoBox width={`${length}px`} height="5px" border="1px" borderColor="purple.900" backgroundColor="#fff" position="relative" _after={{...direction}} />
    )
}

const PharamKeyFacts = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
   
    return (
        <Layout>
            <Text {...normalText} marginBottom="20px" color="purple.200" fontFamily="Gotham Bold" display="block">
                To understand how Hana prevents pregnancy and explain this to women in pharmacy, it’s helpful to have an understanding of the menstrual cycle:
            </Text>
       
            <List spacing="4">
                <ListItem 
                {...referenceText}
                fontFamily="Gotham Book"
                paddingLeft="20px"
                position="relative"
                display="block"
                _before={{
                ...purpleBullet,
                top: "-32px"
                }}
                >
                the normal menstrual cycle varies in length<sup>1</sup> 
                </ListItem>

                <ListItem 
                {...referenceText}
                fontFamily="Gotham Book"
                paddingLeft="20px"
                position="relative"
                display="block"
                _before={{
                ...purpleBullet,
                top: "-32px"
                }}
                >
                    for a woman with a 28-day cycle, the egg grows through the first 12 days or so, reaching maturity at the time of <Text as="span" fontFamily="Gotham Bold" color="purple.200">ovulation</Text> when it’s released from the ovary<sup>1</sup> 
                </ListItem>

                <ListItem 
                {...referenceText}
                fontFamily="Gotham Book"
                paddingLeft="20px"
                position="relative"
                display="block"
                _before={{
                ...purpleBullet,
                top: "-32px"
                }}
                >
                    this is also when the <Text as="span" fontFamily="Gotham Bold" color="purple.200">cervical mucus becomes watery,</Text> allowing the free passage of sperm from the cervix into the uterus to achieve <Text as="span" fontFamily="Gotham Bold" color="purple.200">fertilisation</Text> when it meets the egg<sup>2</sup>
                </ListItem>

                <ListItem 
                {...referenceText}
                fontFamily="Gotham Book"
                paddingLeft="20px"
                position="relative"
                display="block"
                _before={{
                ...purpleBullet,
                top: "-32px"
                }}
                >
                    if fertilisation doesn’t happen, the woman will get her period around 14 days after ovulation when the lining of the uterus is shed<sup>1</sup>
                </ListItem>
            </List>

            <Text {...normalText} margin="30px 0" color="purple.200" fontFamily="Gotham Bold">
                Hana prevents pregnancy in two independent ways:
            </Text>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center" justifyContent="space-between" margin="0 auto" maxWidth="850px" overflow="auto">
                <Box position="relative" width={{base: "100%", lg:"28%"}} minWidth={{base: "auto", lg:"250px"}} marginRight={{base: "0", lg:"20px"}} border="1px solid #6D75B6" borderRadius="23px" padding="4">
                    <Text {...normalText}>primarily by <Text as="span" color="purple.200" fontFamily="Gotham Bold">inhibiting ovulation in up to 97% of cycles</Text> if taken correctly<sup>3,4</sup></Text>
                    <Box display={{base: "none", lg: "block"}} zIndex="500" position="absolute" right={{base: "55%", lg:"-90px"}} bottom={{base: "-60px", lg:"55%"}} transform={{base: "translateY(-50%)" , lg: "translateY(-50%)"}}>
                        <LineArrow direction={right} length={90}/>
                    </Box>
                </Box>
                <Box width={{base: "70%", lg:"28%"}} minWidth="250px" margin="20px auto" display="flex" justifyContent="center">
                    <Woman width="255px" />
                </Box>
                <Box position="relative" width={{base: "100%", lg:"28%"}} minWidth={{base: "auto", lg:"250px"}} marginLeft={{base: "0", lg:"20px"}} border="1px solid #6D75B6" borderRadius="23px" padding="4">
                    <Text {...normalText}>
                        also by <Text as="span" fontFamily="Gotham Bold" color="purple.900">thickening the cervical mucus</Text> to create a plug that <Text as="span" fontFamily="Gotham Bold" color="purple.900"> sperm can't pass through</Text><sup>2</sup>
                    </Text>
                    <Box display={{base: "none", lg: "block"}} zIndex="500" position="absolute" left="-150px" bottom="30%">
                        <LineArrow direction={left} length={150} />
                    </Box>
                </Box>
            </Box>

            
        
            <Text as="u" {...referenceLink} onClick={onOpen}>References</Text>

            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List spacing="4" as="ol" styleType="decimal">
                    <ListItem {...referenceText}>
                        Aitken RJ, Baker MA, Doncel GF, et al. As the world grows: contraception in the 21st century. J Clin Invest 2008;118(4):1330-1343.
                    </ListItem>
                    <ListItem {...referenceText}>
                        McCann MF and Potter LS. Progestin-only oral contraception: a comprehensive review. Contraception 1994;50(6 Suppl 1):S1-S95.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Rice C, Killick S, Hickling D, et al. Ovarian activity and vaginal bleeding patterns with a desogestrel-only preparation at three different doses. Hum Reprod 1996;11(4):737-740.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Rice CF, Killick SR, Dieben T, et al. A comparison of the inhibition of ovulation achieved by desogestrel 75 micrograms and levonorgestrel 30 micrograms daily. Hum Reprod 1999;14(4):982-985.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default PharamKeyFacts