import React, { useState } from'react'
import { useHistory } from 'react-router-dom'
import { Box, Text, Stack, Flex, Button, Link, Heading } from '@chakra-ui/core'

// Load assets
import Card8 from '../../images/cards/picture-7.png'

// Load vids
import MOA from '../../video/MOA.mp4'

// Load components
import Video from '../../components/Video/Video'
import Footer from '../../components/Footer/Footer'
import Resources from '../Resources/Resources'
import Drawer from '../../components/Drawer/Drawer'

// Load assets
import SmPC from '../../pdf/SmPC.pdf'
import PIL from '../../pdf/PIL.pdf'
import {ReactComponent as Logo } from '../../images/Hana_Logo_White.svg'

// Load layout
import Layout from '../../layout/Layout.card'
import LayoutBase from '../../layout/Layout.base'

// Load style
import { headingText } from '../../style/styleFormat'

const SideNav = ({open, setOpen}) => {
    const history = useHistory()
   return ( 
    <Stack spacing={7} align="center" paddingBottom={{base: "30px", lg: "0"}}>
        <Box display={{base: "none", lg: "block"}} mt="25px" width="35%">
            <Logo onClick={() => history.push('/')} height="100%" width="100%" />
        </Box>
        <Box width="100%" position="relative">
            <Drawer show={open} close={setOpen}>
                {open &&<Resources close={setOpen}  />}
            </Drawer>
        </Box>
        <Box width="90%">
            <Text fontSize="sm" color="purple.300" lineHeight="wide">
                For full information about Hana, including all contraindications, warnings and possible side effects,
                please click on the links to access the Summary of Product Characteristics (SmPC) and the Patient Information Leaflet (PIL).
            </Text>
        </Box>
        <Box width={{base:"80%", lg: "90%"}}>
            <Flex align="center" justify="space-between" alignItems="space-between" mb=".5rem">
                <Button width="7.5rem" backgroundColor="purple.800" color="white" variant="solid">
                    <Link display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" href={SmPC} target="_blank" rel="noopener noreferrer">SmPC</Link>
                </Button>
                <Button width="7.5rem" backgroundColor="purple.800" color="white" borderRadius="4px" variant="solid">
                    <Link display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" href={PIL} target="_blank" rel="noopener noreferrer">PIL</Link>
                </Button>
            </Flex>
                <Button onClick={setOpen} backgroundColor="purple.800" color="white" variant="solid" width="full">Resources</Button>
        </Box>
    </Stack>
    )
}

const MOAPage = () => {

    const [drawerOpen, setDrawer] = useState(false)

    return (
        <LayoutBase sideNav={<SideNav open={drawerOpen} setOpen={() => setDrawer(!drawerOpen)} />}>
            <Box height="100%" padding="8" display="flex" flexDirection="column" justifyContent="space-between" alignItems="space-between">
            <Layout>
                <Heading {...headingText} marginBottom="20px">Menstrual Cycle Overview</Heading>
                <Video vidSrc={MOA} imgThumb={Card8} />
            </Layout>
            <Footer productCode="0003" />
            </Box>
        </LayoutBase>
        
    )
}

export default MOAPage