import { useEffect, useState } from 'react'

function useLockBody() {

    const [display, setDisplay] = useState(false)

    // Create scroll lock once displayed
    useEffect(() => {

        if (display) {
            const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
            const body = document.body
            body.style.position = 'fixed'
            body.style.top = `-${scrollY}`
           
            // stop here
            return
        }

        // Revert back to normal
        if(!display) {
            const body = document.body
            const scrollY = body.style.top
            body.style.position = ''
            body.style.top = ''
            window.scrollTo(0, parseInt(scrollY || '0') * -1)
        }
    
    }, [display])

    useEffect(() => {
        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
        })

        return () => {
            window.removeEventListener('scroll', () => {
                document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
            })
        }
    }, [])

    return [setDisplay]
}

export default useLockBody