import React from 'react'
import { Box, Text, useDisclosure, ListItem, List, Stack } from '@chakra-ui/core'

// Load components
import Reference from '../../components/UI/Typography/Reference'
import Modal from '../../components/Modal/Modal'

// Load assets
import {ReactComponent as Woman } from '../../images/womanShape.svg'

// Load style
import { normalText, bkglight, referenceText, referenceLink } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const FactCard = ({ label, content }) => {
    return (
        <Box {...bkglight} display="flex" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between" alignItems="center" >
            <Box width={{base: "100%", lg:"60px"}} display="flex" justifyContent={{base: "center", lg:"space-between"}} alignItems="center" marginBottom={{base: "20px", lg: "0"}}>
                <Woman width={{base: "25%", lg:"100%"}} />
                <Box marginLeft="5px" as="span" width={{base: "auto", lg:"15px"}} fontSize="44px" fontWeight="bold" color="blue.100">{label}</Box>
            </Box>
            <Box width={{base: "100%", lg: "70%"}}>
                {content}
            </Box>
            <Box width="25px"></Box>
        </Box>
    )
}

const EfficacyOfHanaTwo = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Text {...normalText} display="block">
                A Pearl Index of 0.4 equates to 4 women in a thousand getting pregnant on Hana in one year. This is similar to the pregnancy rates for combined oral contraceptives in clinical <span style={{position: 'relative'}}>studies <Reference>1</Reference></span>
            </Text>
            <Text {...normalText} display="block" margin="20px 0">
                It is estimated that, <Text color="purple.900" fontFamily="Gotham Book" fontWeight="bold" fontSize="14px" as="span">if used as recommended:</Text>
            </Text>

            <Stack spacing={4}>
            <FactCard
                label={4}
                content={<Text {...normalText}><Text as="span" color="purple.900" fontFamily="Gotham Bold" fontWeight="bold" fontSize="14px">women in 1000</Text> would get pregnant in one year using a sympto-thermal method (ovulation method with calendar in the pre-ovulatory phase and basal body temperature in the post-ovulatory <span style={{position: 'relative'}}>phase) <Reference>2</Reference></span></Text>}
            />
            <FactCard 
                label={20}
                content={<Text {...normalText}><Text as="span" color="purple.900" fontFamily="Gotham Bold" fontWeight="bold" fontSize="14px">women in 1000</Text> would get pregnant in one year using <span style={{position: 'relative'}}>condoms <Reference>2</Reference></span></Text>}
            />
            <FactCard 
                label={30}
                content={<Text {...normalText}><Text as="span" color="purple.900" fontFamily="Gotham Bold" fontWeight="bold" fontSize="14px">women in 1000</Text> would get pregnant in one year using an ovulation method (cervical mucus) to identify times when there is a risk that they could get <span style={{position: 'relative'}}>pregnant <Reference>2</Reference></span></Text>}
            />
            <FactCard 
                label={180}
                content={<Text {...normalText}><Text as="span" color="purple.900" fontFamily="Gotham Bold" fontWeight="bold" fontSize="14px">women in 1000</Text> would get pregnant in one year using spermicides <span style={{position: 'relative'}}>alone <Reference>2</Reference></span></Text>}
            />
            
            </Stack>

            <Text {...referenceLink} onClick={onOpen} as="u">References</Text>

            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" spacing="4" styleType="decimal">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Trussell J. Contraceptive failure in the United States. Contraception 2011;83(5):397-404.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default EfficacyOfHanaTwo