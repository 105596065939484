import React, { useEffect } from 'react'
import { Box, Text, Heading, Button, Icon } from '@chakra-ui/core'

// Load components
import Checkbox from '../../components/UI/Checkbox/Checkbox'

// Load hook
import useInputHook from '../../hooks/useInputHook'

// Load style
import { normalText, bkglight, headingText, submitBtn } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const questionTwo = {
    q1: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }

    },
    q2: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: false
            }
        }
    },
    q3: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    }
}

const Card = ({content, checkbox, ...props}) => {
    return (
        <Box {...bkglight} display="flex" {...props} alignItems="center" justifyContent="space-between" width={{base: "100%", lg:"48%"}}>
            <Text {...normalText}>
                {content}
            </Text>
            {checkbox}
        </Box>
    )
}

const QuestionTwo = ({ reveal, setNext, changePage }) => {
   const [form, inputCheckHandler, allowNext] = useInputHook(questionTwo)
  
   // Form fields
   const { q1, q2, q3 } = form

   useEffect(() => {
    // Allow next is one answer been selected
    setNext(allowNext)
    }, [allowNext, setNext])
 
    return (
        <Layout>
            <Heading {...headingText} margin="10px 0">
                How should Hana be taken?
            </Heading>

            <Text {...normalText} marginBottom="20px">
                Select ALL that apply
            </Text>
            <Box display="flex" alignItems="flex-start" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between">
              <Card 
                border={(reveal && !q1.value) ? "1px solid #DF6532" : "1px solid #9894C6"} 
                content='At the same time every day'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={reveal ? reveal : form.q1.value} success={q1.validation.valid} amber={!form.q1.value} name="q1" id="q1" />}
                />
                {reveal && <Box width={{base: "100%", lg:"48%"}} marginTop={{base: "-10px", lg: "0"}}>
                    <Text {...normalText} color={(reveal && !q1.value) ? "#DF6532" : "green.900"}>
                        <strong>CORRECT</strong> - Hana should be taken at the same time every day (or within 12 hours of the time the tablet is due) to maintain the maximum contraceptive effect
                    </Text>
                </Box>
                }
            </Box>
            <Box display="flex" alignItems="flex-start" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between">
                <Card 
                content='Once a pack is finished, wait one week until starting the new blister'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={form.q2.value} fail={q2.validation.valid} name="q2" id="q2" />}
                />
                {(reveal && q2.value) && <Box width={{base: "100%", lg:"48%"}} marginTop={{base: "-10px", lg: "0"}}>
                    <Text {...normalText} color="red.900">
                        <strong>INCORRECT</strong> - Hana should be taken every day with no break between packs
                    </Text>
                </Box>
                }
            </Box>

            <Box display="flex" alignItems="flex-start" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between">
                <Card
                border={(reveal && !q3.value) ? "1px solid #DF6532" : "1px solid #9894C6"} 
                content='Hana can be started on the first day of menstrual bleeding without requiring additional precautions'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={reveal ? reveal : form.q3.value} success={q3.validation.valid} amber={!form.q3.value} name="q3" id="q3" />}
                />
                {reveal &&<Box width={{base: "100%", lg:"48%"}} marginTop={{base: "-10px", lg: "0"}}>
                    <Text {...normalText} color={(reveal && !q3.value) ? "#DF6532" : "green.900"}>
                        <strong>CORRECT</strong> - Hana can and should be taken on the first day of menstrual bleeding (day 1), in which case no additional precautions are needed
                    </Text>
                </Box>}
            </Box>
            {!reveal && 
            <Box display="flex" width={{base: "100%", lg:"48%"}} justifyContent="flex-end">
            <Button 
            {...submitBtn}
            
            onClick={changePage}
            >Submit<Icon name="chevron-right" fontSize="32px" /></Button>
            </Box>
            }
        </Layout>
    )
}

export default QuestionTwo