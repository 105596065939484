import React, { Component } from 'react'
import { Text } from '@chakra-ui/core'

// Load questions
import { questions } from '../../content/PharmaLearning/Questions'

// Load Components
import MultiTrueFalse from '../../components/Assessments/MultiTrueFalse'
import SelectExpanded from '../../components/Assessments/SelectExpanded/SelectExpanded'
import { normalText } from '../../style/styleFormat'

// Load assessments/Learning for part one
class PharmaModule extends Component {

    constructor(props) {
        super(props)

        // Content pages
        this.pageList = [
            {
                key: 10,
                Component: () =>  <MultiTrueFalse
                questions={questions['q1'].form} 
                title={questions['q1'].title} 
                subTitle={<Text {...normalText}>Select <Text as="span" color="purple.200" fontFamily="Gotham Bold">TRUE </Text> or <Text as="span" color="purple.200" fontFamily="Gotham Bold">FALSE</Text> for each option</Text>}
                setNext={this.nextQuestion} 
                changePage={this.navigation}
                setAnswer={this.calculateAnswer} />
            },
            {
                key: 11,
                Component: () =>  <MultiTrueFalse
                questions={questions['q1'].form} 
                title={questions['q1'].title} 
                reveal={true} 
                subTitle={<Text {...normalText}>Select <Text as="span" color="purple.200" fontFamily="Gotham Bold">TRUE </Text> or <Text as="span" color="purple.200" fontFamily="Gotham Bold">FALSE</Text> for each option</Text>}
                setNext={this.nextQuestion} 
                changePage={this.navigation}
                setAnswer={this.calculateAnswer} />
            },
            {
                key: 12,
                Component: () =>  <SelectExpanded
                questions={questions['q2'].form} 
                title={questions['q2'].page.title} 
                subTitle={questions['q2'].page.subTitle} 
                setNext={this.nextQuestion} 
                single={false}
                columns
                changePage={this.navigation}
                setAnswer={this.calculateAnswer} />
            },
            {
                key: 13,
                Component: () =>  <SelectExpanded
                questions={questions['q2'].form} 
                title={questions['q2'].page.title} 
                subTitle={questions['q2'].page.subTitle} 
                setNext={this.nextQuestion} 
                single={false}
                columns
                reveal={true}
                changePage={this.navigation}
                setAnswer={this.calculateAnswer} />
            },
            {
                key: 14,
                Component: () =>  <MultiTrueFalse
                questions={questions['q3'].form} 
                title={questions['q3'].title} 
                trueText="CAN"
                falseText="NOT"
                subTitle={<Text {...normalText}>Select either <Text as="span" color="purple.200" fontFamily="Gotham Bold">CAN</Text> be used or should <Text as="span" color="purple.200" fontFamily="Gotham Bold">NOT</Text> be used for each situation</Text>}
                setNext={this.nextQuestion} 
                changePage={this.navigation}
                setAnswer={this.calculateAnswer} />
            },
            {
                key: 15,
                Component: () =>  <MultiTrueFalse
                questions={questions['q3'].form} 
                title={questions['q3'].title} 
                trueText="CAN"
                falseText="NOT"
                subTitle={<Text {...normalText}>Select either <Text as="span" color="purple.200" fontFamily="Gotham Bold">CAN</Text> be used or should <Text as="span" color="purple.200" fontFamily="Gotham Bold">NOT</Text> be used for each situation</Text>}
                reveal
                setNext={this.nextQuestion} 
                changePage={this.navigation}
                setAnswer={this.calculateAnswer} />
            },
            {
                key: 16,
                Component: () =>  <SelectExpanded
                questions={questions['q4'].form} 
                title={questions['q4'].page.title} 
                subTitle={questions['q4'].page.subTitle} 
                setNext={this.nextQuestion} 
                single={false}
                columns
                changePage={this.navigation}
                setAnswer={this.calculateAnswer} />
            },
            {
                key: 17,
                Component: () =>  <SelectExpanded
                questions={questions['q4'].form} 
                title={questions['q4'].page.title} 
                subTitle={questions['q4'].page.subTitle} 
                setNext={this.nextQuestion} 
                single={false}
                columns
                reveal={true}
                changePage={this.navigation}
                setAnswer={this.calculateAnswer} />
            },
            {
                key: 18,
                Component: () =>  <MultiTrueFalse
                questions={questions['q5'].form} 
                title={questions['q5'].title} 
                subTitle={<Text {...normalText}>Select <Text as="span" color="purple.200" fontFamily="Gotham Bold">TRUE </Text> or <Text as="span" color="purple.200" fontFamily="Gotham Bold">FALSE</Text> for each option</Text>}
                setNext={this.nextQuestion} 
                changePage={this.navigation}
                setAnswer={this.calculateAnswer} />
            },
            {
                key: 19,
                Component: () =>  <MultiTrueFalse
                questions={questions['q5'].form} 
                title={questions['q5'].title} 
                reveal={true} 
                subTitle={<Text {...normalText}>Select <Text as="span" color="purple.200" fontFamily="Gotham Bold">TRUE </Text> or <Text as="span" color="purple.200" fontFamily="Gotham Bold">FALSE</Text> for each option</Text>}
                setNext={this.nextQuestion}
                changePage={this.navigation} 
                setAnswer={this.calculateAnswer} />
            },
        ]
    }

    componentDidMount() {
        const { reload } = this.props

        // On direct url hit set to assessment page
        reload(10)
    }

    calculateAnswer = (answer) => {
        const  { setAnswer, correct } = this.props

        // Add to tally or return current score
        const tally = answer ? correct + 1 : correct

        setAnswer(tally)

    }


    // Click here nav on questions
    navigation = () => {
        const {navControl} = this.props

        navControl('increment')
    }

    // check one answer selected
    nextQuestion = (isAllowed) => {
        const { allowNext } = this.props

        allowNext(isAllowed)  
    }


    render() {
        const { currentPage } = this.props

        let component = null

        // Loop over available content
        this.pageList.forEach((item) => {

            if (currentPage === item.key) {
                component = item.Component()
            }
        })
      
        return component
    }
}

export default PharmaModule