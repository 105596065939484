import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'


// Load assets
import headerBk from '../../images/landingHeader.png'
import {ReactComponent as HanaNavy} from '../../images/hana-logo.svg'
import resourcesbk from '../../images/resources-bg.svg'


// Load styles
import { normalText, headingText } from '../../style/styleFormat'

// Load components
import Footer from '../Footer/Footer'
import { TrainingMaterial } from '../LandingPage/LandingPage'
import { Box, Heading, AspectRatioBox, Spinner, Button, useDisclosure, Modal, ModalBody, ModalOverlay, ModalContent, ModalCloseButton } from '@chakra-ui/core'

const buttonPrimary = {
    backgroundColor:"orange.900",
    borderRadius:"25px",
    color:"#fff",
    fontFamily:"Gotham Bold",
    fontSize: {base: "sm", sm: "sm", lg: "16px"},
    textTransform: "uppercase"
}

const WebinarPresentation = () => {
    const history = useHistory()
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
    }, [])

    return (
        <Box as="article" id="webinarQA" backgroundColor="#f4f1e8">
           
        {/** HEADER */}
        <AspectRatioBox as="header" backgroundColor="#f4f1e8"  maxWidth="100vw" minHeight={{base: "420px", md:"480px"}} maxHeight={{base: "773px", lg: "773px"}} ratio={2}>
            <Box>
                <Box padding={{base: 6, lg: 16}} backgroundImage={`url(${headerBk})`}  boxShadow="0 0 50px 50px #f4f1e8 inset" backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center" height="100%" width="100%" maxWidth="1600px" margin="0 auto">
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                            <Box width={{base:"25%", lg: "15%"}} maxWidth={{base: "100px",  lg:"150px"}} onClick={() => history.push('/')}>
                                <HanaNavy />
                            </Box>
                            <Button onClick={onOpen} {...buttonPrimary} display="flex" minHeight="51px"  alignItems="center" justifyContent="center">
                                    Resources
                                <Box marginLeft="10px" marginBottom="2px" fontSize="lg" as={GiHamburgerMenu} />
                            </Button>
                        </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                        <Heading textAlign="center" fontSize={{base: "62px", lg:"90px"}} width={{base: "100%", md: "50%"}} maxW="600px"  fontWeight="300" lineHeight={{base: "55px", lg:"80px"}} fontFamily="kokomo_breezeregular" color="rgb(46, 28, 69)">
                        Slides from the Hana webinar for pharmacists
                        </Heading>
                        <Heading {...normalText} color="rgb(46, 28, 69)" fontWeight="bold" fontSize={{base: "md", md:"lg"}} textAlign="center" width={{base: "100%", md: "50%"}} maxW="600px">
                        As part of the training programme at the launch of Hana, HRA ran a webinar dedicated to pharmacists. The webinar slides below cover various topics, including case study scenarios and practical considerations for supply.
                        </Heading>
                    </Box>
                </Box>
            </Box>
            
        </AspectRatioBox>
        <Box background="linear-gradient(0deg, #fff 0%, #f4f1e8 100%)" height="50px" width="100%"></Box>

        <Box background="white" position="relative" height="100%" display="flex" minHeight="50vh" flexDirection="column" justifyContent="center" alignItems="center" padding={8}>
            <Box maxWidth="100%" overflow="auto">
                <Heading {...headingText} color="purple.700" alignSelf="flex-start" mb={4}>
                    INTRODUCING HANA AND ITS TRAINING RESOURCES
                </Heading>
                    {loading && <Spinner position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" size="lg" color="purple.900" />}
                    <iframe 
                    title='presentation'
                    src="https://docs.google.com/presentation/d/e/2PACX-1vRFF1Uwv0I5xL9pBDXshtIXLN5vtXiK63M2NjuLEWSKtPU2ZbtNKUe7y8czBQ2-AboaRPKdFFhy_StE/embed?start=false&loop=false&delayms=3000" 
                    frameborder="0" 
                    width="1000" 
                    height="591" 
                    allowfullscreen="true" 
                    mozallowfullscreen="true" 
                    webkitallowfullscreen="true"
                    onLoad={() => setLoading(false)}
                    />
            </Box>
        </Box>
    
        {/** Footer */}
        <Box as="footer" backgroundColor="#CCCEE6">
            <Footer productCode="0305" lastMod="12-01-2022" />
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
                <ModalContent borderRadius="25px" background={`linear-gradient(to bottom, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.73)), url(${resourcesbk})`} backgroundPosition="center" backgroundSize="auto" minHeight="25vh" maxWidth="80%" position="relative">
                    <ModalCloseButton />
                    <ModalBody padding="8">
                        <TrainingMaterial />
                    </ModalBody>
                </ModalContent>
        </Modal>
    </Box>
    )
}

export default WebinarPresentation