import React from 'react'
import { Box, Heading, PseudoBox, Flex, Text } from '@chakra-ui/core'

const textStyle = {
    fontSize: "md",
    lineHeight: "wide",
    display: "block",
    color: "#fff",
    fontfamily: "Gotham Book"
}

const LearningOutcome = ({number, content, borderStyle }) => {

    return (
        <Box display="flex" justify="space-between" alignItems="center" marginBottom="20px">
            <Heading as="h2" fontSize="xlg" color="#fff" lineHeight="widest" width="25px" marginRight="5">
                {number}
            </Heading>
            <Box width="100%">
                <PseudoBox 
                as="p" {...textStyle} 
                position="relative"
                padding="10px 0"
                _after={borderStyle && {
                    content: "''",
                    position: 'absolute',
                    width: "100%",
                    bottom: '-15px',
                    left: '0',
                    border: "1px dashed #fff"
                }}
                >
                    {content}
                </PseudoBox>
            </Box>
        </Box>
    )
}

const PharmacyOutComesPt1 = () => {
    return (
        <Flex align="center" direction="column">
            <Box width={{base: "100%", lg:"90%"}} margin="0 auto" padding={{base: "0", lg:"4"}}>
                <Heading fontSize="xlg" color="#fff" textTransform="uppercase" lineHeight="widest" margin="25px 0">
                    Learning outcomes at a glance
                </Heading>
                <Text {...textStyle} marginBottom="30px">
                    After finishing this training, you should:
                </Text>
            
                <LearningOutcome
                number={1}
                content="know what Hana is and how it works"
                borderStyle
                />
                <LearningOutcome
                number={2}
                content="understand your role in supporting your pharmacist(s) in the supply of Hana and the giving of advice"
                borderStyle
                />
                <LearningOutcome
                number={3}
                content="feel prepared for conversations with women about Hana (whether the supply is first-time, repeat, changing from prescription, or ad hoc)"
                borderStyle
                />
                <LearningOutcome
                number={4}
                content="recognise how you can help women feel comfortable in your pharmacy"
                />
            </Box>
        </Flex>
    )
}

export default PharmacyOutComesPt1