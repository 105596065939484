import React from 'react'
import { Box, Text, List, ListItem, Link } from '@chakra-ui/core'

// Load hook
import useWindowSize from '../../hooks/useWindowSize'

// Load pdf
import guide from '../../pdf/Hana_Pharmacy_Guide.pdf'

// Load style
import { normalText} from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const WellDone = ({ openResources }) => {
    const size = useWindowSize()
    const display = size.width > 900 ? 'desktop' : 'mobile'
    return (
        <Layout>
            <Text>
                You have completed Part 1!
            </Text>

            <Box display="flex" justifyContent="space-between" flexDirection={{base: "column", lg: "row"}} alignItems="center" margin="20px 0">
                <Link borderRadius="4px" href={guide}  display="flex" alignItems="center" width={{base: "100%", lg: "50%"}} target="_blank" rel="noopener noreferrer" backgroundColor="orange.900" height="78px" padding="20px" marginRight={{base: "0", lg:"10px"}} marginBottom={{base: "20px", lg: "0"}}>
                    <Text {...normalText} color="#fff" fontWeight="bold">
                       Click here to download a printable version of the guide
                    </Text>
                </Link>
                <Box onClick={() => openResources(display)} borderRadius="4px" backgroundColor="orange.900" display="flex" alignItems="center" height="78px" width={{base: "100%", lg: "50%"}} padding="20px">
                    <Text cursor="pointer" {...normalText} color="#fff" fontWeight="bold">
                        Click here for a list of other useful downloadable documents and resources
                    </Text>
                </Box>
            </Box>

            <Text {...normalText}>
                Continue to Part 2, to learn:
            </Text>

            <List>
                <ListItem 
                {...normalText}
                paddingLeft="20px"
                margin="10px 0"
                position="relative"
                display="block"
                _before={{
                    position: 'absolute',
                    top: '2px',
                    left: '-5px',
                    content: "'•'",
                    color: 'purple.500',
                    fontSize: '54px'
                    }}
                >
                    what you need to know about women when supplying Hana
                </ListItem>
                <ListItem 
                {...normalText}
                paddingLeft="20px"
                margin="10px 0"
                position="relative"
                display="block"
                _before={{
                    position: 'absolute',
                    top: '2px',
                    left: '-5px',
                    content: "'•'",
                    color: 'purple.500',
                    fontSize: '54px'
                    }}
                >
                    how you can assess whether women are suitable for Hana with a few simple questions
                </ListItem>
                <ListItem 
                {...normalText}
                paddingLeft="20px"
                margin="10px 0"
                position="relative"
                display="block"
                _before={{
                    position: 'absolute',
                    top: '2px',
                    left: '-5px',
                    content: "'•'",
                    color: 'purple.500',
                    fontSize: '54px'
                    }}
                >
                    what to do if you do not think women are suitable for Hana
                </ListItem>
                <ListItem 
                {...normalText}
                paddingLeft="20px"
                margin="10px 0"
                position="relative"
                display="block"
                _before={{
                    position: 'absolute',
                    top: '2px',
                    left: '-5px',
                    content: "'•'",
                    color: 'purple.500',
                    fontSize: '54px'
                    }}
                >
                    what advice you should give women about Hana to help them to use it effectively and safely
                </ListItem>
                <ListItem 
                {...normalText}
                paddingLeft="20px"
                margin="10px 0"
                position="relative"
                display="block"
                _before={{
                    position: 'absolute',
                    top: '2px',
                    left: '-5px',
                    content: "'•'",
                    color: 'purple.500',
                    fontSize: '54px'
                    }}
                >
                    what to check if it is a repeat supply of Hana
                </ListItem>
            </List>
        </Layout>
    )
}

export default WellDone