import React from 'react'
import { Box, Text, Heading, ListItem, List, useDisclosure } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import Reference from '../../components/UI/Typography/Reference'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, blueBullet, referenceLink } from '../../style/styleFormat'


const AdviceTakingHanaTwo = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Heading {...secondaryText} display="block" fontSize="lg">
                How to take Hana
            </Heading>
            <Text {...secondaryText} margin="10px 0" fontFamily="Gotham Bold" fontWeight="bold">
            How to take Hana and deal with missed doses or gastrointestinal disturbances
            </Text>

                <Box margin="20px 0" borderTop="1px solid #4580AB" display="flex" flexDirection="column">
                    <Box display="flex" flexDirection={{base: "column", lg: "row"}} borderBottom="1px solid #739FBF" width="100%">
                        <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"20%"}}>
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                How to take Hana
                            </Text>
                        </Box>
                        <Box padding="4" width={{base: "100%", lg:"80%"}}> 
                            <List spacing="4">
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="20px"
                                position="relative"
                                _before={{
                                    ...blueBullet
                                    }}>
                                    One tablet daily, taken at the same time every day 24 hours <span style={{position: 'relative'}}>apart<Reference>1</Reference></span>
                                </ListItem>
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="20px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    Once a pack is finished, start the new pack the next day without a break, regardless of <span style={{position: 'relative'}}>bleeding<Reference>1</Reference></span>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection={{base: "column", lg: "row"}} borderBottom="1px solid #739FBF" width="100%">
                        <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"20%"}}>
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                If a dose is missed
                            </Text>
                        </Box>
                        <Box width={{base: "100%", lg:"30%"}} borderRight={{base: "none", lg:"1px solid #739FBF"}}>
                            <Box padding="4">
                                <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" marginBottom="10px">
                                    If one tablet is missed by ≤12 hours<span style={{position: 'relative'}}>:<Reference>1</Reference></span>
                                </Text>
                                <Text {...secondaryText}>
                                take the tablet immediately and take the next scheduled dose as usual; this could mean taking 2 tablets on the same day
                                </Text>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column" width={{base: "100%", lg:"50%"}}>
                            <Box borderBottom={{base: "none", lg:"1px solid #739FBF"}} flexDirection={{base:"column", lg: "row"}} display="flex">
                                <Box width={{base: "100%", lg:"50%"}} padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}}>
                                    <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" marginBottom="10px">
                                        If one tablet is missed by &gt;12 hours<span style={{position: 'relative'}}>:<Reference>1</Reference></span>
                                    </Text>
                                    <Text {...secondaryText}>
                                        take the tablet immediately and take the next scheduled dose as usual; this could mean taking 2 tablets on the same day; extra precautions
                                        are recommended for the next 7 days
                                    </Text>
                                </Box>
                                <Box width={{base: "100%", lg:"50%"}}  padding="4">
                                    <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" marginBottom="10px">
                                        If more than one tablet has been missed<span style={{position: 'relative'}}>:<Reference>1</Reference></span>
                                    </Text>
                                    <Text {...secondaryText}>
                                    only one missed tablet should be taken immediately, and the next scheduled dose taken as usual; this could mean taking 2 tablets on the same day; extra precautions are recommended for the next 7 days
                                    </Text>
                                </Box>
                           
                            </Box>
                            <Box padding="4">
                                <Text {...secondaryText}>
                                    Missed tablets at any time in the menstrual cycle can reduce the efficacy of Hana and risk pregnancy, but missing a tablet <Text as="span" fontFamily="Gotham Bold">in the first week after starting Hana carries more <span style={{position: 'relative'}}>risk;<Reference>1</Reference></span></Text>&nbsp; if she had sex in the week before the tablets were missed, consider the possibility of pregnancy and, if appropriate, advise the woman to do a pregnancy test; <Text as="span" fontFamily="Gotham Bold">consider the need for emergency contraception for any missed <span style={{position: 'relative'}}>pills<Reference>1</Reference></span></Text>
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection={{base: "column", lg: "row"}} borderBottom="1px solid #739FBF">
                        <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"20%"}}>
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                In case of vomiting within 3-4 hours of taking Hana
                            </Text>
                        </Box>
                        <Box padding="4"> 
                            <Text {...secondaryText}>
                                Take another tablet immediately and take the next scheduled dose as <span style={{position: 'relative'}}>usual<Reference>1</Reference></span>
                            </Text>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection={{base: "column", lg:"row"}}>
                        <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base:"100%", lg:"20%"}}>
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                In case of severe or persistent gastrointestinal disturbances (vomiting or diarrhoea)
                            </Text>
                        </Box>
                        <Box padding="4" width={{base: "100%", lg:"80%"}}> 
                            <Text {...secondaryText}>
                            Continue taking tablets every day, but use extra precautions for as long as the gastrointestinal disturbances or vomiting last, and for 7 days after they have <span style={{position: 'relative'}}>stopped<Reference>1</Reference></span>
                            </Text>
                        </Box>
                    </Box>
                </Box>
    

                <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
                    References
                </Text>
                <Modal isOpen={isOpen} onClose={onClose} title="References">
                    <List as="ol" styleType="decimal" spacing="4">
                        <ListItem {...referenceText}>
                            Hana (desogestrel) Summary of Product Characteristics, November 2020.
                        </ListItem>
                    </List>
                </Modal>
        </Layout>
    )
}

export default AdviceTakingHanaTwo