import React, { useEffect } from 'react'
import { Box, Text, Heading, Button, Icon } from '@chakra-ui/core'

// Load components
import Checkbox from '../../components/UI/Checkbox/Checkbox'

// Load hook
import useInputHook from '../../hooks/useInputHook'

// Load style
import { normalText, bkglight, headingText, submitBtn} from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const questionOne = {
    q1: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }

    },
    q2: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: false
            }
        }
    },
    q3: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: false
            }
        }
    },
    q4: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    }
}

const Card = ({content, checkbox, ...props}) => {
    return (
        <Box {...bkglight} display="flex" {...props} alignItems="center" justifyContent="space-between" width={{base: "100%", lg:"48%"}}>
            <Text {...normalText}>
                {content}
            </Text>
            {checkbox}
        </Box>
    )
}



const QuestionOne = ({ reveal, setNext, changePage }) => {
   const [form, inputCheckHandler, allowNext ] = useInputHook(questionOne)
   
   // Form fields
   const { q1, q2, q3, q4 } = form

   useEffect(() => {
    // Allow next is one answer been selected
    setNext(allowNext)
}, [allowNext, setNext])
 
    return (
        <Layout>
            <Heading {...headingText} margin="10px 0">
                How does Hana work?
            </Heading>

            <Text {...normalText} marginBottom="20px">
                Select ALL that apply
            </Text>
            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
              <Card 
                border={(reveal && !q1.value) ? "1px solid #DF6532" : "1px solid #9894C6"}
                content='Increasing viscosity of the cervical mucus'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={reveal ? reveal : form.q1.value} success={q1.validation.valid} amber={!form.q1.value}  name="q1" id="q1" />}
                />
                {reveal && <Box width={{base: "100%", lg: "48%"}}>
                    <Text {...normalText} color={q1.value ? "green.900" : "orange.900"}>
                        <strong>CORRECT</strong> - Hana works primarily by inhibiting ovulation and also increasing the viscosity of the cervical mucus, to create a barrier to stop sperm from entering the uterus
                    </Text>
                </Box>
                }
            </Box>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
                <Card
                content='Preventing the fertilised egg from implanting in the womb'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={form.q2.value} fail={q2.validation.valid} name="q2" id="q2" />}
                />
                {(reveal && q2.value) && <Box width={{base: "100%", lg: "48%"}}>
                    <Text {...normalText} color="red.900">
                        <strong>INCORRECT</strong> - Hana works primarily by inhibiting ovulation and also increasing the viscosity of the cervical mucus, to create a barrier to stop sperm from entering the uterus
                    </Text>
                </Box>}
            </Box>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
                <Card
                content='Delaying ovulation'
                checkbox={<Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={form.q3.value} fail={q3.validation.valid} name="q3" id="q3" />}
                />

               {(reveal && q3.value) && <Box width={{base: "100%", lg: "48%"}}>
                    <Text {...normalText} color="red.900">
                       <strong>INCORRECT</strong> - Hana works primarily by inhibiting ovulation and also increasing the viscosity of the cervical mucus, to create a barrier to stop sperm from entering the uterus
                    </Text>
                </Box>
                }
            </Box>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
                <Card
                border={(reveal && !q4.value) ? "1px solid #DF6532" : "1px solid #9894C6"} 
                content='Inhibiting ovulation'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={reveal ? reveal : form.q4.value} success={q4.validation.valid} amber={!form.q4.value} name="q4" id="q4" />}
                />
                {reveal && <Box width={{base: "100%", lg: "48%"}}>
                    <Text {...normalText} color={q4.value ? "green.900" : "orange.900"}>
                        <strong>CORRECT</strong> - Hana works primarily by inhibiting ovulation and also increasing the viscosity of the cervical mucus, to create a barrier to stop sperm from entering the uterus
                    </Text>
                </Box>
                }
            </Box>
            {!reveal &&
            <Box display="flex" width={{base: "100%", lg: "48%"}} justifyContent="flex-end">
                <Button 
                {...submitBtn}
                onClick={changePage}
                >Submit<Icon name="chevron-right" fontSize="32px" /></Button>
            </Box>
            }
        </Layout>
    )
}

export default QuestionOne