import React from 'react'
import { Box, Text, Link } from '@chakra-ui/core'

// Load hook
import useWindowSize from '../../hooks/useWindowSize'

// Load pdf
import guide from '../../pdf/Hana_Pharmacy_Guide.pdf'

// Load style
import { normalText, secondaryText} from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const PartThreeComplete = ({ openResources }) => {
    const size = useWindowSize()
    const display = size.width > 900 ? 'desktop' : 'mobile'
    return (
        <Layout>
            <Text {...secondaryText}>
                You have completed Part 3!
            </Text>
            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center" margin="20px 0">
                <Link href={guide} borderRadius="4px" width={{base: "100%", lg: "50%"}} display="flex" alignItems="center" target="_blank" rel="noopener noreferrer" backgroundColor="orange.900" height="78px" padding="20px" marginRight={{base: "0", lg:"10px"}} marginBottom={{base: "20px", lg: "0"}}>
                    <Text {...normalText} color="#fff" fontWeight="bold">
                    Click here to download a printable version of the guide
                    </Text>
                </Link>
                <Box backgroundColor="orange.900" display="flex" alignItems="center" height="78px" padding="20px" borderRadius="4px" width={{base: "100%", lg: "50%"}}>
                    <Text onClick={() => openResources(display)} {...normalText} cursor="pointer" color="#fff" fontWeight="bold">
                        Click here for a list of other useful downloadable documents and resources
                    </Text>
                </Box>
            </Box>
            <Text {...secondaryText}>
                Continue to your assessment, a self-test based on the training in all 3 parts for CPD certification.    
            </Text>
        </Layout>
    )
}

export default PartThreeComplete