import React, { Fragment, useEffect } from 'react'
import { Box } from '@chakra-ui/core'

// Load hook
import useLockBody from '../../hooks/useLockBody'

const drawerContainer = {
    backgroundColor: {base: "#5D599E", lg:"#423F7E"},
    height: "100%",
    width: {base: "100%", lg: "329px"},
    position: "fixed",
    top: "0",
    left: {base: "0", lg:"314px"},
    zIndex: "1300",
    padding: "8",
}

const overlay = {
    position: "fixed",
    top: "0",
    right: "0",
    width: {base: "100%", lg:"calc(100% - 329px)"},
    height: "100%",
    backgroundColor: "black",
    opacity: ".5",
    zIndex: "1200"
}


const Drawer = ({ show, close, children }) => {
    const [setDisplay] = useLockBody()

    useEffect(() => {
        setDisplay(show)
    }, [show, setDisplay])
  
    return (
        show && 
        <Fragment>
            <Box {...overlay} onClick={() => close(false)} />
            <Box {...drawerContainer} overflow="auto">
                <Box display="flex" alignItems="center" paddingBottom="20px">
                 {children}
                </Box>
            </Box>
        </Fragment>
      
    )
}

export default Drawer