import React, { useState, useEffect, useMemo } from 'react'
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext} from 'pure-react-carousel'
import { Box, Text, Button, RadioButtonGroup, Divider } from '@chakra-ui/core'

// Load component
import SpeechBox from '../UI/SpeechBox/SpeechBox'

// Load assets
import {ReactComponent as Table} from '../../images/caseStudies/Table.svg'
import Doctor from '../../images/caseStudies/Doctor.svg'

// Layout
import Layout from '../../layout/Layout.case'
import 'pure-react-carousel/dist/react-carousel.es.css';
import { normalText } from '../../style/styleFormat'

const CustomRadio = React.forwardRef((props, ref) => {
    const { isChecked, isDisabled, value, ...rest } = props;
    return (
      <Button
        ref={ref}
        backgroundColor={!isChecked ? "#fff" : "orange.900"}
        color={isChecked ? "#fff" : "orange.900"}
        aria-checked={isChecked}
        width="65px"
        borderRadius="25px"
        role="radio"
        isDisabled={isDisabled}
        {...rest}
      />
    );
});

const Case = ({ config, questions, totalSlides, component: StartSlide, setNext, setAnswer, questionOne, questionTwo, CaseYesNo, CaseNoYes, CaseYesYes, CaseNoNo, womanSvg, changePage }) => {
    const [scene, setScene] = useState(0)
    const [q1, setQ1] = useState(false)
    const [q2, setQ2] = useState(false)
    const submit = (q1 && q2)

    // Rules to decide with reveal card to display
    const showYesNo = q1 === 'Yes' && q2 === 'No'
    const showNoYes = q1 === 'No' && q2 === 'Yes'
    const showYesYes = q1 === 'Yes' && q2 === 'Yes'
    const showNoNo = q1 === 'No' && q2 === 'No'


    // Check the answer is correct
    const answer = useMemo(() => {
        const anwser = q1 === questions.q1.answer && q2 === questions.q2.answer

        return anwser
        }, [q1, q2, questions.q1.answer, questions.q2.answer]) 

    useEffect(() => {
        setNext(false)
    }, [setNext])

    // Allow next question when user has selected two answers
    useEffect(() => {
        if (q1 && q2) {
            setNext(true)
            setAnswer(answer)
        }
    }, [q1, q2, setNext, submit, answer, setAnswer])
  
    return (
        <Layout>
            {/** Case Scene Questions */}
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" position="relative" marginBottom="100px">
                <CarouselProvider  naturalSlideHeight={250} naturalSlideWidth={250} totalSlides={totalSlides} orientation="vertical" >
                    <Slider style={{width: '400px', position: 'relative', padding: "20px"}}>
                        <Slide index={0}>{StartSlide}</Slide>
                        {config.map((cases, index) => {
                            return (
                            <Slide key={index} index={index}>
                                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                {cases.doctor &&
                                    <Box display="flex" justifyContent="flex-start" marginBottom="30px" marginLeft="10px">
                                        <SpeechBox color="#fff">
                                            <Text {...normalText} color="#1111111" fontWeight="bold">{cases.doctor}</Text>
                                        </SpeechBox>
                                    </Box>}
                                
                                {cases.woman &&
                                    <Box display="flex" justifyContent="flex-end" marginRight="10px">
                                        <SpeechBox color="green.900" arrow="right">
                                            <Text {...normalText} color="#1111111" fontWeight="bold">
                                                {cases.woman}
                                            </Text>
                                        </SpeechBox>
                                    </Box>
                                }
                                </Box>
                            </Slide>
                            )
                        })}
                        <Slide index={totalSlides} style={{overflow: 'auto'}}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Text {...normalText} width="60%" color="#fff">{questionOne}</Text>
                            
                                <Box display="flex" flexDirection="row">
                                    <RadioButtonGroup
                                    onChange={value => setQ1(value)}
                                    isInline >
                                    <CustomRadio isDisabled={submit} value="Yes">Yes</CustomRadio>
                                    <CustomRadio isDisabled={submit} value="No">No</CustomRadio>
                                    </RadioButtonGroup>
                                </Box>
                            </Box>
                            <Divider opacity="0.2" />
                            {q1 &&<Box display="flex" alignItems="center" marginBottom="10px" justifyContent="space-between">
                                <Text {...normalText} width="60%" color="#fff">{questionTwo}</Text>
                            
                                <Box display="flex" flexDirection="row">
                                    <RadioButtonGroup
                                    onChange={value => setQ2(value)}
                                    isInline >
                                    <CustomRadio isDisabled={submit} value="Yes">Yes</CustomRadio>
                                    <CustomRadio isDisabled={submit} value="No">No</CustomRadio>
                                    </RadioButtonGroup>
                                </Box>
                            </Box>}
                            {showYesNo && CaseYesNo}
                            {showNoYes && CaseNoYes}
                            {showYesYes && CaseYesYes}
                            {showNoNo && CaseNoNo}
                        </Slide>
                    </Slider>
                    <Box position="absolute" top="-25px" left="50%" transform="translateX(-50%)" zIndex="1000">
                        {scene > 1 &&<ButtonBack onClick={() => setScene(scene - 1)}><Box borderRadius="50%" backgroundColor="#fff"><FaChevronCircleUp fontSize="25px" color="#DF6532" /></Box></ButtonBack>}
                    </Box>
                    <Box position="absolute" bottom="0px" left="50%" transform="translateX(-50%)" zIndex="1000"> 
                        {scene === 0 && <ButtonNext onClick={() => setScene(scene + 1)}><Button as="span" backgroundColor="orange.900" color="#fff" borderRadius="25px" width="100px">Start</Button></ButtonNext>}
                        {scene === (totalSlides - 2) && <ButtonNext onClick={() => setScene(scene + 1)}><Button as="span" backgroundColor="orange.900" color="#fff" borderRadius="25px" width="100px">Continue</Button></ButtonNext>}
                        {(scene > 0 && scene < totalSlides - 2) && <ButtonNext onClick={() => setScene(scene + 1)}><Box borderRadius="50%" backgroundColor="#fff"><FaChevronCircleDown fontSize="25px" color="#DF6532" /></Box></ButtonNext>}
                        {(scene === (totalSlides - 1) && submit) && <Button onClick={() => changePage('increment')} backgroundColor="orange.900" color="#fff" borderRadius="25px" padding="2" width="100px">Next</Button>}
                    </Box>
                </CarouselProvider>
            </Box>
            

            {/** SVG's */}
            <Box position="absolute" left="0" bottom="0" backgroundImage={`url(${Doctor})`} height={{base: "150px", md:"300px"}} width="260px" backgroundRepeat="no-repeat" /> 
            <Box position="absolute" right="0" bottom="0" backgroundImage={`url(${womanSvg})`} height={{base: "150px", md:"300px"}} width={{base: "100px", md:"180px"}} backgroundPosition="bottom" backgroundRepeat="no-repeat" />
            <Box position="absolute" bottom="0" left="50%" transform="translateX(-50%)" width="80%">
                <Table />
            </Box>
        </Layout>
    )
}

export default Case