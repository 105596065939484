export const caseOne = {
    page: {
        display: [104],
        paraOne: 'A young-looking woman comes to the pharmacy and asks for a supply of desogestrel. She looks fit and well. ',
        paraTwo: 'Depending on her answers to the questions asked, would you consider her suitable to be given a 3-month supply of Hana?',
        questionOne: 'Would you supply 3 months of Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor',
        cases: [
            {
                doctor: 'How old are you?',
                woman: 'I\'m 25',
            },
            {
                doctor: 'Are you already using a form of contraception?',
                woman: 'I’m not using contraception yet, this will be my first experience of it.'
            },
            {
                doctor: 'Is there any chance you could be pregnant, or have you recently been pregnant?',
                woman: 'I’ve not recently been pregnant. I’ve had sex since the start of my last period, but my partner used condoms.'
            },
            {
                doctor: 'Do you have any medical conditions or illnesses, or have you had any in the past?',
                woman: 'No, I’ve got no medical conditions, current or in the past.'
            },
            {
                doctor: 'Are you taking any medications, including herbal remedies?',
                woman: 'I’m not taking any medications or herbal remedies, except paracetamol occasionally, for headache. I also sometimes take vitamin supplements.'
            },
            {
                doctor: 'Do you have any bleeding between periods or after sex?',
                woman: 'I don’t have any irregular bleeding or bleeding after sex. My periods can be painful though.'
            },
            {
                doctor: 'Do you have any allergies?',
                woman: 'My only allergy is mild hay fever in summer.'
            }

        ]

    },
    form: {
        q1: {
            answer: 'Yes',
            value: false,
        },
        q2: {
            answer: 'No',
            value: false,
        }
    }
}

export const caseTwo = {
    page: {
        display: [105],
        paraOne: 'A young woman who looks to be in her mid-to-late 20s comes to the pharmacy and asks for a supply of Hana; she looks well, if a little overweight.',
        paraTwo: 'Depending on her answers to the questions asked, would you supply Hana to her?',
        questionOne: 'Would you supply Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor',
        cases: [
            {
                doctor: 'Have you used Hana before, what contraception are you using?',
                woman: 'I’ve not used Hana, I currently use condoms and a spermicide',
            },
            {
                doctor: 'Do you have any medical conditions or illnesses, or have you had any in the past?',
                woman: 'I have Type 2 diabetes.'
            }
        ]

    },
    form: {
        q1: {
            answer: 'No',
            value: false,
        },
        q2: {
            answer: 'Yes',
            value: false,
        }
    }
}

export const caseThree = {
    page: {
        display: [106],
        paraOne: 'A young woman of around 30 years of age, carrying a newborn in a sling, comes into the pharmacy. She says that after the birth of her baby, she needs a reliable form of contraception and asks for Hana. She tells you that she is breastfeeding her baby.',
        paraTwo: 'Depending on her answers to the questions asked, would you consider her suitable to be given a 3-month supply of Hana?',
        questionOne: 'Would you supply 3 months of Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor?',
        cases: [
            {
                doctor: 'Are you using any form of contraception currently?',
                woman: 'No, but I’ve not had sex since the birth of my baby 2 weeks ago.',
            },
            {
                doctor: 'Have you used desogestrel or Hana before?',
                woman: 'I’ve not previously used desogestrel or Hana. I took the combined oral contraceptive pill in the past.'
            },
            {
                doctor: 'Do you have any medical conditions or illnesses, or have you had any in the past?',
                woman: 'No, I’ve got no medical conditions, current or in the past.'
            },
            {
                doctor: 'Are you taking any medications, including herbal remedies?',
                woman: 'I’m taking vitamin supplements and extra calcium, but no other medications'
            },
            {
                doctor: 'Do you have any allergies?',
                woman: 'I occasionally get some contact dermatitis, but otherwise have no known allergies.'
            },
        ]

    },
    form: {
        q1: {
            answer: 'Yes',
            value: false,
        },
        q2: {
            answer: 'No',
            value: false,
        }
    }
}

export const caseFour = {
    page: {
        display: [107],
        paraOne: 'A woman who appears to be around 35 years old enters the pharmacy. She has been purchasing Hana for some time and would like a repeat supply.',
        paraTwo: 'Depending on her answers to the questions asked, would you resupply Hana to her?',
        questionOne: 'Would you supply Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor?',
        cases: [
            {
                doctor: 'How long have you been taking Hana?',
                woman: 'I’ve been taking Hana for the last 6 months.',
            },
            {
                doctor: 'Has there been any change in your health since you last purchased Hana from the pharmacy?',
                woman: 'I had my blood pressure checked at a well-woman clinic 2 weeks ago and was told it was high; my blood pressure was similarly high when I returned for follow-up a week later and was advised to see my doctor, but I haven’t got around to going yet.'
            },
            {
                doctor: 'Are you taking any medications, including herbal remedies?',
                woman: 'I’m not taking any medications or herbal remedies, other than Hana.'
            },
            {
                doctor: 'Do you have any allergies?',
                woman: 'None.'
            },
        ]

    },
    form: {
        q1: {
            answer: 'No',
            value: false,
        },
        q2: {
            answer: 'Yes',
            value: false,
        }
    }
}

export const caseFive = {
    page: {
        display: [108],
        paraOne: 'A woman who appears to be in her early 40s enters the pharmacy. She wants to buy Hana.',
        paraTwo: 'Depending on her answers to the questions asked, would you consider her suitable to be given a 12-month supply?',
        questionOne: 'Would you supply 12 months of Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor?',
        cases: [
            {
                doctor: 'Are you using any form of contraception currently?',
                woman: 'I’ve purchased Hana from my local pharmacy before, but I’m now getting towards the end of my last pack. I’ve been using it correctly, and haven’t missed a pill in over a month.',
            },
            {
                doctor: 'Have you had any problems with Hana?',
                woman: 'There was some light spotting in the first two weeks and my periods were unpredictable, but nothing I couldn’t manage. Apart from that, everything has been fine.'
            },
            {
                doctor: 'Are the irregular periods especially heavy or prolonged, and does any of the bleeding occur after sex?',
                woman: 'My periods are quite light, just irregular, and I don’t bleed after sex.'
            },
            {
                doctor: 'Do you have any medical problems?',
                woman: 'I’m well, nothing has changed since my last purchase.'
            },
        ]

    },
    form: {
        q1: {
            answer: 'Yes',
            value: false,
        },
        q2: {
            answer: 'No',
            value: false,
        }
    }
}

export const caseSix = {
    page: {
        display: [109],
        paraOne: 'A woman in her late 20s enters the pharmacy. She wants to buy Hana.',
        paraTwo: 'Depending on her answers to the questions asked, would you consider her suitable to be given a 3-month supply?',
        questionOne: 'Would you supply 3 months of Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor?',
        cases: [
            {
                doctor: 'Are you using any form of contraception currently?',
                woman: 'I’ve purchased Hana from another pharmacy, but I’m on my last pack and need further supplies. I’ve been taking it as recommended at the same time every day without a break between packs.',
            },
            {
                doctor: 'Have you had any problems with Hana?',
                woman: 'I’ve not had any problems with Hana, but I’ve noticed that I’m feeling a bit lethargic and fed up. I don’t understand why, there is no reason why I should feel like this.'
            },
            {
                doctor: 'When did you start feeling like this?',
                woman: 'I’ve felt like this for the last month.'
            },
            {
                doctor: 'Do you have any medical problems?',
                woman: 'I’m well, no problems with my health.'
            },
            {
                doctor: 'Are you taking any medications, including herbal remedies?',
                woman: 'I’m not on any other regular medication, or taking any herbal remedies.'
            }
        ]

    },
    form: {
        q1: {
            answer: 'Yes',
            value: false,
        },
        q2: {
            answer: 'Yes',
            value: false,
        }
    }
}

export const caseSeven = {
    page: {
        display: [110],
        paraOne: 'A woman in her mid-40s enters the pharmacy. She has been using Hana and would like a repeat supply.',
        paraTwo: 'Depending on her answers to the questions asked, would you consider her suitable to be given a 12-month supply?',
        questionOne: 'Would you supply 12 months of Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor?',
        cases: [
            {
                doctor: 'Are you using any form of contraception currently?',
                woman: 'I’ve been taking Hana for the last 9 months.',
            },
            {
                doctor: 'Have you had any problems with Hana?',
                woman: 'I’ve had no problems with the pill.'
            },
            {
                doctor: 'Have you had any changes to your health since you bought your last supply of Hana?',
                woman: 'Just a silly accident I had in the garden 3 days ago in which I hurt my leg. My leg is still sore and swollen over my calf and quite hot. It doesn’t seem to be getting any better.'
            },
            {
                doctor: 'Have you felt more breathless than usual, or had a cough or any feeling of tightness in your chest since you hurt your leg?',
                woman: 'I haven’t experienced any of those symptoms.'
            },
            {
                doctor: 'Are you taking any medications, including herbal remedies?',
                woman: 'I’m not on any other regular medication, or taking any herbal remedies.'
            }
        ]

    },
    form: {
        q1: {
            answer: 'No',
            value: false,
        },
        q2: {
            answer: 'Yes',
            value: false,
        }
    }
}

export const caseEight = {
    page: {
        display: [111],
        paraOne: 'A young woman enters the pharmacy. She has been using Hana and would like a repeat supply.',
        paraTwo: 'Depending on her answers to the questions asked, would you consider her suitable to be given a 3-month supply?',
        questionOne: 'Would you supply 3 months of Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor?',
        cases: [
            {
                doctor: 'Are you using any form of contraception currently?',
                woman: 'I’ve been taking Hana for the last 6 months, at the same time every day without a break between packs.',
            },
            {
                doctor: 'Have you had any problems with Hana?',
                woman: 'I’ve had no problems with Hana'
            },
            {
                doctor: 'Have you had any changes to your health since you bought your last supply of Hana?',
                woman: 'My epilepsy hasn’t been quite so well-controlled in the last month, and I’ve had a couple of small fits.'
            },
            {
                doctor: 'Are you taking any medications, including herbal remedies?',
                woman: 'I’m still taking gabapentin for my epilepsy.'
            }
        ]

    },
    form: {
        q1: {
            answer: 'Yes',
            value: false,
        },
        q2: {
            answer: 'Yes',
            value: false,
        }
    }
}

export const caseNine = {
    page: {
        display: [112],
        paraOne: 'A woman in her mid-30s enters the pharmacy. She wants to buy Hana.',
        paraTwo: 'Depending on her answers to the questions asked, would you consider her suitable to be given a 3-month supply?',
        questionOne: 'Would you supply 3 months of Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor?',
        cases: [
            {
                doctor: 'Are you using any form of contraception currently?',
                woman: 'I’ve been using a combination of condoms and monitoring my cycle.',
            },
            {
                doctor: 'Do you have any medical conditions or illnesses, or have you had any in the past?',
                woman: 'I contracted hepatitis 2 years ago and have been on treatment. I no longer need treatment, but have my liver function checked regularly by my doctor.'
            },
            {
                doctor: 'Do you know whether your liver function values are back to normal now?',
                woman: 'I think my liver function values are much better, but I’m not sure they are completely back to normal.'
            },
            {
                doctor: 'Are you taking any medications, including herbal remedies?',
                woman: 'I’m not taking any medication or herbal remedies.'
            },
            {
                doctor: 'Do you have any allergies?',
                woman: 'I get hay fever.'
            }
        ]

    },
    form: {
        q1: {
            answer: 'No',
            value: false,
        },
        q2: {
            answer: 'Yes',
            value: false,
        }
    }
}

export const caseTen = {
    page: {
        display: [113],
        paraOne: 'A woman in her early 30s enters the pharmacy. She wants to buy a resupply of Hana.',
        paraTwo: 'Depending on her answers to the questions asked, would you consider her suitable to be given a 12-month supply?',
        questionOne: 'Would you supply 12 months of Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor?',
        cases: [
            {
                doctor: 'Are you using any form of contraception currently?',
                woman: 'I’ve been taking Hana for the last 6 months.',
            },
            {
                doctor: 'Have you had any problems with Hana?',
                woman: 'My periods were quite irregular to start with, and have now stopped completely.'
            },
            {
                doctor: 'How many months ago did they stop completely?',
                woman: 'I haven’t had any bleeding at all for 2 months now.'
            },
            {
                doctor: 'Have you missed any pills in the last 2 months?',
                woman: 'I missed a couple of pills when I was really busy at work.'
            },
            {
                doctor: 'Do you take your pill at the same time every day?',
                woman: 'I take it at the same time every day, give or take a couple of hours, but pretty much always before lunchtime.'
            },
            {
                doctor: 'Have you had any changes to your health since you bought your last supply of Hana?',
                woman: 'I’ve had no changes in my health.'
            }
        ]

    },
    form: {
        q1: {
            answer: 'Yes',
            value: false,
        },
        q2: {
            answer: 'No',
            value: false,
        }
    }
}

export const caseEleven = {
    page: {
        display: [114],
        paraOne: 'A woman that looks to be under 18 years of age comes to the pharmacy and asks for a supply of desogestrel; she looks fit and well.',
        paraTwo: 'Depending on her answers to the questions asked, would you consider her suitable to be given a 3-month supply of Hana?',
        questionOne: 'Would you supply 3 months of Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor?',
        cases: [
            {
                doctor: 'How old are you?',
                woman: 'I’m 15.',
            },
            {
                doctor: 'Is there any chance you could be pregnant, or have you recently been pregnant?',
                woman: 'I’ve not recently been pregnant, and I’ve not had sex since the start of my last period.'
            },
            {
                doctor: 'Have you told a parent about your need for contraception, or could I tell a parent?',
                woman: 'I haven’t told my parents, and wouldn’t want you to tell them.'
            },
            {
                doctor: 'Why have you come to pharmacy to get Hana?',
                woman: 'I have a boyfriend but want to avoid pregnancy, especially while I’m still studying.'
            },
            {
                doctor: 'Do you have any medical conditions or illnesses, or have you had any in the past?',
                woman: 'No, I’ve got no medical conditions, current or in the past.'
            },
            {
                doctor: 'Are you taking any medications, including herbal remedies?',
                woman: 'I take propranolol for migraine, which controls it well.'
            },
            {
                doctor: 'Do you have any bleeding between periods or after sex?',
                woman: 'I don’t have any irregular bleeding, or any bleeding after sex.'
            },
            {
                doctor: 'Do you have any allergies?',
                woman: 'No, I haven’t got any allergies.'
            }
        ]

    },
    form: {
        q1: {
            answer: 'Yes',
            value: false,
        },
        q2: {
            answer: 'No',
            value: false,
        }
    }
}

export const caseTwelve = {
    page: {
        display: [115],
        paraOne: 'A woman in her mid-30s enters the pharmacy. She wants to buy Hana.',
        paraTwo: 'Depending on her answers to the questions asked, would you consider her suitable to be given a 3-month supply?',
        questionOne: 'Would you supply 3 months of Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor?',
        cases: [
            {
                doctor: 'How old are you?',
                woman: 'I’m 33.',
            },
            {
                doctor: 'Is there any chance you could be pregnant, or have you recently been pregnant?',
                woman: 'I haven’t recently been pregnant, but there’s a chance I could be pregnant now.'
            },
            {
                doctor: 'Have you had sex since the start of your last period?',
                woman: 'I have had sex since the start of my last period, the last time was a week ago.'
            },
            {
                doctor: 'Did you use protection?',
                woman: 'My partner used condoms sometimes, but not all the time.'
            },
            {
                doctor: 'When was your last period?',
                woman: 'About 2 weeks ago.'
            },
            {
                doctor: 'Do you have any medical conditions or illnesses, or have you had any in the past?',
                woman: 'No, I’ve got no medical conditions, current or in the past.'
            },
            {
                doctor: 'Are you taking any medications, including herbal remedies?',
                woman: 'I’m not taking any medications or herbal remedies, but I take vitamin and mineral supplements daily.'
            },
            {
                doctor: 'Do you have any bleeding between periods or after sex?',
                woman: 'I don’t have any irregular bleeding, or any bleeding after sex.'
            },
            {
                doctor: 'Do you have any allergies?',
                woman: 'My only known allergy is to shellfish.'
            }
        ]

    },
    form: {
        q1: {
            answer: 'Yes',
            value: false,
        },
        q2: {
            answer: 'No',
            value: false,
        }
    }
}

export const caseThirteen = {
    page: {
        display: [116],
        paraOne: 'A woman in her early 20s enters the pharmacy. She wants to buy ulipristal acetate emergency contraception.',
        paraTwo: 'Depending on her answers to the questions asked, would you consider her suitable to start regular contraception with Hana, and to be given a 3-month supply?',
        questionOne: 'Would you supply 3 months of Hana to this woman?',
        questionTwo: 'Does she need to be referred to her doctor?',
        cases: [
            {
                doctor: 'How old are you?',
                woman: 'I’m 21.',
            },
            {
                doctor: 'Is there any chance you could be pregnant, or have you recently been pregnant?',
                woman: 'Yes, I had unprotected sex last night.'
            },
            {
                doctor: 'When was your last period?',
                woman: 'About 10 days ago.'
            },
            {
                doctor: 'Do you have any medical conditions or illnesses, or have you had any in the past?',
                woman: 'I have mild asthma.'
            },
            {
                doctor: 'Are you taking any medications, including herbal remedies?',
                woman: 'I’ve taken emergency contraception containing ulipristal acetate a few times this year.'
            },
            {
                doctor: 'Do you have any bleeding between periods or after sex?',
                woman: 'I don’t have any irregular bleeding, or any bleeding after sex.'
            },
            {
                doctor: 'Do you have any allergies?',
                woman: 'No, I’ve got no allergies.'
            }
        ]

    },
    form: {
        q1: {
            answer: 'Yes',
            value: false,
        },
        q2: {
            answer: 'No',
            value: false,
        }
    }
}


