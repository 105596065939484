import React from 'react'
import { Heading, Text, Box, Image } from '@chakra-ui/core'

// Load assets
import CliboardImg from '../../images/cliboard.png'
import Training from '../../images/Training_Quality_Seal_logo.2020.png'

// Load layout
import LayoutCard from '../../layout/Layout.card'
import { textBoldPara } from '../../style/styleFormat'

const headStyle = {
    color: "purple.200",
    textTransform: "uppercase",
    fontSize: {base: "18px",  lg: "lg"},
    lineHeight: "wide",
    margin: "20px 0",
    display: 'block'
}

const textStyle = {
    color: "purple.600",
    fontSize: "md",
    lineHeight: "wide",
    display: "block",
    margin: "30px 0"
}

const Welcome = () => (
    <LayoutCard>
        <Box display={{base: "none", lg: "block"}} style={{ shapeOutside: 'circle(50%)'}} position="relative" float="right" height="627px" width="450px">
            <Image src={CliboardImg} position="absolute" top="0" paddingTop="0" height="520px" right="-13.9%"/>
            <Image src={Training} position="absolute" top="15px" right="25%" width="175px" />
        </Box>
       
        <Heading {...headStyle}>
            Oral contraceptive Hana 75 microgram film coated tablets can be supplied in pharmacy without prescription.
        </Heading>
        <Text {...textStyle}>
            This Pharmacy guide is designed to help you build and test your own knowledge of Hana.
        </Text>
        <Text {...textBoldPara} display="block">
        The content of the guide is fully aligned with legal documentation, that is, the SmPC, unless stated otherwise.
        </Text>

        <Heading {...headStyle}>
            The Pharmacist’s role in supplying Hana
        </Heading>
        <Text {...textStyle}>
            Pharmacists have a key role to play in supplying Hana without prescription. It is the pharmacist’s role to help women assess whether Hana is a suitable option for them. They will check that there are no contraindications to supply, and know when to refer women to their doctor for advice if they have any condition for which there is a warning regarding use of Hana.
        </Text>
        <Text {...textStyle}>
            This guide has been designed to support pharmacists and their teams to appropriately supply and advise on the use of Hana.
        </Text>
        <Text {...textBoldPara} display="block">
            It will help you to make confident recommendations and give appropriate information and advice to women seeking regular oral contraception in your pharmacy.
        </Text>
        <Text {...textBoldPara} display="block" marginTop="20px">
            There will be situations in which you will need to use your professional judgement; apply the principles of risk management in identifying red flags and safety netting, which this guide will help you with.
        </Text>
        <Text {...textStyle} as="i" fontSize="sm">
            The content of this material has been approved by the NPA. The NPA will have no liability to any person or entity with liability, loss or damage caused or alleged to have been caused directly or indirectly by information therein. The NPA is not responsible for the content of any non-NPA websites mentioned in this programme or for the accuracy of any information found there. The fact that a website, organisation or product is mentioned in the programme does not mean that the NPA either approves of it or endorses it.
        </Text>
    </LayoutCard>
)

export default Welcome