import React, { useState } from 'react'
import { Box, Heading, Button, Flex, Icon, Link, Text, Image, useToast, useDisclosure } from '@chakra-ui/core'
import {GiHamburgerMenu as MenuIcon } from 'react-icons/gi'
import { BiSave } from 'react-icons/bi'

// Load components
import Modal from '../Modal/Modal'
import Terms from '../Footer/Content/Terms'
import {ProductInformation} from '../Footer/Footer'
import Privacy from '../Footer/Content/Privacy'
import CookiePolicy from '../Footer/Content/CookiePolicy'

// Load assets
import HanaFooter from '../../images/hrafooter.png'
import Hana from '../../images/Hana_Logo_White.svg'
import PIL from '../../pdf/PIL.pdf'

// Load styles
import { normalText } from '../../style/styleFormat'


const Main = ({ productCode, maxPage, stopPrevious, title, children, passed, navControl, background, save, pageNumber, nextLabel = "Next", openDrawer}) => {
    const [modalType, setModal] = useState()
    const toast = useToast()    
    const { isOpen, onClose, onOpen } = useDisclosure()
    
    let modalContent = null

    switch (modalType) {
        case 'Product Information':
            modalContent = <ProductInformation />
            break
        case 'Terms of Use':
            modalContent = <Terms />
            break
        case 'Privacy Policy':
            modalContent = <Privacy />
            break
        case 'Cookies Policy':
            modalContent = <CookiePolicy />
            break
        default:
            modalContent = <ProductInformation />
    }

    function modalControl(type) {
        setModal(type)
        onOpen()
    }
    return (
        <Box width="100%" 
            height="100%" 
            display="flex" 
            flexDirection="column" 
            justifyContent="space-between"
            backgroundImage={`url(${background})`}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="right top"
            paddingTop="6"
        >
            <Box display={{base: "flex", lg: "none"}} justifyContent="space-between" alignItems="center" padding={{base: "5px 20px", lg:"0 20px"}} marginBottom="25px">
                <Image width="40%" maxWidth="200px" src={Hana} alt="Hana logo" />
                <MenuIcon onClick={openDrawer} color="#fff" fontSize="40px" />
            </Box>
            <Flex  id="next-top" justify="space-between" align="center" padding={{base: "0 20px", lg:"20px 30px"}}>
                {pageNumber < maxPage && <Heading color="white" fontSize={{base: "22px", lg:"xlg"}} textTransform="uppercase" maxWidth={{base: "100%", lg:"90%"}} lineHeight={{base:"30px", lg: "40px"}} fontWeight={700}>{title}</Heading>}
                {pageNumber === maxPage && <Heading color="white" fontSize={{base: "22px", lg:"xlg"}} textTransform="uppercase" maxWidth={{base: "100%", lg:"90%"}} lineHeight="40px" fontWeight={700}>{passed ? 'Well done!' : 'Not Quite, Try again!'}</Heading>}
                {pageNumber !== maxPage &&
                    <Button 
                    display={{base: "none", lg: "block"}}
                    fontSize="1rem"
                    backgroundColor="orange.900"
                    color="#fff"
                    borderColor="#fff"
                    border="1px"
                    height="51px"
                    minWidth="100px"
                    size="lg"
                    fontWeight={700}
                    variant="solid"
                    isDisabled={pageNumber === maxPage}
                    onClick={() => navControl('increment')}
                > {nextLabel}<Icon name="chevron-right" fontSize="32px" /></Button>
                }
            </Flex>
            <Box height="100%" padding={{base:"20px", lg: "30px"}}>
                {children}
            </Box>
            
            <Flex justify={pageNumber > 0 ? "space-between" : 'flex-end'} align="flex-end" padding="20px 30px">
                {pageNumber > 0 && <Button 
                    isDisabled={pageNumber > stopPrevious}
                    fontSize="1rem"
                    backgroundColor="blue.900"
                    color="#fff"
                    borderColor="#fff"
                    border="1px"
                    height="51px"
                    size="lg"
                    fontWeight={700}
                    variant="solid"
                    onClick={() => navControl('decrement')}
                    >
                    <Icon name="chevron-left" fontSize="32px" />
                    <Box as="span" display={{base: "none", lg: "block"}}>Previous</Box>
                </Button>
                }
                {pageNumber > 0 &&
                    <Button 
                    onClick={() => { 
                        save()
                        toast({
                            position: "bottom-left",
                            status: 'info',
                            description: 'Your progress has been saved, you are able to come back and complete this within the next 30 days without restarting the Hana pharmacy guide',
                            duration: 9000,
                            isClosable: true
                            })
                        }
                    }
                    fontSize="1rem"
                    backgroundColor="orange.900"
                    color="#fff"
                    borderColor="#fff"
                    border="1px"
                    height="51px"
                    size="lg"
                    fontWeight={700}
                    variant="solid"
                    > 
                    <Box as="span" display={{base: "none", lg: "block"}}> SAVE AND EXIT </Box>
                    <Box as={BiSave} color="#fff" fontSize="30px" display={{base: "block", lg:"none"}}/>
                </Button>
                }
                {pageNumber === maxPage ? <Box /> :
                <Button 
                    fontSize="1rem"
                    backgroundColor="orange.900"
                    color="#fff"
                    borderColor="#fff"
                    border="1px"
                    height="51px"
                    minWidth={{base: "auto", lg:"100px"}}
                    size="lg"
                    fontWeight={700}
                    variant="solid"
                    onClick={() => navControl('increment')}
                    isDisabled={pageNumber === 117}
                    > 
                    <Box as="span" display={{base: "none", lg: "block"}}> {nextLabel}</Box>
                    <Icon name="chevron-right" fontSize="32px" />
                </Button>
                }
            </Flex>
            <Box textAlign="center" padding="4">
                <Box display="flex" justifyContent="center">
                    <Image src={HanaFooter} alt="hana logo" />
                </Box>      
                <Text {...normalText} display="block" color="#fff">
                    © HRA PHARMA - ALL RIGHTS RESERVED. Last modification: 24-11-2020
                </Text>
                <Text {...normalText} display="block" color="#fff">
                    Hana 75 microgram film coated tablets contain desogestrel and are indicated for use by women of childbearing age to prevent pregnancy
                </Text>
                <Text {...normalText} display="block" color="#fff">
                    Date of preparation: November 2020 | UK/HANA/{productCode}
                </Text>
                <Box color="#fff">
                    <Link textDecoration="underline" fontSize="12px" href="mailto:info-uk@hra-pharma.com" target="_blank" rel="noopener noreferrer">
                     CONTACT
                    </Link>
                    <Text as="span" fontSize="12px" margin="0 5px">/</Text>
                    <Link onClick={() => modalControl('Terms of Use')}  textDecoration="underline" fontSize="12px" href="#">
                        TERMS OF USE
                    </Link>
                    <Text as="span" fontSize="12px" margin="0 5px">/</Text>
                    <Link onClick={() => modalControl('Privacy Policy')}  textDecoration="underline" fontSize="12px" href="#">
                        PRIVACY POLICY
                    </Link>
                    <Text as="span" fontSize="12px" margin="0 5px">/</Text>
                    <Link onClick={() => modalControl('Cookies Policy')}  textDecoration="underline" fontSize="12px" href="#">
                        COOKIE POLICY
                    </Link>
                    <Text as="span" fontSize="12px" margin="0 5px">/</Text>
                    <Link href={PIL} target="_blank" rel="noopener noreferrer" download textDecoration="underline" fontSize="12px">
                       PACK LEAFLET
                    </Link>
                    <Text as="span" fontSize="12px" margin="0 5px">/</Text>
                    <Link textDecoration="underline" fontSize="12px" onClick={() => modalControl('Product Information')}>
                        PRODUCT INFORMATION
                    </Link>
                </Box>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} size="lg-custom" title={modalType}>
                {modalContent}
            </Modal>
        </Box>
    )
}

export default Main