import React from 'react'
import { Box, Text } from '@chakra-ui/core'
import { normalText } from '../../style/styleFormat'

import Layout from '../../layout/Layout.card'

const CaseStudyIntro = ({ paraOne, paraTwo }) => {
    return (
        <Box height="100%" display="flex" alignItems="center">
            <Layout>
                <Text {...normalText} textAlign="center">
                    {paraOne}
                </Text>
                <Text {...normalText} textAlign="center" marginTop="10px" color="orange.900" fontWeight="bold">
                    {paraTwo}
                </Text>
            </Layout>
        </Box>

    )
}

export default CaseStudyIntro