import React, { useState } from 'react'
import { Box, Text, Heading, useDisclosure, ListItem, List, Stack } from '@chakra-ui/core'

// Load components
import Reference from '../../components/UI/Typography/Reference'
import Modal from '../../components/Modal/Modal'

// Load style
import { normalText, referenceText, headingTextbkRnd, referenceLink, textBoldPara, purpleBullet } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const ReferencePopup = () => (
    <List as="ol" spacing="4" styleType="decimal">
        <ListItem {...referenceText}>
            Hana (desogestrel) Summary of Product Characteristics, November 2020.
        </ListItem>
        <ListItem {...referenceText}>
            Hana (desogestrel) Patient Information Leaflet, November 2020.
        </ListItem>
    </List>
)

const InfoPopUp = () => (
    <List  spacing="4" styleType="disc">
        <ListItem 
        {...referenceText}
        paddingLeft="20px"
        position="relative"
        display="block"
        _before={{
        ...purpleBullet,
        top: "-40px"
        }}
        >
            Women may be aware of the potential non-contraceptive benefits of the combined oral contraceptive pill, and may believe that Hana would have similar beneficial effects
        </ListItem>
        <ListItem 
        {...referenceText}
        paddingLeft="20px"
        position="relative"
        display="block"
        _before={{
        ...purpleBullet,
        top: "-40px"
        }}
        >
            While it would be inappropriate for pharmacists to ask women directly whether they are requesting Hana for any reason other than pregnancy prevention, <Text as="span" fontFamily="Gotham Bold">it is important for pharmacists to be aware of this, and if asked be able to respond that:</Text>
        </ListItem>
        <Box marginLeft="15px" padding="2">
        <ListItem 
        {...referenceText}
            paddingLeft="15px"
            position="relative"
            display="block"
            _before={{
            ...purpleBullet,
            top: "-40px",
            left: "-15px"
            }}
            >
            there is very little evidence that Hana would be better than placebo in improving dysmenorrhoea or endometriosis
        </ListItem>
        <ListItem
        {...referenceText}
        paddingLeft="15px"
        margin="10px 0"
        position="relative"
        display="block"
        _before={{
            ...purpleBullet,
            top: "-40px",
            left: "-15px"
            }}
            >
            there is no evidence to support its effectiveness for premenstrual syndrome, acne (which may get worse), or heavy menstrual bleeding
        </ListItem>
        <ListItem
        {...referenceText}
        paddingLeft="15px"
        position="relative"
        display="block"
        _before={{
          ...purpleBullet,
          top: "-40px",
          left: "-15px"
            }}
            >
            it is unlikely to postpone menses for social (or other) reasons
        </ListItem>
        </Box>
    </List>
)

const WhoCanUseHanaTwo = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [content, setContent] = useState('')

    const loadContent = content === 'References' ? <ReferencePopup /> : <InfoPopUp />

    function modalContent(content) {
        setContent(content)
        onOpen()
    }
    return (
        <Layout>
            <Stack spacing={8}>
                <Heading {...headingTextbkRnd} width={{base: "auto", lg:"max-content"}}>
                   When to refer to a doctor before use of Hana
                </Heading>
                <Text {...textBoldPara} color="purple.900">
                    There are other conditions in which Hana might be suitable, <u>but</u> a doctor would need to assess the woman first:<sup>1,2</sup>
                </Text>
            
                <List spacing="4">
                    <ListItem 
                    {...normalText}
                    paddingLeft="20px"
                    position="relative"
                    display="block"
                    _before={{
                        position: 'absolute',
                        top: '2px',
                        left: '-5px',
                        content: "'•'",
                        color: 'purple.500',
                        fontSize: '54px'
                        }}
                    >
                        history of breast, ovarian or uterine cancer
                    </ListItem>
                    <ListItem 
                        {...normalText}
                        paddingLeft="20px"
                        position="relative"
                        display="block"
                        _before={{
                            position: 'absolute',
                            top: '2px',
                            left: '-5px',
                            content: "'•'",
                            color: 'purple.500',
                            fontSize: '54px'
                            }}
                        >
                            current liver cancer or other liver disorders
                    </ListItem>
                    <ListItem 
                    {...normalText}
                    paddingLeft="20px"
                    position="relative"
                    display="block"
                    _before={{
                        position: 'absolute',
                        top: '2px',
                        left: '-5px',
                        content: "'•'",
                        color: 'purple.500',
                        fontSize: '54px'
                        }}>
                        Type 1 or Type 2 diabetes
                    </ListItem>
                </List>

                <Heading {...headingTextbkRnd}  width={{base: "auto", lg:"max-content"}}>
                    Counsel women before or during use of Hana
                </Heading>
                <Text {...textBoldPara}>
                    Hana can be used by women with a history of chloasma or thromboembolic disorders, or who have high blood pressure, but they should be counselled as follows before <span style={{position: 'relative'}}>supply:<Reference>1</Reference></span>
                </Text>

                <List spacing="4">
                    <ListItem 
                    {...normalText}
                    paddingLeft="20px"
                    position="relative"
                    _before={{
                        position: 'absolute',
                        top: '2px',
                        left: '-5px',
                        content: "'•'",
                        color: 'purple.500',
                        fontSize: '54px'
                        }}>
                        chloasma (yellowish-brown pigmentation patches on the skin, particularly of the face) may occasionally occur, especially in women who have had it before; these women should avoid exposure to the sun or UV radiation
                    </ListItem>
                    <ListItem 
                    {...normalText}
                    paddingLeft="20px"
                    position="relative"
                    _before={{
                        position: 'absolute',
                        top: '2px',
                        left: '-5px',
                        content: "'•'",
                        color: 'purple.500',
                        fontSize: '54px'
                        }}>
                        there is the possibility of a recurrence of venous thrombosis or pulmonary embolism in women with a history of these conditions
                    </ListItem>
                    <ListItem 
                        {...normalText}
                        paddingLeft="20px"
                        position="relative"
                        _before={{
                            position: 'absolute',
                            top: '2px',
                            left: '-5px',
                            content: "'•'",
                            color: 'purple.500',
                            fontSize: '54px'
                            }}>
                            women who develop sustained hypertension during use of Hana, or a significant increase in blood pressure that does not adequately respond to antihypertensive therapy, should see their doctor to decide whether Hana should be discontinued (pharmacists are not expected to check/monitor blood pressure prior to or during supply)
                    </ListItem>
                </List>

                <Heading {...headingTextbkRnd}  width="max-content">
                    Use in breastfeeding
                </Heading>
                <Text {...normalText}>
                   <Text {...textBoldPara} as="span">Hana can be used by women who are <span style={{position: 'relative'}}>breastfeeding.<sup>1</sup></span></Text> Although a small amount of the active substance of Hana passes into the milk, Hana does not appear to influence the production or the quality of breast milk<span style={{position:"relative"}}>.<Reference>1</Reference></span>&nbsp; However, there have been infrequent postmarketing reports of a decrease in breast milk production while using desogestrel<span style={{position:"relative"}}>.<Reference>1</Reference></span>&nbsp; The development and growth of a breastfed infant, whose mother uses Hana, should be carefully observed<span style={{position:"relative"}}>.<Reference>1</Reference></span>&nbsp; However, no effect of desogestrel in breastfed newborns/infants has been shown<span style={{position:"relative"}}>.<Reference>1</Reference></span>&nbsp;
                </Text>

                <Text {...normalText}>
                    <Box as="u" cursor="pointer" fontWeight="bold" fontFamily="Gotham Bold" onClick={() => modalContent('Guidance on off-label use')}>Click here</Box> for guidance on what to do if you suspect a woman is requesting Hana for off-label use
                </Text>

                <Text as="u" {...referenceLink} onClick={() => modalContent('References')}>
                    References
                </Text>
            </Stack>
            <Modal isOpen={isOpen} onClose={onClose} title={content}>
                {loadContent}
            </Modal>
        </Layout>
    )
}

export default WhoCanUseHanaTwo