import React from 'react'
import { Box, AspectRatioBox } from '@chakra-ui/core'

// Load asset
import Consultation from '../images/consultation.png'


const LayoutCard = ({ children }) => {
    return (
         <AspectRatioBox minWidth={{base: "100%", lg:"900px"}}  minHeight={{base: "80vh", lg:"600px"}} maxWidth={{base: "100vw", lg:"1200px"}} ratio={16 / 9} margin="0 auto">
            <Box position="relative" backgroundImage={`url(${Consultation})`}  backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center" height="100%"  width="100%" borderRadius="20px" padding="8">
                {children}  
            </Box>
        </AspectRatioBox>
    )
}


export default LayoutCard