import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

export const CaseNineNoYes = () => <Text {...normalText}>
This woman has a history of severe liver disorder, and it is not clear whether her liver function values are back to normal. She should <Text as="span" {...boldGreen}>not be supplied</Text> with Hana <Text as="span" {...boldGreen}>until her doctor has confirmed that it is suitable</Text> for her. If the doctor says her liver function values are normal, advise her to come back, when the pharmacist will be happy to supply her with Hana.
</Text>

export const CaseNineNoNo = () => <Text {...normalText}>
This woman has a history of severe liver disorder, and it is not clear whether her liver function values are back to normal. She should <Text as="span" {...boldRed}>not be supplied</Text> with Hana <Text as="span" {...boldRed}>until her doctor has confirmed that it is suitable</Text> for her. If the doctor says her liver function values are normal, advise her to come back, when the pharmacist will be happy to supply her with Hana.
</Text>

export const CaseNineYesNo = () => <Text {...normalText}>
This woman has a history of severe liver disorder, and it is not clear whether her liver function values are back to normal. She should <Text as="span" {...boldRed}>not be supplied</Text> with Hana <Text as="span" {...boldRed}>until her doctor has confirmed that it is suitable </Text> for her. If the doctor says her liver function values are normal, advise her to come back, when the pharmacist will be happy to supply her with Hana.
</Text>

export const CaseNineYesYes = () => <Text {...normalText}>
This woman has a history of severe liver disorder, and it is not clear whether her liver function values are back to normal. She should <Text as="span" {...boldRed}>not be supplied</Text> with Hana <Text as="span" {...boldRed}>until her doctor has confirmed that it is suitable </Text> for her. If the doctor says her liver function values are normal, advise her to come back, when the pharmacist will be happy to supply her with Hana.
</Text>