import React from 'react'
import { Box, Heading, Text, useDisclosure, List, ListItem, Image } from '@chakra-ui/core'

// Load components
import Reference from '../../components/UI/Typography/Reference'
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load assets
import {ReactComponent as Clock } from '../../images/clockTwleve.svg'
import Bottle from '../../images/bottleP.png'
import {ReactComponent as People } from '../../images/people.svg'
import {ReactComponent as Percent } from '../../images/upToPercent.svg'

// Load styles
import { referenceLink, subTitlelightPurple } from '../../style/styleFormat'

const textStyle = {
    position: 'relative',
    fontSize: 'md',
    lineHeight: 'tight',
    color: 'text.900',
    display: "inline-block"
}

const bkgStyle = {
    backgroundColor: "background.1",
    border: "1px solid #9894C6",
    margin: "20px 0",
    borderRadius: "21px", 
    padding: "4" 
}


const FactCard = ({ svg: SVG, content,img }) => {
    return (
        <Box backgroundColor="#fff" display="flex" flexDirection="column" alignItems="center" width={{base: "100%", lg: "24%"}} padding="5">
            <Box display="flex" alignItems="center" height="60px" marginTop="25px">
                {SVG && <SVG />}
                {img && <Image src={img} alt="bottle outline" />}
            </Box>
            <Box marginTop="40px" {...textStyle}>{content}</Box>
        </Box>
    )
}


const IntroducingHana = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Box width="100%">
                <Text {...subTitlelightPurple}>
                    Hana is a <Text as="u">progestogen-only oral contraceptive pill (POP)</Text>, which contains the progestogen desogestrel.<sup style={{fontSize: "10px"}}>1</sup>
                </Text>
                <br />
                <Text {...textStyle} marginBottom="10px">
                    As a pharmacy medicine, without prescription, it is for women of childbearing age who want to prevent pregnancy<span style={{position: "relative"}}>.<Reference>1</Reference></span> 
                </Text>
                <br/>
                <Text {...textStyle}>
                    Hana is taken daily, at the same time each day, without any breaks between packs<span style={{position: "relative"}}>.<Reference>1</Reference></span>
                </Text>

                <Box {...bkgStyle} width="100%">
                    <Heading fontSize="md" lineHeight="tighter" color="#6C66AC" marginBottom="20px">KEY FACTS ABOUT HANA</Heading>

                    <Box display="flex" flexDirection={{base: "column", lg:"row"}} justifyContent="space-between">
                        <FactCard
                        svg={People}
                        content={<Text {...textStyle}>Like other POPs, Hana is suitable for most women, including during breastfeeding and for women who are unable to or prefer not to use <span style={{position: 'relative'}}>oestrogens<Reference>1</Reference></span></Text>}
                        />
                        <FactCard
                        svg={Percent}
                        content={<Text {...textStyle}>If taken as recommended, i.e. at the same time every day without a break between packs, Hana inhibits ovulation in up to 97% of <span style={{position: 'relative'}}>cycles &nbsp; <Reference> 2,3</Reference></span></Text>}
                        />
                        <FactCard
                        svg={Clock}
                        content={<Text {...textStyle}>Hana has a 12-hour window for missed pills if <span style={{position: 'relative'}}>necessary <Reference>1</Reference></span></Text>}
                        />
                        <FactCard
                        img={Bottle}
                        content={<Text {...textStyle}>Oral contraceptive pills do not protect against sexually transmitted infections, only condoms will do <span style={{position: 'relative'}}>so<Reference>4</Reference></span></Text>}
                        />
                    </Box>
                </Box>
                <Text cursor="pointer" onClick={onOpen} as="u" {...referenceLink} >References</Text>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem fontSize="md" color="#3B2B51">Hana (desogestrel) Summary of Product Characteristics, November 2020.</ListItem>
                    <ListItem fontSize="md" color="#3B2B51">Rice C, Killick S, Hickling D, <em>et al</em>. Ovarian activity and vaginal bleeding patterns with a desogestrel-only preparation at three different doses. Hum Reprod 1996;11(4):737-740.</ListItem>
                    <ListItem fontSize="md" color="#3B2B51">  Rice CF, Killick SR, Dieben T, <em>et al</em>. A comparison of the inhibition of ovulation achieved by desogestrel 75 micrograms and levonorgestrel 30 micrograms daily. Hum Reprod 1999;14(4):982-985.</ListItem>
                    <ListItem fontSize="md" color="#3B2B51">Hana (desogestrel) Patient Information Leaflet, November 2020.</ListItem>
                </List>
            
            </Modal>
        </Layout>
    )
}

export default IntroducingHana