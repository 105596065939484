import React, { Component, Fragment } from 'react'

// Load questions
import { questions } from '../../content/Assessment/Questions'

// Load components
import IntroAssessment from '../../content/Assessment/IntroductionAssessment'
import SelectWithAnswer from '../../components/Assessments/SelectWithAnswer/SelectWithAnswer'
import SelectExpanded from '../../components/Assessments/SelectExpanded/SelectExpanded'
import TrueFalse from '../../components/Assessments/TrueFalse/TrueFalse'
import MoreInfo17 from '../../content/Assessment/MoreInfo17'

class Assessment extends Component {

    state = {
        currentQuestion: 0,
        questionIds: [],
        questionsGenerated: []
    }

    componentDidMount() {
        const { reload } = this.props

        this.buildRandomQuestion()

        // On direct url hit set to assessment page
        reload(63)
    }

    calculateAnswer = (answer) => {
        const  { setAnswer, correct } = this.props

        // Add to tally or return current score
        const tally = answer ? correct + 1 : correct

        setAnswer(tally)
    }

    buildRandomQuestion = () => {
       
        const numbersPicked = []
        const questionsPicked = []

        while (numbersPicked.length < 15) {

            // Make sure random number generated is not repeating
            while (true) {

                // Set the min + max range
                let rnd = Math.floor((Math.random() * 19) + 1)
                
                //if random number not picked yet exit loop
                if (!~numbersPicked.indexOf(rnd)) {
                    numbersPicked.push(rnd)

                    // Add first question
                    if (questionsPicked.length < 1) {
                        questions[rnd].page['display'] = [questionsPicked.length, questionsPicked.length + 1]
                        questions[rnd].page['reveal'] = questionsPicked.length + 1
                        questionsPicked.push(questions[rnd])
                        break
                    }

                    questions[rnd].page['display'] = [questionsPicked.length * 2, (questionsPicked.length * 2) + 1]
                    questions[rnd].page['reveal'] = (questionsPicked.length * 2) + 1
                    questionsPicked.push(questions[rnd])
                    break
                }
            }
        }

        this.setState({ questionIds: numbersPicked, questionsGenerated: questionsPicked })
    }

    buildSingleChoiceAnswer = () => {
        const { allowNext, changePage } = this.props
        const { questionsGenerated, currentQuestion } = this.state
        const { calculateAnswer } = this

        return (
            <Fragment>
              {questionsGenerated.length > 0 && questionsGenerated.map((question, index) => {
                 
                if (question.page.display.includes(currentQuestion) && question.page.withMultiAnswer) {
                     return (
                        <SelectWithAnswer 
                        key={index}
                        questions={question.form} 
                        title={question.page.title} 
                        reveal={currentQuestion === question.page.reveal} 
                        subTitle={question.page.subTitle} 
                        setNext={allowNext} 
                        single={true}
                        changePage={changePage}
                        setAnswer={calculateAnswer} />
                    )
                }

                if (question.page.display.includes(currentQuestion) && question.page.withAnswer) {
                    return (
                       <SelectWithAnswer 
                       key={index}
                       questions={question.form} 
                       title={question.page.title} 
                       reveal={currentQuestion === question.page.reveal} 
                       subTitle={question.page.subTitle} 
                       setNext={allowNext} 
                       single={false}
                       changePage={changePage}
                       setAnswer={calculateAnswer} />
                   )
               }

                if (question.page.display.includes(currentQuestion) && question.page.withTrueFalse) {
                    return (
                        <TrueFalse
                        key={index}
                        questions={question.form} 
                        title={question.page.title} 
                        reveal={currentQuestion === question.page.reveal} 
                        subTitle={question.page.subTitle} 
                        question={question.page.question}
                        setNext={allowNext} 
                        changePage={changePage}
                        setAnswer={calculateAnswer} />
                    )
                }

        
               if (question.page.display.includes(currentQuestion) && question.page.withExpanded) {
                return (
                   <SelectExpanded
                   key={index}
                   questions={question.form} 
                   title={question.page.title} 
                   reveal={currentQuestion === question.page.reveal} 
                   subTitle={question.page.subTitle} 
                   setNext={allowNext} 
                   single={false}
                   changePage={changePage}
                   modalTitle={question.page.modal}
                   component={<MoreInfo17 />}
                   setAnswer={calculateAnswer} />
               )
            }

                return null
            })}
            </Fragment>
        )
    }

    componentDidUpdate(prevProps) {
        const { currentPage } = this.props

        if (prevProps.currentPage < currentPage && currentPage > 64) {
            this.setState(prevProps => {
                return {
                    currentQuestion: prevProps.currentQuestion + 1
                }
            })
        }
    }

    render() {
        const { currentPage, changePage } = this.props
        
        // Single choice only questions
        const buildSingleChoice = this.buildSingleChoiceAnswer()
      
        return (
            <Fragment>
                {currentPage === 63 && <IntroAssessment changePage={changePage} />}
                {currentPage > 63 && buildSingleChoice}
            </Fragment>
        )
    }
}

export default Assessment