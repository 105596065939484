import React from 'react'
import { Box, Text, Heading, ListItem, List, useDisclosure, Link } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import Reference from '../../components/UI/Typography/Reference'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, referenceLink } from '../../style/styleFormat'

const AdviceTakingHanaFour = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Heading {...secondaryText} display="block" fontSize="lg">
                When to seek medical advice
            </Heading>
            <Text {...secondaryText} margin="10px 0" fontFamily="Gotham Bold" fontWeight="bold">
                There are some situations that you should be aware of in case a woman reports these symptoms. These situations require immediate medical attention.
            </Text>

            <Box margin="20px 0" borderTop={{base: "1px solid #4580AB", lg:"2px solid #4580AB"}} display="flex" flexDirection="column">
                <Box display="flex" flexDirection={{base:"column", lg: "row"}}  borderBottom="1px solid #739FBF" width="100%">
                    <Box backgroundColor="#E3ECF3" padding="4" borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"50%"}}>
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Symptom/warning sign
                        </Text>
                    </Box>
                    <Box backgroundColor="#E3ECF3" padding="4" width={{base: "100%", lg:"50%"}}>
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Possible cause
                        </Text>
                    </Box>
                </Box>
                <Box borderBottom="1px solid #739FBF" flexDirection={{base:"column", lg: "row"}}  display="flex">
                    <Box borderRight={{base: "none", lg:"1px solid #739FBF"}} padding="4" width={{base: "100%", lg:"50%"}} >
                        <Text {...secondaryText}>
                        Pelvic pain with either vaginal bleeding/spotting or <span style={{position: 'relative'}}>amenorrhoea<Reference>1</Reference></span>
                        </Text>
                    </Box>
                    <Box width={{base: "100%", lg:"50%"}} padding="4">
                        <Text {...secondaryText} marginBottom="20px">
                            Ectopic pregnancy
                        </Text>
                        <Text {...secondaryText}>
                        As with all women of reproductive age, ectopic pregnancy should be considered a possibility if the woman develops these symptoms, particularly if Hana has been used inconsistently or incorrectly making pregnancy more likely;<sup>1</sup> the woman may not know she is pregnant as she may not have missed a period, or have mistakenly thought she had her period because of bleeding caused by either Hana or the ectopic pregnancy; these symptoms should prompt you to refer the woman for immediate medical attention regardless of whether her use of Hana has been <span style={{position: 'relative'}}>correct<Reference>1</Reference></span>
                        </Text>
                    </Box>
                </Box>

                <Box borderBottom="1px solid #739FBF" flexDirection={{base:"column", lg: "row"}}  display="flex">
                    <Box borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"50%"}} padding="4">
                        <Text {...secondaryText}>
                            Pain or swelling in either leg, heavy ache, warm skin at the site, red skin at the back of the leg below the knee<sup>2,3</sup>
                        </Text>
                    </Box>
                    <Box width={{base: "100%", lg:"50%"}} padding="4">
                        <Text {...secondaryText}>
                            Blood clot in the leg/deep vein thrombosis (DVT)
                        </Text>
                    </Box>
                </Box>

                <Box borderBottom="1px solid #739FBF" flexDirection={{base:"column", lg: "row"}}  display="flex">
                    <Box borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"50%"}} padding="4">
                        <Text {...secondaryText}>
                            Breathlessness, unexplained chest pain, unusual cough, coughing up <span style={{position: 'relative'}}>blood &nbsp;&nbsp;<Reference> 2,3</Reference></span>
                        </Text>
                    </Box>
                    <Box width={{base: "100%", lg:"50%"}} padding="4">
                        <Text {...secondaryText}>
                            Pulmonary embolism
                        </Text>
                    </Box>
                </Box>
                <Box borderBottom="1px solid #739FBF" flexDirection={{base:"column", lg: "row"}}  display="flex">
                    <Box borderRight={{base: "none", lg:"1px solid #739FBF"}} width={{base: "100%", lg:"50%"}} padding="4">
                        <Text {...secondaryText}>
                            Yellowing of the skin or whites of the eyes, dark urine, other signs and symptoms of liver <span style={{position: 'relative'}}>disorders <Reference>2</Reference></span>
                        </Text>
                    </Box>
                    <Box width={{base: "100%", lg:"50%"}} padding="4">
                        <Text {...secondaryText}>
                            Jaundice, other liver disorders, liver cancer
                        </Text>
                    </Box>
                </Box>
            </Box>

            <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Patient Information Leaflet, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        National Health Service (NHS). Deep vein thrombosis. October 2019. Available at: <Link href="https://www.nhs.uk/conditions/deep-vein-thrombosis-dvt/" textDecoration="underline" isExternal>https://www.nhs.uk/conditions/deep-vein-thrombosis-dvt/</Link>. Accessed: November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default AdviceTakingHanaFour