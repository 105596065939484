import React, { useEffect } from "react";

const Privacynotice = () => {
  useEffect(() => {
    // Dynamically inject the OneTrust script
    const script = document.createElement("script");
    script.src = "https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js";
    script.type = "text/javascript";
    script.charset = "UTF-8";
    script.id = "otprivacy-notice-script";
    script.setAttribute("settings", "eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcGVycmlnby1wcml2YWN5Lm15Lm9uZXRydXN0LmNvbS9yZXF1ZXN0L3YxL3ByaXZhY3lOb3RpY2VzL3N0YXRzL3ZpZXdzIn0=");
    document.body.appendChild(script);

    // Load the notices after the script is initialized
    script.onload = () => {
      if (window.OneTrust && window.OneTrust.NoticeApi) {
        window.OneTrust.NoticeApi.Initialized.then(() => {
          window.OneTrust.NoticeApi.LoadNotices([
            "https://privacyportalde-cdn.onetrust.com/5122cbe6-42bb-4462-a3b4-f863cf81253c/privacy-notices/a4f0238f-9e60-4051-8b48-26a686c640de.json",
          ]);
        });
      }
    };

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Container where the privacy notice will be rendered */}
      <div id="otnotice-a4f0238f-9e60-4051-8b48-26a686c640de" className="otnotice contentbox"></div>
    </div>
  );
};

export default Privacynotice;