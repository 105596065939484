import React from 'react'
import { Heading, Text, Box, Image } from '@chakra-ui/core'

// Load assets
import CliboardImg from '../../images/cliboard.png'

// Load layout
import LayoutCard from '../../layout/Layout.card'

const headStyle = {
    color: "purple.200",
    textTransform: "uppercase",
    fontSize: {base: "18px",  lg: "lg"},
    lineHeight: "wide",
    margin: "20px 0",
    display: 'block'
}

const textStyle = {
    color: "purple.600",
    fontSize: "md",
    lineHeight: "wide",
    display: "block",
    margin: "30px 0"
}

const PharmaWelcome = ({ nextPage }) => (
    <LayoutCard>
        <Box display={{base: "none", lg: "block"}} style={{ shapeOutside: 'circle(50%)'}} position="relative" float="right" height="627px" width="450px">
            <Image src={CliboardImg} position="absolute" top="0" paddingTop="0" height="520px" right="-13.9%"/>
        </Box>
       
        <Heading {...headStyle}>
            Hana, daily oral contraception, can be supplied in pharmacy without a prescription
        </Heading>
        <Text {...textStyle}>
            You and the rest of the pharmacy team have a <Text as="span" color="purple.200" fontFamily="Gotham Bold">key role</Text> to play in <Text as="span" color="purple.200" fontFamily="Gotham Bold">supporting your pharmacist(s)</Text> in the supply of Hana to women in pharmacy.
        </Text>
        
        <Text {...textStyle}>
            This training is designed to help you <Text as="span" color="purple.200" fontFamily="Gotham Bold">build your knowledge of Hana</Text> and <Text as="span" color="purple.200" fontFamily="Gotham Bold">support</Text> <Text as="span" color="purple.200" fontFamily="Gotham Bold">conversations with women</Text> seeking regular oral contraception.
        </Text>

        <Text {...textStyle}>
            It should take you <Text as="span" color="purple.200" fontFamily="Gotham Bold">approximately 15 minutes</Text> to finish the training. There’s a short test at the end for you to check your own knowledge, and you’ll receive a certificate upon completion.
        </Text>

        <Text {...textStyle} >
            <Text as="u" onClick={nextPage} fontFamily="Gotham Bold" cursor="pointer">Click here</Text> to get started!
        </Text>
    
       
    </LayoutCard>
)

export default PharmaWelcome