import React, { Fragment, useState } from 'react'
import { Box, Heading, List, ListItem, useDisclosure, Icon } from '@chakra-ui/core'

// Load components
import Video from '../Video/Video'
import Modal from '../Modal/Modal'

// Load styles
import { headingText } from '../../style/styleFormat'

// Load pdf
import guide from '../../pdf/Hana_Pharmacy_Guide.pdf'
import checklist from '../../pdf/Pharmacy_supply_aid_checklist.pdf'
import algorithm from '../../pdf/Pharmacy_supply_algorithm.pdf'
import tool from '../../pdf/Pregnancy_exclusion_tool.pdf'
import leaflet from '../../pdf/HRA_Contraceptive_Options_Leaflet.pdf'
import teamguide from '../../pdf/Pharmacy_team_guide.pdf'


// Load assets
import Card1 from '../../images/cards/card1.png'
import Card2 from '../../images/cards/card2.png'
import Card3 from '../../images/cards/picture-2.png'
import Card4 from '../../images/cards/picture-3.png'
import Card5 from '../../images/cards/picture-4.png'
import Card6 from '../../images/cards/picture-5.png'
import Card7 from '../../images/cards/picture-6.png'
import Card8 from '../../images/cards/picture-7.png'

// Load vids
import vidZero from '../../video/MOA.mp4'
import vidone from '../../video/Scenario1.mp4'
import vidtwo from '../../video/Scenario2.mp4'
import vidthree from '../../video/Scenario3.mp4'
import vidfour from '../../video/Scenario4.mp4'
import vidfive from '../../video/Scenario5.mp4'
import vidsix from '../../video/Scenario6.mp4'
import vidseven from '../../video/Scenario7.mp4'
import videight from '../../video/Scenario8.mp4'


const Resources = ({ close, isPharmacy = false }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [activeVid, setVid] = useState(false)

    function openModal(content) {
        setVid(content)
        onOpen()
    }
    return (   
        <Box width="100%">
        <Icon name="close" cursor="pointer" onClick={() => close(false)} color="#fff" position="absolute" top="20px" right="20px" />
            <Heading {...headingText} marginBottom="20px" color="#fff">
                Resources
            </Heading>
        
            <List styleType="disc" spacing="2" marginLeft="15px">
                    {/** PDF DOWNLOADS */}
                    <ListItem 
                    as="a"
                    href={isPharmacy ? guide : teamguide} 
                    rel="noopener noreferrer"
                    target="_blank"
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                        {isPharmacy ? 'Pharmacy guide' : 'Pharmacy team guide'}
                    </ListItem>
                    {isPharmacy &&
                    <Fragment>
                    <ListItem 
                    as="a"
                    href={checklist} 
                    rel="noopener noreferrer"
                    target="_blank"
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                        Pharmacy supply aid checklist
                    </ListItem>
                    <ListItem 
                    as="a"
                    href={algorithm} 
                    rel="noopener noreferrer"
                    target="_blank"
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                        Pharmacy supply algorithm
                    </ListItem>
                    <ListItem 
                    as="a"
                    href={tool} 
                    rel="noopener noreferrer"
                    target="_blank"
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                        Pregnancy exclusion tool
                    </ListItem>

                    {/** ANIMATIONS */}
                    <ListItem 
                    marginTop="0px"
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                       Animations:
                    </ListItem>
                    <Box marginLeft="15px" marginBottom="15px">
                         {/** ANIMATIONS */}
                    <ListItem 
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    onClick={() => openModal({vid: vidZero, thumb: '', title: 'Hana mechanism of action'})}
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                      Hana mechanism of action
                    </ListItem>
                    <ListItem 
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    onClick={() => openModal({vid: vidone, thumb: Card1, title: 'Hana supply scenario 1'})}
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                      Hana supply scenario 1
                    </ListItem>
                    <ListItem 
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    onClick={() => openModal({vid: vidtwo, thumb: Card2, title: 'Hana supply scenario 2'})}
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                      Hana supply scenario 2
                    </ListItem>
                    <ListItem 
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    onClick={() => openModal({vid: vidthree, thumb: Card3, title: 'Hana supply scenario 3'})}
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                      Hana supply scenario 3
                    </ListItem>
                    <ListItem 
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    onClick={() => openModal({vid: vidfour, thumb: Card4, title: 'Hana supply scenario 4'})}
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                      Hana supply scenario 4
                    </ListItem>
                    <ListItem 
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    onClick={() => openModal({vid: vidfive, thumb: Card5, title: 'Hana supply scenario 5'})}
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                      Hana supply scenario 5
                    </ListItem>
                    <ListItem 
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    onClick={() => openModal({vid: vidsix, thumb: Card6, title: 'Hana supply scenario 6'})}
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                      Hana supply scenario 6
                    </ListItem>
                    <ListItem 
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    onClick={() => openModal({vid: vidseven, thumb: Card7, title: 'Hana supply scenario 7'})}
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                      Hana supply scenario 7
                    </ListItem>
                    <ListItem 
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    onClick={() => openModal({vid: videight, thumb: Card8, title: 'Hana supply scenario 8'})}
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                      Hana supply scenario 8
                    </ListItem>
                    </Box>
                    <ListItem 
                    as="a"
                    href={leaflet} 
                    rel="noopener noreferrer"
                    target="_blank"
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px">
                        Contraceptive options leaflet
                    </ListItem>
                    </Fragment>
                    }
            </List>

            <Modal isOpen={isOpen} onClose={onClose} size="lg-custom" title={activeVid.title}>
                <Video
                vidSrc={activeVid.vid}
                imgThumb={activeVid.thumb}
                />
            </Modal>
        </Box>
    )
}

export default Resources