import React, { useState } from 'react'

// Load styles
import styles from './styles.module.scss'

const Video =({ vidSrc, imgThumb}) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const onLoadedData = () => {
      setIsVideoLoaded(true);
    };
    
    return (
      <div className={styles.container}>
        <img
          src={imgThumb}
          className={[styles.videoThumb, styles.tiny].join(' ')}
          alt="thumb"
          style={{ opacity: isVideoLoaded ? 0 : 1, pointerEvents: "none" }}
        />
        <video
          autoPlay
          playsInline
          controls
          src={vidSrc}
          onLoadedData={onLoadedData}
          style={{ opacity: isVideoLoaded ? 1 : 0 }}
        />
      </div>
    );
  };

  export default Video