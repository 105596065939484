import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

export const CaseSevenNoYes = () => <Text {...normalText}>
This woman is reporting symptoms that could indicate a venous thrombosis. She should be advised to <Text as="span" {...boldGreen}>seek immediate medical help.</Text>
</Text>

export const CaseSevenNoNo = () => <Text {...normalText}>
This woman is reporting symptoms that could indicate a venous thrombosis. She should be advised to <Text as="span" {...boldRed}>seek immediate medical help.</Text>
</Text>

export const CaseSevenYesNo = () => <Text {...normalText}>
This woman is reporting symptoms that could indicate a venous thrombosis. She should be advised to <Text as="span" {...boldRed}>seek immediate medical help.</Text>
</Text>

export const CaseSevenYesYes = () => <Text {...normalText}>
This woman is reporting symptoms that could indicate a venous thrombosis. She should be advised to <Text as="span" {...boldRed}>seek immediate medical help.</Text>
</Text>