import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

/**
 * Case Two answers
 */

 export const CaseTwoNoYes = () => <Text {...normalText}>
 You should advise this woman that, because of her diabetes, she should <Text as="span" {...boldGreen}>see her doctor</Text> first for advice about taking Hana. <Text as="span" {...boldGreen}>If the doctor says it is fine, advise her to come back,</Text> when the pharmacist will be happy to supply her with Hana.
 </Text>

 export const CaseTwoNoNo = () => <Text {...normalText}>
 You should advise this woman that, because of her diabetes, she should <Text as="span" {...boldRed}>see her doctor</Text> first for advice about taking Hana. <Text as="span" {...boldRed}>If the doctor says it is fine, advise her to come back,</Text> when the pharmacist will be happy to supply her with Hana.
 </Text>

 export const CaseTwoYesNo = () => <Text {...normalText}>
 You should advise this woman that, because of her diabetes, she should <Text as="span" {...boldRed}>see her doctor</Text> first for advice about taking Hana. <Text as="span" {...boldRed}>If the doctor says it is fine, advise her to come back,</Text> when the pharmacist will be happy to supply her with Hana.
 </Text>

 export const CaseTwoYesYes = () => <Text {...normalText}>
 You should advise this woman that, because of her diabetes, she should <Text as="span" {...boldRed}>see her doctor</Text> first for advice about taking Hana. <Text as="span" {...boldRed}>If the doctor says it is fine, advise her to come back,</Text> when the pharmacist will be happy to supply her with Hana.
 </Text>