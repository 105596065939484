import React, { useState, useEffect } from 'react'
import { Link as ReachLink, useHistory } from 'react-router-dom'
import { Box, Text, Heading, Stack, AspectRatioBox, Link, Accordion, AccordionHeader, AccordionItem, AccordionIcon, AccordionPanel } from '@chakra-ui/core'

// Load assets
import headerBk from '../../images/landingHeader.png'
import {ReactComponent as HanaNavy} from '../../images/hana-logo.svg'
import {ReactComponent as DownArrow} from '../../images/Scroll.svg'
import NumberCircle  from '../../images/Q-A_Numbers.svg'
import {ReactComponent as HanaAbout}from '../../images/About_Hana_Slash.svg'

// Load content
import { qacontentStarting, qacontentSupplying, qacontentConsumers, qacontentInteractions, qacontentGeneral } from '../../content/Webinar/qacontent'
import WebinarQOne from '../../content/Webinar/question1'

// Load styles
import { normalText } from '../../style/styleFormat'
import styles from './styles.module.scss'

// Load components
import Footer from '../Footer/Footer'

const headingText = {
    color: "purple.100",
    fontSize: {base: "lg", lg: "30px"},
    lineHeight: "tighter"
}

const CustomPanel = ({ content }) => {
    const [open, setOpen] = useState(false) 
    return (
    <AccordionItem isOpen={open} onChange={setOpen} borderRadius={{base: "10px", md: "30px"}} backgroundColor={content.background} position="relative" border="0" _notLast={{ mb: 5}}>
        {({ isExpanded }) => {
            return (
                <>
                    <AccordionHeader _hover _focus position="relative" alignItems="center" justifyContent="center">
                        <Box position="absolute" left={{base: "-25px", md:"0"}} top={{base: "5px", md:"50%"}} transform="translateY(-50%)" background={`url(${NumberCircle})`} backgroundRepeat="no-repeat" backgroundSize="cover" height={{base: "40px", md:"100%"}} width={{base: "40px", md:"54px"}} display="flex" justifyContent="center" alignItems="center">
                            <Text fontWeight="bold">
                                {content.question}
                            </Text>
                        </Box>
                        <Text {...normalText} py={2} fontWeight="bold" fontSize={{base: "xs", lg: "sm"}} color={content.color} textTransform="uppercase">
                            {content.title}
                        </Text>
                        {!isExpanded &&<AccordionIcon color={content.iconColor} position="absolute" bottom="0" left="50%" transform="translateX(-50%)" />}
                    </AccordionHeader>
                    <AccordionPanel px={{base: 2, md: 8, lg: 16}} color={content.color} fontSize="sm" overflow="auto">
                        {content.panel.text === 'component' ? <WebinarQOne /> : <Box dangerouslySetInnerHTML={{__html: content.panel.text}} />}
                        {isExpanded && <AccordionIcon  color={content.iconColor} fontSize="lg" cursor="pointer" onClick={() => setOpen(false)} position="absolute" bottom="0" left="50%" transform="translateX(-50%) rotate(180deg)" />}
                    </AccordionPanel>
                </>
            )
        }}
            
    </AccordionItem>
    )
}

const WebinarQA = () => {
    const history = useHistory()

    useEffect(() => {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
    }, [])

    return (
        <Box as="article" id="webinarQA" backgroundColor="#f4f1e8">
           
        {/** HEADER */}
        <AspectRatioBox as="header" pointerEvents="none" backgroundColor="#f4f1e8"  maxWidth="100vw" minHeight={{base: "340px", md:"480px"}} maxHeight={{base: "773px", lg: "773px"}} ratio={2}>
            <Box>
                <Box padding={{base: 6, lg: 16}} backgroundImage={`url(${headerBk})`}  boxShadow="0 0 50px 50px #f4f1e8 inset" backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center" height="100%" width="100%" maxWidth="1600px" margin="0 auto">
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <Box width={{base:"25%", lg: "15%"}} pointerEvents="fill" maxWidth={{base: "100px",  lg:"150px"}} onClick={() => history.push('/')} cursor="pointer">
                            <HanaNavy />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                        <Heading {...headingText} color="rgb(46, 28, 69)" mb={6} textTransform="uppercase">
                            Pharmacists FAQs
                        </Heading>
                    
                        <Heading textAlign="center" width="100%" fontSize={{base: "50px", lg:"128px"}}  fontWeight="300" lineHeight={{base: "55px", lg:"80px"}} fontFamily="kokomo_breezeregular" color="rgb(46, 28, 69)">
                           <HanaAbout />
                        </Heading>
                        
                
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" position="absolute" bottom="0px" left="50%" transform="translateX(-50%)">
                            <Text {...normalText} fontWeight="bold">SCROLL</Text>
                            <Box width="10px" mt={2}>
                                <DownArrow />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </AspectRatioBox>
        <Box background="linear-gradient(0deg, #7177B7 0%, #f4f1e8 100%)" height="50px" width="100%"></Box>

        <Box as="section" padding={8} width="100%" backgroundColor="purple.400">
            <Box width={{base: "100%", lg: "80%"}} maxWidth="900px" margin="0 auto">
          
                <Stack spacing={8}>
                        <Text {...normalText} color="white" textAlign="center" fontWeight="bold">
                            The following Q&As were captured during a live webinar which HRA ran for pharmacists. If you have any futher questions about hana, please refer to the pharmacy guide. If you would like more information about the webinar, you can find the slides and case studies <Link as={ReachLink} to="/webinar/presentation" textDecoration="underline" cursor="pointer">here</Link>.
                        </Text>

                    <Heading {...headingText} color="white" textTransform="uppercase">
                        Questions on starting hana
                    </Heading>
                

                    <Accordion allowMultiple className={styles.container}>
                        {qacontentStarting.map((content) => {
                            return <CustomPanel content={content} />
                        })}

                        <Heading {...headingText} color="white" textTransform="uppercase" mt={10} mb={6}>
                            Questions on supplying hana
                        </Heading>
                        {qacontentSupplying.map((content) => {
                            return <CustomPanel content={content} />
                        })}

                        <Heading {...headingText} color="white" textTransform="uppercase" mt={10} mb={6}>
                            Questions on advice for consumers on Hana
                        </Heading>
                        {qacontentConsumers.map((content) => {
                            return <CustomPanel content={content} />
                        })}

                        <Heading {...headingText} color="white" textTransform="uppercase" mt={10} mb={6}>
                            Questions about interactions with Hana
                        </Heading>
                        {qacontentInteractions.map((content) => {
                            return <CustomPanel content={content} />
                        })}

                        <Heading {...headingText} color="white" textTransform="uppercase" mt={10} mb={6}>
                            General/Other questions about Hana
                        </Heading>
                        {qacontentGeneral.map((content) => {
                            return <CustomPanel content={content} />
                        })}
                    </Accordion>

                    <Box>
                        <Text {...normalText} color="white" mb={8}>
                            If you have any questions or are looking for additional guidance, all Pharmacy Training materials and videos are available on the Hana website: <br/>
                            <Link textDecoration="underline" isExternal href='https://hanapharmacist.co.uk/training'>https://hanapharmacist.co.uk/training</Link>
                        </Text>

                        <Text {...normalText} fontWeight="bold" textTransform="uppercase" color="white">
                            Reference
                        </Text>
                        <br />
                        <Text {...normalText} color="white">
                            Hana (desogestrel) Summary of Product Characteristics, July 2021
                        </Text>
                    </Box>
                </Stack>
            </Box>
        </Box>

        {/** Footer */}
        <Box as="footer" backgroundColor="#CCCEE6">
            <Footer productCode="0282" />
        </Box>
    </Box>
    )
}

export default WebinarQA