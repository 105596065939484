import React from 'react'
import { Box, Text, ListItem, List, Image } from '@chakra-ui/core'

// Load assets
import Checklist from '../../images/checklistHigh.png'
import {ReactComponent as Info} from '../../images/infoAlt.svg'

// Load style
import { normalText, referenceText, purpleBullet, subBullet, bkLightRed } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'


const PharmacyProcess = () => {
   
    return (
        <Layout>
            <Text {...normalText} marginBottom="20px" display="block">
                You and the rest of the pharmacy team can support your pharmacist(s) by <Text as="span" color="purple.200" fontFamily="Gotham Bold">beginning the process of supplying Hana</Text> to women:
            </Text>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems={{base: "center", lg:"flex-start"}} justifyContent={{base: "center", lg:"space-around"}} marginBottom="20px">
                <Box display="flex" justifyContent="center" width={{base: "100%", lg:"45%"}}>
                    <Image src={Checklist} height="100%" width="100%" maxWidth="400px" alt="Checklist Hana" />
                </Box>

                <Box width={{base: "100%", lg:"45%"}} marginTop={{base: "20px", lg: "0"}}>
                    <List spacing="4">
                        <ListItem 
                        {...referenceText}
                        paddingLeft="25px"
                        position="relative"
                        display="block"
                        _before={{
                        ...purpleBullet,
                        top: "-40px"
                        }}
                        >
                            make sure you’re aware of the <Text as="span" color="purple.200" fontFamily="Gotham Bold">Hana checklist</Text>, available to <Text as="span" color="purple.200" fontFamily="Gotham Bold">help determine if a woman is suitable for Hana</Text> and if additional questions need to be asked, and know where they’re stored in your pharmacy
                        </ListItem>

                        <ListItem 
                        {...referenceText}
                        paddingLeft="25px"
                        position="relative"
                        display="block"
                        _before={{
                        ...purpleBullet,
                        top: "-40px"
                        }}
                        >
                        give the woman a <Text as="span" color="purple.200" fontFamily="Gotham Bold">Hana checklist</Text> to complete, advising her to <Text as="span" color="purple.200" fontFamily="Gotham Bold">scratch off the answer</Text> to each of the questions and <Text as="span" color="purple.200" fontFamily="Gotham Bold">return the card to you</Text> when she’s finished
                        </ListItem>

                        <ListItem 
                        {...referenceText}
                        paddingLeft="60px"
                        position="relative"
                        display="block"
                        _before={{
                        ...subBullet,
                        borderColor: "#795EC3",
                        top: "5px"
                        }}
                        >
                            pass the <Text as="span" color="purple.200" fontFamily="Gotham Bold">completed checklist to your pharmacist</Text>, and advise the woman that the pharmacist will use the revealed responses to guide their consultation
                        </ListItem>
                    </List>
                </Box>
            </Box>
       
           
        
            <Box {...bkLightRed} padding="8" position="relative" marginTop="10px">
                <Box position="absolute" top="-10px" left="-12px">
                    <Info name="info" borderRadius="50%" border="2px solid #fff" backgroundColor="#fff" color="#F0CED5" size="40px" />
                </Box>
                <Text {...normalText}>
                    The Hana checklist is optional, and the supply of Hana is the responsibility of your pharmacist(s), so check with them how your pharmacy will be using the checklist
                </Text>
            </Box>
        </Layout>
    )
}

export default PharmacyProcess