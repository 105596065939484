import React from 'react'


const floatSpan = {
    position: 'absolute',
    top: '-8px',
    right: '-5px',
    fontSize: "10px"
}

const Reference = ({ children, props}) => {
    return <span style={floatSpan} {...props}>{children}</span>
}

export default Reference