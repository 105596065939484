import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom'
import { Text, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/core'
import Cookies from 'js-cookie'

// Load content
import { titles } from '../../content/pageTitles'

// Load assets
import PartOneBk from '../../images/bg1.png'
import PartTwoBk from '../../images/bg2.png'
import PartThreeBk from '../../images/bg3.png'
import {ReactComponent as Logo } from '../../images/Hana_Logo_White.svg'

// Load components
import SideNav from '../SideNav/SideNav'
import Navigation from '../Navigation/Navigation'
import Passed from '../Passed/Passed'
import Failed from '../Failed/Failed'

// Containers
import Learning from '../../containers/Learning/Learning'
import Assessment from '../../containers/Assessment/Assessment'
import CaseStudies from '../../containers/CaseStudies/CaseStudie'


// Load layout
import Layout from '../../layout/Layout.base'

function ELearning() {
  const MAX_PAGE = 99
  const [page, setPage] = useState(0)
  const [correct, setCorrect] = useState(0)
  const [allowNext, setAllow] = useState(false)
  const passed = correct >= 20
  const fail = correct < 20

  const history = useHistory()

  // Mobile menu
  const { isOpen, onClose, onOpen } = useDisclosure()

  // Control sideDrawer display
  const [open, setOpen] = useState(false)

  // Question Alert
  const [showAlert, setAlert] = useState(false)
  const openAlert = () => setAlert(true)
  const closeAlert = () => setAlert(false)

  function reload(page) {
    setPage(page)
  }
 
  // Make sure on each page change we are scrolled to top
  function scrollInView() {
    // Element to target
    const element = document.getElementById('next-top')

    // Scroll into viewport
    if (element) {
     return element.scrollIntoView()
    }
      return
  }

  // SideDrawer open index
  function sidedrawer(index) {
     
      if (open === index) {
         return setOpen(false)
      }

     return setOpen(index)
  }

  useEffect(() => {
    scrollInView()
  }, [page])

  useEffect(() => {
    const progress = Cookies.getJSON('hana-progress')
    if (progress) {
       setPage(parseInt(progress.page))
       setCorrect(parseInt(progress.correct))
       history.push(progress.url)
    }
       
  }, [history])
  
    // Change label before revealing anwsers
    const label = [13, 15, 17, 19, 52, 54, 56,64,66,68,70,72,74,76,78,80,82,84,86,88,90,92,94,96,98,100,102].includes(page) ? 'Show Answer' : 'Next'

    // Page control
    function changePage(direction) {

        if (!allowNext && ([13,15,17,19,52,54,56,].includes(page) || page > 63) && direction === 'increment') {
           return openAlert()
        }
      
        // Dont no progress futher than the pages we have
        if (direction === 'increment' && page === MAX_PAGE) {
          return
        }

        // Stop previous when at start
        if (direction === 'decrement' && page === 0) {
            return
        }

        // Some route changes at increment
        if (direction === 'increment' && page === 62) {
            setPage(prevPage => prevPage +1)
            history.push('/training/assessment')
            return
        }

        if (direction === 'increment' && page === 93) {
          setPage(prevPage => prevPage +1)
          history.push('/training/case-studies')
          return
        }

        if (direction === 'increment' && page === 98) {
          setPage(prevPage => prevPage +1)
          history.push('/training/complete')
          return
        }

        // Some route changes at decrement
        if (direction === 'decrement' && page === 63) {
            history.push('/training')
            setPage((prevPage => prevPage - 1))
            return
        }

        // Handle next action
        if (direction === 'increment') {
            setPage(prevPage => prevPage + 1)
        }

        // Handle previous action
        if (direction === 'decrement') {
            setPage((prevPage => prevPage - 1))
        }

      return
  }

  function saveProgress() {
    Cookies.set('hana-progress', {page, correct, url: history.location.pathname }, { expires: 30})
  }

  // Restart assessment
  function restart() {

    // reset score
    setCorrect(0)

    // back to assessment
    setPage(63)

    // set route
    history.push('/training/assessment')

    // clear cookie progress and reset
    Cookies.remove('hana-progress');
  }

  let background = PartOneBk

  // Change background as progress
  if (page > 22) {
      background = PartTwoBk
  }

  if (page > 59) {
      background = PartThreeBk
  }

  if (page > 62) {
      background = PartOneBk
  }

  return (
      <Layout sideNav={<SideNav progress={page} maxPage={MAX_PAGE} setPage={setPage} open={open} sidedrawer={sidedrawer} setOpen={setOpen} />}>
      <Navigation maxPage={MAX_PAGE} productCode="0003" stopPrevious={64} openDrawer={onOpen} title={titles[page]} passed={passed} background={background} save={saveProgress} pageNumber={page} nextLabel={label} navControl={changePage}>
            
          <Switch>
            <Route exact path="/training" render={() => <Learning setPage={setPage} setOpen={setOpen} openMobileDrawer={onOpen} currentPage={page} navControl={changePage} allowNext={setAllow} />} />
            <Route exact path="/training/assessment" render={() => <Assessment setPage={setPage} reload={reload} currentPage={page} changePage={changePage} allowNext={setAllow} correct={correct} setAnswer={setCorrect} />} />
            <Route exact path="/training/case-studies" render={() => <CaseStudies setPage={setPage} reload={reload} currentPage={page} changePage={changePage} allowNext={setAllow} correct={correct} setAnswer={setCorrect} />} />
            <Route exact path="/training/complete" render={() => {
                setPage(99)
                if (passed) {
                  return <Passed />
                }
      
                if (fail) {
                  return <Failed correct={correct} restart={restart} />
                }
              }} />
              <Redirect to="/training" />
            </Switch>
            <Drawer onClose={onClose} isOpen={isOpen} size="full" placement="left">
              <DrawerOverlay />
              <DrawerContent>
                <DrawerHeader backgroundColor="#5D599E">
                  <Logo width="40%" />
                  <DrawerCloseButton color="#fff" />
                </DrawerHeader>
                <DrawerBody overflowY="auto" padding="0" backgroundColor="#5D599E">
                  <SideNav progress={page} setPage={setPage} open={open} sidedrawer={sidedrawer} setOpen={setOpen} />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
        </Navigation>
        <Modal isOpen={showAlert} onClose={closeAlert} closeOnEsc isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Text padding="4">
                Please select at least one answer.
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Layout>    
  );
}

export default ELearning;
