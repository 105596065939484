import React from 'react'
import { Box, Text, List, ListItem, useDisclosure, PseudoBox } from '@chakra-ui/core'


// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, blueBullet, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const FirstTimeSupplyFour = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Box borderTop="1px solid #4580AB" flexDirection={{base: "column", lg: "row"}} display="flex">
                <Box width="100%" position="relative">
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Checklist question
                        </Text>
                    </Box>
                    <PseudoBox 
                    position="relative" 
                    margin="30px auto"
                    width="80%"
                    height="25%"
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                    justifyContent="center"
                    backgroundColor="#E3ECF3"
                    borderRadius="23px"
                    padding="6"
                    textAlign="center"
                    _before={{
                        content: "''",
                        width: "0px",
                        height: '0px',
                        position: 'absolute',
                        top: '20px',
                        left: '-28px',
                        borderLeft: "25px solid transparent",
                        borderRight: "25px solid #E3ECF3",
                        borderTop: "25px solid #E3ECF3",
                        borderBottom: "25px solid transparent"
                    }}
                    >
                    <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" fontSize="lg" textAlign="left"> 
                        Do you currently have or have you previously had any health conditions?
                    </Text>
                    <Text {...secondaryText} textAlign="left" marginTop="10px">
                        (e.g. cancer, liver disorders or jaundice, a blood clot, diabetes, high blood pressure)
                    </Text>
                </PseudoBox>
            </Box>
            <Box width="100%" borderLeft={{base: "none", lg:"1px solid #739FBF"}} borderTop={{base: "1px solid #739FBF", lg: "none"}}>
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Guidance on pharmacist action
                        </Text>
                    </Box>
                    <Box padding="4">
                        <Text {...secondaryText} margin="10px 0">
                            <Text as="span" fontFamily="Gotham Bold">Hana is not suitable for women who <span style={{position: 'relative'}}>have:<Reference>1</Reference></span></Text>
                        </Text>
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="20px"
                            position="relative"
                            _before={{
                                ...blueBullet
                                }}>
                                breast cancer or other known or suspected sex-steroid sensitive cancers, such as ovarian or uterine cancer
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="20px"
                            position="relative"
                            _before={{
                                ...blueBullet
                                }}>
                                severe liver disorders that have not resolved (blood tests show the liver function values have not returned to normal)
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="20px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                active venous thrombosis or pulmonary embolism
                            </ListItem>
                        </List>
                        <Text {...secondaryText} margin="20px 0" fontFamily="Gotham Bold" fontWeight="bold">
                            Women should see their doctor to assess suitability for Hana before being provided with it if they <span style={{position: 'relative'}}>have: &nbsp;<Reference>1,2</Reference></span>
                        </Text>
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="20px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                history of breast, ovarian or uterine cancer
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="20px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                current liver cancer or other liver disorders
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="20px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                Type 1 or Type 2 diabetes
                            </ListItem>
                        </List>
                        <Text {...secondaryText} margin="20px 0">
                            If a woman is referred to her doctor to check any contraindications or warnings to determine suitability for Hana, the woman should be advised that if her suitability is confirmed, she can return to the pharmacy to obtain Hana.
                        </Text>
                        <Text {...secondaryText} fontFamily="Gotham Bold" marginBottom="20px" fontWeight="bold">
                            Women with a history of chloasma or thromboembolic disorders, or high blood pressure, should be counselled before or during use of <span style={{position:'relative'}}>Hana: &nbsp;<Reference>1,2</Reference></span>
                        </Text>
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="20px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                chloasma may occasionally occur, especially in women who have had it before; these women should avoid exposure to the sun or UV radiation
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="20px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                there is the possibility of a recurrence of venous thrombosis or 	pulmonary embolism in women with a history; these women
                                should be advised to see a doctor urgently if they experience 	symptoms of thromboembolic disorders e.g. warm or
                                swollen, painful leg; shortness of breath; or coughing up blood
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="20px"
                            position="relative"
                            _before={{
                                ...blueBullet
                                }}>
                                women who develop sustained hypertension during use of Hana, 	or a significant increase in blood pressure that does not 	adequately respond to antihypertensive therapy, should see their 	doctor to decide whether Hana should be discontinued (pharmacists are not expected to check/monitor blood pressure prior to or during supply)
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Box>
            <Text {...referenceLink} color="blue.50" as="u" cursor="pointer" onClick={onOpen} marginTop="20px" fontWeight="bold">
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Patient information Leaflet, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default FirstTimeSupplyFour