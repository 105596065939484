import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Load components
import { Box, Heading, Stack, Input, Button, Text } from '@chakra-ui/core'
import Footer from '../Footer/Footer'
import {ReactComponent as Logo } from '../../images/Hana_Logo_White.svg'

// Load pdf
import certImg from '../../pdf/HRA0233_Hana_webinar_certificate.png'

// Load layout
import Layout from '../../layout/Layout.card'
import LayoutBase from '../../layout/Layout.base'

// Load style
import { normalText } from '../../style/styleFormat'
import jsPDF from 'jspdf'

const SideNav = () => {
    const history = useHistory()
   return ( 
    <Stack spacing={7} align="center" paddingBottom={{base: "30px", lg: "0"}}>
        <Box display={{base: "none", lg: "block"}} mt="25px" width="35%">
            <Logo onClick={() => history.push('/')} height="100%" width="100%" />
        </Box>
    </Stack>
    )
}

const Webinar = () => {
    const [name, setName] = useState('')
    const date = new Date()
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const formatDate = `${day}-${month}-${year}`

    function downloadPdf(textInput, date) {
        const canvas = document.getElementById('img')
        let pdf = new jsPDF({orientation: 'landscape', unit: 'px', format: [500, 350]})
    
        pdf.addImage(canvas, 'PNG', 0,0, 500, 350)
        pdf.text(textInput, 250, 190, { align: "center"})
        pdf.text(date, 170, 300)
        pdf.save("certificate.pdf")
    }

    return (
        <LayoutBase sideNav={<SideNav />}>
            <Box padding="8" display="flex" height="100%" flexDirection="column" justifyContent="space-between" alignItems="space-between">
                <Box>
                    <Heading mb={6} color="#fff" textTransform="capitalize" fontSize="50px">Thank you</Heading>
                    <Layout>
                        <Stack spacing="4">
                            <Heading fontSize="20px" color="#2E1C45">You participated in the Hana Webinar</Heading>
                            <Text {...normalText}>
                                Please enter your full name in the box below, then click the button to download your certificate of attendance
                            </Text>
                            <Input placeholder="Enter your name here..." value={name} size="lg" width="100%" maxW="400px" backgroundColor="#F7F6FF" height="61px" focusBorderColor="#9894C6" borderColor="#9894C6" onChange={(event) => setName(event.target.value)} />
                            <Button onClick={() => downloadPdf(name, formatDate)} display="flex" alignItems="center" justifyContent="flex-start" textAlign="left" lineHeight="wide" backgroundColor="#DF6532" width="100%" maxW="400px" color="#fff" height="78px" borderRadius="4px" padding="4" textDecoration="none" fontSize="14px" fontWeight="bold" whiteSpace="initial">
                                Click here to download your certificate of attendance
                            </Button>
                        </Stack>
                        <Box height="10px" overflow="hidden">
                            <img id="img" src={certImg} alt="certificate" style={{opacity: 0}} />
                        </Box>
                    </Layout>
                </Box>
                 <Footer  productCode="0153" />
            </Box>    
        </LayoutBase>
    )
}

export default Webinar