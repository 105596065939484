import React, { Component, Fragment } from 'react'

// Load questions
import {
    caseOne,
    caseTwo,
    caseThree,
    caseFour,
    caseFive,
    caseSix,
    caseSeven,
    caseEight,
    caseNine,
    caseTen,
    caseEleven,
    caseTwelve,
    caseThirteen
 } from '../../content/CaseStudies/caseStudies'

// Load component
import Case from '../../components/Case/Case.js'
import CaseStudyIntro from '../../components/Case/CaseStudyIntro'
import CaseReveal from '../../components/Case/CaseReveal'

// Load assets
import WomanOne from '../../images/caseStudies/Woman-1.svg'
import WomanTwo from '../../images/caseStudies/Women-2.svg'
import WomanThree from '../../images/caseStudies/Women-3.svg'
import WomanFour from '../../images/caseStudies/Women-4.svg'
import WomanFive from '../../images/caseStudies/Women-5.svg'
import WomanSix from '../../images/caseStudies/Women-6.svg'
import WomanSeven from '../../images/caseStudies/Women-7.svg'
import WomanEight from '../../images/caseStudies/Women-8.svg'
import WomanNine from '../../images/caseStudies/Women-9.svg'
import WomanTen from '../../images/caseStudies/Women-10.svg'
import WomanEleven from '../../images/caseStudies/Women-11.svg'
import WomanTwelve from '../../images/caseStudies/Women-12.svg'
import WomanThirteen from '../../images/caseStudies/Women-13.svg'

// Load answers
import { CaseOneYesNo, CaseOneYesYes, CaseOneNoNo, CaseOneNoYes } from '../../content/CaseStudies/CaseAnswers/CaseOne'
import { CaseTwoNoNo, CaseTwoNoYes, CaseTwoYesNo, CaseTwoYesYes } from '../../content/CaseStudies/CaseAnswers/CaseTwo'
import { CaseThreeNoNo, CaseThreeNoYes, CaseThreeYesNo, CaseThreeYesYes } from '../../content/CaseStudies/CaseAnswers/CaseThree'
import { CaseFourNoNo, CaseFourNoYes, CaseFourYesNo, CaseFourYesYes } from '../../content/CaseStudies/CaseAnswers/CaseFour'
import { CaseFiveNoNo, CaseFiveNoYes, CaseFiveYesNo, CaseFiveYesYes } from '../../content/CaseStudies/CaseAnswers/CaseFive'
import { CaseSixNoNo, CaseSixNoYes, CaseSixYesNo, CaseSixYesYes } from '../../content/CaseStudies/CaseAnswers/CaseSix'
import { CaseSevenNoNo, CaseSevenNoYes, CaseSevenYesNo, CaseSevenYesYes } from '../../content/CaseStudies/CaseAnswers/CaseSeven'
import { CaseEightNoNo, CaseEightNoYes, CaseEightYesNo, CaseEightYesYes } from '../../content/CaseStudies/CaseAnswers/CaseEight'
import { CaseNineNoNo, CaseNineNoYes, CaseNineYesNo, CaseNineYesYes } from '../../content/CaseStudies/CaseAnswers/CaseNine'
import { CaseTenNoNo, CaseTenNoYes, CaseTenYesNo, CaseTenYesYes } from '../../content/CaseStudies/CaseAnswers/CaseTen'
import { CaseElevenNoNo, CaseElevenNoYes, CaseElevenYesNo, CaseElevenYesYes } from '../../content/CaseStudies/CaseAnswers/CaseEleven'
import { CaseTwelveNoNo, CaseTwelveNoYes, CaseTwelveYesNo, CaseTwelveYesYes } from '../../content/CaseStudies/CaseAnswers/CaseTwelve'
import { CaseThirteenNoNo, CaseThirteenNoYes, CaseThirteenYesNo, CaseThirteenYesYes } from '../../content/CaseStudies/CaseAnswers/CaseThirteen'

class CaseStudies extends Component {

    state = {
        questionNumbers: [],
    }

     componentDidMount() {
        const { reload } = this.props

        this.buildRandomQuestion()

        // On direct url hit set to assessment page
        reload(94)
    }


    buildRandomQuestion = () => {
       
        const numbersPicked = []
    
        while (numbersPicked.length < 13) {

            // Make sure random number generated is not repeating
            while (true) {

                // Set the min + max range
                let rnd = Math.floor(Math.random() * (106 - 94 + 1) ) + 94;
                
                //if random number not picked yet exit loop
                if (!~numbersPicked.indexOf(rnd)) {
                    numbersPicked.push(rnd)
                    break
                }
            }
        }

        this.setState({questionNumbers: numbersPicked })
    }


    calculateAnswer = (answer) => {
        const  { setAnswer, correct } = this.props
       
        // Add to tally or return current score
        const tally = answer ? correct + 1 : correct

        setAnswer(tally)
    }


    render() {

        const { currentPage, allowNext, changePage }  = this.props
        const { questionNumbers } = this.state
        const { calculateAnswer } = this
        return (
            <Fragment>

             {currentPage === questionNumbers[0] &&
                <Case
                changePage={changePage}
                womanSvg={WomanOne}
                config={caseOne.page.cases}
                totalSlides={caseOne.page.cases.length + 2}
                questions={caseOne.form}
                questionOne={caseOne.page.questionOne}
                questionTwo={caseOne.page.questionTwo}
                setAnswer={calculateAnswer}
                setNext={allowNext}
                CaseYesYes={<CaseReveal text={<CaseOneYesYes />} status="wrong" />}
                CaseYesNo={<CaseReveal text={<CaseOneYesNo />} status="correct" />}
                CaseNoYes={<CaseReveal text={<CaseOneNoYes />} status="wrong" />}
                CaseNoNo={<CaseReveal text={<CaseOneNoNo />} status="wrong" />}
                component={<CaseStudyIntro paraOne={caseOne.page.paraOne} paraTwo={caseOne.page.paraTwo}  />}
                />}

                {currentPage === questionNumbers[1] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanTwo}
                    config={caseTwo.page.cases}
                    totalSlides={caseTwo.page.cases.length + 2}
                    questions={caseTwo.form}
                    questionOne={caseTwo.page.questionOne}
                    questionTwo={caseTwo.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseTwoYesYes />} status="wrong" />}
                    CaseYesNo={<CaseReveal text={<CaseTwoYesNo />} status="wrong" />}
                    CaseNoYes={<CaseReveal text={<CaseTwoNoYes />} status="correct" />}
                    CaseNoNo={<CaseReveal text={<CaseTwoNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseTwo.page.paraOne} paraTwo={caseTwo.page.paraTwo}  />}
                    />}

                {currentPage === questionNumbers[2] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanThree}
                    config={caseThree.page.cases}
                    totalSlides={caseThree.page.cases.length + 2}
                    questions={caseThree.form}
                    questionOne={caseThree.page.questionOne}
                    questionTwo={caseThree.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseThreeYesYes />} status="wrong" />}
                    CaseYesNo={<CaseReveal text={<CaseThreeYesNo />} status="correct" />}
                    CaseNoYes={<CaseReveal text={<CaseThreeNoYes />} status="wrong" />}
                    CaseNoNo={<CaseReveal text={<CaseThreeNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseThree.page.paraOne} paraTwo={caseThree.page.paraTwo}  />}
                    />}

                {currentPage === questionNumbers[3] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanFour}
                    config={caseFour.page.cases}
                    totalSlides={caseFour.page.cases.length + 2}
                    questions={caseFour.form}
                    questionOne={caseFour.page.questionOne}
                    questionTwo={caseFour.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseFourYesYes />} status="wrong" />}
                    CaseYesNo={<CaseReveal text={<CaseFourYesNo />} status="wrong" />}
                    CaseNoYes={<CaseReveal text={<CaseFourNoYes />} status="correct" />}
                    CaseNoNo={<CaseReveal text={<CaseFourNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseFour.page.paraOne} paraTwo={caseFour.page.paraTwo}  />}
                    />}

                {currentPage === questionNumbers[4] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanFive}
                    config={caseFive.page.cases}
                    totalSlides={caseFive.page.cases.length + 2}
                    questions={caseFive.form}
                    questionOne={caseFive.page.questionOne}
                    questionTwo={caseFive.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseFiveYesYes />} status="wrong" />}
                    CaseYesNo={<CaseReveal text={<CaseFiveYesNo />} status="correct" />}
                    CaseNoYes={<CaseReveal text={<CaseFiveNoYes />} status="wrong" />}
                    CaseNoNo={<CaseReveal text={<CaseFiveNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseFive.page.paraOne} paraTwo={caseFive.page.paraTwo}  />}
                    />}

                {currentPage === questionNumbers[5] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanSix}
                    config={caseSix.page.cases}
                    totalSlides={caseSix.page.cases.length + 2}
                    questions={caseSix.form}
                    questionOne={caseSix.page.questionOne}
                    questionTwo={caseSix.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseSixYesYes />} status="correct" />}
                    CaseYesNo={<CaseReveal text={<CaseSixYesNo />} status="wrong" />}
                    CaseNoYes={<CaseReveal text={<CaseSixNoYes />} status="wrong" />}
                    CaseNoNo={<CaseReveal text={<CaseSixNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseSix.page.paraOne} paraTwo={caseSix.page.paraTwo}  />}
                    />}

                {currentPage === questionNumbers[6] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanSeven}
                    config={caseSeven.page.cases}
                    totalSlides={caseSeven.page.cases.length + 2}
                    questions={caseSeven.form}
                    questionOne={caseSeven.page.questionOne}
                    questionTwo={caseSeven.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseSevenYesYes />} status="wrong" />}
                    CaseYesNo={<CaseReveal text={<CaseSevenYesNo />} status="wrong" />}
                    CaseNoYes={<CaseReveal text={<CaseSevenNoYes />} status="correct" />}
                    CaseNoNo={<CaseReveal text={<CaseSevenNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseSeven.page.paraOne} paraTwo={caseSeven.page.paraTwo}  />}
                    />}

                {currentPage === questionNumbers[7] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanEight}
                    config={caseEight.page.cases}
                    totalSlides={caseEight.page.cases.length + 2}
                    questions={caseEight.form}
                    questionOne={caseEight.page.questionOne}
                    questionTwo={caseEight.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseEightYesYes />} status="correct" />}
                    CaseYesNo={<CaseReveal text={<CaseEightYesNo />} status="wrong" />}
                    CaseNoYes={<CaseReveal text={<CaseEightNoYes />} status="wrong" />}
                    CaseNoNo={<CaseReveal text={<CaseEightNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseEight.page.paraOne} paraTwo={caseEight.page.paraTwo}  />}
                    />}

                {currentPage === questionNumbers[8] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanNine}
                    config={caseNine.page.cases}
                    totalSlides={caseNine.page.cases.length + 2}
                    questions={caseNine.form}
                    questionOne={caseNine.page.questionOne}
                    questionTwo={caseNine.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseNineYesYes />} status="wrong" />}
                    CaseYesNo={<CaseReveal text={<CaseNineYesNo />} status="wrong" />}
                    CaseNoYes={<CaseReveal text={<CaseNineNoYes />} status="correct" />}
                    CaseNoNo={<CaseReveal text={<CaseNineNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseNine.page.paraOne} paraTwo={caseNine.page.paraTwo}  />}
                    />}

                {currentPage === questionNumbers[9] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanTen}
                    config={caseTen.page.cases}
                    totalSlides={caseTen.page.cases.length + 2}
                    questions={caseTen.form}
                    questionOne={caseTen.page.questionOne}
                    questionTwo={caseTen.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseTenYesYes />} status="wrong" />}
                    CaseYesNo={<CaseReveal text={<CaseTenYesNo />} status="correct" />}
                    CaseNoYes={<CaseReveal text={<CaseTenNoYes />} status="wrong" />}
                    CaseNoNo={<CaseReveal text={<CaseTenNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseTen.page.paraOne} paraTwo={caseTen.page.paraTwo}  />}
                    />}

                {currentPage === questionNumbers[10] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanEleven}
                    config={caseEleven.page.cases}
                    totalSlides={caseEleven.page.cases.length + 2}
                    questions={caseEleven.form}
                    questionOne={caseEleven.page.questionOne}
                    questionTwo={caseEleven.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseElevenYesYes />} status="wrong" />}
                    CaseYesNo={<CaseReveal text={<CaseElevenYesNo />} status="correct" />}
                    CaseNoYes={<CaseReveal text={<CaseElevenNoYes />} status="wrong" />}
                    CaseNoNo={<CaseReveal text={<CaseElevenNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseEleven.page.paraOne} paraTwo={caseEleven.page.paraTwo}  />}
                    />}

                {currentPage === questionNumbers[11] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanTwelve}
                    config={caseTwelve.page.cases}
                    totalSlides={caseTwelve.page.cases.length + 2}
                    questions={caseTwelve.form}
                    questionOne={caseTwelve.page.questionOne}
                    questionTwo={caseTwelve.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseTwelveYesYes />} status="wrong" />}
                    CaseYesNo={<CaseReveal text={<CaseTwelveYesNo />} status="correct" />}
                    CaseNoYes={<CaseReveal text={<CaseTwelveNoYes />} status="wrong" />}
                    CaseNoNo={<CaseReveal text={<CaseTwelveNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseTwelve.page.paraOne} paraTwo={caseTwelve.page.paraTwo}  />}
                    />}

                {currentPage === questionNumbers[12] &&
                    <Case
                    changePage={changePage}
                    womanSvg={WomanThirteen}
                    config={caseThirteen.page.cases}
                    totalSlides={caseThirteen.page.cases.length + 2}
                    questions={caseThirteen.form}
                    questionOne={caseThirteen.page.questionOne}
                    questionTwo={caseThirteen.page.questionTwo}
                    setAnswer={calculateAnswer}
                    setNext={allowNext}
                    CaseYesYes={<CaseReveal text={<CaseThirteenYesYes />} status="wrong" />}
                    CaseYesNo={<CaseReveal text={<CaseThirteenYesNo />} status="correct" />}
                    CaseNoYes={<CaseReveal text={<CaseThirteenNoYes />} status="wrong" />}
                    CaseNoNo={<CaseReveal text={<CaseThirteenNoNo />} status="wrong" />}
                    component={<CaseStudyIntro paraOne={caseThirteen.page.paraOne} paraTwo={caseThirteen.page.paraTwo}  />}
                    />}
            </Fragment>

        )
    }
}

export default CaseStudies