export const questions = {
    q1: {
            display: [10, 11],
            title: 'What are some of the key benefits of Hana?',
            reveal: 11,
            form: {
                q1: {
                    question: 'Suitable for many women including those breastfeeding',
                    label: 'TRUE',
                    status: 'CORRECT',
                    value: false,
                    validation: {
                        required: false,
                        touched: false,
                        valid: { correct: false, wrong: false},
                        rules: {
                            correct: true
                        }
                    },
                },
                q1B: {
                    question: '',
                    label: 'FALSE',
                    status: 'INCORRECT',
                    value: false,
                    validation: {
                        required: false,
                        touched: false,
                        valid: { correct: false, wrong: false},
                        rules: {
                            wrong: true
                        }
                    },
                },
                q2: {
                    question: 'Protects against sexually transmitted infections (STIs)',
                    label: 'TRUE',
                    status: 'INCORRECT',
                    value: false,
                    validation: {
                        required: false,
                        touched: false,
                        valid: { correct: false, wrong: false},
                        rules: {
                            wrong: true
                        }
                    },
                },
                q2B: {
                    question: '',
                    label: 'FALSE',
                    status: 'CORRECT',
                    value: false,
                    validation: {
                        required: false,
                        touched: false,
                        valid: { correct: false, wrong: false},
                        rules: {
                            correct: true
                        }
                    },
                },
                q3: {
                    question: 'Can be taken by women who are pregnant	',
                    label: 'TRUE',
                    status: 'INCORRECT',
                    value: false,
                    validation: {
                        required: false,
                        touched: false,
                        valid: { correct: false, wrong: false},
                        rules: {
                            wrong: true
                        }
                    },
                },
                q3B: {
                    question: '',
                    label: 'FALSE',
                    status: 'CORRECT',
                    value: false,
                    validation: {
                        required: false,
                        touched: false,
                        valid: { correct: false, wrong: false},
                        rules: {
                            correct: true
                        }
                    },
                },
                q4: {
                    question: 'More than 99% effective when taken correctly',
                    label: 'TRUE',
                    status: 'CORRECT',
                    value: false,
                    validation: {
                        required: false,
                        touched: false,
                        valid: { correct: false, wrong: false},
                        rules: {
                            correct: true
                        }
                    },
                },
                q4B: {
                    question: '',
                    label: 'FALSE',
                    status: 'INCORRECT',
                    value: false,
                    validation: {
                        required: false,
                        touched: false,
                        valid: { correct: false, wrong: false},
                        rules: {
                            wrong: true
                        }
                }
            }
        },
    },
    q2:{
        page: {
            display: [12, 13],
            title: 'How does Hana work?',
            subTitle: 'Select ALL that apply',
            reveal: 13
        },
        form: {
            q1: {
                content: 'Thickening the cervical mucus',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q2: {
                content: 'Preventing the fertilised egg from implanting in the womb',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }, 
            q3: {
                content: 'Delaying ovulation',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q4: {
                content: 'Inhibiting ovulation',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
        }
    },
    q3: {
        display: [14, 15],
        title: 'In which of these situations should Hana NOT be used?',
        reveal: 15,
        form: {
            q1: {
                question: 'Pregnancy',
                label: 'TRUE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q1B: {
                question: '',
                label: 'FALSE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q2: {
                question: 'Breastfeeding',
                label: 'TRUE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q2B: {
                question: '',
                label: 'FALSE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q3: {
                question: 'Under 18 years of age',
                label: 'TRUE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q3B: {
                question: '',
                label: 'FALSE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q4: {
                question: 'Cancer that is affected by sex-steroid hormones (such as breast, ovarian or endometrial cancer)',
                label: 'TRUE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q4B: {
                question: '',
                label: 'FALSE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q5: {
                question: 'Allergy to desogestrel',
                label: 'TRUE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q5B: {
                question: '',
                label: 'FALSE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q6: {
                question: 'Unable to take oestrogens ',
                label: 'TRUE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q6B: {
                question: '',
                label: 'FALSE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    q4:{
        page: {
            display: [16, 17],
            title: 'What are the common side effects with Hana, which affect up to 1 in 10 women?',
            subTitle: 'Select ALL that apply',
            reveal: 17
        },
        form: {
            q1: {
                content: 'Abdominal pain',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q2: {
                content: 'Headaches',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }, 
            q3: {
                content: 'Acne',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q4: {
                content: 'Dizziness',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q5: {
                content: 'Joint pain',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q6: {
                content: 'Changes to periods',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
        }
    },
    q5: {
        display: [18, 19],
        title: 'What can you do to help women feel at ease when discussing Hana?   ',
        reveal: 19,
        form: {
            q1: {
                question: 'Ask them how often they have sex',
                label: 'TRUE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q1B: {
                question: '',
                label: 'FALSE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q2: {
                question: 'Be approachable, supportive and positive',
                label: 'TRUE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q2B: {
                question: '',
                label: 'FALSE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q3: {
                question: 'Discuss their contraception with other customers present',
                label: 'TRUE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q3B: {
                question: '',
                label: 'FALSE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q4: {
                question: 'Be matter-of-fact and reassuring',
                label: 'TRUE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q4B: {
                question: '',
                label: 'FALSE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q5: {
                question: 'Offer a more private location (consultation room) to discuss their needs ',
                label: 'TRUE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q5B: {
                question: '',
                label: 'FALSE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q6: {
                question: 'Reassure them that their visit and information are completely confidential even if they’re under 16',
                label: 'TRUE',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q6B: {
                question: '',
                label: 'FALSE',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
}