import React, { useEffect, useMemo } from 'react'
import { Box, Text, Heading, Button, Icon } from '@chakra-ui/core'

// Load components
import Checkbox from '../../UI/Checkbox/Checkbox'
import MoreInfo17 from '../../../content/Assessment/MoreInfo17'
import MoreInfo18 from '../../../content/Assessment/MoreInfo18'

// Load hook
import useInputHook from '../../../hooks/useInputHook'

// Load style
import { normalText, bkglight, headingText, submitBtn } from '../../../style/styleFormat'

// Load layout
import Layout from '../../../layout/Layout.card'

const Card = ({content, checkbox, ...props}) => {
    return (
        <Box {...bkglight} display="flex" {...props} alignItems="center" justifyContent="space-between" minHeight="80px" width="100%">
            <Text {...normalText} maxWidth="85%">
                {content}
            </Text>
            <Box  minHeight="38px" alignSelf="center">
                {checkbox}
            </Box>
        </Box>
    )
}

const SelectExpanded = ({ changePage, questions=[], title, subTitle, reveal, setNext, setAnswer, single, modalTitle, columns = false }) => {
   const [form, inputCheckHandler, allowNext, checkCorrect] = useInputHook(questions, single)
   const answer = useMemo(() => checkCorrect(), [checkCorrect]) 

   useEffect(() => {

    if (reveal) {
        return setNext(true)
    }
    // Allow next is one answer been selected
    setNext(allowNext)
    }, [reveal,allowNext, setNext])

   useEffect(() => {
        
        // Check if the answer was correct and fire callback
        if (reveal) {
        setAnswer(answer)
    
        }
   }, [reveal, setAnswer, answer])

   let ModalComponent = null

   switch(modalTitle) {
       case 17:
           ModalComponent = <MoreInfo17 />
           break;
        case 18:
            ModalComponent = <MoreInfo18 />
            break;
        default:
            ModalComponent = null
            break
   }
 
    return (
        <Layout>
            <Heading {...headingText} margin="10px 0" dangerouslySetInnerHTML={{__html: title}} />
               
            <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="20px">
                <Text {...normalText}>
                    {subTitle}
                </Text>
            </Box>
            
            <Box display="flex" flexDirection={columns ? "column" : "row"} alignItems="flex-start" justifyContent="space-between" flexWrap="wrap">
                {Object.keys(questions).map((key) => {
                    return (
                        <Box key={key} display="flex" alignItems="flex-start" flexDirection="column" justifyContent="space-between" width={{base: "100%", lg:"48%"}}>
                            <Card 
                            border={(reveal && questions[key].status === 'CORRECT' && !form[key].value) ? "1px solid #DF6532" : "1px solid #9894C6"}
                            content={questions[key].content}
                            checkbox={<Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={(questions[key].status === 'CORRECT' && reveal) ? reveal : form[key].value} amber={!form[key].value} fail={form[key].validation.valid.wrong} success={(form[key].value && form[key].validation.valid.correct)} name={key} id={key} />}
                            />
                        {(reveal && form[key].value && questions[key].status === 'INCORRECT') && 
                        <Box width={{base: "100%", lg:"48%"}} marginTop="-20px">
                            <Text {...normalText} fontFamily="Gotham Bold" color={questions[key].status === 'CORRECT' ? form[key].value ? "green.900" : "orange.900" : "red.900"} >
                            <strong>{questions[key].status}</strong>
                            </Text>
                        </Box>
                        }
                        {(reveal && questions[key].status === 'CORRECT') && 
                        <Box width={{base: "100%", lg:"48%"}} marginTop="-20px">
                            <Text {...normalText} fontFamily="Gotham Bold" color={questions[key].status === 'CORRECT' ? form[key].value ? "green.900" : "orange.900" : "red.900"}>
                                <strong>{questions[key].status}</strong>
                            </Text>
                        </Box>
                        }
                    </Box>
                    )
                })}
            </Box>
            {reveal &&
                <Box margin="20px 0">
                {ModalComponent}
            </Box>
            }
            {(!single && !reveal) &&
                <Box display="flex" width={columns ? "48%" : "100%"} justifyContent="flex-end">
                <Button 
                {...submitBtn}
                onClick={() => changePage('increment')}
                >Submit<Icon name="chevron-right" fontSize="32px" /></Button>
                </Box>
            }
        </Layout>
    )
}

export default SelectExpanded