import React from 'react'
import { Box, Text, Heading, Stack } from '@chakra-ui/core'

// Load assets
import {ReactComponent as Info} from '../../images/info.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText } from '../../style/styleFormat'

const AdviceTakingHana = () => {
    return (
        <Layout>
            <Stack spacing="4">
                <Heading {...secondaryText} fontSize="lg">
                    How to take Hana
                </Heading>
                <Text {...secondaryText}>
                    It is important to give all women advice on how to take Hana, what to do if a dose is missed or in case of gastrointestinal disturbances, how to take Hana after emergency contraception, and what side effects to be aware of when taking Hana.
                </Text>

                <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center">
                    <Info height="50px" width="70px" />
                    <Text {...secondaryText} marginLeft={{base: "0", lg:"20px"}} marginTop={{base:"20px", lg: "0"}}>
                        Remind the woman to read the patient information leaflet (PIL) carefully; all the information in the following section is in the PIL. You can also suggest that she saves the link to the PIL website.
                    </Text>
                </Box>
            </Stack>
        </Layout>
    )
}

export default AdviceTakingHana