import React from 'react'
import { Box, Text, List, ListItem, useDisclosure, Stack } from '@chakra-ui/core'

// Load assets
import {ReactComponent as FirstTime} from '../../images/firtstTimePerson.svg'

// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, blueBullet, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const StartingHana = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Stack spacing="6">
                <Text {...secondaryText}>
                    Directions on when and how to start using Hana in women who are not currently using any contraception, or who are switching from a different form of contraception, are given below.
                </Text>
                <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                    Advice to give to women who are not currently using any contraception
                </Text>
                <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center">
                    <FirstTime width="100px" />
                    <Text {...secondaryText} marginLeft="10px" marginTop={{base: "20px", lg: "0"}}>
                        Ask the woman when she last used contraception. If she has not used contraception recently it may be because she has been pregnant. The advice you give the woman on starting Hana will be different depending on her situation.
                    </Text>
                </Box>

                <Box borderTop={{base: "1px solid #4580AB", lg:"2px solid #4580AB"}}>
                    <Box borderBottom="1px solid #739FBF" display="flex" flexDirection={{base: "column", lg: "row"}}>
                        <Box backgroundColor="#E3ECF3" width={{base: "100%", lg:"50%"}} padding="2">
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                No contraceptive use in the past month
                            </Text>
                        </Box>
                        <Box width={{base: "100%", lg:"50%"}} padding="4"  borderLeft={{base: "none", lg:"1px solid #739FBF"}}>
                            <List spacing="4">
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    Start on day 1, the first day of menstrual bleeding, no need for extra <span style={{position: 'relative'}}>precautions<Reference>1</Reference></span>
                                </ListItem>
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                 ...blueBullet
                                    }}>
                                    Can start on days 2-5 of menstrual bleeding, in which case extra precautions are recommended for the first 7 <span style={{position: 'relative'}}>days<Reference>1</Reference></span>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Box borderBottom="1px solid #739FBF" display="flex" flexDirection={{base: "column", lg: "row"}}>
                        <Box backgroundColor="#E3ECF3" width={{base: "100%", lg:"50%"}} padding="2">
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                Following miscarriage or abortion
                            </Text>
                        </Box>
                        <Box width={{base: "100%", lg:"50%"}} padding="4" borderLeft={{base: "none", lg:"1px solid #739FBF"}}>
                            <List spacing="4">
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    Start immediately or within 5 days after miscarriage or abortion, no need for extra <span style={{position: 'relative'}}>precautions<Reference>1</Reference></span>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Box borderBottom={{base: "none", lg:"1px solid #739FBF"}} display="flex" flexDirection={{base: "column", lg: "row"}}>
                        <Box backgroundColor="#E3ECF3" width={{base: "100%", lg:"50%"}} padding="2">
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                Following childbirth
                            </Text>
                        </Box>
                        <Box width={{base: "100%", lg:"50%"}} padding="4" borderLeft={{base: "none", lg:"1px solid #739FBF"}}>
                            <List spacing="4">
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                    ...blueBullet
                                    }}>
                                    Start any day between day 1 and day 21 after <span style={{position: 'relative'}}>childbirth<Reference>1</Reference></span>
                                </ListItem>
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    Can start later, in which case extra precautions are recommended for the first 7 <span style={{position: 'relative'}}>days<Reference>1</Reference></span>
                                </ListItem>
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    If she has already had unprotected sex, she should take a pregnancy test or see her doctor before starting <span style={{position: 'relative'}}>Hana<Reference>1</Reference></span>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                </Box>
                <Text {...referenceLink} color="blue.50" onClick={onOpen} fontWeight="bold" cursor="pointer" as="u">References</Text>
                <Modal isOpen={isOpen} onClose={onClose} title="References">
                    <List as="ol" styleType="decimal" spacing="4">
                        <ListItem {...referenceText}>
                            Hana (desogestrel) Summary of Product Characteristics, November 2020.
                        </ListItem>
                    </List>
                </Modal>
            </Stack>
        </Layout>
    )
}

export default StartingHana