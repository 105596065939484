import React from 'react'
import { Box, Text, List, ListItem, useDisclosure, Stack } from '@chakra-ui/core'

// Load assets
import {ReactComponent as FirstTime} from '../../images/firtstTimePerson.svg'

// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, blueBullet, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const StartingHana = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Stack spacing="6">
                <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                    Advice to give to women who are changing from existing hormonal contraception
                </Text>
                <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="center">
                    <FirstTime width="100px" />
                    <Text {...secondaryText} marginLeft="10px" marginTop={{base: "20px", lg: "0"}}>
                    Ask the woman what type of hormonal contraception she is currently using and confirm that she has been using it consistently and correctly, with no unscheduled breaks. The advice on when to start Hana depends on the type of hormonal contraception being used.
                    </Text>
                </Box>
                <Box borderTop={{base: "1px solid #4580AB", lg:"2px solid #4580AB"}}>
                    <Box borderBottom="1px solid #739FBF" display="flex" flexDirection={{base: "column", lg: "row"}}>
                        <Box backgroundColor="#E3ECF3" width={{base: "100%", lg:"50%"}} padding="4">
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                Switch from combined oral contraceptive pill, vaginal ring or skin patch
                            </Text>
                        </Box>
                        <Box width={{base: "100%", lg:"50%"}} padding="4" borderLeft={{base: "none", lg:"1px solid #739FBF"}}>
                            <List spacing="4">
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    For the combined pill, start the day after the last tablet (if this is an everyday [ED] pill the woman should start the day after the last tablet containing an active substance), no need for extra <span style={{position: 'relative'}}>precautions<Reference>1</Reference></span>
                                </ListItem>
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                    ...blueBullet
                                    }}>
                                    Start on the day of the removal of the vaginal ring or skin patch, no need for extra <span style={{position: 'relative'}}>precautions<Reference>1</Reference></span>
                                </ListItem>
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                    ...blueBullet
                                    }}>
                                   Or start the day after the usual tablet-, ring- or patch-free interval or the day after the last non-active tablet (if this is an ED pill), in which case extra precautions are recommended for the first 7 <span style={{position: 'relative'}}>days<Reference>1</Reference></span>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Box borderBottom={{base: "none", lg:"1px solid #739FBF"}} display="flex" flexDirection={{base: "column", lg: "row"}}>
                        <Box backgroundColor="#E3ECF3" width={{base: "100%", lg:"50%"}} padding="4">
                            <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                                Switch from progestogen-only method (mini-pill, injection, implant or intrauterine system)
                            </Text>
                        </Box>
                        <Box width={{base: "100%", lg:"50%"}} padding="4" borderLeft={{base: "none", lg:"1px solid #739FBF"}}>
                            <List spacing="4">
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    Switch to Hana from the mini-pill on any day, just take Hana the next day instead of the mini-pill, no need for extra <span style={{position: 'relative'}}>precautions<Reference>1</Reference></span>
                                </ListItem>
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    Start on the day the next injection is due, no need for extra <span style={{position: 'relative'}}>precautions<Reference>1</Reference></span>
                                </ListItem>
                                <ListItem 
                                {...secondaryText}
                                paddingLeft="25px"
                                position="relative"
                                _before={{
                                   ...blueBullet
                                    }}>
                                    Start on the day of the removal of the implant or intrauterine system, no need for extra <span style={{position: 'relative'}}>precautions<Reference>1</Reference></span>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                </Box>
                <Text {...referenceLink} onClick={onOpen} fontWeight="bold" cursor="pointer" as="u" color="blue.50">References</Text>
                <Modal isOpen={isOpen} onClose={onClose} title="References">
                    <List as="ol" styleType="decimal" spacing="4">
                        <ListItem {...referenceText}>
                            Hana (desogestrel) Summary of Product Characteristics, November 2020.
                        </ListItem>
                    </List>
                </Modal>
            </Stack>
        </Layout>
    )
}

export default StartingHana