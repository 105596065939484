import React from 'react'
import { Box, Text, List, ListItem, useDisclosure, Link } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, blueBullet, referenceLink, subBullet } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const InformationQuickStartTwo = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Box borderTop="1px solid #4580AB" flexDirection={{base: "column", lg: "row"}} display="flex">
                <Box width="100%" position="relative">
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Pros
                        </Text>
                    </Box>
                    <List spacing="4" padding="4">
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="25px"
                        position="relative"
                        _before={{
                            ...blueBullet
                            }}>
                            Reduces the time during which a woman is at risk of <span style={{position: "relative"}}>pregnancy<Reference>1</Reference></span>
                        </ListItem>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="50px"
                        position="relative"
                        _before={{
                            ...subBullet
                            }}>
                            Women who have taken emergency contraception or who have irregular menstrual cycles could have an even longer wait until the start of their next menstrual <span style={{position: 'relative'}}>period<Reference>1</Reference></span>
                        </ListItem>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="25px"
                        position="relative"
                        _before={{
                           ...blueBullet
                            }}>
                            Prevents a woman from forgetting recommendations on correct usage of her <span style={{position: 'relative'}}>contraception<Reference>1</Reference></span>
                        </ListItem>
                        <ListItem 
                        {...secondaryText}
                        paddingLeft="25px"
                        position="relative"
                        _before={{
                          ...blueBullet
                            }}>
                            Avoids diminishing enthusiasm for the method and use of a less reliable alternative <span style={{position: 'relative'}}>method<Reference>1</Reference></span>
                        </ListItem>
                    </List>
                </Box>
                <Box width="100%" borderTop={{base: "1px solid #739FBF", lg: "none"}} borderLeft={{base: "none", lg:"1px solid #739FBF"}}>
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Cons
                        </Text>
                    </Box>
                    <Box padding="4">
                        <List spacing="4">
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                               ...blueBullet
                                }}>
                                Safety and efficacy of starting after day 5 of the menstrual cycle have not been established
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                              ...blueBullet
                                }}>
                                Additional contraceptive measures are required for the first 7 days of tablet <span style={{position: 'relative'}}>taking <Reference>2</Reference></span>
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="25px"
                            position="relative"
                            _before={{
                                ...blueBullet
                                }}>
                                Low risk that a woman is already pregnant, or that emergency contraception fails and she falls pregnant from recent unprotected sex, despite use of criteria for excluding <span style={{position: 'relative'}}>pregnancy<Reference>1</Reference></span>
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="50px"
                            position="relative"
                            _before={{
                               ...subBullet
                                }}>
                                Risk of unintentional exposure of a foetus to desogestrel has been shown to be <span style={{position: 'relative'}}>low &nbsp;<Reference> 1,2</Reference></span>
                            </ListItem>
                            <ListItem 
                            {...secondaryText}
                            paddingLeft="50px"
                            position="relative"
                            _before={{
                               ...subBullet
                                }}>
                                If pregnancy cannot be excluded prior to quick starting, a pregnancy test must be performed at least 3 weeks after the last episode of unprotected sex, including any episodes within the 7 days it takes for Hana to reach its maximum <span style={{position: 'relative'}}>effectiveness &nbsp;<Reference> 1,2</Reference></span>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Box>
            <Text {...secondaryText} margin="10px 0">
                Information on quick starting can be found in the Faculty of Sexual and Reproductive Healthcare (FSRH) Guideline on Quick Starting Contraception<sup>1</sup> and the FSRH Guidance on Progestogen-only Contraception<sup>3</sup>
            </Text>
            <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Faculty of Sexual & Reproductive Healthcare Clinical Guidance (FSRH). FSRH Guideline Quick Starting Contraception. April 2017. Available at: <Link href="https://www.fsrh.org/news/updated-clinical-guidance-published-quick-starting/" isExternal textDecoration="underline" position="relative">https://www.fsrh.org/news/updated-clinical-guidance-published-quick-starting/</Link>. Accessed: November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Faculty of Sexual & Reproductive Healthcare Clinical Guidance (FSRH). Progestogen-only Pills. March 2015 (amended April 2019). Available at: <Link textDecoration="underline" href="https://www.fsrh.org/standards-and-guidance/documents/cec-ceu-guidance-pop-mar-2015/" isExternal pos="relative">https://www.fsrh.org/standards-and-guidance/documents/cec-ceu-guidance-pop-mar-2015/</Link>. Accessed: November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default InformationQuickStartTwo