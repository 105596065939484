import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

export const CaseSixYesYes = () => <Text {...normalText}>
This woman appears to have experienced mood changes whilst taking Hana. She <Text as="span" {...boldGreen}>can be resupplied</Text> with just 3 months of Hana to avoid pregnancy, which has been excluded, but she should be advised to <Text as="span" {...boldGreen}>see her doctor</Text> so they can assess the mood changes and whether she should continue taking Hana. She should be advised to start the next pack as soon as she finishes her current one, without a break.
</Text>

export const CaseSixYesNo = () => <Text {...normalText}>
This woman appears to have experienced mood changes whilst taking Hana. She <Text as="span" {...boldRed}>can be resupplied</Text> with just 3 months of Hana to avoid pregnancy, which has been excluded, but she should be advised to <Text as="span" {...boldRed}>see her doctor</Text> so they can assess the mood changes and whether she should continue taking Hana. She should be advised to start the next pack as soon as she finishes her current one, without a break.
</Text>

export const CaseSixNoNo = () => <Text {...normalText}>
This woman appears to have experienced mood changes whilst taking Hana. She <Text as="span" {...boldRed}>can be resupplied</Text> with just 3 months of Hana to avoid pregnancy, which has been excluded, but she should be advised to <Text as="span" {...boldRed}>see her doctor</Text> so they can assess the mood changes and whether she should continue taking Hana. She should be advised to start the next pack as soon as she finishes her current one, without a break.
</Text>

export const CaseSixNoYes = () => <Text {...normalText}>
This woman appears to have experienced mood changes whilst taking Hana. She <Text as="span" {...boldRed}>can be resupplied</Text> with just 3 months of Hana to avoid pregnancy, which has been excluded, but she should be advised to <Text as="span" {...boldRed}>see her doctor</Text> so they can assess the mood changes and whether she should continue taking Hana. She should be advised to start the next pack as soon as she finishes her current one, without a break.
</Text>