import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, List, ListItem, Icon } from '@chakra-ui/core'

// Load assets
import {ReactComponent as PartOne } from '../../images/part1Altnew.svg'
import {ReactComponent as PartTwo } from '../../images/part2Alt.svg'

const linksOne = [
    {
        name: 'Introducing Hana',
        page: 3
    },
    {
        name: 'Menstrual cycle overview',
        page: 4
    }, 
    {
        name: 'How Hana Works',
        page: 5
    },
    {
        name: 'Efficacy of Hana',
        page: 6
    },
    {
        name: 'Who can use Hana',
        page: 8
    },
    {
        name: 'Drug Interactions',
        page: 10
    }
]

const linksTwo = [
    {
        name: 'Supply situations',
        page: 24
    },
    {
        name: 'First-Time Supply - using the Pharmacy supply aid checklist',
        page: 26
    },
    {
        name: 'First-Time Supply - excluding pregnancy',
        page: 32
    },
    {
        name: 'First-Time Supply - starting Hana',
        page: 34
    },
    {
        name: 'Repeat Supply or Changing Supply - using the Pharmacy supply aid checklist',
        page: 36
    },
    {
        name: 'Repeat Supply or Changing Supply - excluding pregnancy',
        page: 41
    },
    {
        name: 'Information on quick starting Hana',
        page: 42
    },
    {
        name: 'Advice for all women taking Hana',
        page: 44
    }
]

const Menu = ({ changePage, toDisplay, close }) => {
    const history = useHistory()
    if (!toDisplay) {
        return null
    }

    function setPageChange(page) {
        changePage(page)
        history.push('training')
    }

    const listDisplay = toDisplay === 1 ? linksOne : linksTwo
    return (   
        <Box width="100%">
            <Icon name="close" cursor="pointer" onClick={() => close(false)} color="#fff" position="absolute" top="20px" right="20px" />
            <Box width="100%" marginBottom="50px" display="flex" justifyContent="center" position="relative">
                {toDisplay === 1 && <PartOne />}
                {toDisplay === 2 && <PartTwo />}
                <Box display={{base: "block", lg: "none"}} position="absolute" left="-20px" top="50%" transform="translateY(-50%)">
                    <Icon onClick={() => close(false)} name="chevron-left" fontSize="44px" color="#fff" />
                </Box>
            </Box>
        
            <List styleType="disc" spacing="4">
            {listDisplay.map((link) => {
                return (
                    <ListItem 
                    paddingLeft="10px"
                    position="relative"
                    display="block"
                    _before={{
                        position: 'absolute',
                        top: '1px',
                        left: '-10px',
                        content: "'•'",
                        color: '#D8D8D8',
                        fontSize: '32px'
                        }}
                    cursor="pointer" color="#fff" fontSize="14px" fontFamily="Gotham Book" lineHeight="29px" onClick={() => setPageChange(link.page)}>{link.name}</ListItem>
                )
            })}
            </List>
        </Box>
    )
}

export default Menu