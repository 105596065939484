/*****************************
 * Common Typography formatting
 * ***************************/ 

// Primary normal text style
export const normalText = {
    color: 'text.800', // #2E1C45
    lineHeight: 'tight', // 22px
    fontSize: 'md', // 14px
    display: 'inline-block',
    fontFamily: "Gotham Book"
}

export const secondaryText = {
    color: 'blue.500', // #4D69A6
    lineHeight: 'tight', // 22px
    fontSize: 'md', // 14px
    display: 'block',
    fontFamily: 'Gotham Book'
}

export const referenceText = {
    fontSize: 'md',
    color: '#3B2B51'
}

export const referenceLink = {
    fontFamily: "Gotham Bold",
    fontWeight: "Bold",
    fontSize: "sm",
    color: "text.800",
    lineHeight: "tight",
    cursor: "pointer"
}

export const headingText = {
    fontSize: 'lg',
    color: 'purple.100',
    lineHeight: 'wide'
}

export const headingTextbkRnd = {
    fontSize: 'lg',
    color: '#fff',
    lineHeight: 'wide',
    backgroundColor: 'purple.900',
    borderRadius: "25px",
    display: "inline-block",
    padding: "4"
}

// Part one sub
export const subTitlebase = {
    lineHeight: 'tight', // 22px
    fontSize: 'lg', // 20px
}

export const subTitlelightPurple = {
    ...subTitlebase,
    color: 'purple.900'
}

export const textBoldPara = {
    ...normalText,
    fontFamily: "Gotham Bold",
    fontWeight: "bold",
    color: "purple.900",
}


/*****************************
 * BACKGROUND STYLES
 * ***************************/ 
export const bkglight = {
    backgroundColor: "background.1", // #F8F7FC
    border: "1px solid #9894C6",
    marginBottom: "20px",
    borderRadius: "10px", 
    padding: "4" 
}

export const bkLightRed = {
    backgroundColor: "background.4",
    borderRadius: "21px",
    padding: "4"
}

/*****************************
 * BUTTON STYLES
 * ***************************/ 
export const submitBtn = {
    fontSize:"1rem",
    backgroundColor:"orange.900",
    color:"#fff",
    borderColor:"orange.900",
    border:"1px",
    height:"51px",
    size:"lg",
    maxWidth: "125px",
    fontWeight: 700,
    variant:"solid"
}

/*****************************
 * BULLET STYLES
 * ***************************/ 
export const purpleBullet = {
    position: 'absolute',
    top: '2px',
    left: '-10px',
    content: "'•'",
    color: 'purple.500',
    fontSize: '60px'
 }

export const blueBullet = {
    position: 'absolute',
    top: '2px',
    left: '-10px',
    content: "'•'",
    color: 'blue.50',
    fontSize: '60px',
    display: "block"
}

export const subBullet = {
    position: 'absolute',
    top: '4px',
    left: '25px',
    content: "''",
    border: '1px solid #657DB2',
    borderRadius: "50%",
    height: '16px',
    width: "16px",
    backgroundColor: "#fff"
}
