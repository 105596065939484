import React from 'react'
import { Stack, Heading, Text, List, ListItem, Link } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const Terms = () => {
    return (
        <Stack spacing="4">
            <Heading {...normalText} fontSize="18px" textDecoration="underline" textAlign="center">
                Legal Information & Terms of Use
            </Heading>

            <List marginLeft="20px" spacing="4" styleType="disc">
                <ListItem {...normalText} display="block list-item">
                    <Text as="span" fontFamily="Gotham Bold">Publisher</Text><br/>
                    Laboratoire HRA Pharma SAS <br/>
                    200 avenue de Paris<br/>
                    F-92 320 Châtillon France<br/>
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    <Text as="span" fontFamily="Gotham Bold">Publishing Director</Text><br/>
                    David Wright, CEO<br/>
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    <Text as="span" fontFamily="Gotham Bold">Website Design</Text><br/>
                    LOOP New Media GmbH, Siezenheimer Str. 39, 5020 Salzburg, Austria
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    <Text as="span" fontFamily="Gotham Bold">Website Host</Text><br/>
                    DigitalOcean, LLC, 101 Avenue of the Americas, 10th Floor, New York, NY 10013 USA
                </ListItem>
            </List>

            <Text {...normalText} fontFamily="Gotham Bold">
                Use of this website
            </Text>
            <Text {...normalText}>
                The website <Link href="www.hana.co.uk" target="_blank" textDecoration="underline" isExternal>www.hana.co.uk</Link> or <Link href="www.hanapharmacist.co.uk" target="_blank" textDecoration="underline" isExternal>www.hanapharmacist.co.uk</Link> (hereafter “Site”) is controlled and operated by Laboratoire HRA Pharma (hereafter “HRA Pharma”), a “Société par Actions Simplifiée” (a private limited company) company incorporated in France and registered to the Trade and Companies Register of Nanterre under number 420 792 582, located 200 avenue de Paris, F-92 320 Châtillon in France. 
            </Text>
            <Text {...normalText}>
                To contact HRA Pharma, click on the following link:  
            </Text>
            <Text {...normalText}>
              <Link href="https://www.hra-pharma.com/index.php/contact-us" target="_blank" textDecoration="underline" isExternal>https://www.hra-pharma.com/index.php/contact-us</Link> or,
            </Text>

            <Text {...normalText}>
                call +33(0)1 40 33 11 30.
            </Text>
            <Text {...normalText}>
                The Site publication director is HERA SAS represented by David Wright, CEO.
            </Text>
            <Text {...normalText}>
                The Site has been developed in accordance with the principles of international regulations.
            </Text>
            <Text {...normalText}>
                Access to and use of the Site are subject to these Terms of Use and to all applicable laws and regulations.
            </Text>
            <Text {...normalText}>
                By accessing and using the Site, you accept without limitation or qualification these Terms of Use.
            </Text>
            <Text {...normalText}>
                HRA Pharma reserves the right to change these Terms of Use from time to time without notice, as it deems necessary; you are therefore advised to check them regularly.
            </Text>
            <Text {...normalText}>
                The Site contains information on a medicine marketed by HRA Pharma or by a subsidiary of HRA Pharma or by a distributor of HRA Pharma.
            </Text>
            <Text {...normalText}>
                The Site has two parts:
            </Text>

            <List marginLeft="20px">
                <ListItem {...normalText} display="block list-item">
                    <span>-</span> HANA PHARMACY RESOURCE HUB dedicated to the UK Healthcare professional only,
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    <span>-</span> CONSUMER SITE dedicated to the UK public.
                </ListItem>
            </List>

            <Text {...normalText}>
                You agree that access to and use of the Site and its content thereof is at your own risk.
            </Text>
            <Text></Text>

            <List marginLeft="20px">
                <ListItem {...normalText} display="block list-item">
                <span>-</span> HANA PHARMACY RESOURCE HUB dedicated to the UK Healthcare professional only
                </ListItem>
            </List>

            <Text {...normalText}>
            It may contain promotional information.
            </Text>

            <Text {...normalText}>
                You’ll find useful information and resources for making sure your pharmacy and pharmacy team are prepared for the appropriate supply of Hana to women. We’re updating the page with helpful content all the time, so check back regularly.
            </Text>

            <Text {...normalText}>
                TRAINING MATERIALS
            </Text>
            <Text {...normalText}>
                Everything your pharmacy needs to train staff effectively.
            </Text>
            <Text {...normalText}>
                Build and test your own knowledge of Hana and its appropriate supply and safe use through this accredited training – download the Pharmacy guide, or do the training online.
            </Text>
            <Text {...normalText}>
                IN-PHARMACY RESOURCES
            </Text>
            <Text {...normalText}>
                Useful resources for you to have within easy reach to aid your consultations and decision-making in pharmacy.
            </Text>
            <Text {...normalText}>
                Comprising a checklist for women and a corresponding supply aid for pharmacists, download the optional Pharmacy supply aid checklist to guide your consultations.
            </Text>
            <Text {...normalText}>
                For support with excluding pregnancy before supply, download the Pregnancy exclusion tool.
            </Text>
            <Text {...normalText}>
                Download the Pharmacy supply algorithm for an overview of questions and responses in different supply situations.
            </Text>
            <Text {...normalText}>
                Download the current Hana Summary of Product Characteristics or Patient Information Leaflet for reference.
            </Text>
            <Text></Text>

            <List marginLeft="20px">
                <ListItem {...normalText} display="block list-item">
                    <span>-</span> CONSUMER SITE
                </ListItem>
            </List>

            <Text {...normalText}>
                It is dedicated to members of the UK public
            </Text>
            <Text {...normalText} >
                It is not intended to be used as a source of medical advice, opinion or diagnosis.
            </Text>

            <Text {...normalText} marginTop="10px">
                Common provisions regarding both parts of the Site
            </Text>
            <Text {...normalText}>
                HRA Pharma and any party involved in creating, producing or delivering the Site hereby expressly exclude:
            </Text>

            <List as="ul" marginLeft="20px" listStyleType="disc">
                <ListItem {...normalText} display="block list-item">
                    All conditions, warranties and other terms that might otherwise be implied by statute, common law or the law of equity,
                </ListItem>
                <ListItem {...normalText} display="block list-item">
                    Any liability for any direct, indirect or consequential loss or damage incurred by any user arising out of access to, use of or inability to use the Site, or any errors or omissions in the content thereof.
                </ListItem>
            </List>
            <Text {...normalText}>
                HRA Pharma accepts no liability for any loss or damage caused by a distributed denial of service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of the audio, video, data or text on the Site or any other aspect of the Site.
            </Text>

            <Text {...normalText} fontFamily="Gotham Bold">
                Personal Data
            </Text>
            <Text {...normalText}>
                HRA Pharma ensure the protection of personal data processed through the Site according to the General Data Protection Regulation and other applicable laws. Detailed information on the processing of your personal data are available in the Privacy Policy and in the Cookies Policy available in the footer of the Site.
            </Text>


            <Text {...normalText} fontFamily="Gotham Bold">
                Trademarks and Logos
            </Text>
            <Text {...normalText}>
                Trademarks and logos used on the Site are the ownership of HRA Pharma and shall not be used without HRA Pharma prior and written consent. They may be registered.
            </Text>

            <Text {...normalText} fontFamily="Gotham Bold">
                Copyright
            </Text>
            <Text {...normalText}>
                HRA Pharma is the owner or licensee of all copyright in the Site.
            </Text>
            <Text {...normalText}>
                All such rights are reserved.
            </Text>

            <Text {...normalText}>
                You may download and retain on your disk or in hard-copy form a single copy of material published on the Site only for your personal, non-commercial use, provided that you do not remove any copyright or other proprietary notices.
            </Text>

            <Text {...normalText}>
                You may not otherwise copy, distribute, display, download, modify, repost, transmit or use all or part of any material without HRA Pharma’s prior written permission. You may not mirror on another web server all or part of any material published by HRA Pharma on the Site, and you may not copy any of the graphics contained in any material published on this site.
            </Text>

            <Text {...normalText}>
                Nothing contained herein shall be construed as conferring by implication, estoppels or otherwise any license or right under any patent or trademark of HRA Pharma, or any third party. 
            </Text>

            <Text {...normalText} fontFamily="Gotham Bold">
                Reservation of Rights
            </Text>
            <Text {...normalText}>
                HRA Pharma reserves the right to alter or delete material from the Site at any time without notice.
            </Text>

            <Text {...normalText} fontFamily="Gotham Bold">
                Governing Laws
            </Text>
            <Text {...normalText}>
                French laws govern these Terms of Use and their interpretation, and the French courts shall have exclusive jurisdiction to settle any dispute arising out of or in connection with these Terms of Use.
            </Text>

            <Text {...normalText} fontFamily="Gotham Bold">
                Miscellaneous
            </Text>
            <Text {...normalText}>
                If any provision of these Terms of Use is held to be unlawful, void or unenforceable, then such provision shall be severable without affecting the validity, legality or enforceability of all remaining provisions. 
            </Text>
            <Text {...normalText}>
                Except as expressly provided, these Terms of Use constitute the entire agreement between you and HRA Pharma with respect to the use of the Site and content.
            </Text>
            <Text {...normalText} as="i">
                Date of preparation: December 2020 | UK/HANA/0117
            </Text>

            
            
          

        
        </Stack>
    )
}

export default Terms