import React from 'react'
import { Box, Text, List, ListItem, useDisclosure, Stack } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import {ReactComponent as Info } from '../../images/infoAlt.svg'
import {ReactComponent as Headache} from '../../images/headache.svg'
import {ReactComponent as Sick } from '../../images/headSick.svg'
import {ReactComponent as Mood } from '../../images/headDepressed.svg'
import {ReactComponent as Sex } from '../../images/sexdrive.svg'
import {ReactComponent as Period } from '../../images/period.svg'
import {ReactComponent as Breast } from '../../images/breastPain.svg'
import {ReactComponent as Acne } from '../../images/headAcne.svg'
import {ReactComponent as Weight } from '../../images/bodyWeight.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { normalText, bkLightRed, referenceText, referenceLink, purpleBullet } from '../../style/styleFormat'

const Card = ({ SVG, text }) => {
    return (
        <Box width={{base: "50%", lg:"23%"}} display="flex" justifyContent="center" alignItems="center" flexDirection="column" padding="5px">
            <SVG />
            <Text marginTop="5px" {...normalText} fontFamily="Gotham Bold" color="purple.200">{text}</Text>
        </Box>
    )
}

const PharamaSideEffects = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Stack spacing={4}>
            <Text {...normalText}>
                Women may ask you about the possible side effects associated with Hana, so it’s important that you’re aware of them.
            </Text>
            <Text {...normalText}>
                When talking about side effects, begin by acknowledging that <Text as="span" fontFamily="Gotham Bold" color="purple.200">like all medicines</Text>, Hana can indeed cause <Text as="span" fontFamily="Gotham Bold" color="purple.200">side effects</Text>, but clarify that <Text as="span" fontFamily="Gotham Bold" color="purple.200">not everybody gets them</Text> and that the product is <Text as="span" fontFamily="Gotham Bold" color="purple.200">generally well-tolerated.</Text><sup>1,2</sup> 
            </Text>
            <Text {...normalText}>
                Here are the <Text as="span" fontFamily="Gotham Bold" color="purple.200">most common side effects</Text>, which affect <Text as="span" fontFamily="Gotham Bold" color="purple.200">up to 1 in 10 women:</Text><sup>1</sup>
            </Text>

            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                <Card SVG={Headache} text="headache" />
                <Card SVG={Sick} text="nausea" />
                <Card SVG={Mood} text="altered/depressed mood" />
                <Card SVG={Sex} text="decreased sex drive" />
                <Card SVG={Period} text="changes to periods" />
                <Card SVG={Breast} text="breast pain" />
                <Card SVG={Acne} text="acne" />
                <Card SVG={Weight} text="increased body weight" />
            </Box>

           

            <Box border="1px" borderColor="purple.200" borderRadius="23px" padding="6">
                <Text {...normalText} color="purple.200" fontFamily="Gotham Bold">
                   Changes to periods
                </Text>
                <Text {...normalText} margin="20px 0">
                    Some women may experience changes to their periods when taking Hana, and may need reassurance about this. Be prepared to provide a short description of how women’s periods could change when taking Hana:<sup>1</sup>
                </Text>
                <List spacing="2">
                    <ListItem 
                    {...referenceText}
                    paddingLeft="20px"
                    position="relative"
                    display="block"
                    _before={{
                    ...purpleBullet,
                    top: "-40px"
                    }}
                    >
                    periods may be <Text as="span" fontFamily="Gotham Bold" color="purple.200">irregular</Text> – less or more frequent, shorter or longer, lighter or heavier than before Hana
                    </ListItem>
                    <ListItem 
                    {...referenceText}
                    paddingLeft="20px"
                    position="relative"
                    display="block"
                    _before={{
                    ...purpleBullet,
                    top: "-40px"
                    }}
                    >
                    <Text as="span" fontFamily="Gotham Bold" color="purple.200">spotting or bleeding</Text> when the <Text as="span" fontFamily="Gotham Bold" color="purple.200">woman is not on her period</Text> – from slight staining that may not require a pad, to heavier bleeding like a light period that may require a sanitary towel or tampon
                    </ListItem>
                    <ListItem 
                    {...referenceText}
                    paddingLeft="20px"
                    position="relative"
                    display="block"
                    _before={{
                    ...purpleBullet,
                    top: "-40px"
                    }}
                    >
                    periods may <Text as="span" fontFamily="Gotham Bold" color="purple.200">stop altogether</Text>
                    </ListItem>
                </List>
            </Box>
            


            <Box {...bkLightRed} padding="8" position="relative" marginTop="10px">
                <Box position="absolute" top="-10px" left="-12px">
                    <Info name="info" borderRadius="50%" border="2px solid #fff" backgroundColor="#fff" color="#F0CED5" size="40px" />
                </Box>
                <Text {...normalText}>
                    Be sure to check whether women have any concerns about possible side effects, and reassure them that they can always report any side effects to their pharmacist or doctor; be aware that repeated bleeding after sex should always be investigated<sup>1,2</sup>
                </Text>
            </Box>
            <Text as="u" {...referenceLink} onClick={onOpen} >References</Text>

            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" spacing="4" styleType="decimal">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Patient Information Leaflet, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
            </Stack>
        </Layout>
    )
}

export default PharamaSideEffects