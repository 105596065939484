import React, { useEffect } from 'react'
import { Box, Text, Heading, Button, Icon } from '@chakra-ui/core'

// Load components
import Checkbox from '../../components/UI/Checkbox/Checkbox'

// Load hook
import useInputHook from '../../hooks/useInputHook'

// Load style
import { normalText, bkglight, headingText, submitBtn} from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const questionTwo = {
    q1: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: false
            }
        }

    },
    q2: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    },
    q3: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: true
            }
        }
    },
    q4: {
        value: false,
        validation: {
            required: false,
            touched: false,
            valid: false,
            rules: {
                anwser: false
            }
        }
    }
}

const Card = ({content, checkbox, ...props}) => {
    return (
        <Box {...bkglight} display="flex" {...props} alignItems="center" justifyContent="space-between" width={{base: "100%", lg: "48%"}}>
            <Text {...normalText} maxWidth="90%">
                {content}
            </Text>
             {checkbox}
        </Box>
    )
}

const QuestionTwo = ({ reveal, setNext, changePage }) => {
   const [form, inputCheckHandler, allowNext] = useInputHook(questionTwo)
  
   // Form fields
   const { q1, q2, q3, q4 } = form

   useEffect(() => {
    // Allow next is one answer been selected
    setNext(allowNext)
}, [allowNext, setNext])
 
    return (
        <Layout>
            <Heading {...headingText} margin="10px 0">
                How should Hana be taken to maximise effectiveness?
            </Heading>

            <Text {...normalText} marginBottom="20px">
                Select ALL that apply
            </Text>
            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
              <Card 
                content='For 21 days with a 7-day break at the end of the pack'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={form.q1.value} fail={q1.validation.valid} name="q1" id="q1" />}
                />
                {(reveal && q1.value) && <Box width={{base: "100%", lg: "48%"}}>
                    <Text {...normalText} color="red.900">
                    <strong>INCORRECT</strong> - Hana should be taken every day with no break between packs
                    </Text>
                </Box>
                }
            </Box>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
                <Card
                border={(reveal && !q2.value) ? "1px solid #DF6532" : "1px solid #9894C6"}
                content='Continuously. Every day without a break between packs'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={reveal ? reveal : form.q2.value} success={q2.validation.valid} amber={!form.q2.value} name="q2" id="q2" />}
                />
                {reveal &&<Box width={{base: "100%", lg: "48%"}}>
                    <Text {...normalText} color={q2.value ? "green.900" : "orange.900"}>
                        <strong>CORRECT</strong> - Hana should be taken every day with no break between packs
                    </Text>
                </Box>}
            </Box>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
                <Card
                border={(reveal && !q3.value) ? "1px solid #DF6532" : "1px solid #9894C6"}
                content='At the same time each day'
                checkbox={<Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={reveal ? reveal : form.q3.value} success={q3.validation.valid} amber={!form.q3.value} name="q3" id="q3" />}
                />

               {reveal && <Box width={{base: "100%", lg: "48%"}}>
                    <Text {...normalText} color={q3.value ? "green.900" : "orange.900"}>
                        <strong>CORRECT</strong> - Hana should be taken at the same time each day, or within 12 hours, to maintain the maximum contraceptive effect
                    </Text>
                </Box>
                }
            </Box>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between">
                <Card 
                content='Every day, can be at a different time each day'
                checkbox={ <Checkbox reveal={reveal} disabled={reveal} on_change={inputCheckHandler} checked={form.q4.value} fail={q4.validation.valid} name="q4" id="q4" />}
                />
                {(reveal && q4.value) && <Box width={{base: "100%", lg: "48%"}}>
                    <Text {...normalText} color="red.900">
                        <strong>INCORRECT</strong> - Hana should be taken at the same time each day, or within 12 hours, to maintain the maximum contraceptive effect
                    </Text>
                </Box>
                }
            </Box>
             {!reveal &&
            <Box display="flex" width={{base: "100%", lg: "48%"}} justifyContent="flex-end">
            <Button 
            {...submitBtn}
            onClick={changePage}
            >Submit<Icon name="chevron-right" fontSize="32px" /></Button>
            </Box>
            }
        </Layout>
    )
}

export default QuestionTwo