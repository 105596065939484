import React, { useState, useEffect } from 'react'
import { Link as ReachLink, useHistory } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Box, Text, Heading, Button, Stack, Image, AspectRatioBox, List, ListItem, Link, useDisclosure, Modal, ModalBody, ModalContent, ModalOverlay, ModalCloseButton } from '@chakra-ui/core'

// Load assets
import headerBk from '../../images/landingHeader.png'
import HanaProduct from '../../images/HANA_Packshot.png'
import Ipad from '../../images/ipadNew.png'
import CheckList from '../../images/Hana_Checklist_New.png'
import resourcesbk from '../../images/resources-bg.svg'
import tablet2 from '../../images/tablet2.png'
import Orange from '../../images/Orange.svg'
import Blue from '../../images/Light-Blue.svg'
import Purple from '../../images/Parple.svg'
import {ReactComponent as HanaNavy} from '../../images/Hana_Logo_Navy_Tag.svg'

// Load pdf
import guide from '../../pdf/Hana_Pharmacy_Guide.pdf'
import checklist from '../../pdf/Pharmacy_supply_aid_checklist.pdf'
import algorithm from '../../pdf/Pharmacy_supply_algorithm.pdf'
import pregnancytool from '../../pdf/Pregnancy_exclusion_tool.pdf'
import PIL from '../../pdf/PIL.pdf'
import SmPC from '../../pdf/SmPC.pdf'
import leaflet from '../../pdf/HRA_Contraceptive_Options_Leaflet.pdf'


// Load styles
import { normalText, purpleBullet } from '../../style/styleFormat'

// Load components
import Footer from '../Footer/Footer'

const headingText = {
    color: "purple.100",
    fontSize: {base: "lg", lg: "30px"},
    lineHeight: "tighter"
}

const buttonPrimary = {
    backgroundColor:"orange.900",
    borderRadius:"25px",
    color:"#fff",
    fontFamily:"Gotham Bold",
    fontSize: {base: "sm", sm: "sm", lg: "16px"},
    textTransform: "uppercase"
}

const WelcomePopUp = ({ closeWelcome }) => {
    return (
        <Box width="100%" height="100%" padding="6">
            <Heading {...headingText} marginBottom="10px" fontSize="20px">
                Welcome to the Hana Pharmacy Resource Hub
            </Heading>
            <Text {...normalText} marginBottom="20px">
                Here you will find information about Hana and support for supplying it in pharmacy.
            </Text>

            <Box width="100%" height="auto" display="flex" justifyContent="space-between" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start">
                <Box width={{base: "100%", lg: "45%"}} minHeight="200px" marginBottom="40px" borderRadius="21px" border="1px solid #9894C6" height="100%" backgroundColor="#F8F7FC" padding="4" position="relative">
                    <Heading {...headingText} marginBottom="10px" fontSize="20px">
                        I’m a UK healthcare professional
                    </Heading>
                    <Text {...normalText} marginBottom="10px">
                        This site is intended for UK healthcare professionals. By entering this site, you are confirming that you are a UK healthcare professional. This site may contain promotional information.
                    </Text>
                    <Button onClick={() => closeWelcome(false)} {...buttonPrimary} fontSize="12px" position="absolute" bottom="-15px" left="50%" transform="translateX(-50%)">
                        Continue to Pharmacy site
                    </Button>
                </Box>

                <Box width={{base: "100%", lg: "45%"}} minHeight="200px" borderRadius="21px" border="1px solid #9894C6" backgroundColor="#F8F7FC" height="100%" padding="4" position="relative">
                    <Heading {...headingText} marginBottom="10px" fontSize="20px">
                        I’m a member of the UK public
                    </Heading>
                    <Text {...normalText} marginBottom="10px">
                        This site is intended for members of the UK public.
                    </Text>
                    <Button {...buttonPrimary} fontSize="12px" position="absolute" bottom="-15px" left="50%" transform="translateX(-50%)">
                        <Link href="http://hana.co.uk/" target="_self" isExternal>Continue to Consumer site</Link>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export const TrainingMaterial = () => {
    return (
        <Box display="flex" flexDirection={{base: "column", lg:"row"}} alignItems="flex-start" justifyContent="space-around">
            <List spacing="4" width={{base: "100%", lg:"33%"}}>
                <Heading {...headingText} marginBottom="10px" fontSize="20px">Training Materials</Heading>
                <ListItem
                {...normalText}
                display="block"
                paddingLeft="30px"
                position="relative"
                lineHeight="wide"
                fontSize="16px"

                _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                >
                   <Link href={guide} target="_blank" rel="noopener noreferrer"> Pharmacy guide</Link>
                </ListItem>
                <ListItem
                {...normalText}
                display="block"
                paddingLeft="30px"
                position="relative"
                lineHeight="wide"
                fontSize="16px"

                _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                >
                   <Link as={ReachLink} to="/training">Online pharmacy training</Link>
                </ListItem>
            </List>
            <List spacing="4" width={{base: "100%", lg:"33%"}} margin={{base: "20px 0", lg: "0"}}>
                <Heading {...headingText} marginBottom="10px"  fontSize="20px">
                    In-Pharmacy Resources
                </Heading>
                <ListItem
                {...normalText}
                display="block"
                paddingLeft="30px"
                position="relative"
                lineHeight="wide"
                fontSize="16px"

                _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                >
                    <Link href={checklist} target="_blank" rel="noopener noreferrer" >Pharmacy supply aid checklist</Link>
                </ListItem>
                <ListItem
                {...normalText}
                display="block"
                paddingLeft="30px"
                position="relative"
                lineHeight="wide"
                fontSize="16px"

                _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                >
                   <Link href={algorithm} target="_blank" rel="noopener noreferrer">Pharmacy supply algorithm</Link>
                </ListItem>
                <ListItem
                {...normalText}
                display="block"
                paddingLeft="30px"
                position="relative"
                lineHeight="wide"
                fontSize="16px"

                _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                >
                    <Link href={pregnancytool} target="_blank" rel="noopener noreferrer">Pregnancy exclusion tool</Link>
                </ListItem>
                <ListItem
                {...normalText}
                display="block"
                paddingLeft="30px"
                position="relative"
                lineHeight="wide"
                fontSize="16px"

                _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                >
                    <Link href={SmPC} target="_blank" rel="noopener noreferrer" isExternal>SmPC</Link>
                </ListItem>
                <ListItem
                {...normalText}
                display="block"
                paddingLeft="30px"
                position="relative"
                lineHeight="wide"
                fontSize="16px"

                _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                >
                    <Link href={PIL} target="_blank" rel="noopener noreferrer" isExternal>PIL</Link>
                </ListItem>

                <ListItem
                {...normalText}
                display="block"
                paddingLeft="30px"
                position="relative"
                lineHeight="wide"
                fontSize="16px"

                _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                >
                    <Link href={leaflet} target="_blank" rel="noopener noreferrer" isExternal>Contraceptive options leaflet</Link>
                </ListItem>
            </List>
            <List spacing="4" width={{base: "100%", lg:"33%"}}>
                <Heading {...headingText} marginBottom="10px" fontSize="20px">
                    Point-of-Sale Materials
                </Heading>
                <ListItem
                {...normalText}
                display="block"
                paddingLeft="30px"
                position="relative"
                lineHeight="wide"
                fontSize="16px"

                _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                >
                    <Link href="mailto:hanapos@precision.co.uk" target="_blank" rel="noopener noreferrer">Click here to email us to request point-of-sale materials for your pharmacy </Link>
                    <Text fontSize="16px">(or alternatively email: <Link href="mailto:hanapos@precision.co.uk" target="_blank" rel="noopener noreferrer">hanapos@precision.co.uk</Link>)</Text>
                </ListItem>
            </List>
        </Box>
    )
}

const LandingPage = () => {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [showWelcome, setWelcome] = useState(true)
    const history = useHistory()

    useEffect(() => {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
    }, [])

    return (
        <Box backgroundColor="#f4f1e8" minHeight="100vh">
            {/** HEADER */}
            <AspectRatioBox backgroundColor="#f4f1e8"  maxWidth="100vw" minHeight={{base: "390px", md:"480px"}} maxHeight={{base: "773px", lg: "773px"}} ratio={1}>
                <Box>
                    <Box as="header" padding={{base: 6, lg: 16}} backgroundImage={`url(${headerBk})`} boxShadow="0 0 50px 50px #f4f1e8 inset" backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center" height="100%" width="100%" maxWidth="1600px" margin="0 auto">
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                            <Box width={{base:"25%", lg: "15%"}} maxWidth={{base: "100px",  lg:"150px"}}>
                                <HanaNavy />
                            </Box>
                            <Button onClick={onOpen} {...buttonPrimary} display="flex" minHeight="51px"  alignItems="center" justifyContent="center">
                                    Resources
                                <Box marginLeft="10px" marginBottom="2px" fontSize="lg" as={GiHamburgerMenu} />
                            </Button>
                        </Box>

                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                            <Heading {...headingText}>
                                WELCOME
                            </Heading>
                            <Image margin="20px 0" src={HanaProduct} Width="50%" maxWidth={{base: "50vw", lg:"600px"}}  alt="Hana package illustration" />
                            <Heading textAlign="center" fontSize={{base: "70px", lg:"128px"}} fontWeight="300" lineHeight={{base: "55px", lg:"80px"}} fontFamily="kokomo_breezeregular" color="rgb(46, 28, 69)">
                                Pharmacy <br/> Resource Hub
                            </Heading>
                            <Button {...buttonPrimary} minHeight="40px" marginTop="10px">
                                <Link as={ReachLink} to="/training" _hover={{textDecoration: "none"}}>Start Training</Link>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </AspectRatioBox>

            {/** Section - Intro */}
            <Box as="section" padding="6" mt={4} background="linear-gradient(180deg, #f4f1e8 0%, #F6CDD5 100%)">
                <Stack spacing="4">
                    <Text {...normalText} textAlign="center">
                        On this page you’ll find useful information and resources for making sure your pharmacy and pharmacy team are prepared for the appropriate supply of Hana to women.
                    </Text>
                    <Text {...normalText} textAlign="center">
                        We’re updating the page with helpful content all the time, so check back regularly.
                    </Text>
                </Stack>
            </Box>
            {/** Section - Training material */}
            <Box as="section" background="#F6CCD5" position="relative" width="100%" display="flex" justifyContent="space-between" alignItems="center" flexDirection={["column", "column", "column","row"]} minHeight="600px">
                <Box background={`url(${Blue})`} backgroundPosition="left" backgroundSize="contain" paddingLeft={{base: "0", lg: "5%"}} backgroundRepeat="no-repeat" width={{ base: "100%", lg: "45%"}} maxWidth="800px" display="flex" alignItems="center" alignSelf={{base: "baseline", lg: "center"}} justifyContent="center">
                    <Image src={Ipad} maxHeight={{base:"300px", lg: "500px"}} zIndex={10}  />
                </Box>
                
                {/* <Box position="relative"  width={{sm: "100%", lg:"48%"}}  height="100%" display="flex" alignItems="center" maxHeight="600px" justifyContent="center">
                    <Image src={Ipad} maxHeight={{base:"300px", lg: "500px"}} zIndex={10}  />
                    <Image src={Blue} maxHeight={{base:"300px", lg: "400px"}} zIndex={5} position="absolute" top="0" left="0" alt="shapes design" />
                </Box> */}
    
                <Box padding="8" paddingRight={{base: "8", lg: "5%"}} width={{base: "100%", lg:"48%"}} maxWidth="800px">
                    <Heading {...headingText} textTransform="uppercase">
                        Training materials
                    </Heading>
                    <Text {...normalText} fontSize="16px" color="purple.100" margin="20px 0">
                        Everything your pharmacy needs to train staff effectively.
                    </Text>
                    <List spacing="4">
                        <ListItem
                        {...normalText}
                        paddingLeft="30px"
                        position="relative"
                        lineHeight="wide"
                        fontSize="16px"

                        _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                        >
                            Build and test your own knowledge of Hana and its appropriate supply and safe use through this accredited training – download the <Link href={guide} rel="noopener noreferrer" target="_blank" textDecoration="underline">Pharmacy guide</Link>, or do the training online
                        </ListItem>
                    </List>

                    <Button {...buttonPrimary} width={{base:"100%", lg: "50%"}} minHeight="50px" margin="20px 0" border="1px solid #fff">
                        <Link as={ReachLink} to="/training" _hover={{textDecoration: "none"}}>Start Training</Link>
                    </Button>
                </Box>
            </Box>
            <Box as="section" padding="6" background="linear-gradient(0deg, #6F9BBD 0%, #F5CCD5 100%)" height="50px" width="100%"></Box>

            <Box as="section" id="webinar" background="#6F9BBD" width="100%" display="flex" alignItems="center" justifyContent="space-between" flexDirection={["column", "column", "column","row-reverse"]} minHeight="600px">
                <Box background={`url(${Purple})`} backgroundPosition="right" backgroundSize="contain" paddingRight={{base: "0", lg: "5%"}} backgroundRepeat="no-repeat" width={{ base: "100%", lg: "40%"}} maxWidth="800px" display="flex" alignItems="center" alignSelf={{base: "end", lg: "center"}} justifyContent="center">
                    <Image src={tablet2} maxHeight={{base:"300px", lg: "500px"}} zIndex={10}  />
                </Box>
                
                {/* <Box position="relative"  width={{base: "100%", lg:"48%"}} height="100%" display="flex" alignItems="center" maxHeight="600px" justifyContent="center">
                    <Image src={tablet2} maxHeight={{base:"300px", lg: "500px"}} zIndex={10}  />
                    <Image src={Purple} maxHeight={{base:"300px", lg: "400px"}} zIndex={5} position="absolute" top="0" right="0" alt="shapes design" />
                </Box> */}
                <Box padding="8" paddingLeft={{base: "8", lg: "5%"}} width={{base: "100%", lg:"48%"}} maxWidth="800px">
                    <Heading {...headingText} textTransform="uppercase" color="white" mb="4">
                        Webinar Toolkit
                    </Heading>
                    <Text {...normalText} fontSize="16px" color="white" mb="4">
                        All the practical information you need from the Hana launch webinars.
                    </Text>
                    <List spacing="4">
                        <ListItem
                         {...normalText}
                        color="white"
                        paddingLeft="30px"
                        position="relative"
                        lineHeight="wide"
                        fontSize="16px"
                        _before={{...purpleBullet, fontSize: "75px", color: "white"}}
                        >
                            Learn about case study scenarios and considerations for supply by accessing the <Link as={ReachLink} to="/webinar/presentation" textDecoration="underline">webinar slides</Link>
                        </ListItem>

                        <ListItem
                         {...normalText}
                        color="white"
                        paddingLeft="30px"
                        position="relative"
                        lineHeight="wide"
                        fontSize="16px"
                        _before={{...purpleBullet, fontSize: "75px", color: "white"}}

                        >
                            Find answers to questions raised during the <Link as={ReachLink} to="/webinar/qa" textDecoration="underline">webinar Q&A session</Link>
                        </ListItem>
                    </List>

                    <Box mt="10" display="flex" flexDirection="row" justifyContent="space-between">
                        <Button {...buttonPrimary} onClick={() => history.push('/webinar/presentation')} width="48%" border="1px solid #fff">
                            See the slides
                        </Button>
                        <Button  {...buttonPrimary} onClick={() => history.push('/webinar/qa')} width="48%" border="1px solid #fff">
                            Browse the Q&A
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box as="section" padding="6" background="linear-gradient(0deg, #CCCEE6 0%, #6F9BBD 100%)" height="50px" width="100%"></Box>

            {/** Section - Pharmacy resources */}
            <Box as="section" id="resources" backgroundColor="#CCCEE6" width="100%" display="flex" alignItems="center" justifyContent="space-between" flexDirection={["column", "column", "column","row"]} minHeight="600px">
                <Box background={`url(${Orange})`} backgroundPosition="left" backgroundSize="contain" paddingLeft={{base: "0", lg: "5%"}} backgroundRepeat="no-repeat" width={{ base: "100%", lg: "40%"}} maxWidth="800px" display="flex" alignItems="center" alignSelf={{base: "baseline", lg: "center"}} justifyContent="center">
                    <Image src={CheckList} maxHeight={{base:"300px", lg: "500px"}} zIndex={10}  />
                </Box>
                
                {/* <Box position="relative"  width={{base: "100%", lg:"48%"}}  height="100%" display="flex" alignItems="center" maxHeight="600px" justifyContent="center">
                    <Image src={CheckList} maxHeight={{base:"300px", lg: "400px"}} zIndex={10}  />
                    <Image src={Orange} maxHeight={{base:"300px", lg: "400px"}} zIndex={5} position="absolute" top="0" left="0" alt="shapes design" />
                </Box> */}

                <Box padding="8" paddingRight={{base: "8", lg: "5%"}} width={{base: "100%", lg:"48%"}} maxWidth="800px" >
                    <Heading {...headingText} textTransform="uppercase">
                        In-pharmacy resources
                    </Heading>
                    <Text {...normalText} fontSize="16px" color="purple.100" margin="20px 0">
                        Useful resources for you to have within easy reach to aid your consultations and decision-making in pharmacy.
                    </Text>
                    <List spacing="4">
                        <ListItem
                        {...normalText}
                        paddingLeft="30px"
                        position="relative"
                        lineHeight="wide"
                        fontSize="16px"

                        _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                        >
                            Comprising a checklist for women and a corresponding supply aid for pharmacists, download the optional <Link href={checklist} rel="noopener noreferrer" target="_blank" textDecoration="underline" download>Pharmacy supply aid checklist</Link> to guide your consultations
                        </ListItem>
                        <ListItem
                        {...normalText}
                        paddingLeft="30px"
                        position="relative"
                        lineHeight="wide"
                        fontSize="16px"

                        _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                        >
                            For support with excluding pregnancy before supply, download the <Link href={pregnancytool} rel="noopener noreferrer" target="_blank" textDecoration="underline" download>Pregnancy exclusion tool</Link>
                        </ListItem>
                        <ListItem
                        {...normalText}
                        paddingLeft="30px"
                        position="relative"
                        lineHeight="wide"
                        fontSize="16px"

                        _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                        >
                        Download the <Link href={algorithm} rel="noopener noreferrer" target="_blank" textDecoration="underline" download>Pharmacy supply algorithm</Link> for an overview of questions and responses in different supply situations
                        </ListItem>
                        <ListItem
                        {...normalText}
                        paddingLeft="30px"
                        position="relative"
                        lineHeight="wide"
                        fontSize="16px"

                        _before={{...purpleBullet, fontSize: "75px", color: "#6C66AC"}}
                        >
                        Download the current Hana <Link href={SmPC} download rel="noopener noreferrer" target="_blank" textDecoration="underline">Summary of Product Characteristics</Link> or <Link href={PIL} download rel="noopener noreferrer" target="_blank" textDecoration="underline">Patient Information Leaflet</Link> for reference
                        </ListItem>
                    </List>
                </Box>
            </Box>
             {/* <Box padding="12" width="100%" backgroundColor="#CCCEE6" >
                <Box textAlign="center">
                    <Text display="inline-block" borderRadius="36px" padding="26px" backgroundColor="#fff" textAlign="center">
                        <Link href="mailto:hanapos@precision.co.uk" target="_blank" rel="noopener noreferrer"  textDecoration="underline" variant="solid" fontSize="16px" lineHeight="18px" whiteSpace="normal" color="#2E1C45">
                            Click here to email us to request point-of-sale materials for your pharmacy
                        </Link>
                    </Text>
                        <Text textAlign="center" fontSize="16px" mt={2}>
                            (or alternatively email: hanapos@precision.co.uk)
                    </Text>
                </Box>
            </Box> */}


            {/** Footer */}
            <Box as="footer" backgroundColor="#CCCEE6">
              <Footer productCode="0301" />
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                    <ModalContent borderRadius="25px" background={`linear-gradient(to bottom, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.73)), url(${resourcesbk})`} backgroundPosition="center" backgroundSize="auto" minHeight="25vh" maxWidth="80%" position="relative">
                        <ModalCloseButton />
                        <ModalBody padding="8">
                         <TrainingMaterial />
                        </ModalBody>
                    </ModalContent>
            </Modal>

            {/**Custom on mount modal */}
            {showWelcome &&
            <Box height="100%" width="100%">
                <Box position="fixed" top="0" left="0" backgroundColor="black" opacity=".5" width="100%" height="100%" zIndex="8000" />
                <Box zIndex="8000" overflowY="auto" position="absolute" top={{base: "200px", lg:"300px"}} left="50%" transform="translateX(-50%)"  borderRadius="25px" background={`linear-gradient(to bottom, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.73)), url(${resourcesbk})`} backgroundPosition="center" backgroundSize="auto" width={{base: "95%", lg:"80%"}} minHeight="25vh" maxWidth="1600px">
                    <WelcomePopUp closeWelcome={setWelcome} />
                </Box>
            </Box>
            }
        </Box>
    )
}

export default LandingPage