import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom'
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure } from '@chakra-ui/core'
import Cookies from 'js-cookie'

// Load helper
import scrollInView from '../../helper/scrollView'

// Load content
import { pharmaTitles } from '../../content/pageTitles'

// Load assets
import PartOneBk from '../../images/bg1.png'
import {ReactComponent as Logo } from '../../images/Hana_Logo_White.svg'

// Load components
import SideNav from '../SideNav/SideNav'
import Navigation from '../Navigation/Navigation'
import { PharmaPassed } from '../Passed/Passed'
import Failed from '../Failed/Failed'

// Containers
import PharmaModule from '../../containers/PharmacyModule/PharmacyModule'
import PharmaAssessment from '../../containers/Assessment/PharmaAssessment'

// Load layout
import Layout from '../../layout/Layout.base'

function PharmaLearning() {
  const MAX_PAGE = 20
  const [page, setPage] = useState(0)
  const [correct, setCorrect] = useState(0)
  const [allowNext, setAllow] = useState(false)
  const passed = correct >= 5
  const fail = correct < 5

  const history = useHistory()

  // Mobile menu
  const { isOpen, onClose, onOpen } = useDisclosure()

  // Control sideDrawer display
  const [open, setOpen] = useState(false)

  function reload(page) {
    setPage(page)
  }


  // SideDrawer open index
  function sidedrawer(index) {

      if (open === index) {
         return setOpen(false)
      }

     return setOpen(index)
  }

  useEffect(() => {
    scrollInView()
  }, [page])

  useEffect(() => {
    const progress = Cookies.getJSON('hana-progress-pharmacy')
    if (progress) {
       setPage(parseInt(progress.page))
       setCorrect(parseInt(progress.correct))
       history.push(progress.url)
    }

  }, [history])

    // Change label before revealing anwsers
    const label = [10, 12, 14, 16, 18].includes(page) ? 'Show Answer' : 'Next'

    // Page control
    function changePage(direction) {

        if (!allowNext && [10,12,14,16,18].includes(page) && direction === 'increment') {
           return alert('Please select at least one answer.')
        }

        // Dont no progress futher than the pages we have
        if (direction === 'increment' && page === MAX_PAGE) {
          return
        }

        // Stop previous when at start
        if (direction === 'decrement' && page === 0) {
            return
        }

        if (direction === 'increment' && page === 9) {
          setPage(prevPage => prevPage +1)
          history.push('/teamtraining/test')
          return
        }

        // Some route changes at decrement
        if (direction === 'decrement' && page === 10) {
            history.push('/teamtraining')
            setPage((prevPage => prevPage - 1))
            return
        }

        // Handle next action
        if (direction === 'increment') {
            setPage(prevPage => prevPage + 1)
        }

        // Handle previous action
        if (direction === 'decrement') {
            setPage((prevPage => prevPage - 1))
        }

      return
  }

  function saveProgress() {
    Cookies.set('hana-progress-pharmacy', {page, correct, url: history.location.pathname }, { expires: 30})
  }

  function restart() {

    // reset score
    setCorrect(0)

    // set route
    history.push('/teamtraining/test')

    // back to assessment
    setPage(10)

    // clear cookie progress and reset
    Cookies.remove('hana-progress-pharmacy');
  }

  return (
      <Layout sideNav={<SideNav hasMenu={false} maxPage={MAX_PAGE} progress={page} setPage={setPage} open={open} sidedrawer={sidedrawer} setOpen={setOpen} />}>
        <Navigation maxPage={MAX_PAGE} productCode="0059" stopPrevious={10} openDrawer={onOpen} title={pharmaTitles[page]} passed={passed} background={PartOneBk} save={saveProgress} pageNumber={page} nextLabel={label} navControl={changePage}>
        <Switch>
          <Route exact path="/teamtraining" render={() => <PharmaModule setPage={setPage} setOpen={setOpen} currentPage={page} navControl={changePage} allowNext={setAllow} correct={correct} setAnswer={setCorrect} />} />
          <Route exact path="/teamtraining/test" render={() => <PharmaAssessment setPage={setPage} reload={reload}  currentPage={page} navControl={changePage} allowNext={setAllow} correct={correct} setAnswer={setCorrect} />} />
          <Redirect to="/teamtraining" />
        </Switch>
        {(MAX_PAGE === page && passed) && <PharmaPassed />}
        {(MAX_PAGE === page && fail) && <Failed isPharmacy correct={correct} restart={restart} />}
        <Drawer onClose={onClose} isOpen={isOpen} size="full" placement="left">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader backgroundColor="#5D599E">
                <Logo width="40%" />
              <DrawerCloseButton color="#fff" />
            </DrawerHeader>
            <DrawerBody overflowY="auto" padding="0" backgroundColor="#5D599E">
              <SideNav hasMenu={false} progress={page} setPage={setPage} open={open} sidedrawer={sidedrawer} setOpen={setOpen} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        </Navigation>
      </Layout>
  );
}

export default PharmaLearning;