import React from 'react'
import { Box, PseudoBox, Text, ListItem, List, Stack } from '@chakra-ui/core'

// Load components
import Reference from '../../components/UI/Typography/Reference'

// Load assets
import {ReactComponent as ArrowAngle } from '../../images/arrowAngle.svg'
import {ReactComponent as ArrowRight } from '../../images/arrowRight.svg'
import {ReactComponent as ArrowDouble } from '../../images/doubleArrow.svg'

// Load style
import { normalText, bkLightRed } from '../../style/styleFormat'


const circle = {
    position: 'absolute',
    top: '-90px',
    left: '50%',
    transform: 'translateX(-50%)',
    border: '1px solid #463D90',
    height: '108px',
    width: '108px',
    background: '#fff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

const highlight = {
    color: "#7162B6",
    fontWeight: "bold",
    fontFamily: "Gotham Bold",
    fontSize: "md"
}

const DrugInteractions = () => (
    <Box {...bkLightRed} display="flex" flexDirection={{base: "column", lg:"row"}} justifyContent="space-between" marginTop="60px" marginBottom="20px" padding="8">
    <Box width={{base: "100%", lg:"32%"}} padding={{base: "0", lg: "4"}} position="relative">
        <Box {...circle}>
            <ArrowAngle />
        </Box>
        <Box marginBottom="20px"></Box>
        <PseudoBox 
        as="div"
        position="relative" 
        height="100%"
        _after={{
            position: 'absolute',
            content: "''",
            top: 0,
            right: "-8%",
            width: '1px',
            height: '100%',
            backgroundColor: '#4B3F8D',
        }}
        >
    <Stack spacing="4" >
        <Text {...normalText}>
        Substances may <Text as="span" {...highlight}>increase the clearance of contraceptive hormones </Text>
        by enzyme induction, which may lead to breakthrough bleeding and/or contraceptive <span style={{position: 'relative'}}>failure.<Reference>1</Reference></span>
        </Text>
        <Text {...highlight}>
        Women need to use additional precautions during treatment with and for 28 days after stopping these enzyme-inducing medicines. If these medicines are used chronically or long term, Hana may not be the most appropriate method of contraception and the woman should be referred to her doctor for further advice<span style={{position: 'relative'}}>. &nbsp;<Reference>1,2</Reference></span>
        </Text>
        <Text {...normalText} color="#7162B6">
            These medicines include treatments <span style={{position: 'relative'}}>for: &nbsp;<Reference>1,2</Reference></span>
        </Text>

        <List spacing="4">
            <ListItem 
            {...normalText}
            paddingLeft="30px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '2px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '54px'
                }}
            >
                epilepsy (e.g. primidone, phenytoin, carbamazepine, oxcarbazepine, felbamate, topiramate, phenobarbital)
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="30px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '2px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '54px'
                }}
            >
                tuberculosis (e.g. rifampicin, rifabutin)
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="30px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '2px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '54px'
                }}
            >
                HIV infections (e.g. efavirenz)
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="30px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '2px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '54px'
                }}
            >
                pulmonary arterial hypertension (e.g. bosentan)
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="30px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '2px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '54px'
                }}
            >
                depression (the herbal remedy St. John’s Wort)
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="30px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '2px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '54px'
                }}
            >
                fungal infections (e.g. griseofulvin)
            </ListItem>
        </List>
    </Stack>
</PseudoBox>
</Box>

<Box width={{base: "100%", lg:"32%"}} padding={{base: "0", lg: "4"}}  marginTop={{base: "100px", lg: "0"}} position="relative">
   <Box {...circle}>
    <ArrowDouble />
   </Box>
   <Box marginBottom="20px"></Box>
    <Stack spacing="4">
        <Text {...normalText}>
            Substances may have <Text as="span" {...highlight}>variable effects on the clearance of contraceptive hormones,</Text> increasing or decreasing the plasma concentrations of progestins, which may have a clinically relevant net effect in some <span style={{position: 'relative'}}>cases.<Reference>1</Reference></span>
        </Text>
        <Text {...highlight}>
        In case of doubt, women should use additional precautions during treatment with these medicines. If these medicines are used chronically or long term, Hana may not be the most appropriate method of contraception and the woman should be referred to her doctor for further advice<span style={{position: 'relative'}}>. &nbsp;<Reference>1,2</Reference></span>
        </Text>
        <Text {...normalText} color="#7162B6">
            These medicines include treatments <span style={{position: 'relative'}}>for: &nbsp;<Reference>1,2</Reference></span>
        </Text>
        <List spacing="4">
            <ListItem 
            {...normalText}
            paddingLeft="30px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '2px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '54px'
                }}
            >
                HIV infections (e.g. ritonavir, nelfinavir, nevirapine)
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="30px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '2px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '54px'
                }}
            >
                hepatitis C virus infection (e.g. boceprevir, telaprevir)
            </ListItem>
        </List>
    </Stack>
</Box>

<Box width={{base: "100%", lg:"32%"}} padding={{base: "0", lg: "4"}}  marginTop={{base:"100px", lg: "0"}} position="relative">
    <Box {...circle}>
        <ArrowRight />
    </Box>
    <Box marginBottom="20px"></Box>
    <PseudoBox 
    as="div"
    position="relative" 
    height="100%"
    _before={{
        position: 'absolute',
        content: "''",
        top: 0,
        left: "-8%",
        width: '1px',
        height: '100%',
        backgroundColor: '#4B3F8D',
    }}
    >
    <Stack spacing="4">
        <Text {...normalText}>
            Substances may <Text as="span" {...highlight}>decrease the clearance of contraceptive hormones</Text> by enzyme inhibition, increasing the serum concentrations of progestins including the active metabolite of desogestrel, the clinical relevance of which remains <span style={{position: 'relative'}}>unknown.<Reference>1</Reference></span>
        </Text>
        <Text {...highlight}>
            If these medicines are used chronically or long term, the woman should be referred to her doctor for further advice<span style={{position: 'relative'}}>.&nbsp;&nbsp;<Reference>1,2</Reference></span>
        </Text>
        <Text {...normalText} color="#7162B6">
            These medicines include treatments <span style={{position: 'relative'}}>for: &nbsp;<Reference>1,2</Reference></span>
        </Text>
        <List spacing="4">
            <ListItem 
            {...normalText}
            paddingLeft="30px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '2px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '54px'
                }}
            >
                fungal infections (e.g. ketoconazole, itraconazole, fluconazole)
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="30px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '2px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '54px'
                }}
            >
                bacterial infections (e.g. clarithromycin, erythromycin)
            </ListItem>
            <ListItem 
            {...normalText}
            paddingLeft="30px"
            position="relative"
            display="block"
            _before={{
                position: 'absolute',
                top: '2px',
                left: '-5px',
                content: "'•'",
                color: 'purple.500',
                fontSize: '54px'
                }}
            >
                high blood pressure, abnormal heart rhythm or angina (e.g. diltiazem)
            </ListItem>
        </List>
        </Stack>
    </PseudoBox>
</Box>
</Box>
)

export default DrugInteractions