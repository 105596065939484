import React  from'react'
import { Box, Text, List, ListItem, useDisclosure, Link } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, blueBullet, subBullet, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const InformationQuickStart = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <List spacing="4" marginLeft="20px">
                <ListItem 
                {...secondaryText}
                paddingLeft="25px"
                position="relative"
                display="block"
                _before={{
                   ...blueBullet
                    }}
                >
                Quick starting is the term used to describe immediate initiation of a contraceptive method at the time a woman requests it rather than waiting for the start of her next menstrual <span style={{position: 'relative'}}>period<Reference>1</Reference></span>
                </ListItem>
                <ListItem 
                {...secondaryText}
                paddingLeft="25px"
                position="relative"
                display="block"
                _before={{
                  ...blueBullet
                    }}
                >
                Quick starting is outside the approved use of Hana and its SmPC so cannot be recommended for pharmacy supply, although it is common practice in family planning settings and may be appropriate for some women or situations
                </ListItem>
                <ListItem 
                {...secondaryText}
                paddingLeft="50px"
                position="relative"
                display="block"
                _before={{
                    ...subBullet
                    }}
                >
                    For example, women who may be especially vulnerable to unplanned pregnancy if regular contraception is not started as soon as possible, or women who have required emergency <span style={{position: 'relative'}}>contraception &nbsp; <Reference> 1,2</Reference></span>
                </ListItem>
                <ListItem 
                {...secondaryText}
                paddingLeft="50px"
                position="relative"
                display="block"
                _before={{
                   ...subBullet
                    }}
                >
                    Pharmacists may use their professional judgement and have a woman quick start Hana
                </ListItem>
                <ListItem 
                {...secondaryText}
                paddingLeft="25px"
                position="relative"
                display="block"
                _before={{
                    ...blueBullet
                    }}
                >
                    Quick starting Hana after use of emergency contraception:
                </ListItem>
            </List>
        
            <Box display="flex" alignItems="center" flexDirection={{base:"column", lg: "row"}}  width={{base: "100%", lg:"90%"}} margin="20px auto">
                <Box minHeight="125px" border="2px solid #4580AB" backgroundColor="#E3ECF3" borderRadius="10px" marginRight={{base: "0", lg:"10px"}} marginBottom={{base: "20px", lg: "0"}} padding="4" width={{base: "100%", lg:"50%"}}>
                    <Text {...secondaryText}>
                        She can start Hana immediately after levonorgestrel (additional contraceptive measures for first 7 days<span style={{position: 'relative'}}>)<Reference>3</Reference></span>
                    </Text>
                </Box>
                <Box minHeight="125px" border="2px solid #4580AB" backgroundColor="#E3ECF3" borderRadius="10px" padding="4" width={{base: "100%", lg:"50%"}}>
                    <Text {...secondaryText}>
                        She can start Hana no sooner than 5 days after ulipristal acetate (additional contraceptive measures for 5 days of waiting and for first 7 days of Hana use; i.e. 12 days in total<span style={{position: 'relative'}}>)<Reference>3</Reference></span>
                    </Text>
                </Box>
            </Box>
            <Text {...referenceLink} color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
            References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                    Faculty of Sexual & Reproductive Healthcare Clinical Guidance (FSRH). FSRH Guideline Quick Starting Contraception. April 2017. Available at: <Link href="https://www.fsrh.org/news/updated-clinical-guidance-published-quick-starting/" textDecoration="underline" isExternal>https://www.fsrh.org/news/updated-clinical-guidance-published-quick-starting/</Link>. Accessed: November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Faculty of Sexual & Reproductive Healthcare Clinical Guidance (FSRH). Progestogen-only Pills. March 2015 (amended April 2019). Available at: <Link href="https://www.fsrh.org/standards-and-guidance/documents/cec-ceu-guidance-pop-mar-2015/" isExternal textDecoration="underline">https://www.fsrh.org/standards-and-guidance/documents/cec-ceu-guidance-pop-mar-2015/</Link>. Accessed: November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default InformationQuickStart