import React from 'react'
import { Box, Text, List, ListItem, useDisclosure, PseudoBox } from '@chakra-ui/core'


// Load components
import Modal from '../../components/Modal/Modal'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, referenceLink } from '../../style/styleFormat'
import Reference from '../../components/UI/Typography/Reference'

const FirstTimeSupplyTwo = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Box borderTop="1px solid #4580AB" flexDirection={{base: "column", lg: "row"}} display="flex">
                <Box width="100%" position="relative">
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Checklist question
                        </Text>
                    </Box>
                    <PseudoBox 
                    position="relative" 
                    margin="30px auto"
                    width="80%"
                    height="50%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#E3ECF3"
                    borderRadius="23px"
                    padding="6"
                    textAlign="center"
                    _before={{
                        content: "''",
                        width: "0px",
                        height: '0px',
                        position: 'absolute',
                        top: '20px',
                        left: '-28px',
                        borderLeft: "25px solid transparent",
                        borderRight: "25px solid #E3ECF3",
                        borderTop: "25px solid #E3ECF3",
                        borderBottom: "25px solid transparent"
                    }}
                    >
                    <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold" fontSize="lg" textAlign="left"> 
                        Do you experience any bleeding between your periods or after sex?
                    </Text>
                </PseudoBox>
            </Box>
            <Box width="100%" borderLeft={{base: "none", lg:"1px solid #739FBF"}} borderTop={{base: "1px solid #739FBF", lg: "none"}}>
                    <Box backgroundColor="#E3ECF3" padding="4">
                        <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                            Guidance on pharmacist action
                        </Text>
                    </Box>
                    <Box padding="4">
                        <Text {...secondaryText} margin="10px 0">
                            <Text as="span" fontFamily="Gotham Bold">Hana is not suitable for women who have unexplained vaginal bleeding between their periods or repeatedly have bleeding after sex;</Text> the woman should be advised to see her doctor for investigation to exclude any underlying <span style={{position: 'relative'}}>pathology.<Reference>1</Reference></span>
                        </Text>
                        <Text {...secondaryText}>
                        Women can experience bleeding disturbances on Hana so it is important to understand the cause of any pre-existing unexplained vaginal bleeding before starting <span style={{position: 'relative'}}>Hana.<Reference>1</Reference></span>
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Text {...referenceLink} color="blue.50" as="u" cursor="pointer" onClick={onOpen} marginTop="20px">
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default FirstTimeSupplyTwo