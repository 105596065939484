/**
 * QUSTIONS
 */
export const questions = [
    {
        page: {
            id: 0,
            display: [64, 65],
            title: 'Hana is:',
            subTitle: 'Please select ONE correct answer',
            reveal: 65,
            withMultiAnswer: true
        },
        form: {
            q1: {
                content: 'A combined hormone contraceptive pill',
                status: 'INCORRECT',
                reveal: 'Hana is a progestogen-only oral contraceptive pill',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q2: {
                content: 'A progestogen-only contraceptive pill',
                status: 'CORRECT',
                reveal: 'Hana is a progestogen-only oral contraceptive pill',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }, 
            q3: {
                content: 'An oestrogen-only contraceptive pill',
                status: 'INCORRECT',
                reveal: 'Hana is a progestogen-only oral contraceptive pill',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 1,
            display: [66, 67],
            title: 'How does Hana work?',
            subTitle: 'Please select ONE correct answer',
            reveal: 67,
            withMultiAnswer: true
        },
        form: {
            q1: {
                content: 'It inhibits ovulation and increases the viscosity of cervical mucus to create a barrier',
                status: 'CORRECT',
                reveal: 'Hana works primarily by inhibiting ovulation. It also increases the viscosity of the cervical mucus, creating a barrier to stop sperm from entering the uterus.',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q2: {
                content: 'It delays ovulation',
                status: 'INCORRECT',
                reveal: 'Hana works primarily by inhibiting ovulation. It also increases the viscosity of the cervical mucus, creating a barrier to stop sperm from entering the uterus',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }, 
            q3: {
                content: 'It decreases the viscosity of the cervical mucus',
                status: 'INCORRECT',
                reveal: 'Hana works primarily by inhibiting ovulation. It also increases the viscosity of the cervical mucus, creating a barrier to stop sperm from entering the uterus',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 2,
            display: [68, 69],
            title: 'How should Hana be taken for maximum effectiveness?',
            subTitle: 'Please select ONE correct answer',
            reveal: 69,
            withMultiAnswer: true
        },
        form: {
            q1: {
                content: 'At the same time every day with no break between packs',
                status: 'CORRECT',
                reveal: 'It is important to take Hana at the same time every day, with no break between packs',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q2: {
                content: 'At any time of the day with no break between packs',
                status: 'INCORRECT',
                reveal: 'It is critically important to take Hana at the same time every day, with no break between packs',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }, 
            q3: {
                content: 'At the same time every day with a 7-day break between packs',
                status: 'INCORRECT',
                reveal: 'It is critically important to take Hana at the same time every day, with no break between packs',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 3,
            display: [70, 71],
            title: 'Hana should be taken at the same time every day. If a woman has missed a pill, what is the maximum delay allowed before additional contraceptive precautions for 7 days?',
            subTitle: 'Please select ONE correct answer',
            reveal: 71,
            withMultiAnswer: true
        },
        form: {
            q1: {
                content: '3 hours',
                status: 'INCORRECT',
                reveal: 'The maximum delay is 12 hours. If one tablet is missed/delayed by 12 hours or less, there is no need for extra precautions. If more than 12 hours, extra precautions should be used for 7 days',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q2: {
                content: '12 hours',
                status: 'CORRECT',
                reveal: 'If one tablet is missed/delayed by 12 hours or less, there is no need for extra precautions. If more than 12 hours, extra precautions should be used for 7 days.',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }, 
            q3: {
                content: '24 hours',
                status: 'INCORRECT',
                reveal: 'The maximum delay is 12 hours. If one tablet is missed/delayed by more than 12 hours, extra precautions should be used for 7 days',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 4,
            display: [72, 73],
            title: 'If taken as recommended, in what proportion of cycles does Hana inhibit ovulation?',
            subTitle: 'Please select ONE correct answer',
            reveal: 73,
            withMultiAnswer: true
        },
        form: {
            q1: {
                content: '≤97%',
                status: 'CORRECT',
                reveal: 'If taken as recommended, i.e. at the same time every day without a break between packs, Hana inhibits ovulation in up to 97% of cycles',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q2: {
                content: '<95%',
                status: 'INCORRECT',
                reveal: 'If taken as recommended, i.e. at the same time every day without a break between packs, Hana inhibits ovulation in up to 97% of cycles',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }, 
            q3: {
                content: '92%',
                status: 'INCORRECT',
                reveal: 'If taken as recommended, i.e. at the same time every day without a break between packs, Hana inhibits ovulation in up to 97% of cycles',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 5,
            display: [74, 75],
            title: 'TRUE or FALSE',
            subTitle: 'Please select TRUE or FALSE for the statement below',
            question: 'Hana can be used in pregnancy',
            reveal: 75,
            withTrueFalse: true
        },
        form: {
            q1: {
                label: 'TRUE',
                status: 'INCORRECT',
                reveal: 'Incorrect. Hana is not indicated in pregnancy. Pregnancy should be excluded for each supply of Hana. If a woman becomes pregnant whilst taking Hana she should stop further use',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q2: {
                label: 'FALSE',
                status: 'CORRECT',
                reveal: 'Correct. Hana is not indicated in pregnancy. Pregnancy should be excluded for each supply of Hana. If a woman becomes pregnant whilst taking Hana she should stop further use',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 6,
            display: [76, 77],
            title: 'TRUE or FALSE',
            subTitle: 'Please select TRUE or FALSE for the statement below',
            question: 'Hana can be used by women who are breastfeeding',
            reveal: 77,
            withTrueFalse: true
        },
        form: {
            q1: {
                label: 'TRUE',
                status: 'CORRECT',
                reveal: 'Correct. Hana can be used by women who are breastfeeding. Although a small amount of the active substance of Hana passes into the milk, Hana does not appear to influence the production or the quality of breast milk. However, there have been infrequent postmarketing reports of a decrease in breast milk production while using desogestrel. The development and growth of a breastfed infant, whose mother uses Hana, should be carefully observed. However, no effect of desogestrel in breastfed newborns/infants has been shown',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q2: {
                label: 'FALSE',
                status: 'INCORRECT',
                reveal: 'Incorrect. Hana can be used by women who are breastfeeding. Although a small amount of the active substance of Hana passes into the milk, Hana does not appear to influence the production or the quality of breast milk. However, there have been infrequent postmarketing reports of a decrease in breast milk production while using desogestrel. The development and growth of a breastfed infant, whose mother uses Hana, should be carefully observed. However, no effect of desogestrel in breastfed newborns/infants has been shown',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 7,
            display: [78, 79],
            title: 'TRUE or FALSE',
            subTitle: 'Please select TRUE or FALSE for the statement below',
            question: 'Hana can be supplied in pharmacy for use by young women under the age of 18 years',
            reveal: 79,
            withTrueFalse: true
        },
        form: {
            q1: {
                label: 'TRUE',
                status: 'CORRECT',
                reveal: 'Correct. Hana can be supplied to women under the age of 18 years provided they will understand the advice and what is involved; cannot be persuaded to inform their parents; are very likely to begin or continue to have sexual intercourse without contraception; their physical or mental health (or both) would suffer without contraceptive advice or treatment; and their best interests require them to receive contraceptive advice or treatment with or without parental consent. The maximum supply is for 3 months',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q2: {
                label: 'FALSE',
                status: 'INCORRECT',
                reveal: 'Incorrect. Hana can be supplied to women under the age of 18 years provided they will understand the advice and what is involved; cannot be persuaded to inform their parents; are very likely to begin or continue to have sexual intercourse without contraception; their physical or mental health (or both) would suffer without contraceptive advice or treatment; and their best interests require them to receive contraceptive advice or treatment with or without parental consent. The maximum supply is for 3 months',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 8,
            display: [80, 81],
            title: 'TRUE or FALSE',
            subTitle: 'Please select TRUE or FALSE for the statement below',
            question: 'Hana can be supplied in pharmacy without prescription to women who have never used an oral contraceptive pill before',
            reveal: 81,
            withTrueFalse: true
        },
        form: {
            q1: {
                label: 'TRUE',
                status: 'CORRECT',
                reveal: 'Correct. Hana can be supplied in pharmacy without prescription to women who have never used an oral contraceptive pill before as long as they are eligible (see SmPC for contraindications, medical conditions, drug interactions)',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q2: {
                label: 'FALSE',
                status: 'INCORRECT',
                reveal: ' Incorrect. Hana can be supplied in pharmacy without prescription to women who have never used an oral contraceptive pill before, as long as they are eligible (see SmPC for contraindications, medical conditions, drug interactions)',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 9,
            display: [82, 83],
            title: 'TRUE or FALSE',
            subTitle: 'Please select TRUE or FALSE for the statement below',
            question: 'Hana can be supplied in pharmacy without prescription to women taking carbamazepine for epilepsy and women taking St John’s Wort for depression, both as long-term treatments',
            reveal: 83,
            withTrueFalse: true
        },
        form: {
            q1: {
                label: 'TRUE',
                status: 'INCORRECT',
                reveal: ' Incorrect. Carbamazepine and St John’s Wort are two of the medicines that reduce the effectiveness of Hana. Enzyme induction by these substances increases the clearance of contraceptive hormones, which may lead to breakthrough bleeding and/or contraceptive failure. Women using these medicines chronically or long term should be referred to their doctor for further advice',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q2: {
                label: 'FALSE',
                status: 'CORRECT',
                reveal: 'Correct. Carbamazepine and St John’s Wort are two of the medicines that reduce the effectiveness of Hana. Enzyme induction by these substances increases the clearance of contraceptive hormones, which may lead to breakthrough bleeding and/or contraceptive failure. Women using these medicines chronically or long term should be referred to their doctor for further advice',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 10,
            display: [84, 85],
            title: 'TRUE or FALSE',
            subTitle: 'Please select TRUE or FALSE for the statement below',
            question: 'Irregular bleeding is the most commonly reported side effect with Hana',
            reveal: 85,
            withTrueFalse: true
        },
        form: {
            q1: {
                label: 'TRUE',
                status: 'CORRECT',
                reveal: 'Correct. Irregular bleeding has been reported by up to 50% of women using Hana. Bleeding may become more frequent in 20-30% of women and less frequent or totally absent in 20%. For most women the pattern of bleeding settles after a few months of use',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q2: {
                label: 'FALSE',
                status: 'INCORRECT',
                reveal: 'Incorrect. Irregular bleeding has been reported by up to 50% of women using Hana. Bleeding may become more frequent in 20-30% of women and less frequent or totally absent in 20%. For most women the pattern of bleeding settles after a few months of use',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 11,
            display: [86, 87],
            title: 'TRUE or FALSE',
            subTitle: 'Please select TRUE or FALSE for the statement below',
            question: 'Women who have never previously used hormonal contraception can start using Hana at any time during their menstrual cycle',
            reveal: 87,
            withTrueFalse: true
        },
        form: {
            q1: {
                label: 'TRUE',
                status: 'INCORRECT',
                reveal: 'Incorrect. Women who have not used hormonal contraception within the last month should start using Hana on day 1 of their next period, the first day of period bleeding. They will not need to use extra precautions. They can also start on days 2-5 of their next period. In this case, extra precautions are recommended for the first 7 days',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q2: {
                label: 'FALSE',
                status: 'CORRECT',
                reveal: 'Correct. Women who have not used hormonal contraception within the last month should start using Hana on day 1 of their next period, the first day of period bleeding. They will not need to use extra precautions. They can also start on days 2-5 of their next period. In this case, extra precautions are recommended for the first 7 days',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 12,
            display: [88, 89],
            title: 'TRUE or FALSE',
            subTitle: 'Please select TRUE or FALSE for the statement below',
            question: 'Women who are switching from a 21-day combined oral contraceptive can start Hana the day after their last tablet containing an active substance',
            reveal: 89,
            withTrueFalse: true
        },
        form: {
            q1: {
                label: 'TRUE',
                status: 'CORRECT',
                reveal: 'Correct. Women taking the 21-day combined oral contraceptive can start taking Hana the day after the last tablet containing an active substance with no need for extra precautions ',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                },
            },
            q2: {
                label: 'FALSE',
                status: 'INCORRECT',
                reveal: 'Incorrect. Women taking the 21-day combined oral contraceptive can start taking Hana the day after the last tablet containing an active substance with no need for extra precautions',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 13,
            display: [90, 91],
            title: 'TRUE or FALSE',
            subTitle: 'Please select TRUE or FALSE for the statement below',
            question: 'Women who are starting Hana after childbirth can start at any time, and do not need to use extra precautions',
            reveal: 91,
            withTrueFalse: true
        },
        form: {
            q1: {
                label: 'TRUE',
                status: 'INCORRECT',
                reveal: 'Incorrect. Women can start Hana any day between day 1 and day 21 after childbirth, no extra precautions required. When starting later, women should use extra precautions for the first 7 days, but if unprotected intercourse has already occurred they should take a pregnancy test or talk to their doctor before starting Hana',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q2: {
                label: 'FALSE',
                status: 'CORRECT',
                reveal: 'Correct. Women can start Hana any day between day 1 and day 21 after childbirth, no extra precautions required. When starting later, women should use extra precautions for the first 7 days, but if unprotected intercourse has already occurred they should take a pregnancy test or talk to their doctor before starting Hana',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 14,
            display: [92, 93],
            title: 'TRUE or FALSE',
            subTitle: 'Please select TRUE or FALSE for the statement below',
            question: 'If a woman who has not used Hana before has chloasma, you need to advise her to see her doctor before you can supply her with Hana',
            reveal: 93,
            withTrueFalse: true
        },
        form: {
            q1: {
                label: 'TRUE',
                status: 'INCORRECT',
                reveal: ' Incorrect. Women who have chloasma do not need to be referred to their doctor; they should be counselled before use of Hana about the need to avoid exposure to the sun or UV radiation',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                },
            },
            q2: {
                label: 'FALSE',
                status: 'CORRECT',
                reveal: 'Correct. Women who have chloasma do not need to be referred to their doctor; they should be counselled before use of Hana about the need to avoid exposure to the sun or UV radiation',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 15,
            display: [94, 95],
            title: 'If a woman forgets to take her Hana at the usual time but remembers 8 hours later, she should:',
            subTitle: 'Please select ALL the correct responses',
            reveal: 95,
            withAnswer: true
        },
        form: {
            q1: {
                content: 'Take the missed pill immediately and take the next scheduled dose as usual, but take extra precautions for 7 days',
                status: 'INCORRECT',
                reveal: 'If one tablet is missed for 12 hours or less, the missed tablet should be taken immediately and the next scheduled dose should be taken as usual. There is no need for extra precautions because Hana has a 12-hour missed pill window',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q2: {
                content: 'Take the missed pill immediately and take the next scheduled dose as usual with no need to use extra precautions',
                status: 'CORRECT',
                reveal: ' If one tablet is missed for 12 hours or less, the missed tablet should be taken immediately and the next scheduled dose should be taken as usual. There is no need for extra precautions because Hana has a 12-hour missed pill window',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }, 
            q3: {
                content: 'Not take the missed pill until her next scheduled  dose but use extra precautions for 7 days',
                status: 'INCORRECT',
                reveal: 'If one tablet is missed for 12 hours or less, the missed tablet should be taken immediately and the next scheduled dose should be taken as usual. There is no need for extra precautions because Hana has a 12-hour missed pill window',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q4: {
                content: 'Do a pregnancy test',
                status: 'INCORRECT',
                reveal: 'If one tablet is missed for 12 hours or less, the missed tablet should be taken immediately and the next scheduled dose should be taken as usual. There is no need for extra precautions because Hana has a 12-hour missed pill window',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 16,
            display: [96, 97],
            title: 'In which of the following situations would you <u>not provide a first supply of Hana</u> to a woman and advise her to see her doctor?',
            subTitle: 'Please select ALL the correct responses',
            modal: 17,
            reveal: 97,
            withExpanded: true
        },
        form: {
            q1: {
                content: 'She has a history of hepatitis and is not sure whether her liver function values are back to normal',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q2: {
                content: 'She is taking medication for epilepsy',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }, 
            q3: {
                content: 'She has insulin-dependent diabetes',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q4: {
                content: 'She has a lactose intolerance',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q5: {
                content: 'She had breast cancer 7 years ago but has been clear of cancer for the last 6 years',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q6: {
                content: 'She has just had a pregnancy terminated',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q7: {
                content: 'She had a DVT (venous thrombosis) following a long haul flight 5 years ago',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q8: {
                content: 'She had a baby 2 months ago',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q9: {
                content: 'She is over 40 years old',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q10: {
                content: 'She thinks she is pregnant',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q11: {
                content: 'She has just taken emergency contraception',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q12: {
                content: 'She often bleeds after sex',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }
        }
    },
    {
        page: {
            id: 17,
            display: [98, 99],
            title: 'When should a woman taking Hana seek medical help either immediately or as soon as possible',
            subTitle: 'Please select ALL the correct responses',
            modal: 18,
            reveal: 99,
            withExpanded: true
        },
        form: {
            q1: {
                content: 'If she thinks she may be pregnant',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q2: {
                content: 'If she has a swollen, painful, hot leg',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }, 
            q3: {
                content: 'If she experiences a headache',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q4: {
                content: 'If she has pelvic pain with vaginal bleeding',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q5: {
                content: 'If she notices a change (yellowing) of her skin or eyes',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q6: {
                content: 'If she suffers from vomiting or diarrhoea',
                status: 'INCORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q7: {
                content: 'If she notices changes to her breasts',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q8: {
                content: 'If she has an unusual cough, chest pain and breathlessness',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q9: {
                content: 'If she notices her bleeding gets heavy or prolonged',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
        }
    },
    {
        page: {
            id: 18,
            display: [100, 101],
            title: 'What advice should you give a woman when you supply Hana?',
            subTitle: 'Please select ALL the correct responses',
            modal: 19,
            reveal: 101,
            withExpanded: true
        },
        form: {
            q1: {
                content: 'To take Hana at the same time every day',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q2: {
                content: 'When to start taking Hana',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }, 
            q3: {
                content: 'That she might experience changes in her bleeding',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q4: {
                content: 'Where to find information on different types of contraception',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q5: {
                content: 'To make sure she has regular cervical smears and is aware of any changes to her breasts',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q6: {
                content: 'That Hana will not protect her against sexually transmitted infections',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q7: {
                content: 'What to do if she misses a pill',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q8: {
                content: 'To read the pack leaflet carefully',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q9: {
                content: 'To come back if she experiences any problems',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q10: {
                content: 'When to come back to pharmacy',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
            q11: {
                content: 'To advise her doctor that she has been supplied with Hana in pharmacy',
                status: 'CORRECT',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            },
        }
    },
    {
        page: {
            id: 19,
            display: [102, 103],
            title: 'A woman taking Hana had a gastrointestinal infection 5 days ago with vomiting and diarrhoea that lasted and she stopped taking her Hana for 3 days. She did not take extra precautions after she recovered and had sex last night. She is still taking Hana but wants to take emergency contraception. <br/><br/>What advice would you give her?',
            subTitle: 'Please select ALL the correct responses',
            reveal: 103,
            withExpanded: true
        },
        form: {
            q1: {
                content: 'Take ulipristal acetate and take Hana at the same time as usual on the same day; no need to use additional contraceptive measures (abstinence or barrier methods)',
                status: 'INCORRECT',
                reveal: 'If women want to continue using Hana after taking ulipristal acetate, they should restart their Hana 5 days after taking ulipristal acetate, and use a additional contraceptive measures (abstinence or barrier methods) for the 5 days plus the first 7 days of Hana (i.e. 12 days in total). If women take levonorgestrel, they should restart their Hana at the same time as usual on the same day, and use additional contraceptive measures for the next 7 days.',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q2: {
                content: 'Take levonorgestrel and take Hana at the same time as usual on the same day; no need to use additional contraceptive measures (abstinence or barrier methods)',
                status: 'INCORRECT',
                reveal: 'If women want to continue using Hana after taking ulipristal acetate, they should restart their Hana 5 days after taking ulipristal acetate, and use a additional contraceptive measures (abstinence or barrier methods) for the 5 days plus the first 7 days of Hana (i.e. 12 days in total). If women take levonorgestrel, they should restart their Hana at the same time as usual on the same day, and use additional contraceptive measures for the next 7 days.',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }, 
            q3: {
                content: 'Take ulipristal acetate but stop taking Hana, only restarting it 5 days after taking ulipristal acetate; use extra precautions for the 5 days, no need to use additional contraceptive measures (abstinence or barrier methods) once back on Hana',
                status: 'INCORRECT',
                reveal: 'If women want to continue using Hana after taking ulipristal acetate, they should restart their Hana 5 days after taking ulipristal acetate, and use additional contraceptive measures (abstinence or barrier methods) for the 5 days plus the first 7 days of Hana (i.e. 12 days in total). If women take levonorgestrel, they should restart their Hana at the same time as usual on the same day, and use additional contraceptive measures for the next 7 days.',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            },
            q4: {
                content: 'Take ulipristal acetate but stop taking Hana, only restarting it 5 days after taking ulipristal acetate; use additional contraceptive measures (abstinence or barrier methods) for the 5 days plus the first 7 days back on Hana (i.e. 12 days in total)',
                status: 'CORRECT',
                reveal: 'If women want to continue using Hana after taking ulipristal acetate, they should restart their Hana 5 days after taking ulipristal acetate, and use additional contraceptive measures (abstinence or barrier methods) for the 5 days plus the first 7 days of Hana (i.e. 12 days in total). If women take levonorgestrel, they should restart their Hana at the same time as usual on the same day, and use additional contraceptive measures for the next 7 days.',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }, 
            q5: {
                content: 'Do not take levonorgestrel if taking Hana',
                status: 'INCORRECT',
                reveal: ' If women want to continue using Hana after taking ulipristal acetate, they should restart their Hana 5 days after taking ulipristal acetate, and use additional contraceptive measures (abstinence or barrier methods) for the 5 days plus the first 7 days of Hana (i.e. 12 days in total). If women take levonorgestrel, they should restart their Hana at the same time as usual on the same day, and use additional contraceptive measures for the next 7 days.',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        wrong: true
                    }
                }
            }, 
            q6: {
                content: 'Take levonorgestrel and take Hana at the same time as usual on the same day; continue using Hana but use additional contraceptive measures (abstinence or barrier methods) for the next 7 days',
                status: 'CORRECT',
                reveal: 'If women want to continue using Hana after taking ulipristal acetate, they should restart their Hana 5 days after taking ulipristal acetate, and use additional contraceptive measures (abstinence or barrier methods) for the 5 days plus the first 7 days of Hana (i.e. 12 days in total). If women take levonorgestrel, they should restart their Hana at the same time as usual on the same day, and use additional contraceptive measures for the next 7 days.',
                value: false,
                validation: {
                    required: false,
                    touched: false,
                    valid: { correct: false, wrong: false},
                    rules: {
                        correct: true
                    }
                }
            }
        }
    },
]
