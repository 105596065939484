import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

export const CaseTenYesNo = () => <Text {...normalText}>
Whilst you do not want the woman to stop taking Hana unnecessarily, her erratic use of Hana might mean that the cessation of bleeding is because of pregnancy. She is <Text as="span" {...boldGreen}>suitable</Text> for a 12-month repeat supply of Hana, but advise her to <Text as="span" {...boldGreen}>perform a pregnancy test</Text> at least 3 weeks after the last episode of unprotected sex. <br/><br/>Advise the woman to continue taking Hana (without breaks) and start the new pack if the test is negative, but to stop taking Hana and see her doctor if it is positive. Remind her that to be effective, Hana should be taken at the same time every day, and advise her that if she finds that difficult she should see her doctor to discuss an alternative method of contraception.
</Text>

export const CaseTenYesYes = () => <Text {...normalText}>
Whilst you do not want the woman to stop taking Hana unnecessarily, her erratic use of Hana might mean that the cessation of bleeding is because of pregnancy. She is <Text as="span" {...boldRed}>suitable</Text> for a 12-month repeat supply of Hana, but advise her to <Text as="span" {...boldRed}>perform a pregnancy test</Text> at least 3 weeks after the last episode of unprotected sex. <br/><br/>Advise the woman to continue taking Hana (without breaks) and start the new pack if the test is negative, but to stop taking Hana and see her doctor if it is positive. Remind her that to be effective, Hana should be taken at the same time every day, and advise her that if she finds that difficult she should see her doctor to discuss an alternative method of contraception.
</Text>

export const CaseTenNoYes = () => <Text {...normalText}>
Whilst you do not want the woman to stop taking Hana unnecessarily, her erratic use of Hana might mean that the cessation of bleeding is because of pregnancy. She is <Text as="span" {...boldRed}>suitable</Text> for a 12-month repeat supply of Hana, but advise her to <Text as="span" {...boldRed}>perform a pregnancy test</Text> at least 3 weeks after the last episode of unprotected sex. <br/><br/>Advise the woman to continue taking Hana (without breaks) and start the new pack if the test is negative, but to stop taking Hana and see her doctor if it is positive. Remind her that to be effective, Hana should be taken at the same time every day, and advise her that if she finds that difficult she should see her doctor to discuss an alternative method of contraception.
</Text>

export const CaseTenNoNo = () => <Text {...normalText}>
Whilst you do not want the woman to stop taking Hana unnecessarily, her erratic use of Hana might mean that the cessation of bleeding is because of pregnancy. She is <Text as="span" {...boldRed}>suitable</Text> for a 12-month repeat supply of Hana, but advise her to <Text as="span" {...boldRed}>perform a pregnancy test</Text> at least 3 weeks after the last episode of unprotected sex. <br/><br/>Advise the woman to continue taking Hana (without breaks) and start the new pack if the test is negative, but to stop taking Hana and see her doctor if it is positive. Remind her that to be effective, Hana should be taken at the same time every day, and advise her that if she finds that difficult she should see her doctor to discuss an alternative method of contraception.
</Text>