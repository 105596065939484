import React, { Component } from 'react'

// Load content - Part One
import Welcome from '../../content/PartOne/Welcome'
import AssementIntro from '../../content/PartOne/AssementIntro'
import LearningOutComes from '../../content/PartOne/LearningOutcomesPt1'
import IntroducingHana from '../../content/PartOne/IntroducingHana'
import MenstrualCycle from '../../content/PartOne/MenstrualOverview'
import HowHanaWorks from '../../content/PartOne/HowHanaWorks'
import EfficacyOfHana from '../../content/PartOne/EfficacyOfHana'
import EfficacyOfHanaTwo from '../../content/PartOne/EfficacyOfHanaTwo'
import WhoCanUseHana from '../../content/PartOne/WhoCanUseHana'
import WhoCanUseHanaTwo from '../../content/PartOne/WhoCanUseHanaTwo'
import DrugInteractions from '../../content/PartOne/DrugInteractions'
import Interactions from '../../content/PartOne/Interactions'
import Questions from '../../content/PartOne/Questions'
import QuestionOne from '../../content/PartOne/QuestionOne'
import QuestionTwo from '../../content/PartOne/QuestionTwo'
import QuestionThree from '../../content/PartOne/QuestionThree'
import QuestionFour from '../../content/PartOne/QuestionFour'
import WellDone from '../../content/PartOne/WellDone'
import Reminder from '../../content/PartOne/Reminder'

// content - part Two
import LearningPartTwo from '../../content/PartTwo/LearningOutcomesTwo'
import SupplySituation from '../../content/PartTwo/SupplySituation'
import SupplySituationTwo from '../../content/PartTwo/SupplySituationTwo'
import FirstTimeSupply from '../../content/PartTwo/FirstTimeSupply'
import FirstTimeSupplyTwo from '../../content/PartTwo/FirstTimeSupplyTwo'
import FirstTimeSupplyThree from '../../content/PartTwo/FirstTimeSupplyThree'
import FirstTimeSupplyFour from '../../content/PartTwo/FirstTimeSupplyFour'
import FirstTimeSupplyFive from '../../content/PartTwo/FirstTimeSupplyFive'
import FirstTimeSupplySix from '../../content/PartTwo/FirstSupplySix'
import ExcludingPregnancy from '../../content/PartTwo/ExcludingPregnancy'
import ExcludingPregnancyTwo from '../../content/PartTwo/ExcludingPregnancyTwo'
import StartingHana from '../../content/PartTwo/StartingHana'
import StartingHanaTwo from '../../content/PartTwo/StartingHanaTwo'
import RepeatSupply from '../../content/PartTwo/RepeatSupply'
import RepeatSupplyTwo from '../../content/PartTwo/RepeatSupplyTwo'
import RepeatSupplyThree from '../../content/PartTwo/RepeatSupplyThree'
import RepeatSupplyFour from '../../content/PartTwo/RepeatSupplyFour'
import RepeatSupplyFive from '../../content/PartTwo/RepeatSupplyFive'
import RepeatSupplySix from '../../content/PartTwo/RepeatSupplySix'
import InformationQuickStart from '../../content/PartTwo/InformationQuickStart'
import InformationQuickStartTwo from '../../content/PartTwo/InformationQuickStartTwo'
import AdviceTakingHana from '../../content/PartTwo/AdviceTakingHana'
import AdviceTakingHanaTwo from '../../content/PartTwo/AdviceTakingHanaTwo'
import AdviceTakingHanaThree from '../../content/PartTwo/AdviceTakingHanaThree'
import AdviceTakingHanaFour from '../../content/PartTwo/AdviceTakingHanaFour'
import AdviceTakingHanaFive from '../../content/PartTwo/AdviceTakingHanaFive'
import AdviceTakingHanaSix from '../../content/PartTwo/AdviceTakingHanaSix'
import AdviceTakingHanaSeven from '../../content/PartTwo/AdviceTakingHanaSeven'
import QuestionOneTwo from '../../content/PartTwo/QuestionOneTwo'
import QuestionTwoTwo from '../../content/PartTwo/QuestionTwoTwo'
import QuestionThreeTwo from '../../content/PartTwo/QuestionThreeTwo'
import PartTwoComplete from '../../content/PartTwo/PartTwoComplete'
import ReminderPartTwo from '../../content/PartTwo/ReminderPartTwo'

// content - Part three
import LearningOutcomesThree from '../../content/PartThree/LearningOutcomesThree'
import AssessingSuitability from '../../content/PartThree/AssessingSuitability'
import PartThreeComplete from '../../content/PartThree/WelldoneThree'

// Load assessments/Learning for part one
class Learning extends Component {

    constructor(props) {
        super(props)

        // Content pages
        this.pageList = [
             {
                 key: 0,
                 Component: () => <Welcome />  
             }, 
             {
                 key: 1,
                 Component: () => <AssementIntro />
             },
             {
                 key: 2,
                 Component: () => <LearningOutComes />
             },
             {
                 key: 3,
                 Component: () => <IntroducingHana />
             },
             {
                 key: 4,
                 Component: () => <MenstrualCycle />
             },
             {
                 key: 5,
                 Component: () => <HowHanaWorks />
             },
             {
                 key: 6,
                 Component: () => <EfficacyOfHana />
             },
             {
                 key: 7,
                 Component: () => <EfficacyOfHanaTwo />
             },
             {
                 key: 8,
                 Component: () => <WhoCanUseHana />
             },
             {
                 key: 9,
                 Component: () => <WhoCanUseHanaTwo />
             },
             {
                 key: 10,
                 Component: () => <DrugInteractions />
             },
             {
                 key: 11,
                 Component: () => <Interactions />
             },
             {
                 key: 12,
                 Component: () => <Questions click={this.navigation}/>
             },
             {
                 key: 13,
                 Component: () => <QuestionOne changePage={this.navigation} setNext={this.nextQuestion} />
             },
             {
                 key: 14,
                 Component: () => <QuestionOne changePage={this.navigation} setNext={this.nextQuestion} reveal />
             },
             {
                 key: 15,
                 Component: () => <QuestionTwo changePage={this.navigation} setNext={this.nextQuestion} />
             },
             {
                 key: 16,
                 Component: () => <QuestionTwo changePage={this.navigation} setNext={this.nextQuestion} reveal />
             },
             {
                 key: 17,
                 Component: () => <QuestionThree setNext={this.nextQuestion} />
             },
             {
                 key: 18,
                 Component: () => <QuestionThree  setNext={this.nextQuestion}reveal />
             },
             {
                 key: 19,
                 Component: () => <QuestionFour changePage={this.navigation} setNext={this.nextQuestion} />
             },
             {
                 key: 20,
                 Component: () => <QuestionFour changePage={this.navigation} setNext={this.nextQuestion} reveal />
             },
             {
                 key: 21,
                 Component: () => <WellDone openResources={this.openSidedrawer} />
             },
             {
                 key: 22,
                 Component: () => <Reminder />
             },
             {
                 key: 23,
                 Component: () => <LearningPartTwo />
             },
             {
                 key: 24,
                 Component: () => <SupplySituation />
             },
            {
                key: 25,
                Component: () => <SupplySituationTwo />
            },
            {
                key: 26,
                Component: () => <FirstTimeSupply />
            },
            {
                key: 27,
                Component: () => <FirstTimeSupplyTwo />
            },
            {
                key: 28,
                Component: () => <FirstTimeSupplyThree />
            },
            {
                key: 29,
                Component: () => <FirstTimeSupplyFour />
            },
            {
                key: 30,
                Component: () => <FirstTimeSupplyFive />
            },
            {
                key: 31,
                Component: () => <FirstTimeSupplySix />
            },
            {
                key: 32,
                Component: () => <ExcludingPregnancy />
            },
            {
                key: 33,
                Component: () => <ExcludingPregnancyTwo />
            },
            {
                key: 34,
                Component: () => <StartingHana />
            },
            {
                key: 35,
                Component: () => <StartingHanaTwo />
            },
            {
                key: 36,
                Component: () => <RepeatSupply />
            },
            {
                key: 37,
                Component: () => <RepeatSupplyTwo />
            },
            {
                key: 38,
                Component: () => <RepeatSupplyThree />
            },
            {
                key: 39,
                Component: () => <RepeatSupplyFour />
            },
            {
                key: 40,
                Component: () => <RepeatSupplyFive />
            },
            {
                key: 41,
                Component: () => <RepeatSupplySix />
            },
            {
                key: 42,
                Component: () => <InformationQuickStart />
            },
            {
                key: 43,
                Component: () => <InformationQuickStartTwo />
            },
            {
                key: 44,
                Component: () => <AdviceTakingHana />
            },
            {
                key: 45,
                Component: () => <AdviceTakingHanaTwo />
            },
            {
                key: 46,
                Component: () => <AdviceTakingHanaThree />
            },
            {
                key: 47,
                Component: () => <AdviceTakingHanaFour />
            },
            {
                key: 48,
                Component: () => <AdviceTakingHanaFive />
            },
            {
                key: 49,
                Component: () => <AdviceTakingHanaSix />
            },
            {
                key: 50,
                Component: () => <AdviceTakingHanaSeven />
            },
            {
                key: 51,
                Component: () => <Questions click={this.navigation} />
            },
            {
                key: 52,
                Component: () => <QuestionOneTwo setNext={this.nextQuestion}/>
            },
            {
                key: 53,
                Component: () => <QuestionOneTwo reveal setNext={this.nextQuestion}/>
            },
            {
                key: 54,
                Component: () => <QuestionTwoTwo changePage={this.navigation} setNext={this.nextQuestion}/>
            },
            {
                key: 55,
                Component: () => <QuestionTwoTwo changePage={this.navigation} reveal setNext={this.nextQuestion}/>
            },
            {
                key: 56,
                Component: () => <QuestionThreeTwo changePage={this.navigation} setNext={this.nextQuestion}/>
            },
            {
                key: 57,
                Component: () => <QuestionThreeTwo changePage={this.navigation} reveal setNext={this.nextQuestion}/>
            },
            {
                key: 58,
                Component: () => <PartTwoComplete openResources={this.openSidedrawer} />
            },
            {
                key: 59,
                Component: () => <ReminderPartTwo />
            },
            {
                key: 60,
                Component: () => <LearningOutcomesThree />
            },
            {
                key: 61,
                Component: () => <AssessingSuitability />
            },
            {
                key: 62,
                Component: () => <PartThreeComplete openResources={this.openSidedrawer} />
            }
        ]
    }


    // Click here nav on questions
    navigation = () => {
        const {navControl} = this.props

        navControl('increment')
    }

    // check one answer selected
    nextQuestion = (isAllowed) => {
        const { allowNext } = this.props

        allowNext(isAllowed)  
    }


    // callback to open sideDrawer
    openSidedrawer = (device) => {
        const { setOpen, openMobileDrawer } = this.props

        if (device === 'mobile') {
            openMobileDrawer()
            setOpen(3)
            return
        }
        
        // index 3 for resources
        return setOpen(3)
    }


    render() {
        const { currentPage } = this.props

        let component = null

        // Loop over available content
        this.pageList.forEach((item) => {

            if (currentPage === item.key) {
                component = item.Component()
            }
        })
      
        return component
    }
}

export default Learning