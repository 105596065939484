import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider, CSSReset } from '@chakra-ui/core'

// Load components
import ELearning from './components/ELearning/ELearning'
import Privacy from './components/Privacy/Privacy'
import Cookieprivacy from './components/Cookieprivacy/Cookieprivacy'
import Cookielist from './components/Cookielist/Cookielist'
import LandingPage from './components/LandingPage/LandingPage';
import PharmacyTraining from './components/PharmaLearning/PharmaLearning'
import Animations from './components/Animations/Animations'
import MOA from './components/MOA/MOA'
import Webinar from './components/Webinar/Webinar'
import WebinarQA from './components/Webinar/WebinarQA';
import WebinarPresentation from './components/Webinar/WebinarPresentation';
import CookieBanner from './components/Cookiebanner/Cookiebanner';


// Load theme
import customTheme from './theme'
import './font/stylesheet.css'

function App() {
  return (
   <ThemeProvider theme={customTheme}>
      <CSSReset />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/training"  component={ELearning}/>
        <Route path="/privacy"  component={Privacy}/>
        <Route path="/cookies"  component={Cookieprivacy}/>
        <Route path="/cookie-list"  component={Cookielist}/>
        <Route path="/teamtraining" component={PharmacyTraining} />
        <Route path="/animations" component={Animations} />
        <Route path="/moa" component={MOA} />
        <Route exact path="/webinar" component={Webinar} />
        <Route exact path="/webinar/qa" component={WebinarQA} />
        <Route exact path="/webinar/presentation" component={WebinarPresentation} />
        <Redirect to="/" />
      </Switch>
      <CookieBanner />
   </ThemeProvider>
      
  );
}

export default App;
