import React from 'react'
import { Box, Text, useDisclosure, ListItem, List, Image, Link } from '@chakra-ui/core'

// Load components
import Reference from '../../components/UI/Typography/Reference'
import Modal from '../../components/Modal/Modal'

// Load assets
import {ReactComponent as Lights } from '../../images/lightsTraffic.svg'
import {ReactComponent as People } from '../../images/people.svg'
import {ReactComponent as Clock } from '../../images/clock.svg'
import {ReactComponent as Coffee } from '../../images/coffee.svg'
import NineNine from '../../images/99.png'
import {ReactComponent as Hands } from '../../images/handsFace.svg'
import Bottle from '../../images/bottleP.png'

// Load style
import { normalText, referenceText, referenceLink } from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const Row = ({ svg: SVG, content, img, imgWidth }) => {
    return (
        <Box display="flex" alignItems={{base: "center", lg:"flex-start"}} marginBottom="40px">
            <Box marginRight="10px" alignSelf="flex-start" minWidth="80px">
                {SVG && <SVG width="50px" />}
                {img && <Image height="60px" maxWidth={imgWidth} objectFit="contain" src={img} alt="bottle" />}
            </Box>
            <Box minHeight="50px" display="flex" alignItems="center">
                {content}
            </Box>
        </Box>
    )
}

const PharamKeyFacts = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
   
    return (
        <Layout>
            <Text {...normalText} marginBottom="20px" color="purple.200" fontFamily="Gotham Bold" display="block">
                Hana is a daily contraceptive used to prevent pregnancy for women of childbearing <span style={{position: 'relative'}}>age.<Reference>1</Reference></span>
            </Text>
       
            <Text {...normalText} marginBottom="20px" display="block" >
                Women seeking regular oral contraception may have heard about Hana being available in pharmacy and ask you for some general information about it. So here are some key points to remember:
            </Text>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between">
                <Box width={{base: "100%", lg:"48%"}}>
                    <Row
                    svg={Lights}
                    content={<Text {...normalText}>Hana is a <Text as="span" color="purple.200" fontFamily="Gotham Bold">progestogen-only pill</Text> (POP; commonly referred to as a mini-pill) containing <Text as="span" fontFamily="Gotham Bold" color="purple.200">desogestrel</Text><sup>1</sup></Text>}
                    />
                    <Row 
                    svg={People}
                    content={<Text {...normalText}>Hana is <Text as="span" color="purple.200" fontFamily="Gotham Bold">suitable for many women</Text> of reproductive age, including those who are <Text as="span" color="purple.200" fontFamily="Gotham Bold">breastfeeding</Text> and those who <Text as="span" color="purple.200" fontFamily="Gotham Bold">can’t or don’t want to take oestrogens</Text><sup>1</sup></Text>}
                    />
                    <Row 
                    svg={Clock}
                    content={<Text {...normalText}>Hana has a <Text as="span" color="purple.200" fontFamily="Gotham Bold">12-hour window for missed pills</Text><sup>1</sup></Text>}
                    />
                    <Row
                    svg={Coffee}
                    content={<Text {...normalText}><Text as="span" color="purple.200" fontFamily="Gotham Bold">One pill every day</Text>, taken <Text as="span" color="purple.200" fontFamily="Gotham Bold">at the same time every day without a break</Text> between packs<sup>1</sup></Text>}
                    />
                </Box>
                <Box width={{base: "100%", lg:"45%"}}>
                    <Row
                    img={NineNine}
                    imgWidth="70px"
                    content={<Text {...normalText}>Hana is <Text as="span" color="purple.200" fontFamily="Gotham Bold">more than 99% effective</Text> when taken correctly<sup>2</sup></Text>}
                    />
                    <Row
                    svg={Hands}
                    content={<Text {...normalText} display="block">Hana is generally <Text as="span" color="purple.200" fontFamily="Gotham Bold">well-tolerated</Text> and serious side effects are <Text as="span" color="purple.200" fontFamily="Gotham Bold">rare</Text><sup>1,2</sup></Text>}
                    />
                    <Row
                    img={Bottle}
                    imgWidth="140px"
                    content={<Text {...normalText} display="block">Hana <Text as="span" color="purple.200" fontFamily="Gotham Bold">doesn’t protect against sexually transmitted infections</Text> (STIs), only condoms can do this<sup>1</sup></Text>}
                    />
                </Box>
            </Box>

        
            <Text as="u" {...referenceLink} onClick={onOpen}>References</Text>

            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List spacing="4" as="ol" styleType="decimal">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Patient Information Leaflet, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Faculty of Sexual & Reproductive Healthcare Clinical Guidance (FSRH). Progestogen-only Pills. March 2015 (amended April 2019). Available at: <Link href="https://www.fsrh.org/standards-and-guidance/documents/cec-ceu-guidance-pop-mar-2015/" target="_bold" isExternal>https://www.fsrh.org/standards-and-guidance/documents/cec-ceu-guidance-pop-mar-2015/</Link>. Accessed: November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default PharamKeyFacts