import React from 'react'
import { Text, Stack, Heading } from '@chakra-ui/core'

// Load style
import { normalText, headingText} from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const Assessment = ({ changePage }) => {
    return (
        <Layout>
            <Stack spacing="4">
                <Heading {...headingText} color="#2E1C45">
                    OK, now it’s time to test your knowledge and obtain a CPD certificate as evidence.
                </Heading>
                <Text {...normalText}>
                    You have finished the Pharmacy guide. To assess your knowledge of Hana and how to supply it in pharmacy and receive your CPD certificate, we invite you to answer a series of questions and give your opinion on some case studies.
                </Text>
                <Text {...normalText}>
                    There are 15 questions and 5 case studies. You will have access to the SmPC whilst completing the exercise – as you would in your pharmacy.
                </Text>
                <Text { ...normalText}>
                    Scores of 100% will earn a certificate that can be used for CPD and to fulfil Clinical Governance requirements. You can retake the exercise if you do not succeed the first time.
                </Text>
                <Text {...normalText}>
                    <Text as="u" cursor="pointer" fontWeight="bold" onClick={() => changePage('increment')}>Click here</Text> (or next) to start the exercise.
                </Text>
            </Stack>
        </Layout>
    )
}

export default Assessment