import React from 'react'
import { Text, Box, List, ListItem, Link, useDisclosure, Image } from '@chakra-ui/core'

// Load typography
import { normalText, bkglight, referenceText, referenceLink, subTitlelightPurple, purpleBullet } from '../../style/styleFormat'

// Load components
import Modal from '../../components/Modal/Modal'
import Reference from '../../components/UI/Typography/Reference'

// Load assets
import Menstrual from '../../images/menstrualsmall.png'
import MenstrualPDF from '../../pdf/HRA0201_Pharmacy_guide_ Menstrual_Diagram.pdf'

// Load layout
import Layout from '../../layout/Layout.card'


const MenstrualOverview = () => {   
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Layout>
            <Text {...subTitlelightPurple} marginBottom="20px">
                In order to understand how Hana works it is useful to review the menstrual cycle.
            </Text>

            <Box display="flex" flexDirection={{base: "column", lg:"row"}} justifyContent="space-between">
                <Box {...bkglight} marginBottom="0" height="60%" maxWidth={{base: "100%", lg:"48%"}} >
                    <List spacing="4">
                        <ListItem 
                        {...normalText}
                        paddingLeft="20px"
                        position="relative"
                        _before={{...purpleBullet}}>
                            The normal menstrual cycle varies in <span style={{position: 'relative'}}>length<Reference>1</Reference></span>
                        </ListItem>
                        <ListItem
                        {...normalText}
                        paddingLeft="20px"
                        position="relative"
                        _before={{...purpleBullet}}>
                            For a woman with a 28-day cycle, the egg grows through the first 12 days or so, reaching maturity at the time of ovulation (which may vary between individual women and between individual <span style={{position: 'relative'}}>cycles)<Reference>1</Reference></span>
                        </ListItem>
                        <ListItem
                        {...normalText}
                        paddingLeft="20px"
                        position="relative"
                        _before={{
                            ...purpleBullet
                            }}>
                            In the absence of conception, menses occur around 14 days after ovulation when the endometrium is <span style={{position: 'relative'}}>shed <Reference>1</Reference></span>
                        </ListItem>
                    </List>

                    <Link href={MenstrualPDF} target="_blank" download  {...normalText} whiteSpace="wrap" marginTop="40px" textAlign="left" overflow="hidden" fontWeight="bold" border="1px solid #9894C6" backgroundColor="#fff" borderRadius="8px"  display="flex" alignItems="center" justifyContent="center" variant="outline" minHeight="79px" padding="15px" width="full">
                        Click here to download the infographic explaining the menstrual cycle
                    </Link>
                </Box>
                <Box margin={{base: "20px 0", lg:"0"}} width={{base: "100%", lg:"48%"}}>
                    <Image src={Menstrual} width="100%" alt="menstrual cycle diagram" />
                </Box>
            </Box>
            <Text onClick={onOpen} as="u" {...referenceLink}>References</Text>

            <Modal isOpen={isOpen} onClose={onClose} size='xl' title="References">
                <List as="ol" spacing="4" styleType="decimal">
                    <ListItem {...referenceText}>
                        Aitken RJ, Baker MA, Doncel GF, <em>et al</em>. As the world grows: contraception in the 21st century. J Clin Invest 2008;118(4):1330-1343.
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default MenstrualOverview