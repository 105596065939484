import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

export const CaseFourNoYes = () => <Text {...normalText}>
You should <Text as="span" {...boldGreen}>not supply</Text> Hana to this woman, but <Text as="span" {...boldGreen}>refer her to her doctor</Text> to get advice on her sustained high blood pressure and to decide whether Hana should be discontinued, provided she has enough Hana to use until she will be seen. If she does not have enough Hana, advise her to use a reliable barrier method (such as condoms) in the interim. If the doctor says her blood pressure is fine, advise her to come back, when the pharmacist will be happy to supply her with Hana.
</Text>

export const CaseFourNoNo = () => <Text {...normalText}>
You should <Text as="span" {...boldRed}>not supply</Text> Hana to this woman, but <Text as="span" {...boldRed}>refer her to her doctor</Text> to get advice on her sustained high blood pressure and to decide whether Hana should be discontinued, provided she has enough Hana to use until she will be seen. If she does not have enough Hana, advise her to use a reliable barrier method (such as condoms) in the interim. If the doctor says her blood pressure is fine, advise her to come back, when the pharmacist will be happy to supply her with Hana.
</Text>

export const CaseFourYesNo = () => <Text {...normalText}>
You should <Text as="span" {...boldRed}>not supply</Text> Hana to this woman, but <Text as="span" {...boldRed}>refer her to her doctor</Text> to get advice on her sustained high blood pressure and to decide whether Hana should be discontinued, provided she has enough Hana to use until she will be seen. If she does not have enough Hana, advise her to use a reliable barrier method (such as condoms) in the interim. If the doctor says her blood pressure is fine, advise her to come back, when the pharmacist will be happy to supply her with Hana.
</Text>

export const CaseFourYesYes = () => <Text {...normalText}>
You should <Text as="span" {...boldRed}>not supply</Text> Hana to this woman, but <Text as="span" {...boldRed}>refer her to her doctor</Text> to get advice on her sustained high blood pressure and to decide whether Hana should be discontinued, provided she has enough Hana to use until she will be seen. If she does not have enough Hana, advise her to use a reliable barrier method (such as condoms) in the interim. If the doctor says her blood pressure is fine, advise her to come back, when the pharmacist will be happy to supply her with Hana.
</Text>