import React from 'react'
import { Text, Stack, Button, Link } from '@chakra-ui/core'

// Load components
import uploadPregnancyTool from '../../pdf/Pregnancy_exclusion_tool.pdf'

// Load style
import { secondaryText,} from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const ExcludingPregnancyTwo = () => {
    return (
        <Layout>
            <Stack spacing="4">
                <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                    To support you in excluding pregnancy in women requesting a first-time supply of Hana in pharmacy, a Pregnancy exclusion tool has been developed for you to have within easy reach.
                </Text>
                <Text {...secondaryText}>
                    This tool consists of useful decision trees, detailing questions to ask and responses to give, for women who have not recently been pregnant, have delivered or have had a miscarriage, abortion or ectopic pregnancy.
                </Text>
                <Text {...secondaryText}>
                    The Pregnancy exclusion tool is included as an appendix in the printed/printable version of the guide and has also been distributed to all pharmacies with the Pharmacy supply aid checklist. Use of this material is optional, and you are advised to use your professional judgement to decide when and how to use it.
                </Text>
                <Button fontWeight="bold" borderRadius="4px" backgroundColor="#3E7DAA" height="78px" fontSize="sm" width={{base: "100%", lg:"50%"}} whiteSpace="normal" color="#fff">
                    <Link href={uploadPregnancyTool} target="_blank" rel="noopener noreferrer" download>Click here to download the Pregnancy exclusion tool</Link>
                </Button>
            </Stack>
        </Layout>
    )
}

export default ExcludingPregnancyTwo