import React, { Fragment, useState } from 'react'
import { Box, Text, List, ListItem, useDisclosure, Stack, Divider } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import {ReactComponent as People } from '../../images/peopleCross.svg'
import {ReactComponent as Doctor } from '../../images/doctor.svg'
import {ReactComponent as Woman } from '../../images/Group 17.svg'
import {ReactComponent as Liver } from '../../images/Group 21.svg'
import {ReactComponent as Unexplained } from '../../images/Group 23.svg'
import {ReactComponent as Star } from '../../images/Group 31.svg'
import {ReactComponent as Vein } from '../../images/Group 27.svg'
import {ReactComponent as Molecule } from '../../images/Group 43.svg'

import {ReactComponent as MoleculeAlt } from '../../images/moleculeAlt.svg'
import {ReactComponent as LiverProblem } from '../../images/liverProblems.svg'
import {ReactComponent as Diabetes } from '../../images/diabetes.svg'
import {ReactComponent as Medication } from '../../images/medicationBottle.svg'
import {ReactComponent as BloodClot } from '../../images/bloodClot.svg'
import {ReactComponent as HighBlood } from '../../images/highBlood.svg'
import {ReactComponent as HeadChloasma } from '../../images/headChloasma.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { normalText, referenceText, referenceLink } from '../../style/styleFormat'

const Row = ({ svg: SVG, content }) => {
    return (
        <Box display="flex" alignItems="center" marginBottom="40px">
            <Box marginRight="35px" width="60px">
                <SVG width="60px" />
            </Box>
            <Box>
                {content}
            </Box>
        </Box>
    )
}

const ContrainHanaPop = () => {
    return (
        <Fragment>
        <Text {...normalText} marginBottom="20px">
            Hana should <Text as="span" color="purple.200" fontFamily="Gotham Bold">not be used</Text> in women who:<sup>1</sup>
        </Text>
        <Box display="flex" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between">
            <Box width={{base: "100%", lg:"45%"}}>
                <Row
                svg={Star}
                content={<Text {...normalText}>have cancer that is affected by sex-steroid hormones, such as certain types of breast cancer, ovarian cancer, and endometrial (uterine) cancer</Text>}
                />
                <Row 
                svg={Vein}
                content={<Text {...normalText}>have a blood clot in the legs (deep vein thrombosis) or lungs (pulmonary embolism)</Text>}
                />
                <Row 
                svg={Liver}
                content={<Text {...normalText}>have, or have had, jaundice (yellowing of the skin or whites of the eyes) or severe liver diseases and blood tests show their liver has not returned to normal</Text>}
                />
            </Box>
            <Box width={{base: "100%", lg:"45%"}}>
                <Row
                svg={Unexplained}
                content={<Text {...normalText}>have any unexplained vaginal bleeding between their periods, or if they repeatedly have bleeding after sex</Text>}
                />
                <Row
                svg={Molecule}
                content={<Text {...normalText} display="block">are allergic to desogestrel or other ingredients of Hana (e.g. lactose)*</Text>}
                />
                <Row
                svg={Woman}
                content={<Text {...normalText} display="block">are pregnant or think they may be pregnant</Text>}
                />
            </Box>
        </Box>
        <Text {...normalText}>
            * Hana contains lactose monohydrate, alpha-tocopherol, maize starch, Povidone K25, stearic acid, hypromellose, macrogol 400 and titanium dioxide (E 171).<sup>1</sup>
        </Text>
        </Fragment>
    )
}



const ReferencePop = () => {
    return (
        <List as="ol" styleType="decimal">
            <ListItem {...referenceText}>
                Hana (desogestrel) Patient Information Leaflet, November 2020.
            </ListItem>
        </List>
    )
}

const WarningPop = () => {
    return (
        <Box display="flex" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between">
            <Box width={{base: "100%", lg:"45%"}}>
                <Text {...normalText} marginBottom="20px">
                    A <Text as="span" color="purple.200" fontFamily="Gotham Bold">doctor's referral</Text> may be needed in women who:<sup>1</sup>
                </Text>
                <Row
                svg={MoleculeAlt}
                content={<Text {...normalText}>have had cancer that is affected by sex-steroid hormones, such as certain types of breast cancer, ovarian cancer, and endometrial (uterine) cancer</Text>}
                />
                <Row 
                svg={LiverProblem}
                content={<Text {...normalText}>have liver cancer or other liver problems</Text>}
                />
                <Row 
                svg={Diabetes}
                content={<Text {...normalText}>have diabetes</Text>}
                />
                <Row 
                svg={Medication}
                content={<Text {...normalText}>are on long-term treatment with medicines or herbal remedies that interact with Hana, including some medicines for epilepsy and tuberculosis</Text>}
                />
            </Box>
            <Divider display={{base: "none", lg: "block"}} height="auto" opacity="1" width="5px" orientation="vertical" color="#393767" />
            <Box width={{base: "100%", lg:"45%"}} >
                <Text {...normalText} marginBottom="20px">
                    <Text as="span" color="purple.200" fontFamily="Gotham Bold">Specific advice</Text> may be needed in women who have:<sup>1</sup>
                </Text>
                <Row
                svg={BloodClot}
                content={<Text {...normalText}>had a blood clot in the legs (deep vein thrombosis) or lungs (pulmonary embolism)</Text>}
                />
                <Row
                svg={HighBlood}
                content={<Text {...normalText} display="block">high blood pressure</Text>}
                />
                <Row
                svg={HeadChloasma}
                content={<Text {...normalText} display="block">chloasma (yellowish-brown pigmentation patches on the skin, particularly of the face)</Text>}
                />
            </Box>
        </Box>
    )
}

const PharamaHanaImportant = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [content, setContent] = useState('')

    let modalContent = null

    switch (content) {
        case 'References':
            modalContent = <ReferencePop />
            break
        case 'Contraindications to Hana':
            modalContent = <ContrainHanaPop />
            break
        case 'Warnings and precautions for Hana':
            modalContent = <WarningPop />
            break
        default:
            modalContent = <ReferencePop />
    }

    function setModal(content) {
        setContent(content)
        onOpen()
    }
    return (
        <Layout>
            <Stack spacing={4}>
            <Text {...normalText}>
                Hana may not be suitable for some women, and it’s important to know about the circumstances in which it may not be appropriate. A checklist is also available to help determine suitability; more information on this on the next screen.
            </Text>
            <Text {...normalText}>
            Once the woman has completed the checklist, you may see that she’s selected some “red box” answers that indicate that she may not be suitable for Hana. In this case, it’s important to understand the situations in which Hana should <Text as="span" color="purple.200" fontFamily="Gotham Bold">not be used</Text>, as well as those in which a <Text as="span" color="purple.200" fontFamily="Gotham Bold">doctor’s referral or specific advice</Text> before supply may be needed.<sup>1</sup>
            </Text>

            <Box display="flex" flexDirection={{base: "column", lg: "row"}} alignItems="flex-start" justifyContent="space-between" maxWidth={{base: "100%", lg:"70%"}} marginTop="20px" margin="0 auto">
                <Box width={{base:"100%", lg:"45%"}}>
                    <Box display="flex" alignItems="center" justifyContent="center" marginBottom="20px">
                        <People />
                    </Box>
                    <Text {...normalText} fontFamily="Gotham Bold" textAlign="center" marginTop="10px">
                        <Text as="u" cursor="pointer" fontFamily="inherit" onClick={() => setModal('Contraindications to Hana')}>Click here</Text> for situations in which Hana should not be used
                    </Text>
                </Box>

                <Box width={{base:"100%", lg:"45%"}} marginTop={{base:"30px", lg: "0"}}>
                    <Box display="flex" alignItems="center" justifyContent="center"  height="110px" marginBottom="20px">
                        <Doctor />
                    </Box>
                    <Text {...normalText} textAlign="center"  fontFamily="Gotham Bold" marginTop="10px">
                        <Text as="u" cursor="pointer" fontFamily="inherit" onClick={() => setModal('Warnings and precautions for Hana')}>Click here</Text> for situations that may need a doctor’s referral or specific advice
                    </Text>
                </Box>
            
            </Box>

            
        
            <Text as="u" {...referenceLink} onClick={() => setModal('References')} >References</Text>

            <Modal isOpen={isOpen} onClose={onClose} size={content === 'References' ? "xl" : "lg-custom"} title={content}>
                {modalContent}
            </Modal>
            </Stack>
        </Layout>
    )
}

export default PharamaHanaImportant