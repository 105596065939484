import React from 'react'
import { Box, Heading, PseudoBox, Flex } from '@chakra-ui/core'

// Load assetss
import {ReactComponent as SVG} from '../../images/part1Altnew.svg'

const textStyle = {
    fontSize: "md",
    lineHeight: "wide",
    display: "block",
    color: "#fff",
}

const LearningOutcome = ({number, content, borderStyle }) => {

    return (
        <Box display="flex" justify="space-between" alignItems="center" marginBottom="20px">
            <Heading as="h2" fontSize="xlg" color="#fff" lineHeight="widest" width="25px" marginRight="5">
                {number}
            </Heading>
            <Box width="100%">
                <PseudoBox 
                as="p" {...textStyle} 
                position="relative"
                padding="10px 0"
                _after={borderStyle && {
                    content: "''",
                    position: 'absolute',
                    width: "100%",
                    bottom: '-15px',
                    left: '0',
                    border: "1px dashed #fff"
                }}
                >
                    {content}
                </PseudoBox>
            </Box>
        </Box>
    )
}

const LearningOutComesPt1 = () => {
    return (
        <Flex align="center" direction="column">
            <SVG width={{base: "100%", lg:"400px"}} />
            <Box>
                <Heading fontSize="xlg" color="#fff" textTransform="uppercase" lineHeight="widest" margin="25px 0">
                    Learning outcomes at a glance
                </Heading>
            
                <LearningOutcome
                number={1}
                content="Understand the basic elements of the menstrual cycle"
                borderStyle
                />
                <LearningOutcome
                number={2}
                content="Know the key facts about Hana and understand how it works"
                borderStyle
                />
                <LearningOutcome
                number={3}
                content="Understand how effective Hana is and how it needs to be taken for maximum effectiveness"
                borderStyle
                />
                <LearningOutcome
                number={4}
                content="Know the contraindications and warnings for Hana and know when to refer to doctor"
                />
            </Box>
        </Flex>
    )
}

export default LearningOutComesPt1