import React, { useState, useEffect } from 'react'
import { Box, Text, Heading, ListItem } from '@chakra-ui/core'

// Load svg
import {ReactComponent as Cross} from '../../images/cancel-2.svg'
import {ReactComponent as Tick} from '../../images/tick-2.svg'

// DnD
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// Load style
import { normalText, bkglight, headingText} from '../../style/styleFormat'

// Load layout
import Layout from '../../layout/Layout.card'

const content = {
    initial: [
        {
            name: 'Pregnancy',
            answer: false,
            id: 'wrong-1'
        },
        {
            name: 'Unable to take oestrogens',
            answer: true,
            id: 'correct-1'
        },
        {
            name: 'Breastfeeding',
            answer: true,
            id: 'correct-2'
        },
        {
            name: 'Aged <18 years',
            answer: true,
            id: 'correct-3'
        },
        {
            name: 'Lactose intolerance',
            answer: false,
            id: 'wrong-2'
        },
        {
            name: 'Allergy to desogestrel',
            answer: false,
            id: 'wrong-3'
        },
        {
            name: 'No previous pregnancy',
            answer: true,
            id: 'correct-4'
        },
        {
            name: 'A current blood clot in the legs (venous thrombosis) or lungs (pulmonary embolism)',
            answer: false,
            id: 'wrong-4'
        },
        {
            name: 'Current sex-steroid sensitive malignancies such as certain types of breast cancer',
            answer: false,
            id: 'wrong-5'
        },
        {
            name: 'Severe liver disorders where the liver function values have not returned to normal',
            answer: false,
            id: 'wrong-6'
        },
        {
            name: 'A blood clot in the legs (venous thrombosis) or lungs (pulmonary embolism) in the past',
            answer: true,
            id: 'correct-5'
        }
    ],
    correct: [],
    wrong: []
}

// Reorder list
function reorder(list, startIndex, endIndex) {
   
    // Reordered the same list
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
  
    return result
}

// Move items in list
function move(init, source, destination, droppableSource, droppableDestination) {
    const sourceClone = Array.from(source)
    const destinationClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destinationClone.splice(droppableDestination.index, 0, removed)

    const result = {...init}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destinationClone

    return result

}

// Apply stlye when dragging over drop box
function getListStyle(isDraggingOver) {
 return isDraggingOver ? '.7' : '1'
 
}

const QuestionThreeDrag = ({ reveal, setNext }) => {
    const [list, updateList] = useState(content)
    const allowNext = list.initial.length <= 0
    
    
    useEffect(() => {
        // Allow next is one answer been selected
        setNext(allowNext)
    }, [allowNext, setNext])
    
    // Access list from store
    function getList (id) {
        return list[id]
    }

    // Hanlde onDragEnd data
    function handleDragEnd(result) {
        const { source, destination } = result
       
        // Check we are within drag constraints
        if (!result.destination) {
            return
        }

        // Reorder same list
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            )
             
            let copyState = {...list}
            let listUpdate = {...copyState[source.droppableId]}
            listUpdate = items
            copyState[source.droppableId] = listUpdate


           return updateList(copyState)
        }   
        
        // Move list
        if (source.droppableId !== destination.droppableId) {
            const result = move(
                list,
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            )

            return updateList(result)
        }
    }

    return (
        <Layout>
            <DragDropContext onDragEnd={handleDragEnd}>

                <Heading {...headingText}>
                    In which of these situations should Hana NOT be used?
                </Heading>
                <Text {...normalText} margin="20px 0">
                    Drag your answers into either the <Text as="span" color="purple.900" fontFamily="Gotham Bold">CAN</Text> be used or should <Text as="span" color="purple.900" fontFamily="Gotham Bold">NOT</Text> be used box
                </Text>
                <Box width="100%">
                <Droppable droppableId="initial">
                {(provided) => (
                    <Box as="ul" textDecoration="none" listStyleType="none" {...provided.droppableProps}  ref={provided.innerRef} spacing="4" display="flex" justifyContent="flex-start" flexWrap="wrap">
                    {list['initial'].map((item, index) => {
                        return (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                                    <ListItem
                                    listStyleType="none"
                                    textDecoration="none"
                                    {...bkglight}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    padding="10px"
                                    textAlign="center"
                                    maxWidth={{base: "100%", lg: "290px"}}
                                    width={{base: "100%", lg: "auto"}}
                                    marginRight="10px"
                                    minHeight="60px"
                                    display="flex"
                                    alignItems="center"        
                                    >
                                    <Text {...normalText} fontSize="12px">
                                        {item.name}
                                    </Text>
                                </ListItem>
                            )}
                            </Draggable>
                        )
                    })} 
                    {provided.placeholder}
                    </Box>            
                )}
                </Droppable>
            </Box>

            <Box width="100%" height={{base: "800px", lg:"500px"}}  display="flex" flexDirection={{base: "column", lg: "row"}} justifyContent="space-between" alignItems="center">
                <Droppable droppableId="correct">
                {(provided, snapshot) => (
                    <Box height="100%" overflowY="auto" width={{base: "100%", lg: "48%"}}{...bkglight} opacity={getListStyle(snapshot.isDraggingOver)}  {...provided.droppableProps}  ref={provided.innerRef}>
                        <Text {...normalText} color="green.900" marginBottom="20px">
                            Hana <Text as="span" fontWeight="bold">CAN</Text> be used in:
                        </Text>
                    <Box as="ul" textDecoration="none" listStyleType="none" spacing="4">
                    {list['correct'].length > 0 && list['correct'].map((item, index) => {
                        return (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided) => (
                                    <ListItem
                                    textDecoration="none"
                                    listStyleType="none"
                                    {...bkglight}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    padding="10px"
                                    textAlign="center"
                                    maxWidth="100%"
                                    marginRight="10px"
                                    minHeight="60px"
                                    display="flex"
                                    alignSelf="center"
                                    alignItems="center"
                                    borderColor={(!reveal) ? "#9894C6" : (reveal && !item.answer)  ? "red.900" : "green.900"}

                                    >
                                    <Text {...normalText} width="100%" fontSize="12px" display="flex" alignItems="center" justifyContent="space-between"> 
                                        {item.name}
                                       {(reveal && !item.answer) &&
                                        <Box as="span" width="10px">
                                            <Cross width="15px" height="15px" />
                                       </Box>
                                       }
                                       {(reveal && item.answer) &&
                                        <Box as="span" width="10px">
                                            <Tick width="15px" height="15px" />
                                       </Box>
                                       }
                                    </Text>
                                </ListItem>
                            )}
                            </Draggable>
                        )
                    })} 
                    {provided.placeholder}
                    </Box>
                </Box>
                )}
            </Droppable>

            <Droppable droppableId="wrong">
            {(provided, snapshot) => (
                <Box height="100%" overflowY="auto" width={{base: "100%", lg: "48%"}} {...bkglight} opacity={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}  ref={provided.innerRef}>
                <Text {...normalText} color="red.900" marginBottom="20px">
                    Hana should <Text as="span" fontWeight="bold">NOT</Text> be used in:
                </Text>
                <Box as="ul" textDecoration="none" listStyleType="none" spacing="4">
                {list['wrong'].length > 0 && list['wrong'].map((item, index) => {
                    return (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                                <ListItem
                                textDecoration="none"
                                listStyleType="none"
                                {...bkglight}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                padding="10px"
                                textAlign="center"
                                maxWidth="100%"
                                marginRight="10px"
                                minHeight="60px"
                                display="flex"
                                alignSelf="center"
                                alignItems="center"
                                borderColor={(!reveal) ? "#9894C6" : (reveal && item.answer)  ? "red.900" : "green.900"}

                                >
                                <Text {...normalText} width="100%" fontSize="12px" display="flex" alignItems="center" justifyContent="space-between">
                                    {item.name}
                                    {(reveal && item.answer) &&
                                    <Box as="span" width="10px">
                                        <Cross width="15px" height="15px" />
                                    </Box>
                                    }
                                    {(reveal && !item.answer) &&
                                        <Box as="span" width="10px">
                                            <Tick width="15px" height="15px" />
                                       </Box>
                                    }
                                </Text>
                            </ListItem>
                        )}
                        </Draggable>
                    )
                })} 
                {provided.placeholder}
                </Box>
            </Box>
            )}
        </Droppable>
        </Box>
        
        </DragDropContext>
    </Layout>
    )
}

export default QuestionThreeDrag
