import React from 'react'
import { Box, Text, ListItem, List, useDisclosure } from '@chakra-ui/core'

// Load components
import Modal from '../../components/Modal/Modal'
import Reference from '../../components/UI/Typography/Reference'
import {ReactComponent as Info} from '../../images/infoAlt.svg'

// Load layout
import Layout from '../../layout/Layout.card'

// Load style
import { secondaryText, referenceText, bkLightRed, blueBullet, subBullet, referenceLink } from '../../style/styleFormat'

const AdviceTakingHanaFive = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Layout>
            <Text {...secondaryText} fontFamily="Gotham Bold" margin="10px 0" fontWeight="bold">
            There are also some non-urgent warning signs that you should be aware of in case a woman reports these symptoms while using Hana. All require medical assessment and are listed in the PIL.
            </Text>
            <List spacing="4">
                <ListItem 
                {...secondaryText}
                paddingLeft="25px"
                position="relative"
                display="block"
                _before={{
                   ...blueBullet
                    }}>
                    Changes to the breasts, including breast discharge, breast pain or feeling a <span style={{position: 'relative'}}>lump &nbsp;<Reference>1,2</Reference></span>
                </ListItem>
                <ListItem 
                {...secondaryText}
                paddingLeft="25px"
                position="relative"
                display="block"
                _before={{
                  ...blueBullet
                    }}>
                    Changes in bleeding patterns are common with Hana and women may be happy to accommodate them<span style={{position: "relative"}}>. <Reference>1,2</Reference></span>&nbsp; However, there are some specific types of bleeding that might have an underlying pathology; if women experience the following changes in their bleeding pattern during use, they should be referred to their doctor for investigation<span style={{position: 'relative'}}>:<Reference>1</Reference></span>
                </ListItem>
                <ListItem 
                {...secondaryText}
                paddingLeft="50px"
                position="relative"
                display="block"
                _before={{
                   ...subBullet,
                    }}>
                    bleeding changes that differ from that which would be expected with Hana or bleeding that is unusually heavy
                </ListItem>
                <ListItem 
                {...secondaryText}
                paddingLeft="50px"
                position="relative"
                display="block"
                _before={{
                   ...subBullet,
                    }}>
                    repeated vaginal bleeding after sex
                </ListItem>
                <ListItem 
                {...secondaryText}
                paddingLeft="25px"
                position="relative"
                display="block"
                _before={{
                    ...blueBullet
                    }}>
                    Mood changes and depression are known side effects of hormonal contraceptive <span style={{position: 'relative'}}>use<Reference>1</Reference></span>
                </ListItem>
                <ListItem 
                {...secondaryText}
                paddingLeft="50px"
                position="relative"
                display="block"
                _before={{
                   ...subBullet
                    }}>
                    Depression can be serious, and women should see their doctor if they experience mood changes or symptoms of <span style={{position: 'relative'}}>depression<Reference>1</Reference></span>
                </ListItem>
                <ListItem 
                {...secondaryText}
                paddingLeft="25px"
                position="relative"
                display="block"
                _before={{
                   ...blueBullet
                    }}>
                    Sustained increase in blood pressure (sustained hypertension) or high blood pressure that is not adequately responding to antihypertensive therapy as incidental findings (pharmacists are not expected to check/monitor blood pressure prior to or during supply<span style={{position: 'relative'}}>)<Reference>1</Reference></span>
                </ListItem>
            </List>
            <Text {...secondaryText} margin="20px 0">
                These situations may arise during use of Hana, but are not necessarily related to Hana.
            </Text>
        
            <Box {...bkLightRed} padding="8" margin="20px 0" maxWidth={{base: "100%", lg:"70%"}} position="relative">
                <Box position="absolute" top="-6px" left="-10px">
                    <Info name="info" borderRadius="50%" border="2px solid #fff" backgroundColor="#fff" color="#F0CED5" size="40px" />
                </Box>
                <Text {...secondaryText} fontFamily="Gotham Bold" fontWeight="bold">
                    Remind every woman to read the PIL carefully before taking Hana.
                </Text>
            </Box>

            <Text {...referenceLink} fontFamily="Gotham Bold" color="blue.50" fontWeight="bold" cursor="pointer" as="u" onClick={onOpen}>
                References
            </Text>
            <Modal isOpen={isOpen} onClose={onClose} title="References">
                <List as="ol" styleType="decimal" spacing="4">
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Summary of Product Characteristics, November 2020.
                    </ListItem>
                    <ListItem {...referenceText}>
                        Hana (desogestrel) Patient Information Leaflet, November 2020
                    </ListItem>
                </List>
            </Modal>
        </Layout>
    )
}

export default AdviceTakingHanaFive