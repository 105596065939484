import React from 'react'
import { Text } from '@chakra-ui/core'
import { normalText } from '../../../style/styleFormat'

const boldRed = {
    color: "red.900",
    fontFamily: "Gotham Bold"
}

const boldGreen = {
    color: "green.900",
    fontFamily: "Gotham Bold"
}

export const CaseThreeYesNo = () => <Text {...normalText}>
<Text as="span" {...boldGreen}>You can supply</Text> Hana to this woman, 3 months for a first-time supply. She has no contraindications and there is <Text as="span" {...boldGreen}>no need for a doctor referral.</Text> <br/><br/>Advise her to start taking it straight away. If she takes it later than day 21 after the birth, she will need to take extra precautions for the first 7 days. If she has had unprotected sex, she should take a pregnancy test or see her doctor. <br/><br/>You can advise her that she can take this pill even though she is breastfeeding. Hana does not appear to influence the production or the quality of breast milk. However, there have been infrequent postmarketing reports of a decrease in breast milk production while using desogestrel. Although the development and growth of the breastfed newborn should be carefully observed, no effect of desogestrel in breastfed newborns/infants has been shown.
</Text>

export const CaseThreeYesYes = () => <Text {...normalText}>
<Text as="span" {...boldRed}>You can supply</Text> Hana to this woman, 3 months for a first-time supply. She has no contraindications and there is <Text as="span" {...boldRed}>no need for a doctor referral.</Text> <br/><br/>Advise her to start taking it straight away. If she takes it later than day 21 after the birth, she will need to take extra precautions for the first 7 days. If she has had unprotected sex, she should take a pregnancy test or see her doctor. <br/><br/>You can advise her that she can take this pill even though she is breastfeeding. Hana does not appear to influence the production or the quality of breast milk. However, there have been infrequent postmarketing reports of a decrease in breast milk production while using desogestrel. Although the development and growth of the breastfed newborn should be carefully observed, no effect of desogestrel in breastfed newborns/infants has been shown.
</Text>

export const CaseThreeNoYes = () => <Text {...normalText}>
<Text as="span" {...boldRed}>You can supply</Text> Hana to this woman, 3 months for a first-time supply. She has no contraindications and there is <Text as="span" {...boldRed}>no need for a doctor referral.</Text> <br/><br/>Advise her to start taking it straight away. If she takes it later than day 21 after the birth, she will need to take extra precautions for the first 7 days. If she has had unprotected sex, she should take a pregnancy test or see her doctor. <br/><br/>You can advise her that she can take this pill even though she is breastfeeding. Hana does not appear to influence the production or the quality of breast milk. However, there have been infrequent postmarketing reports of a decrease in breast milk production while using desogestrel. Although the development and growth of the breastfed newborn should be carefully observed, no effect of desogestrel in breastfed newborns/infants has been shown.
</Text>

export const CaseThreeNoNo = () => <Text {...normalText}>
<Text as="span" {...boldRed}>You can supply</Text> Hana to this woman, 3 months for a first-time supply. She has no contraindications and there is <Text as="span" {...boldRed}>no need for a doctor referral.</Text> <br/><br/>Advise her to start taking it straight away. If she takes it later than day 21 after the birth, she will need to take extra precautions for the first 7 days. If she has had unprotected sex, she should take a pregnancy test or see her doctor. <br/><br/>You can advise her that she can take this pill even though she is breastfeeding. Hana does not appear to influence the production or the quality of breast milk. However, there have been infrequent postmarketing reports of a decrease in breast milk production while using desogestrel. Although the development and growth of the breastfed newborn should be carefully observed, no effect of desogestrel in breastfed newborns/infants has been shown.
</Text>